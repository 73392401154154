import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import { Dropdown } from 'react-bootstrap';

import {
    useGetRequisitionOrdersQuery,
    useApproveRequisitionOrderMutation,
    useRejectRequisitionOrderMutation,
    useCreateRequisitionOrderMutation,
    useUpdateRequisitionOrderMutation,
    useDeleteRequisitionOrderMutation,
    useGetCompanySuppliersQuery,
    useGetCompanyEmployeesQuery

} from '../../../Redux/apiSlice';
import { strings } from '../../../strings/strings';
import util from '../../../util';
import Spinner from '../../../components/shared/Spinner';
import PurchaseRequisitionModal from './PurchaseRequisitionModal';
import { toast } from 'react-toastify';
import CustomPagination from '../../../components/shared/OxygenPagination';
import ApprovalPopupModal from '../general/ApprovalPopupModal';
import RejectPopup from '../general/RejectPopup';

function PurchaseRequisitions() {
    const getStatusBadge = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-secondary';
            case 'pending':
                return 'bg-info';
            case 'pendings':
                return 'bg-warning';
            case 'partial':
                return 'bg-primary';
            case 'approved':
                return 'bg-success';
            case 'rejected':
                return 'bg-warning';
            default:
                return 'bg-secondary';
        }
    };
    // get company id from redux store
    const { _id: companyId } = useSelector((state) => state.company);

    //get employee id from redux store
    const { _id: employeeId } = useSelector((state) => state.employee);

    // get purchase requisitions from API
    const {
        data: purchaseRequisitions,
        isLoading,
        refetch: refetchPurchaseRequisitions,
    } = useGetRequisitionOrdersQuery(companyId);

    const {
        data: companyEmployees,
        error: companyEmployeesError,
        isLoading: companyEmployeesLoading,
        refetch: refetchCompanyEmployees,
    } = useGetCompanyEmployeesQuery(companyId);

    // get suppliers from API
    const {
        data: suppliers,
        isLoading: isLoadingSuppliers,
        refetch: refetchSuppliers,
    } = useGetCompanySuppliersQuery(companyId);

    // approve purchase requisition
    const [approvePurchaseRequisition, approvePurchaseRequisitionResult] =
        useApproveRequisitionOrderMutation();

    // reject purchase requisition
    const [rejectPurchaseRequisition, rejectPurchaseRequisitionResult] =
        useRejectRequisitionOrderMutation();

    // create purchase requisition
    const [createPurchaseRequisition, createPurchaseRequisitionResult] =
        useCreateRequisitionOrderMutation();

    // update purchase requisition
    const [updatePurchaseRequisition, updatePurchaseRequisitionResult] =
        useUpdateRequisitionOrderMutation();

    // delete purchase requisition
    const [deletePurchaseRequisition, deletePurchaseRequisitionResult] =
        useDeleteRequisitionOrderMutation();

    // set state for displaying modal
    const [showModal, setShowModal] = useState(false);

    //set state for approving purchase requisition
    const [showApproveModal, setShowApproveModal] = useState(false);

    //set state for rejecting purchase requisition
    const [showRejectModal, setShowRejectModal] = useState(false);

    // set state for rejection reason
    const [rejectionReason, setRejectionReason] = useState('');

    // set state for selected purchase requisition when editing
    const [selectedPurchaseRequisition, setSelectedPurchaseRequisition] = useState({});

    // set state for search query
    const [searchQuery, setSearchQuery] = useState('');

    // set state for filtered purchase requisitions
    const [filteredPurchaseRequisitions, setFilteredPurchaseRequisitions] = useState([]);

    // set state for selected supplier
    const [selectedSupplier, setSelectedSupplier] = useState(null);

    // set state for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    //filters 

    const [createdByFilter, setAssignedToFilter] = useState('Anyone');
    const [createdByOptions, setAssignedToOptions] = useState([]);

    // handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Perform any other logic or data fetching based on the new page
    };

    // handle page size change
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        // Perform any other logic or data fetching based on the new page size
    };

    //handle approve purchase requisition modal close
    const handleApprovePurchaseRequisitionModalClose = () => {
        setSelectedPurchaseRequisition({});
        setShowApproveModal(false);
    };

    //handle approve purchase requisition modal show
    const handleApprovePurchaseRequisitionModalShow = (purchaseRequisition) => (e) => {
        e.preventDefault();
        setSelectedPurchaseRequisition(purchaseRequisition);
        setShowApproveModal(true);
    };

    //handle reject purchase requisition modal close
    const handleRejectPurchaseRequisitionModalClose = () => {
        setSelectedPurchaseRequisition({});
        setShowRejectModal(false);
    };

    //handle reject purchase requisition modal show
    const handleRejectPurchaseRequisitionModalShow = (purchaseRequisition) => (e) => {
        e.preventDefault();
        setSelectedPurchaseRequisition(purchaseRequisition);
        setShowRejectModal(true);
    };

    // handle approve purchase requisition
    const handleApprovePurchaseRequisition = () => {
        approvePurchaseRequisition({
            requisition_order: selectedPurchaseRequisition._id,
            employee: employeeId,
        });
    };

    // handle rejection reason change
    const handleRejectionReasonChange = (reason) => {
        setRejectionReason(reason);
    };

    // handle reject purchase requisition
    const handleRejectPurchaseRequisition = () => {
        rejectPurchaseRequisition({
            requisition_order: selectedPurchaseRequisition._id,
            employee: employeeId,
            reason: rejectionReason,
        });
    };

    const pageSizes = [25, 50, 100, 500];

    // handle close purchase requisition modal
    const handlePurchaseRequisitionModalClose = () => {
        setSelectedPurchaseRequisition({});
        setShowModal(false);
    };

    // handle show purchase requisition modal for creating new purchase requisition
    const handlePurchaseRequisitionModalShow = () => {
        setSelectedPurchaseRequisition({});
        setShowModal(true);
    };

    // handle show purchase requisition modal for editing purchase requisition
    const handleEditPurchaseRequisition = (purchaseRequisition) => (e) => {
        e.preventDefault();
        setSelectedPurchaseRequisition({
            ...purchaseRequisition,
            supplier: purchaseRequisition.supplier?._id,
            employee: purchaseRequisition.employee?._id,
        });
        setShowModal(true);
    };

    // handle search query change
    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // handle supplier selection
    const handleSupplierSelection = (supplier) => {
        setSelectedSupplier(supplier);
    };

    // handle filter by supplier
    const handleFilterBySupplier = () => {
        if (selectedSupplier) {
            setFilteredPurchaseRequisitions(
                purchaseRequisitions.filter((pr) => pr.supplier?._id === selectedSupplier.value)
            );
        } else {
            setFilteredPurchaseRequisitions(purchaseRequisitions);
        }
    };

    // handle filter by search query
    const handleFilterBySearchQuery = () => {
        if (searchQuery) {
            const filteredRequisitions = purchaseRequisitions.filter(
                (pr) =>
                    pr.purchase_order_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    pr.supplier?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    pr.created_by?.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    pr.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredPurchaseRequisitions(filteredRequisitions);
        } else {
            setFilteredPurchaseRequisitions(purchaseRequisitions);
        }
    };

    // handle filter by supplier and search query
    const handleFilter = () => {
        handleFilterBySupplier();
        handleFilterBySearchQuery();
    };

    // refresh purchase requisitions after creating, editing, or deleting a purchase requisition
    const handlePurchaseRequisitionModalHide = () => {
        refetchPurchaseRequisitions();
        setShowModal(false);
    };

    // create or update purchase requisition
    const handleCreateOrUpdatePurchaseRequisition = (data) => {
        if (data._id) {
            // Update purchase requisition
            // TODO: Implement update logic
            console.log('Update purchase requisition:', data);
        } else {
            // Create purchase requisition
            createPurchaseRequisition(data);
        }
    };

    // handle delete purchase requisition
    const handleDeletePurchaseRequisition = (purchaseRequisition) => (e) => {
        e.preventDefault();
        deletePurchaseRequisition(purchaseRequisition._id);
        console.log('Delete purchase requisition:', purchaseRequisition);
    };

    useEffect(() => {
        console.log('purchaseRequisitions:', purchaseRequisitions);
        handleFilter();
    }, [purchaseRequisitions, selectedSupplier, searchQuery]);

    useEffect(() => {
        if (createPurchaseRequisitionResult.isSuccess) {
            toast.success('Purchase requisition created successfully');
            handlePurchaseRequisitionModalHide();
        }
        if (createPurchaseRequisitionResult.error) {
            toast.error('Error creating purchase requisition');
        }
        if (rejectPurchaseRequisitionResult.data) {
            toast.success('Purchase requisition rejected successfully');
            handleRejectPurchaseRequisitionModalClose();
            refetchPurchaseRequisitions();
        }
        if (rejectPurchaseRequisitionResult.error) {
            toast.error('Error rejecting purchase requisition');
        }
        if (approvePurchaseRequisitionResult.data) {
            toast.success('Purchase requisition approved successfully');
            handleApprovePurchaseRequisitionModalClose();
            refetchPurchaseRequisitions();
        }
        if (approvePurchaseRequisitionResult.error) {
            toast.error('Error approving purchase requisition');
        }
        if (deletePurchaseRequisitionResult.isSuccess) {
            toast.success('Purchase requisition deleted successfully');
            refetchPurchaseRequisitions();
        }

        if (deletePurchaseRequisitionResult.isError) {
            toast.error('Error deleting purchase requisition');
        }

        if (companyEmployees) {
            setAssignedToOptions(companyEmployees);
        }

    }, [
        createPurchaseRequisitionResult.isLoading,
        companyEmployeesLoading,
        rejectPurchaseRequisitionResult.isLoading,
        approvePurchaseRequisitionResult.isLoading,
        deletePurchaseRequisitionResult.isLoading,
    ]);

    if (
        isLoading ||
        isLoadingSuppliers ||
        createPurchaseRequisitionResult.isLoading ||
        rejectPurchaseRequisitionResult.isLoading ||
        approvePurchaseRequisitionResult.isLoading ||
        deletePurchaseRequisitionResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Purchase Requisition'
                handleShow={handlePurchaseRequisitionModalShow}
                headerTitle='PURCHASE REQUISITIONS'
                headerDescription='Manage Purchase Requisitions'
                searchText={searchQuery}
                handleSearch={handleSearchQueryChange}
                handleFilter={handleFilter}
                employeeFilterLable={'Created By'}
                employeeFilters={createdByOptions}
                employeeFilterValue={createdByFilter}
                selectedSupplier={selectedSupplier}
                handleSupplierSelect={handleSupplierSelection}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Requisition No.</th>
                            <th>Supplier</th>
                            <th>Created By</th>
                            <th>Created At</th>

                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseRequisitions &&
                            purchaseRequisitions.map((purchaseRequisition) => (
                                <tr key={purchaseRequisition._id}>
                                    <td>
                                        <span
                                            className={`badge ${getStatusBadge(
                                                purchaseRequisition.status
                                            )} text-white`}>
                                            {purchaseRequisition.status}
                                        </span>
                                    </td>

                                    <td>{purchaseRequisition.order_no}</td>
                                    <td>{purchaseRequisition.supplier?.business_name}</td>
                                    <td>{purchaseRequisition.employee?.first_name}</td>
                                    <td>
                                        <Moment format='YYYY-MM-DD'>
                                            {purchaseRequisition.created}
                                        </Moment>
                                    </td>

                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {/* {purchaseRequisition.status === 'pending' && ( */}
                                                    <Dropdown.Item
                                                        onClick={handleEditPurchaseRequisition(
                                                            purchaseRequisition
                                                        )}>
                                                        View
                                                    </Dropdown.Item>
                                                {/* )} */}
                                                {/* <Dropdown.Item>Preview</Dropdown.Item> */}
                                                {purchaseRequisition.status === 'pending' && (
                                                    <Dropdown.Item
                                                        onClick={handleApprovePurchaseRequisitionModalShow(
                                                            purchaseRequisition
                                                        )}>
                                                        Approve
                                                    </Dropdown.Item>
                                                )}
                                                {purchaseRequisition.status === 'pending' && (
                                                    <Dropdown.Item
                                                        onClick={handleRejectPurchaseRequisitionModalShow(
                                                            purchaseRequisition
                                                        )}>
                                                        Reject
                                                    </Dropdown.Item>
                                                )}
                                                {/* <Dropdown.Item>Submit</Dropdown.Item> */}
                                                <Dropdown.Item onClick={handleDeletePurchaseRequisition(purchaseRequisition)} >Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showModal && (
                <PurchaseRequisitionModal
                    show={showModal}
                    onHide={handlePurchaseRequisitionModalHide}
                    purchaseRequisitionData={selectedPurchaseRequisition}
                />
            )}

            {showApproveModal && (
                <ApprovalPopupModal
                    show={showApproveModal}
                    onHide={handleApprovePurchaseRequisitionModalClose}
                    onApprove={handleApprovePurchaseRequisition}
                    recordName={'Purchase Requisition'}
                />
            )}

            {showRejectModal && (
                <RejectPopup
                    show={showRejectModal}
                    onHide={handleRejectPurchaseRequisitionModalClose}
                    onReject={handleRejectPurchaseRequisition}
                    handleRejectionReasonChange={handleRejectionReasonChange}
                    recordName={'Purchase Requisition'}
                />
            )}
        </div>
    );
}

export default PurchaseRequisitions;
