import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row } from 'react-bootstrap';
import PurchaseRequisitionItems from './PurchaseRequisitionItems';
import { useSelector } from 'react-redux';
import {
    useCreateRequisitionOrderMutation,
    useUpdateRequisitionOrderMutation,
    useGetCompanySuppliersQuery,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';

const PurchaseRequisitionModal = ({ show, onHide, purchaseRequisitionData }) => {
    // Company
    const company = useSelector((state) => state.company);

    //employee

    const employee = useSelector((state) => state.employee);

    // Check if purchase requisition data has been passed
    if (!purchaseRequisitionData._id) {
        purchaseRequisitionData = {
            _id: '',
            company: company._id,
            order_no: '',
            employee: '',
            supplier: '',
            status: 'pending',
        };
    }

    // Get purchase requisition items
    const [purchaseRequisition, setPurchaseRequisition] = useState(purchaseRequisitionData);

    // Create purchase requisition
    const [createPurchaseRequisition, createPurchaseRequisitionResult] =
        useCreateRequisitionOrderMutation();

    // Update purchase requisition
    const [updatePurchaseRequisition, updatePurchaseRequisitionResult] =
        useUpdateRequisitionOrderMutation();

    // Get suppliers

    const {
        data: suppliers,
        error: suppliersError,
        isLoading: suppliersLoading,
        refetch: refetchSuppliers,
    } = useGetCompanySuppliersQuery(company._id);

    // Handle input change

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPurchaseRequisition((prevPurchaseRequisition) => {
            return { ...prevPurchaseRequisition, [name]: value };
        });
    };

    // Handle create purchase requisition
    const handleCreatePurchaseRequisition = () => {
        const newPurchaseRequisition = {
            company: company._id,
            employee: employee._id,
            supplier: purchaseRequisition.supplier,
        };
        createPurchaseRequisition(newPurchaseRequisition);
    };

    // Handle update purchase requisition
    const handleUpdatePurchaseRequisition = () => {
        const updatedPurchaseRequisition = {
            _id: purchaseRequisition._id,
            company: company._id,
            order_no: purchaseRequisition.order_no,
            employee: purchaseRequisition.employee,
            supplier: purchaseRequisition.supplier._id,
            status: purchaseRequisition.status,
        };
        updatePurchaseRequisition(updatedPurchaseRequisition);
    };

    useEffect(() => {
        if (createPurchaseRequisitionResult.data) {
            setPurchaseRequisition(createPurchaseRequisitionResult.data);
        }
        if (updatePurchaseRequisitionResult.data) {
            setPurchaseRequisition((prevPurchaseRequisition) => ({
                ...prevPurchaseRequisition,
                status: updatePurchaseRequisitionResult.data.status,
            }));
        }
    }, [createPurchaseRequisitionResult.isLoading, updatePurchaseRequisitionResult.isLoading]);

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {purchaseRequisition._id
                        ? `EDIT PURCHASE REQUISITION #${purchaseRequisition.order_no}`
                        : `CREATE NEW PURCHASE REQUISITION`}
                </Modal.Title>
            </Modal.Header>
            {createPurchaseRequisitionResult.isLoading || updatePurchaseRequisitionResult.isLoading ? (<Spinner/>) : (<Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Order Number</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='order_no'
                                    value={purchaseRequisition.order_no}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Supplier</Form.Label>
                                <Form.Control
                                    as={'select'}
                                    name='supplier'
                                    value={purchaseRequisition.supplier}
                                    onChange={handleInputChange}>
                                    <option value=''>Select Supplier</option>
                                    {suppliers &&
                                        suppliers.map((supplier) => (
                                            <option key={supplier._id} value={supplier._id}>
                                                {supplier.business_name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className='col-md-4'>{/* Add fields for employee selection */}</div>
                        <div className='col-md-4'>{/* Add fields for date selection */}</div>
                    </div>
                </Form>
                <Row>
                    {purchaseRequisition._id && (
                        <PurchaseRequisitionItems purchaseRequisitionId={purchaseRequisition._id} />
                    )}
                </Row>
            </Modal.Body>)}
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {purchaseRequisitionData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdatePurchaseRequisition}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreatePurchaseRequisition}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default PurchaseRequisitionModal;
