/**
 * defaulters.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';

let instance;

export class DefaulterService extends APIService {
    constructor() {
        const collection = 'defaulters';
        super(collection, 'defaulters');
    }

    static create() {
        if (!instance) {
            instance = new DefaulterService();
        }
        return instance;
    }
}
