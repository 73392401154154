import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';

const BrandModal = ({
    show,
    onHide,
    modalText,
    brand,
    handleCreateBrand,
    handleUpdateBrand,
    handleChange,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name='name'
                            value={brand.name}
                            onChange={handleChange}
                            placeholder='Brand Name'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            name='description'
                            value={brand.description}
                            onChange={handleChange}
                            placeholder='Optional'
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText === 'New Brand' ? (
                    <OxButton text='Submit' action={handleCreateBrand} />
                ) : (
                    <OxButton text='Update' action={handleUpdateBrand} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default BrandModal;
