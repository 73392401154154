import React, { useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

import { 
    useCreateJobCardItemTypeMutation,
    useUpdateJobCardItemTypeMutation,
 } from '@redux/apiSlice';
import { toast } from 'react-toastify';
import Spinner from '@components/shared/Spinner';
import { useSelector } from 'react-redux';

function NewJobCardItemTypeForm({jobCardItemType, handleClose, refetch }) {

  const company = useSelector((state) => state.company);

  const [createJobCardItemType, createJobCardItemTypeResult] = useCreateJobCardItemTypeMutation();
  const [updateJobCardItemType, updateJobCardItemTypeResult] = useUpdateJobCardItemTypeMutation();


  const handleSubmit = (values) => {

    if (jobCardItemType._id) {
        updateJobCardItemType({
            _id: jobCardItemType._id,
            name: values.name,
            description: values.description,
        });
    } else {
        createJobCardItemType({
            name: values.name,
            description: values.description,
            company: company._id,
        });
    }
   
  };

  useEffect(() => {
    if (createJobCardItemTypeResult.isSuccess) {
        formik.resetForm();
      toast.success('Job Card Item Type created successfully');
      refetch();
      handleClose();
    }

    if (createJobCardItemTypeResult.isError) {
        toast.error('Something went wrong');
    }

    if (updateJobCardItemTypeResult.isSuccess) {
        formik.resetForm();

        toast.success('Job Card Item Type updated successfully');
        refetch();
        handleClose();
    }

    if (updateJobCardItemTypeResult.isError) {
        toast.error('Something went wrong');
    }

  }, [createJobCardItemTypeResult.isLoading, updateJobCardItemTypeResult.isLoading]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      ...jobCardItemType,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  if (createJobCardItemTypeResult.isLoading || updateJobCardItemTypeResult.isLoading) {
    return <Spinner />;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      {/* Name */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormControl
          type="text"
          id="name"
          {...formik.getFieldProps('name')}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-danger">{formik.errors.name}</div>
        ) : null}
      </FormGroup>

      {/* Description */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="description">Description</FormLabel>
        <FormControl
          type="text"
          id="description"
          {...formik.getFieldProps('description')}
        />
        {formik.touched.description && formik.errors.description ? (
          <div className="text-danger">{formik.errors.description}</div>
        ) : null}
      </FormGroup>

      <Button type="submit">Submit</Button>
    </Form>
  );
}

export default NewJobCardItemTypeForm;
