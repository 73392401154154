import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';

import {
    useGetTerminalsQuery,
    useCreateTerminalMutation,
    useUpdateTerminalMutation,
    useDeleteTerminalMutation,
} from '../../../Redux/apiSlice';
import TerminalModal from './TerminalModal';

function Terminals() {
    const company = useSelector((state) => state.company);

    // Get terminals
    const { data, error, isLoading, refetch: refetchTerminals } = useGetTerminalsQuery(company._id);

    // Create terminal
    const [createTerminal, createTerminalResult] = useCreateTerminalMutation();

    // Update terminal
    const [updateTerminal, updateTerminalResult] = useUpdateTerminalMutation();

    // Delete terminal
    const [deleteTerminal, deleteTerminalResult] = useDeleteTerminalMutation();

    // Terminal state
    const [terminal, setTerminal] = React.useState({
        terminal_no: '',
        tank: '',
        company: company._id,
    });

    // Terminals array state
    const [terminals, setTerminals] = React.useState([]);

    // Modal text
    const [modalText, setModalText] = React.useState('New Terminal');

    // Terminal Modal state
    const [showTerminalModal, setShowTerminalModal] = React.useState(false);

    // Handle show terminal modal
    const handleShowTerminalModal = () => {
        setShowTerminalModal(true);
        setModalText('New Terminal');
        setTerminal({
            terminal_no: '',
            tank: '',
            company: company._id,
        });
    };

    // Handle close terminal modal
    const handleCloseTerminalModal = () => {
        setShowTerminalModal(false);
    };

    // Handle edit terminal
    const handleEditTerminal = (terminal) => {
        setShowTerminalModal(true);
        setModalText('Edit Terminal');
        setTerminal(terminal);
    };

    // Search text
    const [searchText, setSearchText] = React.useState('');

    // Handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTerminal((prevTerminal) => ({
            ...prevTerminal,
            [name]: value,
        }));
    };

    // Handle create terminal
    const handleCreateTerminal = () => {
        createTerminal(terminal);
    };

    // Handle update terminal
    const handleUpdateTerminal = () => {
        updateTerminal(terminal);
    };

    // Handle delete terminal
    const handleDeleteTerminal = (terminal) => {
        deleteTerminal(terminal._id);
    };

    useEffect(() => {
        if (data) {
            setTerminals(data);
        }
        if (createTerminalResult.data) {
            refetchTerminals();
            handleCloseTerminalModal();
        }

        if (updateTerminalResult.data) {
            refetchTerminals();
            handleCloseTerminalModal();
        }

        if (deleteTerminalResult.data) {
            refetchTerminals();
        }
    }, [
        data,
        createTerminalResult.isLoading,
        updateTerminalResult.isLoading,
        deleteTerminalResult.isLoading,
    ]);

    if (
        isLoading ||
        createTerminalResult.isLoading ||
        updateTerminalResult.isLoading ||
        deleteTerminalResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='card strpied-tabled-with-hover'>
            <CardHeader
                buttonText={'New Terminal'}
                handleSearch={handleSearch}
                handleShow={handleShowTerminalModal}
                headerTitle={'PUMPS'}
                headerDescription={'Manage Pumps'}
                placeholder={'Search Pump'}
                searchText={searchText}
            />

            <div className='card-body table-full-width table-responsive'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th width='40%'>Terminal No</th>
                            <th width='40%'>Tank</th>
                            <th width='20%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {terminals &&
                            terminals.map((terminal) => (
                                <tr key={terminal._id}>
                                    <td>{terminal.terminal_no}</td>
                                    <td>{terminal.tank?.name || 'None'}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditTerminal(terminal)}
                                            text={'Edit'}
                                        />{' '}
                                        &nbsp;
                                        <OxButton
                                            action={() => handleDeleteTerminal(terminal)}
                                            text={'Delete'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <TerminalModal
                show={showTerminalModal}
                onHide={handleCloseTerminalModal}
                modalText={modalText}
                terminal={terminal}
                handleCreateTerminal={handleCreateTerminal}
                handleUpdateTerminal={handleUpdateTerminal}
                handleChange={handleChange}
            />
        </div>
    );
}

export default Terminals;
