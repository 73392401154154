/**
 * OxygenCompanyRegistration.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, Button, Card, Container, Row, Col, Image } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaBuilding } from 'react-icons/fa';

import Logo from '@shared/Logo';
import BlackLogo from '@assets/img/logo_transparent.png';
import { useDispatch, useSelector } from 'react-redux';

const validationSchema = yup.object({
    name: yup.string().required('Company name is required'),
    first_name: yup.string().required('Contact Person First name is required'),
    last_name: yup.string().required('Contact Person Last name is required'),
    email: yup.string().email('Invalid email format').required('Email is required')
});

import { useCreateCompanyMutation } from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../config';
import { useNavigate } from 'react-router-dom';
import { companyAction } from '../../../Redux/companySlice';
import { employeeAction } from '../../../Redux/employeeSlice';

function OxygenCompanyRegistration() {

    const {DASHBOARD} = ROUTES;

    const user = useSelector((state) => state.user.user);

    const license_type = useSelector((state) => state.licenseType);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [createCompany, result] = useCreateCompanyMutation();

    const formik = useFormik({
        initialValues: {
            name: '',
            first_name: '',
            last_name: '',
            email: user.email,
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);

            if (!license_type._id) return toast.error('Please select a license type');

            if (!user.email) return toast.error('Please login first');

            createCompany({
                name: values.name,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                license_type: license_type._id
            });
        },
    });

    useEffect(() => {
        if (result.isSuccess) {
            toast.success('Company registration successful');
            dispatch(companyAction.setCompany(result.data.data.company));
            dispatch(employeeAction.setEmployee(result.data.data.employee));
            navigate(`/${DASHBOARD}`);
        }

        if (result.isError) {
            toast.error(result.error.data.message);
        }
    }, [result.isLoading]);

    if (result.isLoading) {
        return <Spinner />
    }

    return (
        <section>
            <Container>
                <Row className='justify-content-center align-items-center' style={{ marginTop: '70px' }}>
                    <Col xl={10}>
                        <Card style={{ borderRadius: '1rem' }}>
                            <Row className='g-0 justify-content-center align-items-center'>
                                <Col md={6} lg={5}>
                                    <Image
                                        src='https://i.pinimg.com/564x/9d/73/dc/9d73dc08dc375c4279dd899c267f592d.jpg'
                                        alt='registration form'
                                        className='img-fluid'
                                        style={{ borderRadius: '1rem 0 0 1rem' }}
                                    />
                                </Col>
                                <Col md={6} lg={7} className='d-flex align-items-center'>
                                    <Card.Body>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Logo src={BlackLogo} width={250} />
                                            <br />
                                            <h5 className='fw-normal mb-3'>Company Registration</h5>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>
                                                    <FaBuilding /> Company Name
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    id='name'
                                                    {...formik.getFieldProps('name')}
                                                />
                                                {formik.touched.name && formik.errors.name ? (
                                                    <div className='text-danger'>{formik.errors.name}</div>
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>
                                                    <FaUser /> First Name
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    id='first_name'
                                                    {...formik.getFieldProps('first_name')}
                                                />
                                                {formik.touched.first_name && formik.errors.first_name ? (
                                                    <div className='text-danger'>{formik.errors.first_name}</div>
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>
                                                    <FaUser /> Last Name
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    id='last_name'
                                                    {...formik.getFieldProps('last_name')}
                                                />
                                                {formik.touched.last_name && formik.errors.last_name ? (
                                                    <div className='text-danger'>{formik.errors.last_name}</div>
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>
                                                    <FaEnvelope /> Email
                                                </Form.Label>
                                                <Form.Control
                                                    type='email'
                                                    id='email'
                                                    {...formik.getFieldProps('email')}
                                                    disabled
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className='text-danger'>{formik.errors.email}</div>
                                                ) : null}
                                            </Form.Group>

                                            <Button disabled={!formik.isValid} type='submit'>
                                                Register Company
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OxygenCompanyRegistration;
