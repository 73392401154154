import React, { useState, useEffect } from 'react';
import CardHeader from '../../components/shared/CardHeader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LicenseModal from './LicenseModal';
import Side_nav from '../../shared_components/side_nav';
import Top_nav from '../../shared_components/top_nav';
import Footer from '../../shared_components/footer';
import Industries from './Industries';
import LicenseTypes from './LicenseTypes';

import {
    useGetLicensesQuery,
    useCreateLicenseMutation,
    useUpdateLicenseMutation,
    useDeleteLicenseMutation,
} from '../../Redux/apiSlice';
import OxButton from '../../components/shared/OxButton';
import Features from './Features/Features';

function Licenses() {
    //get licenses
    const { data, error, isLoading, refetch: refechLicenses } = useGetLicensesQuery();

    //create license
    const [createLicense, createLicenseResult] = useCreateLicenseMutation();

    //update license
    const [updateLicense, updateLicenseResult] = useUpdateLicenseMutation();

    //delete license
    const [deleteLicense, deleteLicenseResult] = useDeleteLicenseMutation();

    //License modal state

    const [showLicenseModal, setShowLicenseModal] = useState(false);

    //license types array state
    const [licenses, setLicensesArray] = useState([]);

    //modal text
    const [modalText, setModalText] = useState('New License');

    //handle show license modal
    const handleShowLicenseModal = () => {
        setShowLicenseModal(true);
        setModalText('New License');
        //clear license state
        setLicense({
            license_type: '',
            license_start_date: '',
            company: '',
            license_end_date: '',
            license_status: '',
        });
    };

    //handle close license modal
    const handleCloseLicenseModal = () => {
        setShowLicenseModal(false);
    };

    //search text state

    const [searchText, setSearchText] = useState('');

    //license state
    //license state
    const [license, setLicense] = useState({
        license_type: '',
        license_start_date: '',
        company: '',
        license_end_date: '',
        license_status: '',
    });

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLicense((prevLicense) => ({
            ...prevLicense,
            [name]: value,
        }));
    };

    //handle create license

    const handleCreateLicense = () => {
        createLicense(license);
    };

    //handle update license

    const handleUpdateLicense = () => {
        updateLicense(license);
    };

    //handle delete license

    const handleDeleteLicense = () => {
        deleteLicense(license._id);
    };

    //handle edit license

    const handleEditLicense = (license) => {
        setLicense(license);
        setShowLicenseModal(true);
        setModalText('Edit License');
    };

    //handle serach

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        if (data) {
            setLicensesArray(data);
        }

        if (createLicenseResult.data) {
            refechLicenses();
            handleCloseLicenseModal();
        }

        if (updateLicenseResult.data) {
            refechLicenses();
            handleCloseLicenseModal();
        }

        if (deleteLicenseResult.data) {
            refechLicenses();
            handleCloseLicenseModal();
        }
    }, [
        data,
        createLicenseResult.isLoading,
        updateLicenseResult.isLoading,
        deleteLicenseResult.isLoading,
    ]);
    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <Tabs>
                                <TabList>
                                    <Tab>Licenses</Tab>
                                    <Tab>License Types</Tab>
                                    <Tab>Industries</Tab>
                                    <Tab>Features</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='card strpied-tabled-with-hover'>
                                        <CardHeader
                                            buttonText={'New License'}
                                            handleSearch={handleSearch}
                                            handleShow={handleShowLicenseModal}
                                            headerTitile={'Licenses'}
                                            headerDescription={'Manage Licenses'}
                                            placeholder={'Search Licenses'}
                                            searchText={searchText}
                                        />

                                        <div className='card-body table-full-width table-responsive'>
                                            <table className='table table-hover table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Compnay</th>
                                                        <th>Status</th>
                                                        <th>Expiry Date</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {licenses &&
                                                        licenses.map((license) => (
                                                            <tr key={license._id}>
                                                                <td>{license.license_type?.name}</td>
                                                                <td>{license.company?.name}</td>
                                                                <td>{license.license_status}</td>
                                                                <td>{license.license_end_date}</td>
                                                                <td>
                                                                    <OxButton
                                                                        action={() =>
                                                                            handleEditLicense(
                                                                                license
                                                                            )
                                                                        }
                                                                        text={'Edit'}
                                                                    />{' '}
                                                                    &nbsp;
                                                                    <OxButton
                                                                        action={() =>
                                                                            handleDeleteLicense(
                                                                                license._id
                                                                            )
                                                                        }
                                                                        text={'Delete'}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <LicenseModal
                                            show={showLicenseModal}
                                            onHide={handleCloseLicenseModal}
                                            modalText={modalText}
                                            license={license}
                                            handleCreateLicense={handleCreateLicense}
                                            handleUpdateLicense={handleUpdateLicense}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <LicenseTypes />
                                </TabPanel>
                                <TabPanel>
                                    <Industries />
                                </TabPanel>
                                <TabPanel>
                                   <Features/>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Licenses;
