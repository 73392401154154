import React, { useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    BsHouseDoor,
    BsPeople,
    BsPersonPlus,
    BsShieldLock,
    BsTools,
    BsListCheck,
    BsClipboardData,
} from 'react-icons/bs';
import { TbMailShare } from 'react-icons/tb';
import { LiaTelegram } from 'react-icons/lia';
import { EmailShareButton, EmailIcon, TelegramShareButton, WhatsappShareButton } from 'react-share';

import { FaFileContract, FaBuilding, FaWhatsapp, FaTelegram } from 'react-icons/fa';
import { GrCertificate } from 'react-icons/gr';
import { FcApproval } from 'react-icons/fc';
import { useGetCompanyPropertiesQuery } from '@redux/apiSlice';
import Side_nav from '@shared_components/side_nav';
import Top_nav from '@shared_components/top_nav';
import Footer from '@shared_components/footer';
import { MenuCard } from '@shared';
import DashboardCard from '@shared/DashboardCard';
import { ROUTES, RGS_LICENSE_TYPES } from '@enums';
import { ENV } from '@config';
const { PROPERTIES, LEASE_APPLICATION, DEFAULTERS, MANAGE_TENANT_APPLICANTS, CONTRACTS } = ROUTES;
const { FRONTEND_URL } = ENV;

const { RGS_PMC, RGS_REA, RGS_LL, RGS_ADM } = RGS_LICENSE_TYPES;

const enabledColor = '#60caed';
const disabledColor = 'grey';

function RealEstateDashboard() {
    const company = useSelector((state) => state.company);
    const license = useSelector((state) => state.license);

    const isAdmin = license.license_type.code === RGS_ADM;

    const { data, isLoading, error } = useGetCompanyPropertiesQuery(company._id);

    const [properties, setProperties] = React.useState([]);

    const inviteUrl = `${FRONTEND_URL}/${LEASE_APPLICATION}/${company._id}`;

    const menuItems = [
        {
            title: 'Manage Properties',
            icon: <BsHouseDoor size={48} style={{ color: enabledColor }} />,
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsHouseDoor size={48} style={{ color }} />;
            },
            path: PROPERTIES,
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: true,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        {/* <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button> */}
                    </div>
                );
            },
        },
        {
            title: 'Manage Defaulters',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsPeople size={48} style={{ color }} />;
            },
            path: DEFAULTERS,
            license: [RGS_ADM],
            enabled: true,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        {/* <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button> */}
                    </div>
                );
            },
        },
        {
            title: 'Manage Tenant Applicants',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsPersonPlus size={48} style={{ color }} />;
            },
            path: MANAGE_TENANT_APPLICANTS,
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: true,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        {/* <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button> */}
                    </div>
                );
            },
        },
        {
            title: 'Invite Tenants',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsPersonPlus size={48} style={{ color }} />;
            },
            path: `${LEASE_APPLICATION}/${company._id}`,
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: true,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return (
                    <div className='text-center'>
                        <EmailShareButton
                            disabled={!this.enabled}
                            subject='Tenant Application Form'
                            body='Good Day, Kindly fill in the below form'
                            url={inviteUrl}
                            className='me-2'>
                            <TbMailShare size='50' style={{ color }} />
                        </EmailShareButton>
                        <WhatsappShareButton
                            disabled={!this.enabled}
                            title='Tenant Application Form'
                            url={inviteUrl}
                            className='me-2'>
                            <FaWhatsapp size='50' style={{ color }} />
                        </WhatsappShareButton>
                        <TelegramShareButton
                            disabled={!this.enabled}
                            title='Tenant Application Form'
                            url={inviteUrl}
                            className='me-2'>
                            <LiaTelegram size='50' style={{ color }} />
                        </TelegramShareButton>
                    </div>
                );
            },
        },
        {
            title: 'Manage Contracts',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <FaFileContract size={48} style={{ color }} />;
            },
            path: CONTRACTS,
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: true,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        {/* <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button> */}
                    </div>
                );
            },
        },
        {
            title: 'Manage Tenants',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsPeople size={48} style={{ color }} />;
            },
            path: '/manage-tenants',
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: false,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Tenant Background Check',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsShieldLock size={48} style={{ color }} />;
            },
            path: '/tenant-background-check',
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: false,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Property Maintenance',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <BsTools size={48} style={{ color }} />;
            },
            path: '/property-maintenance',
            license: [RGS_LL, RGS_PMC, RGS_REA],
            enabled: false,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Rental Good Standing',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <GrCertificate size={48} style={{ color }} />;
            },
            path: '/rental-good-standing',
            license: [RGS_ADM],
            enabled: false,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Endorsement Companies',
            icon: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                return <FaBuilding size={48} style={{ color }} />;
            },
            path: '/endorement companies',
            license: [RGS_ADM],
            enabled: false,
            menuFooter: function () {
                const color = this.enabled ? enabledColor : disabledColor;
                const variant = this.enabled ? 'outline-primary' : 'dark';
                return (
                    <div className='text-center'>
                        <Button variant={variant} className='me-2'>
                            <BsPersonPlus style={{ color }} />
                        </Button>
                        <Button variant={variant} className='me-2'>
                            <BsListCheck style={{ color }} />
                        </Button>
                        <Button variant={variant}>
                            <BsClipboardData style={{ color }} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (data) {
            setProperties(data);
        }
    }, [data]);

    //create a function to convert a number to a comma separated string

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    // render menu items from array list
    const renderMenuItems = (item, index) => {
        const hasAccess = item.license.includes(license.license_type.code);
        if (!hasAccess) {
            return null;
        }
        return <MenuCard key={index} item={item} />;
    };

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <Container>
                        {!isAdmin && (
                            <Row>
                                <Col md={4}>
                                    <DashboardCard
                                        header='PROPERTIES'
                                        title={properties.length}
                                        description='Total Properties'
                                    />
                                </Col>
                                <Col md={4}>
                                    <DashboardCard
                                        header='FOR SALE'
                                        title={
                                            properties.filter(
                                                (property) =>
                                                    property.property_status === 'For Sale'
                                            ).length
                                        }
                                        description='Properties For Sale'
                                    />
                                </Col>
                                <Col md={4}>
                                    <DashboardCard
                                        header='FOR RENT'
                                        title={
                                            properties.filter(
                                                (property) =>
                                                    property.property_status === 'For Rent'
                                            ).length
                                        }
                                        description='Properties For Rent'
                                    />
                                </Col>
                            </Row>
                        )}
                        {!isAdmin && (
                            <Row>
                                {/**nice table heading here */}

                                <h4>RECENTLY ADDED PROPERTIES</h4>
                                <br />
                                <Col>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Status</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {properties.map((property) => (
                                                <tr key={property._id}>
                                                    <td>{property.name}</td>
                                                    <td>{property.town + '' + property.surbub}</td>
                                                    <td>{property.property_status}</td>
                                                    <td>
                                                        NAD{' '}
                                                        {numberWithCommas(property.property_price)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        )}
                        <Row xs={1} md={2} lg={3} className='g-4'>
                            {menuItems.map(renderMenuItems)}
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default RealEstateDashboard;
