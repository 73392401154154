import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import { useGetPaymentMethodsQuery } from '../../../Redux/apiSlice';

const SalePaymentModal = ({
    show,
    onHide,
    modalText,
    salePayment,
    handleChange,
    handleCreatePayment,
    handleUpdatePayment,
}) => {
    const company = useSelector((state) => state.company);

    const {
        data: paymentMethods,
        error: paymentMethodsError,
        isLoading: paymentMethodsLoading,
    } = useGetPaymentMethodsQuery(company._id);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Payment Method</Form.Label>
                        <Form.Control
                            as='select'
                            name='payment_method'
                            value={salePayment.payment_method}
                            onChange={handleChange}>
                            <option value='' disabled>
                                --Select Payment Method--
                            </option>
                            {paymentMethods &&
                                paymentMethods.map((paymentMethod) => (
                                    <option key={paymentMethod._id} value={paymentMethod._id}>
                                        {paymentMethod.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Amount Tendered</Form.Label>
                        <Form.Control
                            type='number'
                            name='amount_tendered'
                            value={salePayment.amount_tendered}
                            onChange={handleChange}
                            placeholder='Amount Tendered'
                            required
                        />
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={onHide}>
                            Cancel
                        </Button>
                        {modalText === 'New Sale Payment' ? (
                            <OxButton action={handleCreatePayment} text='Submit' />
                        ) : (
                            <OxButton action={handleUpdatePayment} text='Update' />
                        )}
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SalePaymentModal;
