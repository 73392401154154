/**
 * MenuCard.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BsPersonPlus, BsListCheck, BsClipboardData } from 'react-icons/bs';
import { colors } from '@strings/colors';

import './MenuCard.scss';

export function MenuCard({ item }) {
    const navigate = useNavigate();
    const { enabled } = item;
    const bordertype = enabled ? 'border-info' : 'border-dark';
    const color = enabled ? colors.primary : 'grey';
    const variant = enabled ? 'outline-primary' : 'dark';

    const navigateTo = () => {
        if (enabled) {
            navigate(`/${item.path}`);
        }
    };

    const enabledCard = () => (
        <Col className='menuCard'>
            <Card className={`h-100 border ${bordertype} link`}>
                <Card.Header
                    onClick={navigateTo}
                    className='text-center'
                    style={{ fontSize: '20px' }}>
                    {item.icon()}
                </Card.Header>
                <Card.Body onClick={navigateTo}>
                    <Card.Title className='text-center'>{item.title}</Card.Title>
                    {/* <Card.Text>Description of {item.title}</Card.Text> */}
                </Card.Body>
                <Card.Footer className='text-center mb-4'>{item.menuFooter()}</Card.Footer>

                {/* <Link to={`/${item.path}`} className='stretched-link'>
                    <span className='visually-hidden'>Go to {item.title}</span>
                </Link> */}
            </Card>
        </Col>
    );

    const disabledCard = () => (
        <Col className='menuCard'>
            <Card className={`h-100 border ${bordertype}`}>
                <Card.Header className='text-center' style={{ fontSize: '20px' }}>
                    {item.icon()}
                </Card.Header>
                <Card.Body>
                    <Card.Title className='text-center'>{item.title}</Card.Title>
                    <p className='text-center text-danger'>UNDER CONSTRUCTION !!</p>
                </Card.Body>
                <Card.Footer className='text-center mb-4'>
                    <Button variant={variant} className='me-2'>
                        <BsPersonPlus style={{ color }} />
                    </Button>
                    <Button variant={variant} className='me-2'>
                        <BsListCheck style={{ color }} />
                    </Button>
                    <Button variant={variant}>
                        <BsClipboardData style={{ color }} />
                    </Button>
                </Card.Footer>
            </Card>
        </Col>
    );
    return enabled ? enabledCard() : disabledCard();
}
