import React, { useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import UnderConstruction from '../shared_components/UnderConstruction';
function Billing() {
    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <UnderConstruction module={'Billing'} />
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Billing;
