import React from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import UnderConstruction from '../shared_components/UnderConstruction';
import { useSelector } from 'react-redux';
import WorkshopDashboard from './industries/workshop/WorkshopDashboard';
import PotenciaDashbord from './industries/retail/PotenciaDashbord';
import RealEstateDashboard from './industries/real_estate_stay/RealEstateDashboard';

function Dashboard() {
    const license = useSelector((state) => state.license);

    switch (license.license_type.code) {
        case 'OWS':
            return <WorkshopDashboard />;
        case 'RGS_PMC':
            return <RealEstateDashboard />;
        case 'RGS_REA':
            return <RealEstateDashboard />;
        case 'RGS_LL':
            return <RealEstateDashboard />;
        case 'RGS_ADM':
            return <RealEstateDashboard />;

        case 'POT':
            return (
                <div className='wrapper'>
                    <Side_nav />
                    <div className='main-panel'>
                        {/* Navbar */}
                        <Top_nav />
                        {/* End Navbar */}
                        <div className='content'>
                            <PotenciaDashbord />
                        </div>
                        <Footer />
                    </div>
                </div>
            );
        default:
            return (
                <div className='wrapper'>
                    <Side_nav />
                    <div className='main-panel'>
                        {/* Navbar */}
                        <Top_nav />
                        {/* End Navbar */}
                        <div className='content'>
                            <UnderConstruction module={'Dashbord'} />
                        </div>
                        <Footer />
                    </div>
                </div>
            );
    }
}

export default Dashboard;
