/**
 * CircularOxButton.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Button } from 'react-bootstrap';

export function CircularButton({ variant = '', icon, ...props }) {
    const circularButtonStyle = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
    };

    return (
        <Button variant={variant} style={circularButtonStyle} {...props}>
            {icon}
        </Button>
    );
}
