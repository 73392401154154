import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetCompanySuppliersQuery,
    useCreateCompanySupplierMutation,
    useUpdateCompanySupplierMutation,
    useDeleteCompanySupplierMutation,
    useUpdateCompanyMutation,
} from '../Redux/apiSlice';

import Footer from '../shared_components/footer';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import { Modal, Button, Form } from 'react-bootstrap';
import util from '../util';

import { companyAction } from '../Redux/companySlice';

import OxButton from '../components/shared/OxButton';
import Spinner from '../components/shared/Spinner';

function Suppliers() {
    const dispatch = useDispatch();
    const companyState = useSelector((state) => state.company);
    const userState = useSelector((state) => state.user);

    const { data, isSuccess, isLoading, refetch } = useGetCompanySuppliersQuery(companyState._id);
    const [createCompanySupplier, createCompanySupplierResult] = useCreateCompanySupplierMutation();
    const [updateCompanySupplier, updateCompanySupplierResult] = useUpdateCompanySupplierMutation();
    const [deleteCompanySupplier, deleteCompanySupplierResult] = useDeleteCompanySupplierMutation();
    const [updateCompany, updateCompanyResult] = useUpdateCompanyMutation();

    const [suppliers, setSuppliers] = React.useState([]);

    const [show, setShow] = React.useState(false);

    //supplier state variables

    const [supplier_id, setSupplierId] = React.useState('');

    const [supplier_number, setSupplierNumber] = React.useState('');

    const [first_name, setFirstName] = React.useState('');

    const [last_name, setLastName] = React.useState('');

    const [gender, setGender] = React.useState('');

    const [id_number, setIdNumber] = React.useState('');

    const [phone_number, setPhoneNumber] = React.useState('');

    const [email, setEmail] = React.useState('');

    const [residencial_address, setResidencialAddress] = React.useState('');

    const [postal_address, setPostalAddress] = React.useState('');

    const [agency_name, setAgencyName] = React.useState('');

    const [business_name, setBusinessName] = React.useState('');

    const [business_registation_number, setBusinessRegistationNumber] = React.useState('');

    const [tax_registration_number, setTaxRegistrationNumber] = React.useState('');

    const [category, setCategory] = React.useState('');

    const [created_by, setCreatedBy] = React.useState('');

    const [company, setCompany] = React.useState('');

    //modal text state

    const [modal_text, setModalText] = React.useState('');

    //search text state

    const [search_text, setSearchText] = React.useState('');

    const handleShow = () => {
        setShow(true);

        //clear state variables

        setSupplierId('');

        setSupplierNumber('');

        setFirstName('');

        setLastName('');

        setGender('');

        setIdNumber('');

        setPhoneNumber('');

        setEmail('');

        setResidencialAddress('');

        setPostalAddress('');

        setAgencyName('');

        setBusinessName('');

        setBusinessRegistationNumber('');

        setTaxRegistrationNumber('');

        setCategory('');

        setCreatedBy('');

        setCompany('');

        //set modal text

        setModalText('New Supplier');
    };

    const handleClose = () => setShow(false);

    //handle supplier creation

    const handleCreateSupplier = (e) => {
        e.preventDefault();

        const company = JSON.parse(JSON.stringify(companyState));

        console.log(company);

        //get current supplier number prefix
        const supplier_number_prefix = company.supplier_number_prefix;

        //get current supplier number
        const current_supplier_number = company.supplier_number_counter;

        //increment supplier number
        company.supplier_number_counter = current_supplier_number + 1;

        //update company

        dispatch(companyAction.setCompany(company));

        updateCompany(company);

        const body = {
            supplier_number: util.generateEntityNumber(
                supplier_number_prefix,
                company.supplier_number_counter
            ),

            first_name,

            last_name,

            gender,

            id_number,

            phone_number,

            email,

            residencial_address,

            postal_address,

            agency_name,

            business_name,

            business_registation_number,

            tax_registration_number,

            category,

            created_by: userState._id,

            company: companyState._id,
        };

        createCompanySupplier(body);

        handleClose();
    };

    //handle supplier update

    const handleUpdateSupplier = (e) => {
        e.preventDefault();

        const body = {
            _id: supplier_id,

            supplier_number,

            first_name,

            last_name,

            gender,

            id_number,

            phone_number,

            email,

            residencial_address,

            postal_address,

            agency_name,

            business_name,

            business_registation_number,

            tax_registration_number,

            category,

            created_by: userState._id,

            company: companyState._id,
        };

        updateCompanySupplier(body);

        handleClose();
    };

    //handle edit supplier

    const handleEditSupplier = (supplier) => (e) => {
        e.preventDefault();

        //clear state variables

        setSupplierId('');

        setSupplierNumber('');

        setFirstName('');

        setLastName('');

        setGender('');

        setIdNumber('');

        setPhoneNumber('');

        setEmail('');

        setResidencialAddress('');

        setPostalAddress('');

        setAgencyName('');

        setBusinessName('');

        setBusinessRegistationNumber('');

        setTaxRegistrationNumber('');

        setCategory('');

        setCreatedBy('');

        //set state variables

        setSupplierId(supplier._id);

        setSupplierNumber(supplier.supplier_number);

        setFirstName(supplier.first_name);

        setLastName(supplier.last_name);

        setGender(supplier.gender);

        setIdNumber(supplier.id_number);

        setPhoneNumber(supplier.phone_number);

        setEmail(supplier.email);

        setResidencialAddress(supplier.residencial_address);

        setPostalAddress(supplier.postal_address);

        setAgencyName(supplier.agency_name);

        setBusinessName(supplier.business_name);

        setBusinessRegistationNumber(supplier.business_registation_number);

        setTaxRegistrationNumber(supplier.tax_registration_number);

        setCategory(supplier.category);

        setCreatedBy(supplier.created_by);

        setShow(true);

        setModalText('Edit Supplier');
    };

    //handle delete supplier

    const handleDeleteSupplier = (id) => (e) => {
        e.preventDefault();

        deleteCompanySupplier(id);
    };

    //handle search supplier

    const handleSearchSupplier = (text) => {
        setSearchText(text);

        if (text.length > 0) {
            const filteredSuppliers = suppliers.filter((supplier) =>
                supplier.business_name.toLowerCase().includes(search_text.toLowerCase())
            );
            setSuppliers(filteredSuppliers);
        } else {
            setSuppliers(data);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setSuppliers(data);
        }
        if (createCompanySupplierResult.isSuccess) {
            refetch();
        }
        if (updateCompanySupplierResult.isSuccess) {
            refetch();
        }
        if (deleteCompanySupplierResult.isSuccess) {
            refetch();
        }
    }, [
        data,
        isLoading,
        isSuccess,
        createCompanySupplierResult,
        updateCompanySupplierResult,
        deleteCompanySupplierResult,
    ]);

    if (
        isLoading ||
        createCompanySupplierResult.isLoading ||
        updateCompanySupplierResult.isLoading ||
        deleteCompanySupplierResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='section'>
                            {' '}
                            <div className='card strpied-tabled-with-hover'>
                                <div className='card-header '>
                                    <div className='form-row'>
                                        <div className='col-md-5'>
                                            <h4 className='card-title'>Suppliers</h4>
                                            <p className='card-category'>Manage Suppliers</p>
                                        </div>
                                        <div className='col-md-7'>
                                            <div className='form-row'>
                                                <div className='col-md-8'>
                                                    <div className='form-group'>
                                                        <input
                                                            type='text'
                                                            onChange={(e) =>
                                                                handleSearchSupplier(e.target.value)
                                                            }
                                                            className='form-control'
                                                            placeholder='Search By Business name'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <OxButton
                                                        text='New Supplier'
                                                        action={handleShow}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body table-full-width table-responsive'>
                                    <table className='table table-hover table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Supplier No.</th>
                                                <th>Business Name</th>
                                                <th>Agency</th>
                                                <th>Category</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Phone Number</th>
                                                <th>E-mail Address</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {suppliers.map((supplier, index) => (
                                                <tr key={index}>
                                                    <td>{supplier.supplier_number}</td>
                                                    <td>{supplier.business_name}</td>
                                                    <td>{supplier.agency_name}</td>
                                                    <td>{supplier.category}</td>
                                                    <td>{supplier.first_name}</td>
                                                    <td>{supplier.last_name}</td>
                                                    <td>{supplier.phone_number}</td>
                                                    <td>{supplier.email}</td>
                                                    <td>
                                                        <OxButton
                                                            text='Edit'
                                                            action={handleEditSupplier(supplier)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <OxButton
                                                            text='Delete'
                                                            action={handleDeleteSupplier(
                                                                supplier._id
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{modal_text}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <fieldset>
                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='_business_name'>
                                                    Business Name
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='_business_name'
                                                    placeholder='Business Name'
                                                    value={business_name}
                                                    onChange={(e) =>
                                                        setBusinessName(e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='_agency'>Agency</Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='_agency'
                                                    placeholder='Agency'
                                                    value={agency_name}
                                                    onChange={(e) => setAgencyName(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <div className='form-row'>
                                                    <div className='col-md-3'>
                                                        <Form.Label> Category</Form.Label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Form.Check
                                                            type='radio'
                                                            label='Goods'
                                                            id='_goods'
                                                            name='category'
                                                            value='Goods'
                                                            onChange={(e) =>
                                                                setCategory(e.target.value)
                                                            }
                                                            checked={category === 'Goods'}
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Form.Check
                                                            type='radio'
                                                            label='Expenses'
                                                            id='_expenses'
                                                            name='category'
                                                            value='Expenses'
                                                            onChange={(e) =>
                                                                setCategory(e.target.value)
                                                            }
                                                            checked={category === 'Expenses'}
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='tax'>VAT Reg No.</Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='tax'
                                                    placeholder='VAT Registration No.'
                                                    value={tax_registration_number}
                                                    onChange={(e) =>
                                                        setTaxRegistrationNumber(e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='first_name'>
                                                    First Name
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='first_name'
                                                    placeholder='First Name'
                                                    value={first_name}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='last_name'>
                                                    Last Name
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='last_name'
                                                    placeholder='Last Name'
                                                    value={last_name}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <div className='form-row'>
                                                    <div className='col-md-3'>
                                                        <Form.Label htmlFor='disabledSelect'>
                                                            Gender
                                                        </Form.Label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Form.Check
                                                            type='radio'
                                                            label='Male'
                                                            id='gender_male'
                                                            name='gender'
                                                            value='male'
                                                            onChange={(e) =>
                                                                setGender(e.target.value)
                                                            }
                                                            checked={gender === 'male'}
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <Form.Check
                                                            type='radio'
                                                            label='Female'
                                                            id='gender_female'
                                                            name='gender'
                                                            value='female'
                                                            onChange={(e) =>
                                                                setGender(e.target.value)
                                                            }
                                                            checked={gender === 'female'}
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='_id_number'>
                                                    ID Number
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='_id_number'
                                                    placeholder='ID Number'
                                                    value={id_number}
                                                    onChange={(e) => setIdNumber(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='phone_number'>
                                                    Phone Number
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='tel'
                                                    id='phone_number'
                                                    placeholder='Phone Number'
                                                    value={phone_number}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='email'>
                                                    E-mail Address
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='email'
                                                    id='email'
                                                    placeholder='E-mail Address'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='postal_address'>
                                                    Postal Address
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='postal_address'
                                                    placeholder='Postal Address'
                                                    value={postal_address}
                                                    onChange={(e) =>
                                                        setPostalAddress(e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='address'>
                                                    Resdential Address
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='address'
                                                    placeholder='Resdential Address'
                                                    value={residencial_address}
                                                    onChange={(e) =>
                                                        setResidencialAddress(e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </fieldset>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='secondary' onClick={handleClose}>
                                        Close
                                    </Button>
                                    {modal_text === 'New Supplier' ? (
                                        <OxButton text='Submit' action={handleCreateSupplier} />
                                    ) : (
                                        <OxButton text='Update' action={handleUpdateSupplier} />
                                    )}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Suppliers;
