import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Top_nav() {
    const navigate = useNavigate();

    const userState = useSelector((state) => state.user);
    const companyState = useSelector((state) => state.company);

    return (
        <nav className='navbar navbar-expand-lg ' color-on-scroll={500}>
            <div className='container-fluid'>
                <a
                    className='navbar-brand'
                    title='Swicth Companies'
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => navigate('/companies')}>
                    {companyState.name}
                </a>
                <button
                    className='navbar-toggler navbar-toggler-right'
                    type='button'
                    data-toggle='collapse'
                    aria-controls='navigation-index'
                    aria-expanded='false'
                    aria-label='Toggle navigation'>
                    <span className='navbar-toggler-bar burger-lines'></span>
                    <span className='navbar-toggler-bar burger-lines'></span>
                    <span className='navbar-toggler-bar burger-lines'></span>
                </button>
                <div className='collapse navbar-collapse justify-content-end' id='navigation'>
                    <ul className='navbar-nav ml-auto'>
                        <li className='nav-item'>
                            <a
                                className='nav-link'
                                href='#pablo'
                                onClick={(e) => navigate('/user')}>
                                {' '}
                                <i
                                    style={{ fontSize: 22 }}
                                    className='nc-icon nc-circle-09'></i>{' '}
                                &nbsp;
                                <span className='no-icon'>{userState.user.username}</span>
                            </a>
                        </li>

                        <li className='nav-item'>
                            <a className='nav-link' href='/'>
                                <span className='no-icon'>Logout</span> &nbsp;
                                <i
                                    style={{ fontSize: 18 }}
                                    className='nc-icon nc-lock-circle-open'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Top_nav;
