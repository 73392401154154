import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Image, InputGroup } from 'react-bootstrap';

const RealEstateImagesModal = ({ images, show, onHide, onUpload, onDelete }) => {
    const [file, setFile] = useState(null);

    const baseUrl = 'http://siskusserver.com:7800/api/stream/';

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = (e) => {
        e.preventDefault();
        if (file) {
            onUpload(file);
            setFile(null);
        }
    };

    const handleDelete = (image) => {
        onDelete(image);
    };

    return (
        <Modal show={show} onHide={onHide} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Real Estate Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {images.length === 0 ? (
                    <div>No images to display</div>
                ) : (
                    <Row xs={1} md={2} lg={3} className='g-4'>
                        {images.map((image) => (
                            <Col key={image._id}>
                                <div className='d-flex flex-column align-items-center'>
                                    <Image
                                        src={baseUrl + image.filename}
                                        alt='real estate'
                                        rounded
                                        width={200}
                                        height={200}
                                    />
                                    <Button
                                        variant='danger'
                                        size='sm'
                                        onClick={() => handleDelete(image)}>
                                        Delete
                                    </Button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )}

                <Form onSubmit={handleUpload}>
                    <Form.Group controlId='formFile'>
                        <Form.Label>Upload new image:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type='file'
                                name='sampleFile'
                                onChange={handleFileChange}
                            />
                            <Button variant='primary' type='submit'>
                                Upload
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RealEstateImagesModal;
