/**
 * ViewDefaulter.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { capitalizeFirstLetter, capitalizeFirstLetters } from '@util/misc.util';
import { SessionService, FilesService } from '@services';
import { LOCAL_STORAGE_ENUMS } from '@enums';
import { OxPdfFileView } from '@shared';
import { isEmpty } from '@util/array.util';

const filesService = FilesService.create();

const session = SessionService.session;

let initialSub = session;

export const ViewDefaulter = ({ item }) => {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        getData();
        return () => {
            initialSub.unsubscribe();
        };
    }, []);

    const prepareData = (values) => {
        console.log('values: ', values);
        setDocuments(values);
    };

    const getData = () => {
        initialSub = filesService.get(item._id).subscribe(prepareData, (error) => {
            if (error.status === 404) {
                setDocuments([]);
            }
            console.log('Dependencies Get Error: ', error);
        });
    };

    const excludeKeys = ['__v', 'deleted', '_id', 'created_at', 'updated_at'];
    return (
        <Container>
            <Card className='mb-4'>
                <Card.Header>
                    <h5>Defaulter Details</h5>
                </Card.Header>
                <Card.Body>
                    <Row>
                        {Object.entries(item).map(([key, value], index) => {
                            if (excludeKeys.includes(key) === false) {
                                return (
                                    <Col key={index} md={6} className='mb-2'>
                                        <span className='defaulter-key'>
                                            {capitalizeFirstLetters(key.replace(/_/g, ' '))}:{' '}
                                        </span>
                                        <span className='defaulter-value'>
                                            {typeof value === 'boolean' ? value.toString() : value}
                                        </span>
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </Card.Body>
            </Card>

            {!isEmpty(documents) && (
                <Card>
                    <Card.Header>
                        <h5>Documents</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {documents.map((doc, index) => (
                                <Col key={index} md={4}>
                                    <OxPdfFileView file={doc} deletehandler={() => null} />
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};
