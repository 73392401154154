/**
 * DefaultersTable.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';

import Side_nav from '@shared_components/side_nav';
import Top_nav from '@shared_components/top_nav';
import Footer from '@shared_components/footer';

import { Popconfirm, Button as AntButton, Dropdown, Space, Menu } from 'antd';
import { filter } from 'rxjs/operators';
import OxButton from '@shared/OxButton';
import { LoadingPage, CircularButton, OxNotify, OxModal } from '@shared';
import { SessionService, DefaulterService } from '@services';
import { defined } from '@util/array.util';
import { FaEye, FaEllipsisH } from 'react-icons/fa';

import { LOCAL_STORAGE_ENUMS } from '@enums';

import { DefaulterFormModal } from './';
import { ViewDefaulter } from './ViewDefaulter';
import './Defaulters.scss';

import { DeleteTwoTone } from '@ant-design/icons';

const { DEFAULTERS } = LOCAL_STORAGE_ENUMS;

// service instances
const defaulterService = DefaulterService.create();
const session = SessionService.session;

//subscriptions
let localDataSubscription = session;
let initialSub = session;

export const DefaultersTable = () => {
    const [defaulters, setDefaulters] = useState(undefined);
    const [defaulter, setDefaulter] = useState(undefined);
    const [showModal, setShowModal] = React.useState(false);
    const [viewModal, setViewModal] = useState(false);
    const tableHeads = ['Full Name', 'Id Number', 'Phone Number', 'Status', 'E-mail Address'];

    useEffect(() => {
        listenToSession();
        getData();
    }, []);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        refresh();
    };

    const prepareData = (values) => {
        console.log('values', values);
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === DEFAULTERS) {
                setDefaulters(data);
            }
        }
    };

    const listenToSession = () => {
        const concerns = [DEFAULTERS];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const getData = () => {
        initialSub = defaulterService.get().subscribe(prepareData, (error) => {
            console.log('Dependencies Get Error: ', error);
        });
    };
    const refresh = () => {
        initialSub = defaulterService.fetchAndStore().subscribe(prepareData, (error) => {
            console.log('Dependencies fetch Error: ', error);
        });
    };

    const confirmDelete = (item) => {
        defaulterService.patch(item._id, { deleted: true }).subscribe(
            (data) => {
                console.log('Deleted: ', data);

                OxNotify({
                    title: 'Success',
                    content: 'Delete Success',
                    type: 'success',
                    duration: 10,
                });
                refresh();
            },
            (error) => {
                console.log('Error: ', error);
                OxNotify({ title: 'Error', content: error.message, type: 'error', duration: 10 });
            }
        );
    };

    const items = [
        {
            key: '1',
            label: 'WHITELIST',
            update: (defaulter) => {
                update(defaulter, { status: 'RESOLVED' });
            },
        },
        {
            key: '2',
            label: 'BLACKLIST',
            update: (defaulter) => {
                update(defaulter, { status: 'ACTIVE' });
            },
        },
        {
            key: '3',
            label: 'UNDER INVESTIGATION',
            update: (defaulter) => {
                update(defaulter, { status: 'UNDER INVESTIGATION' });
            },
        },
    ];

    const menuProps = {
        items,
        onClick: (e) => {},
    };

    const update = (item, data) => {
        defaulterService.patch(item._id, data).subscribe(
            (data) => {
                console.log('Deleted: ', data);

                OxNotify({
                    title: 'Success',
                    content: 'Defaulter Updated',
                    type: 'success',
                    duration: 10,
                });
                refresh();
            },
            (error) => {
                console.log('Error: ', error);
                OxNotify({ title: 'Error', content: error.message, type: 'error', duration: 10 });
            }
        );
    };

    /** returns true if all data dependencies have been loaded from the api */
    const isReady = defined([defaulters]);

    // render table heads from array list
    const renderTableHeads = (head, index) => {
        return <th key={index}>{head}</th>;
    };

    const renderTableRows = (defaulter, index) => {
        let statusColor = 'black';

        switch (defaulter.status) {
            case 'ACTIVE':
                statusColor = 'red';
                break;
            case 'RESOLVED':
                statusColor = 'green';
                break;
            case 'UNDER INVESTIGATION':
                statusColor = 'orange';
                break;
            default:
                statusColor = 'black';
                break;
        }
        return (
            <tr key={index}>
                <td>{defaulter?.full_name}</td>

                <td>{defaulter.id_number}</td>
                <td>{defaulter.contact_number}</td>
                <td style={{ color: statusColor }}>{defaulter.status}</td>
                <td>{defaulter.email_address}</td>
                <td>
                    <CircularButton
                        variant='primary'
                        onClick={() => {
                            setDefaulter(defaulter);
                            setViewModal(true);
                        }}
                        icon={<FaEye />}
                    />
                </td>
                {/* <td>
                    <CircularButton variant='success' icon={<FaEdit />} />
                </td> */}
                <td>
                    <Popconfirm
                        placement='top'
                        title='Confirm Delete'
                        okText='Yes'
                        cancelText='No'
                        onConfirm={() => confirmDelete(defaulter)}>
                        <AntButton
                            className='tableDeleteButton'
                            shape='circle'
                            icon={
                                <DeleteTwoTone
                                    key='delete'
                                    twoToneColor='red'
                                    style={{ fontSize: '15px' }}
                                />
                            }
                        />
                        {/* <CircularButton variant='danger' icon={<FaTrash />} /> */}
                    </Popconfirm>
                </td>
                <td>
                    <Dropdown
                        overlay={
                            <Menu onClick={menuProps.onClick}>
                                {menuProps.items.map((item) => (
                                    <Menu.Item
                                        key={item.key}
                                        onClick={() => {
                                            item.update(defaulter);
                                        }}>
                                        {item.label}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }>
                        <CircularButton variant='success' icon={<FaEllipsisH />} />
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const renderView = () => {
        return (
            <div className='content'>
                <div className='container-fluid'>
                    <div className='section'>
                        {' '}
                        <div className='card strpied-tabled-with-hover'>
                            <div className='card-header '>
                                <div className='form-row'>
                                    <div className='col-md-5'>
                                        <h4 className='card-title'>Defaulters</h4>
                                        <p className='card-category'>Manage Defaulters</p>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='form-row'>
                                            <div className='col-md-8'>
                                                <div className='form-group'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Search By Id number'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <OxButton
                                                    text='New Defaulter'
                                                    action={handleOpenModal}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body table-full-width table-responsive'>
                                <table className='table table-hover table-striped'>
                                    <thead>
                                        <tr>{tableHeads.map(renderTableHeads)}</tr>
                                    </thead>
                                    <tbody>{defaulters.map(renderTableRows)}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <DefaulterFormModal show={showModal} handleClose={handleCloseModal} />
            </div>
        );
    };

    return isReady ? (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {renderView()}

                {viewModal && (
                    <OxModal
                        scrollable={true}
                        size='lg'
                        dark={false}
                        title={'DEFAULTER DETAILS'}
                        visible={viewModal}
                        handleClose={() => setViewModal(false)}
                        children={
                            <ViewDefaulter
                                item={defaulter}
                                documents={[{ filename: 'anything.pdf' }]}
                            />
                        }
                    />
                )}

                <Footer />
            </div>
        </div>
    ) : (
        <LoadingPage title={'Loading defaulters list'} />
    );
};
