/**
 * contract.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';
import { LOCAL_STORAGE_ENUMS } from '@enums';
const { CONTRACTS } = LOCAL_STORAGE_ENUMS;

let instance;

export class ContractsService extends APIService {
    url = `${this.url}?populate=tenant&populate=property&populate=agent`;
    constructor() {
        const collection = CONTRACTS;
        super(collection, CONTRACTS);
    }

    static create() {
        if (!instance) {
            instance = new ContractsService();
        }
        return instance;
    }
}
