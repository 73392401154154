import { createSlice } from '@reduxjs/toolkit';

export const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        _id: '',
        employee_number: '',
        first_name: '',
        last_name: '',
        gender: '',
        dob: undefined,
        id_number: '',
        phone_number: '',
        email: '',
        residencial_address: '',
        postal_address: '',
        country: '',
        company: '',
        role: '',
        qualifications: [],
        joined_at: '',
        social_security_number: '',
        created_by: '',
        created_at: '',
        deleted: false,
    },
    reducers: {
        setEmployee: (state, action) => {
            state._id = action.payload._id;
            state.employee_number = action.payload.employee_number;
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.gender = action.payload.gender;
            state.dob = action.payload.dob;
            state.id_number = action.payload.id_number;
            state.phone_number = action.payload.phone_number;
            state.email = action.payload.email;
            state.residencial_address = action.payload.residencial_address;
            state.postal_address = action.payload.postal_address;
            state.country = action.payload.country;
            state.company = action.payload.company;
            state.role = action.payload.role;
            state.qualifications = action.payload.qualifications;
            state.joined_at = action.payload.joined_at;
            state.social_security_number = action.payload.social_security_number;
            state.created_by = action.payload.created_by;
            state.created_at = action.payload.created_at;
            state.deleted = action.payload.deleted;
        },

        clearEmployee: (state) => {
            state._id = '';
            state.employee_number = '';
            state.first_name = '';
            state.last_name = '';
            state.gender = '';
            state.dob = undefined;
            state.id_number = '';
            state.phone_number = '';
            state.email = '';
            state.residencial_address = '';
            state.postal_address = '';
            state.country = '';
            state.company = '';
            state.role = '';
            state.qualifications = [];
            state.joined_at = '';
            state.social_security_number = '';
            state.created_by = '';
            state.created_at = '';
            state.deleted = false;
        },
    },
});

export const employeeAction = employeeSlice.actions;
