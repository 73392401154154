/**
 * oxygen-license-types.enums.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const OWS = 'OWS'; // OXYGEN WORKSHOP STANDARD
const OXE = 'OXE'; // OXYGEN ERP
const OFD = 'OFD'; // OXYGEN FUEL DEPOT
const POT = 'POT'; // OXYGEN POTENCIA (POWER) RETAIL


export const OXYGEN_LICENSE_TYPES = {
    OWS,
    OXE,
    OFD,
    POT,
};
