import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '../config';
const { oxygen_end_point, APP_MODE } = ENV;

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: oxygen_end_point,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json');
            headers.set('Access-Control-Allow-Origin', '*');
            headers.set('Token', `Bearer ${localStorage.getItem('token')}`);
            headers.set('app_mode', APP_MODE);
            return headers;
            
        },
    }),

    endpoints: (builder) => ({
        //Login Mutation
        login: builder.mutation({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body,
            }),
        }),
        //Signup Mutation
        signup: builder.mutation({
            query: (body) => ({
                url: '/register',
                method: 'POST',
                body,
            }),
        }),

        //reset password mutation
        resetPassword: builder.mutation({
            query: (body) => ({
                url: '/reset-password',
                method: 'POST',
                body,
            }),
        }),

        //get employees by email populated with person and company
        getEmployees: builder.query({
            query: (email) => ({
                url: `/employees?email=${email}&&populate=company`,
                method: 'GET',
            }),
        }),

        //create company admin role mutation

        createCompanyAdminRole: builder.mutation({
            query: (body) => ({
                url: `/create-admin-role/${body.company_id}`,
                method: 'POST',
                body,
            }),
        }),

        //get company by id
        getCompany: builder.query({
            query: (id) => ({
                url: `/companies/${id}`,
                method: 'GET',
            }),
        }),

        //get companies
        getCompanies: builder.query({
            query: () => ({
                url: '/companies',
                method: 'GET',
            }),
        }),

        //create new company

        createCompany: builder.mutation({
            query: (body) => ({
                url: '/companies',
                method: 'POST',
                body,
            }),
        }),
        //upaate company

        updateCompany: builder.mutation({
            query: (body) => ({
                url: `/companies/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //create new employee

        createEmployee: builder.mutation({
            query: (body) => ({
                url: '/employees',
                method: 'POST',
                body,
            }),
        }),

        //create person mutation
        createPerson: builder.mutation({
            query: (body) => ({
                url: '/people',
                method: 'POST',
                body,
            }),
        }),

        updatePerson: builder.mutation({
            query: (body) => ({
                url: `/people/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //Roles CRUD

        //get company roles
        getCompanyRoles: builder.query({
            query: (company_id) => ({
                url: `/roles?company=${company_id}`,
            }),
        }),

        //create new role
        createRole: builder.mutation({
            query: (body) => ({
                url: '/roles',
                method: 'POST',
                body,
            }),
        }),

        //update role
        updateRole: builder.mutation({
            query: (body) => ({
                url: `/roles/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete role
        deleteRole: builder.mutation({
            query: (id) => ({
                url: `/roles/${id}`,
                method: 'DELETE',
            }),
        }),

        //get all company permissions

        getCompanyPermissions: builder.query({
            query: (company_id) => ({
                url: `/permissions?company=${company_id}`,
                method: 'GET',
            }),
        }),

        //create new permission

        createPermission: builder.mutation({
            query: (body) => ({
                url: '/permissions',
                method: 'POST',
                body,
            }),
        }),

        //detete permission

        deletePermission: builder.mutation({
            query: (id) => ({
                url: `/permissions/${id}`,
                method: 'DELETE',
            }),
        }),

        //get role permissions

        getRolePermissions: builder.query({
            query: (role_id) => ({
                url: `/permissions?role=${role_id}`,
                method: 'GET',
            })
        }),

        //Company Employees CRUD

        //get all company employees

        getCompanyEmployees: builder.query({
            query: (id) => ({
                url: `/employees?company=${id}&&populate=role`,
                method: 'GET',
            }),
        }),

        //create new company employee
        createCompanyEmployee: builder.mutation({
            query: (body) => ({
                url: '/employees',
                method: 'POST',
                body,
            }),
        }),

        //update company employee

        updateCompanyEmployee: builder.mutation({
            query: (body) => ({
                url: `/employees/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company employee using soft delete

        deleteCompanyEmployee: builder.mutation({
            query: (id) => ({
                url: `/employees/${id}`,
                method: 'DELETE',
            }),
        }),

        //Company customers CRUD

        //get all company customers

        getCompanyCustomers: builder.query({
            query: (id) => ({
                url: `/customers?company=${id}`,
                method: 'GET',
            }),
        }),

        //create new company customer

        createCompanyCustomer: builder.mutation({
            query: (body) => ({
                url: '/customers/create',
                method: 'POST',
                body,
            }),
        }),

        //update company customer

        updateCompanyCustomer: builder.mutation({
            query: (body) => ({
                url: `/customers/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company customer using soft delete

        deleteCompanyCustomer: builder.mutation({
            query: (id) => ({
                url: `/customers/${id}`,
                method: 'DELETE',
            }),
        }),

        //Company suppliers CRUD

        //get all company suppliers

        getCompanySuppliers: builder.query({
            query: (id) => ({
                url: `/suppliers?company=${id}`,
                method: 'GET',
            }),
        }),

        //create new company supplier

        createCompanySupplier: builder.mutation({
            query: (body) => ({
                url: '/suppliers/create',
                method: 'POST',
                body,
            }),
        }),

        //update company supplier

        updateCompanySupplier: builder.mutation({
            query: (body) => ({
                url: `/suppliers/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company supplier using soft delete

        deleteCompanySupplier: builder.mutation({
            query: (id) => ({
                url: `/suppliers/${id}`,
                method: 'DELETE',
            }),
        }),

        //Accounting module

        //get account types

        getAccountTypes: builder.query({
            query: (id) => ({
                url: `/account_types`,
                method: 'GET',
            }),
        }),

        //CRUD for accounts

        //get comapny accounts by account type

        getCompanyAccountsByAccountType: builder.query({
            query: (body) => ({
                url: `/accounts?company=${body.company}&&populate=account_type`,
                method: 'GET',
            }),
        }),

        //create new account

        createAccount: builder.mutation({
            query: (body) => ({
                url: '/accounts',
                method: 'POST',
                body,
            }),
        }),

        //update account

        updateAccount: builder.mutation({
            query: (body) => ({
                url: `/accounts/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete account

        deleteAccount: builder.mutation({
            query: (id) => ({
                url: `/accounts/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for transactions

        //get all company transactions

        getCompanyTransactions: builder.query({
            query: (id) => ({
                url: `/transactions?company=${id}&&populate=account_credited_id&&populate=account_debited_id`,
                method: 'GET',
            }),
        }),

        //create new transaction

        createTransaction: builder.mutation({
            query: (body) => ({
                url: '/create-transaction',
                method: 'POST',
                body,
            }),
        }),

        //update transaction

        updateTransaction: builder.mutation({
            query: (body) => ({
                url: `/transactions/${body._id}`,
            }),
        }),

        //delete transaction

        deleteTransaction: builder.mutation({
            query: (id) => ({
                url: `/transactions/${id}`,
                method: 'DELETE',
            }),
        }),

        //update accounts credited and debited

        updateAccountsCreditedAndDebited: builder.mutation({
            query: (body) => ({
                url: `/update-accounts`,
                method: 'POST',
                body,
            }),
        }),

        //generate account statement

        generateAccountStatement: builder.mutation({
            query: (body) => ({
                url: `/account_statement`,
                method: 'POST',
                body,
            }),
        }),

        //Crud for company expenses

        //get all company expenses

        getCompanyExpenses: builder.query({
            query: (id) => ({
                url: `/expenses?company=${id}&&populate=expense_category&&populate=source_account`,
                method: 'GET',
            }),
        }),

        //create new company expense

        createCompanyExpense: builder.mutation({
            query: (body) => ({
                url: '/create-expense',
                method: 'POST',
                body,
            }),
        }),

        //update company expense

        updateCompanyExpense: builder.mutation({
            query: (body) => ({
                url: `/expenses/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company expense

        deleteCompanyExpense: builder.mutation({
            query: (id) => ({
                url: `/expenses/${id}`,
                method: 'DELETE',
            }),
        }),

        //get expense accounts

        getExpenseAccounts: builder.query({
            query: (id) => ({
                url: `/expenses_accounts/${id}`,
                method: 'GET',
            }),
        }),

        //get assets accounts

        getAssetsAccounts: builder.query({
            query: (id) => ({
                url: `/assets_accounts/${id}`,
                method: 'GET',
            }),
        }),

        //get revenue accounts

        getRevenueAccounts: builder.query({
            query: (id) => ({
                url: `/income_accounts/${id}`,
            }),
        }),

        //get company expense suppliers

        getCompanyExpenseSuppliers: builder.query({
            query: (id) => ({
                url: `/suppliers?company=${id}&&category=Expenses`,
                method: 'GET',
            }),
        }),

        //Items CRUD

        //get all company items

        getCompanyItems: builder.query({
            query: (id) => ({
                url: `/items?company=${id}&&populate=category&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create new company item

        createCompanyItem: builder.mutation({
            query: (body) => ({
                url: '/items',
                method: 'POST',
                body,
            }),
        }),

        //update company item

        updateCompanyItem: builder.mutation({
            query: (body) => ({
                url: `/items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company item using soft delete

        deleteCompanyItem: builder.mutation({
            query: (id) => ({
                url: `/items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //CRUD for item categories

        //get all company item categories

        getCompanyItemCategories: builder.query({
            query: (id) => ({
                url: `/item_categories?company=${id}`,
                method: 'GET',
            }),
        }),

        //create new company item category

        createCompanyItemCategory: builder.mutation({
            query: (body) => ({
                url: '/item_categories',
                method: 'POST',
                body,
            }),
        }),

        //update company item category

        updateCompanyItemCategory: builder.mutation({
            query: (body) => ({
                url: `/item_categories/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company item category (soft delete)

        deleteCompanyItemCategory: builder.mutation({
            query: (id) => ({
                url: `/item_categories/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),


        //get company goods suppliers

        getCompanyGoodsSuppliers: builder.query({
            query: (id) => ({
                url: `/suppliers?company=${id}&&category=Goods`,
                method: 'GET',
            }),
        }),

        //CRUD for company properties

        //get all company properties

        getCompanyProperties: builder.query({
            query: (id) => ({
                url: `/properties?company=${id}`,
                method: 'GET',
            }),
        }),

        //create new company property

        createCompanyProperty: builder.mutation({
            query: (body) => ({
                url: '/properties',
                method: 'POST',
                body,
            }),
        }),

        //update company property

        updateCompanyProperty: builder.mutation({
            query: (body) => ({
                url: `/properties/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete company property

        deleteCompanyProperty: builder.mutation({
            query: (id) => ({
                url: `/properties/${id}`,
                method: 'DELETE',
            }),
        }),

        //get company customer property owners

        getCompanyCustomerPropertyOwners: builder.query({
            query: (id) => ({
                url: `/customers?company=${id}&&populate=proper_owner`,
                method: 'GET',
            }),
        }),

        //upload company property image

        uploadCompanyPropertyImage: builder.mutation({
            query: (body) => ({
                url: '/upload',
                method: 'POST',
                body,
            }),
        }),

        //get property images

        getPropertyImages: builder.query({
            query: (id) => ({
                url: `/files/${id}`,
                method: 'GET',
            }),
        }),

        //delete property image

        deletePropertyImage: builder.mutation({
            query: (filename) => ({
                url: `/delete-file/${filename}`,
                method: 'POST',
                body: {},
            }),
        }),

        //CRUD for Industries

        //get all industries

        getIndustries: builder.query({
            query: () => ({
                url: '/industries',
                method: 'GET',
            }),
        }),

        //get all public industries

        getPublicIndustries: builder.query({
            query: () => ({
                url: '/industries?type=public',
                method: 'GET',
            }),
        }),

        //create new industry

        createIndustry: builder.mutation({
            query: (body) => ({
                url: '/industries',
                method: 'POST',
                body,
            }),
        }),

        //update industry

        updateIndustry: builder.mutation({
            query: (body) => ({
                url: `/industries/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete industry

        deleteIndustry: builder.mutation({
            query: (id) => ({
                url: `/industries/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for licenses

        //get all licenses

        getLicenses: builder.query({
            query: () => ({
                url: `/licenses?populate=license_type&&populate=company`,
                method: 'GET',
            }),
        }),

        //get all licenses by company

        getLicensesByCompany: builder.query({
            query: (id) => ({
                url: `/licenses?company=${id}&&populate=license_type`,
                method: 'GET',
            }),
        }),

        //create new license

        createLicense: builder.mutation({
            query: (body) => ({
                url: '/licenses',
                method: 'POST',
                body,
            }),
        }),

        //update license

        updateLicense: builder.mutation({
            query: (body) => ({
                url: `/licenses/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete license

        deleteLicense: builder.mutation({
            query: (id) => ({
                url: `/licenses/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for license types

        //get all license types

        getLicenseTypes: builder.query({
            query: () => ({
                url: `/license_types`,
                method: 'GET',
            }),
        }),

        //get industry license types

        getIndustryLicenseTypes: builder.query({
            query: (id) => ({
                url: `/license_types?industry=${id}&&populate=features`,
                method: 'GET',
            }),
        }),

        //create new license type

        createLicenseType: builder.mutation({
            query: (body) => ({
                url: '/license_types',
                method: 'POST',
                body,
            }),
        }),

        //update license type

        updateLicenseType: builder.mutation({
            query: (body) => ({
                url: `/license_types/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete license type

        deleteLicenseType: builder.mutation({
            query: (id) => ({
                url: `/license_types/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for features

        //get all features

        getFeatures: builder.query({
            query: () => ({
                url: `/features`,
                method: 'GET',
            }),
        }),

        //create new feature

        createFeature: builder.mutation({
            query: (body) => ({
                url: '/features',
                method: 'POST',
                body,
            }),
        }),

        //update feature

        updateFeature: builder.mutation({
            query: (body) => ({
                url: `/features/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete feature

        deleteFeature: builder.mutation({
            query: (id) => ({
                url: `/features/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for brands

        //get all brands

        getBrands: builder.query({
            query: (company_id) => ({
                url: `/brands?company=${company_id}`,
                method: 'GET',
            }),
        }),

        //create new brand

        createBrand: builder.mutation({
            query: (body) => ({
                url: '/brands',
                method: 'POST',
                body,
            }),
        }),

        //update brand

        updateBrand: builder.mutation({
            query: (body) => ({
                url: `/brands/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete brand

        deleteBrand: builder.mutation({
            query: (id) => ({
                url: `/brands/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for Job Cards

        //get all job cards

        getJobCards: builder.query({
            query: (company_id) => ({
                url: `/job_cards?company=${company_id}&&populate=assigned_to&&populate=customer&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get company job card by job card no

        getJobCardByJobCardNo: builder.query({
            query: (query_data) => ({
                url: `/job_cards?company=${query_data.company}&&job_card_no=${query_data.job_card_no}&&populate=assigned_to&&populate=customer&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get job cards by query

        getJobCardsByQuery: builder.query({
            query: (query_data) => ({
                url: `/job_cards?company=${query_data.company}&&populate=assigned_to&&populate=customer&&deleted=false${query_data.query}`,
                method: 'GET',
            }),
        }),

        //create new job card

        createJobCard: builder.mutation({
            query: (body) => ({
                url: '/create-job-card',
                method: 'POST',
                body,
            }),
        }),

        //update job card

        updateJobCard: builder.mutation({
            query: (body) => ({
                url: `/job_cards/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete job card

        deleteJobCard: builder.mutation({
            query: (id) => ({
                url: `/job_cards/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //CRUD for Job Card Items

        //get all job card items

        getJobCardItems: builder.query({
            query: (job_card_id) => ({
                url: `/job_card_items?job_card=${job_card_id}&&populate=status&&populate=item&&populate=brand&&populate=job_card`,
                method: 'GET',
            }),
        }),

        //create new job card item

        createJobCardItem: builder.mutation({
            query: (body) => ({
                url: '/job_card_items',
                method: 'POST',
                body,
            }),
        }),

        //update job card item

        updateJobCardItem: builder.mutation({
            query: (body) => ({
                url: `/job_card_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete job card item

        deleteJobCardItem: builder.mutation({
            query: (id) => ({
                url: `/job_card_items/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD job card item statuses

        //get all job card item statuses

        getJobCardItemStatuses: builder.query({
            query: (company_id) => ({
                url: `/job_card_item_statuses?company=${company_id}`,
                method: 'GET',
            }),
        }),

        //create new job card item status

        createJobCardItemStatus: builder.mutation({
            query: (body) => ({
                url: '/job_card_item_statuses',
                method: 'POST',
                body,
            }),
        }),

        //update job card item status

        updateJobCardItemStatus: builder.mutation({
            query: (body) => ({
                url: `/job_card_item_statuses/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete job card item status

        deleteJobCardItemStatus: builder.mutation({
            query: (id) => ({
                url: `/job_card_item_statuses/${id}`,
                method: 'DELETE',
            }),
        }),

        //CRUD for job card item type

        //get all job card item types

        getJobCardItemTypes: builder.query({
            query: (company_id) => ({
                url: `/job_card_item_types?company=${company_id}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create new job card item type

        createJobCardItemType: builder.mutation({
            query: (body) => ({
                url: '/job_card_item_types',
                method: 'POST',
                body,
            }),
        }),

        //update job card item type

        updateJobCardItemType: builder.mutation({
            query: (body) => ({
                url: `/job_card_item_types/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),


        //delete job card item type soft delete

        deleteJobCardItemType: builder.mutation({
            query: (id) => ({
                url: `/job_card_item_types/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),


        //CRUD for job card item description items

        //get all job card item description items

        getJobCardItemDescriptionItems: builder.query({
            query: (job_card_item_id) => ({
                url: `/job_card_item_description_items?job_card_item=${job_card_item_id}`,
                method: 'GET',
            }),
        }),

        //create new job card item description item

        createJobCardItemDescriptionItem: builder.mutation({
            query: (body) => ({
                url: '/job_card_item_description_items',
                method: 'POST',
                body,
            }),
        }),

        //update job card item description item

        updateJobCardItemDescriptionItem: builder.mutation({
            query: (body) => ({
                url: `/job_card_item_description_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete job card item description item

        deleteJobCardItemDescriptionItem: builder.mutation({
            query: (id) => ({
                url: `/job_card_item_description_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //CRUD for item status change

        //get all item status changes

        getItemStatusChanges: builder.query({
            query: (job_card_item_id) => ({
                url: `/item_status_changes?job_card_item=${job_card_item_id}`,
                method: 'GET',
            }),
        }),

        //create new item status change

        createItemStatusChange: builder.mutation({
            query: (body) => ({
                url: '/item_status_changes',
                method: 'POST',
                body,
            }),
        }),

        //update item status change

        updateItemStatusChange: builder.mutation({
            query: (body) => ({
                url: `/item_status_changes/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete item status change

        deleteItemStatusChange: builder.mutation({
            query: (id) => ({
                url: `/item_status_changes/${id}`,
                method: 'DELETE',
            }),
        }),

        //create invoice from job card

        createInvoiceFromJobCard: builder.mutation({
            query: (body) => ({
                url: '/create-invoice-from-job-card',
                method: 'POST',
                body,
            }),
        }),

        //get currencies

        getCurrencies: builder.query({
            query: () => ({
                url: '/currencies',
                method: 'GET',
            }),
        }),
        //get business accounts by account type

        getBusinessAccountsByAccountType: builder.query({
            query: (account_type, company_id) => ({
                url: `/accounts?account_type=${account_type}&&company=${company_id}`,
                method: 'GET',
            }),
        }),

        //change job card item status

        changeJobCardItemStatus: builder.mutation({
            query: (body) => ({
                url: '/change-job-card-item-status',
                method: 'POST',
                body,
            }),
        }),

        //invoice crud

        //get all invoices

        getInvoices: builder.query({
            query: (company_id) => ({
                url: `/sales?company=${company_id}&&populate=customer&&populate=job_card&&sale_type=invoice&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get all invoices

        getInvoicesByCustomer: builder.query({
            query: (customer_id) => ({
                url: `/sales?customer=${customer_id}&&populate=customer&&populate=job_card&&sale_type=invoice&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get all invoices

        getInvoicesByJobCard: builder.query({
            query: (job_card_id) => ({
                url: `/sales?job_card=${job_card_id}&&populate=customer&&populate=job_card&&sale_type=invoice&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get today invoices

        getTodayInvoices: builder.query({
            query: (company_id) => ({
                url: `/sales?company=${company_id}&&populate=customer&&populate=job_card&&sale_type=invoice&&created_at__gte=${new Date()
                    .toISOString()
                    .slice(0, 10)}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get invoices by status

        getInvoicesByStatus: builder.query({
            query: (company_id, status) => ({
                url: `/sales?company=${company_id}&&populate=customer&&populate=job_card&&sale_type=invoice&&status=${status}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create invoice manually

        createInvoiceManually: builder.mutation({
            query: (body) => ({
                url: '/create-invoice',
                method: 'POST',
                body,
            }),
        }),

        //CREATE INVOICE

        createInvoice: builder.mutation({
            query: (body) => ({
                url: '/sales',
                method: 'POST',
                body,
            }),
        }),

        //update invoice

        updateInvoice: builder.mutation({
            query: (body) => ({
                url: `/sales/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete invoice (soft delete)

        deleteInvoice: builder.mutation({
            query: (id) => ({
                url: `/sales/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //approve-invoice

        approveInvoice: builder.mutation({
            query: (body) => ({
                url: '/approve-invoice',
                method: 'POST',
                body,
            }),
        }),

        //crud for invoice items

        //get all invoice items

        getInvoiceItems: builder.query({
            query: (invoice_id) => ({
                url: `/sale_items?sale=${invoice_id}`,
                method: 'GET',
            }),
        }),

        //create invoice item

        createInvoiceItem: builder.mutation({
            query: (body) => ({
                url: '/sale_items',
                method: 'POST',
                body,
            }),
        }),

        //update invoice item

        updateInvoiceItem: builder.mutation({
            query: (body) => ({
                url: `/sale_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete invoice item

        deleteInvoiceItem: builder.mutation({
            query: (id) => ({
                url: `/sale_items/${id}`,
                method: 'DELETE',
            }),
        }),

        //invoices-by-query

        getInvoicesByQuery: builder.mutation({
            query: (body) => ({
                url: '/invoices-by-query',
                method: 'POST',
                body,
            }),
        }),

        //CRUD for payment methods

        //get all payment methods

        getPaymentMethods: builder.query({
            query: (comapny) => ({
                url: `/payment_methods?company=${comapny}&&deleted=false&&populate=account`,
                method: 'GET',
            }),
        }),

        //create payment method

        createPaymentMethod: builder.mutation({
            query: (body) => ({
                url: '/payment_methods',
                method: 'POST',
                body,
            }),
        }),

        //update payment method

        updatePaymentMethod: builder.mutation({
            query: (body) => ({
                url: `/payment_methods/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete payment method soft delete

        deletePaymentMethod: builder.mutation({
            query: (id) => ({
                url: `/payment_methods/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //CRUD for taxes

        //get all taxes

        getTaxes: builder.query({
            query: (comapny) => ({
                url: `/tax_rates?company=${comapny}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create tax

        createTax: builder.mutation({
            query: (body) => ({
                url: '/tax_rates',
                method: 'POST',
                body,
            }),
        }),

        //update tax

        updateTax: builder.mutation({
            query: (body) => ({
                url: `/tax_rates/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete tax soft delete
        deleteTax: builder.mutation({
            query: (id) => ({
                url: `/tax_rates/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //sale payment crud

        //create sale payment

        createSalePayment: builder.mutation({
            query: (body) => ({
                url: '/record-invoice-payment',
                method: 'POST',
                body,
            }),
        }),

        //send-invoice

        sendInvoice: builder.mutation({
            query: (body) => ({
                url: '/send-invoice',
                method: 'POST',
                body,
            }),
        }),

        //crud for fuel_types

        //get all fuel types

        getFuelTypes: builder.query({
            query: (company) => ({
                url: `/fuel_types?company=${company}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create fuel type

        createFuelType: builder.mutation({
            query: (body) => ({
                url: '/fuel_types',
                method: 'POST',
                body,
            }),
        }),

        //update fuel type

        updateFuelType: builder.mutation({
            query: (body) => ({
                url: `/fuel_types/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete fuel type soft delete

        deleteFuelType: builder.mutation({
            query: (id) => ({
                url: `/fuel_types/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //get fuel delivery-note items

        getFuelDeliveryNoteItems: builder.query({
            query: (comapny) => ({
                url: `/fuel/delivery-notes-items/${comapny}`,
                method: 'GET',
            }),
        }),

        //get all tanks

        getTanks: builder.query({
            query: (company) => ({
                url: `/tanks?company=${company}&&deleted=false&&populate=item`,
                method: 'GET',
            }),
        }),

        //get all tanks by item

        getTanksByItem: builder.query({
            query: (item) => ({
                url: `/tanks?item=${item}&&deleted=false&&populate=item`,
                method: 'GET',
            }),
        }),

        //create tank

        createTank: builder.mutation({
            query: (body) => ({
                url: '/tanks',
                method: 'POST',
                body,
            }),
        }),

        //update tank

        updateTank: builder.mutation({
            query: (body) => ({
                url: `/tanks/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete tank soft delete

        deleteTank: builder.mutation({
            query: (id) => ({
                url: `/tanks/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for terminals

        //get all terminals

        getTerminals: builder.query({
            query: (company) => ({
                url: `/terminals?company=${company}&&populate=tank&&deleted=false`,
                method: 'GET',
            }),
        }),

        //get all terminals by tank

        getTerminalsByTank: builder.query({
            query: (tank) => ({
                url: `/terminals?tank=${tank}&&populate=tank&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create terminal

        createTerminal: builder.mutation({
            query: (body) => ({
                url: '/terminals',
                method: 'POST',
                body,
            }),
        }),

        //update terminal

        updateTerminal: builder.mutation({
            query: (body) => ({
                url: `/terminals/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete terminal soft delete

        deleteTerminal: builder.mutation({
            query: (id) => ({
                url: `/terminals/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for tank_level_records

        //get all tank_level_records

        getTankLevelRecords: builder.query({
            query: (company) => ({
                url: `/get_tank_level_records/${company}`,
                method: 'GET',
            }),
        }),

        //create tank_level_record

        createTankLevelRecord: builder.mutation({
            query: (body) => ({
                url: '/record-tank-level-manually',
                method: 'POST',
                body,
            }),
        }),

        //update tank_level_record

        updateTankLevelRecord: builder.mutation({
            query: (body) => ({
                url: `/tank_level_records/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete tank_level_record soft delete

        deleteTankLevelRecord: builder.mutation({
            query: (id) => ({
                url: `/tank_level_records/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for requisition orders

        //get all requisition orders

        getRequisitionOrders: builder.query({
            query: (company) => ({
                url: `/requisition_orders?company=${company}&&deleted=false&&populate=employee&&populate=supplier`,
                method: 'GET',
            }),
        }),

        //create requisition order

        createRequisitionOrder: builder.mutation({
            query: (body) => ({
                url: '/create-requisition-order-manually',
                method: 'POST',
                body,
            }),
        }),

        //update requisition order

        updateRequisitionOrder: builder.mutation({
            query: (body) => ({
                url: `/requisition_orders/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete requisition order soft delete

        deleteRequisitionOrder: builder.mutation({
            query: (id) => ({
                url: `/requisition_orders/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for requisition order items

        //get all requisition order items

        getRequisitionOrderItems: builder.query({
            query: (requisition_order) => ({
                url: `/requisition_order_items?requisition_order=${requisition_order}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create requisition order item

        createRequisitionOrderItem: builder.mutation({
            query: (body) => ({
                url: '/requisition_order_items',
                method: 'POST',
                body,
            }),
        }),

        //update requisition order item

        updateRequisitionOrderItem: builder.mutation({
            query: (body) => ({
                url: `/requisition_order_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete requisition order item soft delete

        deleteRequisitionOrderItem: builder.mutation({
            query: (id) => ({
                url: `/requisition_order_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //approve requisition order

        approveRequisitionOrder: builder.mutation({
            query: (body) => ({
                url: `/approve-requisition-order`,
                method: 'POST',
                body,
            }),
        }),

        //reject requisition order
        rejectRequisitionOrder: builder.mutation({
            query: (body) => ({
                url: `/reject-requisition-order`,
                method: 'POST',
                body,
            }),
        }),

        //crud for purchase orders

        //get all purchase orders

        getPurchaseOrders: builder.query({
            query: (company) => ({
                url: `/purchase_orders?company=${company}&&deleted=false&&populate=employee&&populate=supplier`,
                method: 'GET',
            }),
        }),

        //create purchase order

        createPurchaseOrder: builder.mutation({
            query: (body) => ({
                url: '/create-purchase-order-manually',
                method: 'POST',
                body,
            }),
        }),

        //update purchase order

        updatePurchaseOrder: builder.mutation({
            query: (body) => ({
                url: `/purchase_orders/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete purchase order soft delete

        deletePurchaseOrder: builder.mutation({
            query: (id) => ({
                url: `/purchase_orders/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //receive purchase order

        receivePurchaseOrder: builder.mutation({
            query: (body) => ({
                url: `/recieve-purchase-order`,
                method: 'POST',
                body,
            }),
        }),

        //cancel purchase order

        cancelPurchaseOrder: builder.mutation({
            query: (body) => ({
                url: `/cancel-purchase-order`,
                method: 'POST',
                body,
            }),
        }),

        confirmRecieving: builder.mutation({
            query: (body) => ({
                url: `/confirm-recieving`,
                method: 'POST',
                body,
            }),
        }),

        //crud for purchase order items

        //get all purchase order items

        getPurchaseOrderItems: builder.query({
            query: (purchase_order) => ({
                url: `/purchase_order_items?purchase_order=${purchase_order}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create purchase order item

        createPurchaseOrderItem: builder.mutation({
            query: (body) => ({
                url: '/purchase_order_items',
                method: 'POST',
                body,
            }),
        }),

        //update purchase order item

        updatePurchaseOrderItem: builder.mutation({
            query: (body) => ({
                url: `/purchase_order_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete purchase order item soft delete

        deletePurchaseOrderItem: builder.mutation({
            query: (id) => ({
                url: `/purchase_order_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for recieving

        //get all recieving

        getRecievings: builder.query({
            query: (company) => ({
                url: `/recievings?company=${company}&&deleted=false&&populate=employee&&populate=supplier`,
                method: 'GET',
            }),
        }),

        //create recieving

        createRecieving: builder.mutation({
            query: (body) => ({
                url: '/create-recieving-manually',
                method: 'POST',
                body,
            }),
        }),

        //update recieving

        updateRecieving: builder.mutation({
            query: (body) => ({
                url: `/recievings/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete recieving soft delete

        deleteRecieving: builder.mutation({
            query: (id) => ({
                url: `/recievings/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //confirm-recieving

        confirmRecieving: builder.mutation({
            query: (body) => ({
                url: `/confirm-recieving`,
                method: 'POST',
                body,
            }),
        }),

        //crud for recieving items

        //get all recieving items

        getRecievingItems: builder.query({
            query: (receiving) => ({
                url: `/recieving_items?recieving=${receiving}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create recieving item

        createRecievingItem: builder.mutation({
            query: (body) => ({
                url: '/recieving_items',
                method: 'POST',
                body,
            }),
        }),

        //update recieving item

        updateRecievingItem: builder.mutation({
            query: (body) => ({
                url: `/recieving_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete recieving item soft delete

        deleteRecievingItem: builder.mutation({
            query: (id) => ({
                url: `/recieving_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for sales orders

        //get all sales orders

        getSalesOrders: builder.query({
            query: (company) => ({
                url: `/sale_orders?company=${company}&&deleted=false&&populate=employee&&populate=customer`,
                method: 'GET',
            }),
        }),

        //create sales order

        createSalesOrder: builder.mutation({
            query: (body) => ({
                url: '/create-sale-order-manually',
                method: 'POST',
                body,
            }),
        }),

        //update sales order

        updateSalesOrder: builder.mutation({
            query: (body) => ({
                url: `/sale_orders/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete sales order soft delete

        deleteSalesOrder: builder.mutation({
            query: (id) => ({
                url: `/sale_orders/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for sales order items

        //get all sales order items

        getSalesOrderItems: builder.query({
            query: (sale_order) => ({
                url: `/sale_order_items?sale_order=${sale_order}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create sales order item

        createSalesOrderItem: builder.mutation({
            query: (body) => ({
                url: '/sale_order_items',
                method: 'POST',
                body,
            }),
        }),

        //update sales order item

        updateSalesOrderItem: builder.mutation({
            query: (body) => ({
                url: `/sale_order_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete sales order item soft delete

        deleteSalesOrderItem: builder.mutation({
            query: (id) => ({
                url: `/sale_order_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for delivery notes

        //get all delivery notes

        getDeliveryNotes: builder.query({
            query: (company) => ({
                url: `/delivery_notes?company=${company}&&deleted=false&&populate=employee&&populate=customer`,
                method: 'GET',
            }),
        }),

        //create delivery note

        createDeliveryNote: builder.mutation({
            query: (body) => ({
                url: '/create-delivery-note-manually',
                method: 'POST',
                body,
            }),
        }),

        //approve delivery note

        approveDeliveryNote: builder.mutation({
            query: (body) => ({
                url: '/approve-delivery-note',
                method: 'POST',
                body,
            }),
        }),

        //update delivery note

        updateDeliveryNote: builder.mutation({
            query: (body) => ({
                url: `/delivery_notes/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete delivery note soft delete

        deleteDeliveryNote: builder.mutation({
            query: (id) => ({
                url: `/delivery_notes/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for delivery note items

        //get all delivery note items

        getDeliveryNoteItems: builder.query({
            query: (delivery_note) => ({
                url: `/delivery_note_items?delivery_note=${delivery_note}&&deleted=false&&populate=item`,
                method: 'GET',
            }),
        }),

        //create delivery note item

        createDeliveryNoteItem: builder.mutation({
            query: (body) => ({
                url: '/delivery_note_items',
                method: 'POST',
                body,
            }),
        }),

        //create-invoice-from-delivery-note

        createInvoiceFromDeliveryNote: builder.mutation({
            query: (body) => ({
                url: '/create-invoice-from-delivery-note',
                method: 'POST',
                body,
            }),
        }),

        //update delivery note item

        updateDeliveryNoteItem: builder.mutation({
            query: (body) => ({
                url: `/delivery_note_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete delivery note item soft delete

        deleteDeliveryNoteItem: builder.mutation({
            query: (id) => ({
                url: `/delivery_note_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for stock adjustments

        //get all stock adjustments

        getStockAdjustments: builder.query({
            query: (company) => ({
                url: `/get_stock_adjustments/${company}`,
                method: 'GET',
            }),
        }),

        //create stock adjustment

        createStockAdjustment: builder.mutation({
            query: (body) => ({
                url: '/stock_adjustments',
                method: 'POST',
                body,
            }),
        }),

        //update stock adjustment

        updateStockAdjustment: builder.mutation({
            query: (body) => ({
                url: `/stock_adjustments/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete stock adjustment soft delete

        deleteStockAdjustment: builder.mutation({
            query: (id) => ({
                url: `/stock_adjustments/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //approve-stock-adjustment

        approveStockAdjustment: builder.mutation({
            query: (body) => ({
                url: `/approve-stock-adjustment`,
                method: 'POST',
                body,
            }),
        }),

        //sales agreement

        //get all sale agreements

        getSaleAgreements: builder.query({
            query: (company) => ({
                url: `/sale_agreements?company=${company}&&deleted=false&&populate=customer&&populate=created_by`,
                method: 'GET',
            }),
        }),

        //create sale agreement

        createSaleAgreement: builder.mutation({
            query: (body) => ({
                url: '/create-sale-agreement-manually',
                method: 'POST',
                body,
            }),
        }),

        //update sale agreement

        updateSaleAgreement: builder.mutation({
            query: (body) => ({
                url: `/sale_agreements/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete sale agreement soft delete

        deleteSaleAgreement: builder.mutation({
            query: (id) => ({
                url: `/sale_agreements/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //crud for sale agreement items

        //get all sale agreement items

        getSaleAgreementItems: builder.query({
            query: (sale_agreement) => ({
                url: `/sale_agreement_items?sale_agreement=${sale_agreement}&&deleted=false`,
                method: 'GET',
            }),
        }),

        //create sale agreement item

        createSaleAgreementItem: builder.mutation({
            query: (body) => ({
                url: '/sale_agreement_items',
                method: 'POST',
                body,
            }),
        }),

        //update sale agreement item

        updateSaleAgreementItem: builder.mutation({
            query: (body) => ({
                url: `/sale_agreement_items/${body._id}`,
                method: 'PUT',
                body,
            }),
        }),

        //delete sale agreement item soft delete

        deleteSaleAgreementItem: builder.mutation({
            query: (id) => ({
                url: `/sale_agreement_items/${id}`,
                method: 'PUT',
                body: {
                    deleted: true,
                },
            }),
        }),

        //delivery-notes-items-meter-report mutation

        deliveryNotesItemsMeterReport: builder.mutation({
            query: (body) => ({
                url: '/delivery-notes-items-meter-report',
                method: 'POST',
                body,
            }),
        }),

        //fulfill-sale-order-with-delivery-note

        fulfillSaleOrderWithDeliveryNote: builder.mutation({
            query: (body) => ({
                url: '/fulfill-sale-order-with-delivery-note',
                method: 'POST',
                body,
            }),
        }),


    }),
});

export const {
    useLoginMutation,
    useSignupMutation,
    useResetPasswordMutation,
    useGetEmployeesQuery,
    useCreateCompanyAdminRoleMutation,
    useGetCompanyQuery,
    useGetCompaniesQuery,
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
    useCreateEmployeeMutation,
    useGetCompanyEmployeesQuery,
    useCreatePersonMutation,
    useUpdatePersonMutation,
    useGetCompanyRolesQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useGetCompanyPermissionsQuery,
    useGetRolePermissionsQuery,
    useCreatePermissionMutation,
    useDeletePermissionMutation,
    useCreateCompanyEmployeeMutation,
    useUpdateCompanyEmployeeMutation,
    useDeleteCompanyEmployeeMutation,

    //Company Customers CRUD
    useGetCompanyCustomersQuery,
    useCreateCompanyCustomerMutation,
    useUpdateCompanyCustomerMutation,
    useDeleteCompanyCustomerMutation,

    //Company Suppliers CRUD

    useGetCompanySuppliersQuery,
    useCreateCompanySupplierMutation,
    useUpdateCompanySupplierMutation,
    useDeleteCompanySupplierMutation,

    //Accounting module
    useGetAccountTypesQuery,
    useGetCompanyAccountsByAccountTypeQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    useDeleteAccountMutation,
    useGetCompanyTransactionsQuery,
    useCreateTransactionMutation,
    useUpdateTransactionMutation,
    useDeleteTransactionMutation,
    useUpdateAccountsCreditedAndDebitedMutation,
    useGenerateAccountStatementMutation,

    //Company Expenses CRUD

    useGetCompanyExpensesQuery,
    useCreateCompanyExpenseMutation,
    useUpdateCompanyExpenseMutation,
    useDeleteCompanyExpenseMutation,
    useGetExpenseAccountsQuery,
    useGetRevenueAccountsQuery,
    useGetAssetsAccountsQuery,
    useGetCompanyExpenseSuppliersQuery,

    //Items CRUD

    useGetCompanyItemsQuery,
    useCreateCompanyItemMutation,
    useUpdateCompanyItemMutation,
    useDeleteCompanyItemMutation,
    useGetCompanyGoodsSuppliersQuery,

    //Items categories CRUD

    useGetCompanyItemCategoriesQuery,
    useCreateCompanyItemCategoryMutation,
    useUpdateCompanyItemCategoryMutation,
    useDeleteCompanyItemCategoryMutation,

    //Company Properties CRUD

    useGetCompanyPropertiesQuery,
    useCreateCompanyPropertyMutation,
    useUpdateCompanyPropertyMutation,
    useDeleteCompanyPropertyMutation,
    useGetCompanyCustomerPropertyOwnersQuery,
    useUploadCompanyPropertyImageMutation,
    useGetPropertyImagesQuery,
    useDeletePropertyImageMutation,

    //CRUD for Industries

    useGetIndustriesQuery,
    useGetPublicIndustriesQuery,
    useCreateIndustryMutation,
    useUpdateIndustryMutation,
    useDeleteIndustryMutation,

    //CRUD for licenses

    useGetLicensesQuery,
    useGetLicensesByCompanyQuery,
    useCreateLicenseMutation,
    useUpdateLicenseMutation,
    useDeleteLicenseMutation,

    //CRUD for license types

    useGetLicenseTypesQuery,
    useGetIndustryLicenseTypesQuery,
    useCreateLicenseTypeMutation,
    useUpdateLicenseTypeMutation,
    useDeleteLicenseTypeMutation,

    //CRUD for features

    useGetFeaturesQuery,
    useCreateFeatureMutation,
    useUpdateFeatureMutation,
    useDeleteFeatureMutation,

    //CRUD for brands

    useGetBrandsQuery,
    useCreateBrandMutation,
    useUpdateBrandMutation,
    useDeleteBrandMutation,

    //CRUD for job cards

    useGetJobCardsQuery,
    useGetJobCardByJobCardNoQuery,
    useGetJobCardsByQueryQuery,
    useCreateJobCardMutation,
    useUpdateJobCardMutation,
    useDeleteJobCardMutation,

    //CRUD for job card items

    useGetJobCardItemsQuery,
    useCreateJobCardItemMutation,
    useUpdateJobCardItemMutation,
    useDeleteJobCardItemMutation,

    //CRUD for job card item types

    useGetJobCardItemTypesQuery,
    useCreateJobCardItemTypeMutation,
    useUpdateJobCardItemTypeMutation,
    useDeleteJobCardItemTypeMutation,

    //CRUD for job card item statuses

    useGetJobCardItemStatusesQuery,
    useCreateJobCardItemStatusMutation,
    useUpdateJobCardItemStatusMutation,
    useDeleteJobCardItemStatusMutation,

    //CRUD for item status changes

    useGetItemStatusChangesQuery,
    useCreateItemStatusChangeMutation,
    useUpdateItemStatusChangeMutation,
    useDeleteItemStatusChangeMutation,

    //create invoice from job card

    useCreateInvoiceFromJobCardMutation,

    //get currencies

    useGetCurrenciesQuery,

    //get business accounts by account type

    useGetBusinessAccountsByAccountTypeQuery,

    //change job card item status

    useChangeJobCardItemStatusMutation,

    //get today invoices

    useGetTodayInvoicesQuery,

    //get invoices by status

    useGetInvoicesByStatusQuery,

    //create invoice manually

    useCreateInvoiceManuallyMutation,

    //create invoice

    useCreateInvoiceMutation,

    //update invoice

    useUpdateInvoiceMutation,

    //delete invoice

    useDeleteInvoiceMutation,

    //approve invoice

    useApproveInvoiceMutation,

    //crud for invoice items

    //get all invoice items
    useGetInvoiceItemsQuery,
    useCreateInvoiceItemMutation,
    useUpdateInvoiceItemMutation,
    useDeleteInvoiceItemMutation,

    //invoices-by-query

    useGetInvoicesByQueryMutation,

    //CRUD for payment methods

    //get all payment methods
    useGetPaymentMethodsQuery,
    useCreatePaymentMethodMutation,
    useUpdatePaymentMethodMutation,
    useDeletePaymentMethodMutation,

    //CRUD for taxes

    //get all taxes
    useGetTaxesQuery,
    useCreateTaxMutation,
    useUpdateTaxMutation,
    useDeleteTaxMutation,

    //sale payment crud

    //create sale payment

    useCreateSalePaymentMutation,

    //send invoice

    useSendInvoiceMutation,

    //crud for fuel_types

    //get all fuel_types
    useGetFuelTypesQuery,
    useCreateFuelTypeMutation,
    useUpdateFuelTypeMutation,
    useDeleteFuelTypeMutation,

    //get fuel delivery-note items

    useGetFuelDeliveryNoteItemsQuery,

    //crud for tanks

    //get all tanks
    useGetTanksQuery,
    useGetTanksByItemQuery,
    useCreateTankMutation,
    useUpdateTankMutation,
    useDeleteTankMutation,

    //crud for terminals

    //get all terminals
    useGetTerminalsQuery,
    useCreateTerminalMutation,
    useUpdateTerminalMutation,
    useDeleteTerminalMutation,

    //crud for tank_level_records

    //get all tank_level_records
    useGetTankLevelRecordsQuery,
    useCreateTankLevelRecordMutation,
    useUpdateTankLevelRecordMutation,
    useDeleteTankLevelRecordMutation,

    //crud for requisition orders

    //get all requisition orders
    useGetRequisitionOrdersQuery,
    useCreateRequisitionOrderMutation,
    useUpdateRequisitionOrderMutation,
    useDeleteRequisitionOrderMutation,

    //crud for requisition order items

    //get all requisition order items
    useGetRequisitionOrderItemsQuery,
    useCreateRequisitionOrderItemMutation,
    useUpdateRequisitionOrderItemMutation,
    useDeleteRequisitionOrderItemMutation,
    useApproveRequisitionOrderMutation,
    useRejectRequisitionOrderMutation,

    //crud for purchase orders

    //get all purchase orders
    useGetPurchaseOrdersQuery,
    useCreatePurchaseOrderMutation,
    useUpdatePurchaseOrderMutation,
    useDeletePurchaseOrderMutation,
    useReceivePurchaseOrderMutation,
    useCancelPurchaseOrderMutation,
    useConfirmRecievingMutation,

    //crud for purchase order items

    //get all purchase order items
    useGetPurchaseOrderItemsQuery,
    useCreatePurchaseOrderItemMutation,
    useUpdatePurchaseOrderItemMutation,
    useDeletePurchaseOrderItemMutation,

    //crud for recieving

    //get all recieving
    useGetRecievingsQuery,
    useCreateRecievingMutation,
    useUpdateRecievingMutation,
    useDeleteRecievingMutation,

    //crud for recieving items

    //get all recieving items
    useGetRecievingItemsQuery,
    useCreateRecievingItemMutation,
    useUpdateRecievingItemMutation,
    useDeleteRecievingItemMutation,

    //crud for sales order

    //get all sales order
    useGetSalesOrdersQuery,
    useCreateSalesOrderMutation,
    useUpdateSalesOrderMutation,
    useDeleteSalesOrderMutation,

    //crud for sales order items

    //get all sales order items
    useGetSalesOrderItemsQuery,
    useCreateSalesOrderItemMutation,
    useUpdateSalesOrderItemMutation,
    useDeleteSalesOrderItemMutation,

    //crud for stock adjustments

    //get all stock adjustments
    useGetStockAdjustmentsQuery,
    useCreateStockAdjustmentMutation,
    useUpdateStockAdjustmentMutation,
    useDeleteStockAdjustmentMutation,
    useApproveStockAdjustmentMutation,

    //crud for delivery notes

    //get all delivery notes
    useGetDeliveryNotesQuery,
    useCreateDeliveryNoteMutation,
    useUpdateDeliveryNoteMutation,
    useDeleteDeliveryNoteMutation,
    useApproveDeliveryNoteMutation,

    //crud for delivery note items

    //get all delivery note items
    useGetDeliveryNoteItemsQuery,
    useCreateDeliveryNoteItemMutation,
    useCreateInvoiceFromDeliveryNoteMutation,
    useUpdateDeliveryNoteItemMutation,
    useDeleteDeliveryNoteItemMutation,

    //crud for sale agreements

    //get all sale agreements
    useGetSaleAgreementsQuery,
    useCreateSaleAgreementMutation,
    useUpdateSaleAgreementMutation,
    useDeleteSaleAgreementMutation,

    //crud for sale agreement items

    //get all sale agreement items
    useGetSaleAgreementItemsQuery,
    useCreateSaleAgreementItemMutation,
    useUpdateSaleAgreementItemMutation,
    useDeleteSaleAgreementItemMutation,

    useDeliveryNotesItemsMeterReportMutation,

    useFulfillSaleOrderWithDeliveryNoteMutation,

} = apiSlice;
