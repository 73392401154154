/**
 * CompanyRegistration.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { filter } from 'rxjs/operators';
import { useDispatch, useSelector } from 'react-redux';

import { Steps } from 'antd';
import { FileUploader, OxNotify } from '@shared';

import Logo from '@shared/Logo';
import BlackLogo from '@assets/img/rgs/logo_transparent.png';
import { SessionService, CompaniesService } from '@services';
import { LOCAL_STORAGE_ENUMS, ROUTES } from '@enums';

import { companyAction } from '@redux/companySlice';
import { employeeAction } from '@redux/employeeSlice';

const { Step } = Steps;

const { RGS_LICENSE_TYPES, REGISTRATION_LICENSE_TYPE } = LOCAL_STORAGE_ENUMS;
const { DASHBOARD } = ROUTES;

const companiesService = CompaniesService.create();
const session = SessionService.session;

let localDataSubscription = session;

const sm = SessionService.create();

const validationSchema = Yup.object({
    name: Yup.string().required('Company name is required'),
    endorsement_company: Yup.string().required('Endorsement company is required'),
    registration_number: Yup.string().required('Registration number is required'),
    tax_number: Yup.string().required('Tax number is required'),
    NEAB_number: Yup.string().required('NEAB number is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone_number: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    contact_person_ID_number: Yup.string().required('Contact person ID number is required'),
    physical_address: Yup.string().required('Physical address is required'),
    license_type: Yup.string(),
});

const displayError = (touched, errors, fieldName) => {
    if (touched[fieldName] && errors[fieldName]) {
        return <div className='text-danger'>{errors[fieldName]}</div>;
    }
    return null;
};

const uploadInstructions = {
    RGSP: {
        title: 'Property Management Company',
        description: 'Upload the following documents:',
        documents: [
            'Company Registration Documents',
            'Tax Certificate',
            'NEAB Certificate',
            'Endorsement Letter',
        ],
    },
    RGS_LL: {
        title: 'LandLord',
        description: 'Upload the following documents:',
        documents: ['Proof of residence/ownership', 'Identification Document'],
    },
    RGS_REA: {
        title: 'Real Estate Agent',
        description: 'Upload the following documents:',
        documents: ['NEAB Certificate', 'Endorsement Letter', 'Identification Document'],
    },
};

export const CompanyRegistration = () => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(0);
    const [licenseType, setLicenseType] = useState({ _id: '', code: 'RGSP' });

    const userState = useSelector((state) => state.user);

    useEffect(() => {
        listenToSession();
        const a = sm.get(REGISTRATION_LICENSE_TYPE);
        console.log('userState: ', userState);
        return () => {
            if (localDataSubscription) localDataSubscription.unsubscribe();
        };
    }, []);

    const prepareData = (values) => {
        console.log('values: ', values);
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === REGISTRATION_LICENSE_TYPE) {
                setLicenseType(data);
            }
        }
    };

    const listenToSession = () => {
        const concerns = [RGS_LICENSE_TYPES, REGISTRATION_LICENSE_TYPE];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            endorsement_company: '',
            registration_number: '',
            tax_number: '',
            NEAB_number: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            email: userState.user.email,
            contact_person_ID_number: '',
            physical_address: '',
            license_type: licenseType._id,
        },
        validationSchema,
        onSubmit: (values) => {
            values.license_type = licenseType._id;
            values.email = userState.user.email;
            console.log('values: ', values);
            companiesService.create(values).subscribe(
                (response) => {
                    console.log('created company: ', response);
                    OxNotify({
                        title: 'Success',
                        content: 'Company Registration Success',
                        type: 'success',
                    });
                    const data = response.data;

                    dispatch(companyAction.setCompany(data.company));

                    dispatch(employeeAction.setEmployee(data.employee));

                    setCurrentStep(1);
                },
                (error) => {
                    console.log('Error: ', error);
                    OxNotify({
                        title: 'Error',
                        content: error.message,
                        type: 'error',
                    });
                }
            );
        },
    });

    return (
        <div className='license-container'>
            <Container className='border border-info  mt-3 mb-3'>
                <Row className='mb-2 mt-2 text-center'>
                    <Col>
                        <Logo src={BlackLogo} width={250} />
                        {/* <p className='mt-3'>System Description Here</p> */}
                    </Col>
                </Row>

                {/* Title */}
                <Row className='mb-4'>
                    <Col className='text-center'>
                        <h2 style={{ color: '#60caed' }}>Please fill in your details</h2>
                    </Col>
                </Row>
                <Steps current={currentStep} className='mb-7 w-75 text-center'>
                    <Step title='Company Details' />
                    <Step title='Upload Documents' />
                </Steps>

                {currentStep === 0 ? (
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            {/* Company Name */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        isInvalid={formik.touched.name && !!formik.errors.name}
                                    />
                                    {displayError(formik.touched, formik.errors, 'name')}
                                </Form.Group>
                            </Col>

                            {/* Endorsement Company */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Endorsement Company</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='endorsement_company'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.endorsement_company}
                                        isInvalid={
                                            formik.touched.endorsement_company &&
                                            !!formik.errors.endorsement_company
                                        }
                                    />
                                    {displayError(
                                        formik.touched,
                                        formik.errors,
                                        'endorsement_company'
                                    )}
                                </Form.Group>
                            </Col>

                            {/* Registration Number */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Registration Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='registration_number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.registration_number}
                                        isInvalid={
                                            formik.touched.registration_number &&
                                            !!formik.errors.registration_number
                                        }
                                    />
                                    {displayError(
                                        formik.touched,
                                        formik.errors,
                                        'registration_number'
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {/* Tax Number */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Tax Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='tax_number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tax_number}
                                        isInvalid={
                                            formik.touched.tax_number && !!formik.errors.tax_number
                                        }
                                    />
                                    {displayError(formik.touched, formik.errors, 'tax_number')}
                                </Form.Group>
                            </Col>

                            {/* NEAB Number */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>NEAB Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='NEAB_number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.NEAB_number}
                                        isInvalid={
                                            formik.touched.NEAB_number &&
                                            !!formik.errors.NEAB_number
                                        }
                                    />
                                    {displayError(formik.touched, formik.errors, 'NEAB_number')}
                                </Form.Group>
                            </Col>

                            {/* First Name */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='first_name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.first_name}
                                        isInvalid={
                                            formik.touched.first_name && !!formik.errors.first_name
                                        }
                                    />
                                    {displayError(formik.touched, formik.errors, 'first_name')}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {/* Last Name */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='last_name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.last_name}
                                        isInvalid={
                                            formik.touched.last_name && !!formik.errors.last_name
                                        }
                                    />
                                    {displayError(formik.touched, formik.errors, 'last_name')}
                                </Form.Group>
                            </Col>

                            {/* Phone Number */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='phone_number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone_number}
                                        isInvalid={
                                            formik.touched.phone_number &&
                                            !!formik.errors.phone_number
                                        }
                                    />
                                    {displayError(formik.touched, formik.errors, 'phone_number')}
                                </Form.Group>
                            </Col>

                            {/* Email */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type='email'
                                        name='email'
                                        disabled
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        isInvalid={formik.touched.email && !!formik.errors.email}
                                    />
                                    {displayError(formik.touched, formik.errors, 'email')}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {/* Contact Person ID Number */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Contact Person ID Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='contact_person_ID_number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.contact_person_ID_number}
                                        isInvalid={
                                            formik.touched.contact_person_ID_number &&
                                            !!formik.errors.contact_person_ID_number
                                        }
                                    />
                                    {displayError(
                                        formik.touched,
                                        formik.errors,
                                        'contact_person_ID_number'
                                    )}
                                </Form.Group>
                            </Col>

                            {/* Physical Address */}
                            <Col>
                                <Form.Group>
                                    <Form.Label>Physical Address</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='physical_address'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.physical_address}
                                        isInvalid={
                                            formik.touched.physical_address &&
                                            !!formik.errors.physical_address
                                        }
                                    />
                                    {formik.touched.physical_address &&
                                    formik.errors.physical_address ? (
                                        <div className='text-danger'>
                                            {formik.errors.physical_address}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* License Type (hidden) */}
                        <Form.Group>
                            <Form.Control
                                type='hidden'
                                name='license_type'
                                value={formik.values.license_type}
                            />
                        </Form.Group>

                        <Button className='mt-4 mb-4' type='submit' onClick={formik.handleSubmit}>
                            Create Company
                        </Button>
                    </Form>
                ) : (
                    <div>
                        {/* Placeholder for Ant Design Upload Component */}
                        <FileUploader
                            _id={licenseType._id}
                            path={DASHBOARD}
                            uploadInstructions={uploadInstructions[licenseType.code]}
                        />{' '}
                    </div>
                )}
            </Container>
        </div>
    );
};
