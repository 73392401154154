import React, { useEffect } from 'react';
import CardHeader from '../../components/shared/CardHeader';
import IndustryModal from './IndustryModal';

import {
    useGetIndustriesQuery,
    useCreateIndustryMutation,
    useUpdateIndustryMutation,
    useDeleteIndustryMutation,
} from '../../Redux/apiSlice';
import OxButton from '../../components/shared/OxButton';

function Industries() {
    //get industries
    const { data, error, isLoading, refetch: refechIndustries } = useGetIndustriesQuery();

    //create industry
    const [createIndustry, createIndustryResult] = useCreateIndustryMutation();

    //update industry
    const [updateIndustry, updateIndustryResult] = useUpdateIndustryMutation();

    //delete industry
    const [deleteIndustry, deleteIndustryResult] = useDeleteIndustryMutation();

    //industry state
    const [industry, setIndustry] = React.useState({
        name: '',
        description: '',
        type: '',
    });

    //industries array state

    const [industries, setIndustriesArray] = React.useState([]);

    //modal text
    const [modalText, setModalText] = React.useState('New Industry');

    //Industry Modal state

    const [showIndustryModal, setShowIndustryModal] = React.useState(false);

    //hande show industry modal

    const handleShowIndustryModal = () => {
        setShowIndustryModal(true);
        setModalText('New Industry');
        //clear industry state
        setIndustry({
            name: '',
            description: '',
            type: '',
        });
    };

    //handle close industry modal

    const handleCloseIndustryModal = () => {
        setShowIndustryModal(false);
    };

    //handle edit industry

    const handleEditIndustry = (industry) => {
        setShowIndustryModal(true);
        setModalText('Edit Industry');
        setIndustry(industry);
    };

    //search text
    const [searchText, setSearchText] = React.useState('');

    //handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setIndustry((prevIndustry) => ({
            ...prevIndustry,
            [name]: value,
        }));
    };

    //handle create industry

    const handleCreateIndustry = () => {
        createIndustry(industry);
    };

    //handle update industry

    const handleUpdateIndustry = () => {
        updateIndustry(industry);
    };

    //handle delete industry

    const handleDeleteIndustry = () => {
        deleteIndustry(industry._id);
    };

    useEffect(() => {
        if (data) {
            setIndustriesArray(data);
        }

        if (createIndustryResult.data) {
            refechIndustries();
            handleCloseIndustryModal();
        }

        if (updateIndustryResult.data) {
            refechIndustries();
            handleCloseIndustryModal();
        }

        if (deleteIndustryResult.data) {
            refechIndustries();
            handleCloseIndustryModal();
        }
    }, [
        data,
        createIndustryResult.isLoading,
        updateIndustryResult.isLoading,
        deleteIndustryResult.isLoading,
    ]);

    return (
        <div className='card strpied-tabled-with-hover'>
            <CardHeader
                buttonText={'New Industry'}
                handleSearch={handleSearch}
                handleShow={handleShowIndustryModal}
                headerTitile={'Industries'}
                headerDescription={'Manage Industries'}
                placeholder={'Search Industries'}
                searchText={searchText}
            />

            <div className='card-body table-full-width table-responsive'>
                <table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {industries &&
                            industries.map((industry) => (
                                <tr key={industry._id}>
                                    <td>{industry.name}</td>
                                    <td>{industry.description}</td>
                                    <td>{industry.type}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditIndustry(industry)}
                                            text={'Edit'}
                                        />
                                        <OxButton
                                            action={() => handleDeleteIndustry(industry)}
                                            text={'Delete'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <IndustryModal
                show={showIndustryModal}
                onHide={handleCloseIndustryModal}
                modalText={modalText}
                industry={industry}
                handleCreateIndustry={handleCreateIndustry}
                handleUpdateIndustry={handleUpdateIndustry}
                handleChange={handleChange}
            />
        </div>
    );
}

export default Industries;
