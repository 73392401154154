import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';

import {
    useGetTaxesQuery,
    useCreateTaxMutation,
    useUpdateTaxMutation,
    useDeleteTaxMutation,
} from '../../../Redux/apiSlice';
import TaxRateModal from './TaxRateModal';

function TaxRates() {
    const company = useSelector((state) => state.company);

    // Get tax rates
    const { data, error, isLoading, refetch: refetchTaxRates } = useGetTaxesQuery(company._id);

    // Create tax rate
    const [createTaxRate, createTaxRateResult] = useCreateTaxMutation();

    // Update tax rate
    const [updateTaxRate, updateTaxRateResult] = useUpdateTaxMutation();

    // Delete tax rate
    const [deleteTaxRate, deleteTaxRateResult] = useDeleteTaxMutation();

    // Tax rate state
    const [taxRate, setTaxRate] = React.useState({
        name: '',
        description: '',
        rate: 15,
        type: 'percentage',
        company: company._id,
    });

    // Tax rates array state
    const [taxRates, setTaxRates] = React.useState([]);

    // Modal text
    const [modalText, setModalText] = React.useState('New Tax Rate');

    // Tax Rate Modal state
    const [showTaxRateModal, setShowTaxRateModal] = React.useState(false);

    // Handle show tax rate modal
    const handleShowTaxRateModal = () => {
        setShowTaxRateModal(true);
        setModalText('New Tax Rate');
        setTaxRate({
            name: '',
            description: '',
            rate: 15,
            type: 'percentage',
            company: company._id,
        });
    };

    // Handle close tax rate modal
    const handleCloseTaxRateModal = () => {
        setShowTaxRateModal(false);
    };

    // Handle edit tax rate
    const handleEditTaxRate = (taxRate) => {
        setShowTaxRateModal(true);
        setModalText('Edit Tax Rate');
        setTaxRate(taxRate);
    };

    // Search text
    const [searchText, setSearchText] = React.useState('');

    // Handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTaxRate((prevTaxRate) => ({
            ...prevTaxRate,
            [name]: value,
        }));
    };

    // Handle create tax rate
    const handleCreateTaxRate = () => {
        createTaxRate(taxRate);
    };

    // Handle update tax rate
    const handleUpdateTaxRate = () => {
        updateTaxRate(taxRate);
    };

    // Handle delete tax rate
    const handleDeleteTaxRate = (taxRate) => {
        deleteTaxRate(taxRate._id);
    };

    useEffect(() => {
        if (data) {
            setTaxRates(data);
        }

        if (createTaxRateResult.data) {
            refetchTaxRates();
            handleCloseTaxRateModal();
        }

        if (updateTaxRateResult.data) {
            refetchTaxRates();
            handleCloseTaxRateModal();
        }

        if (deleteTaxRateResult.data) {
            refetchTaxRates();
        }
    }, [
        data,
        createTaxRateResult.isLoading,
        updateTaxRateResult.isLoading,
        deleteTaxRateResult.isLoading,
    ]);

    if (
        isLoading ||
        createTaxRateResult.isLoading ||
        updateTaxRateResult.isLoading ||
        deleteTaxRateResult.isLoading
    ) {
        return <Spinner />;
    }
    return (
        <div className='card strpied-tabled-with-hover'>
            <CardHeader
                buttonText={'New Tax Rate'}
                handleSearch={handleSearch}
                handleShow={handleShowTaxRateModal}
                headerTitle={'TAX RATES'}
                headerDescription={'Manage Tax Rates'}
                placeholder={'Search Tax Rates'}
                searchText={searchText}
            />

            <div className='card-body table-full-width table-responsive'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th width='20%'>Name</th>
                            <th width='40%'>Description</th>
                            <th width='20%'>Rate</th>
                            <th width='10%'>Type</th>
                            <th width='10%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taxRates &&
                            taxRates.map((taxRate) => (
                                <tr key={taxRate._id}>
                                    <td>{taxRate.name}</td>
                                    <td>{taxRate.description}</td>
                                    <td>{taxRate.rate}</td>
                                    <td>{taxRate.type}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditTaxRate(taxRate)}
                                            text={'Edit'}
                                        />{' '}
                                        &nbsp;
                                        <OxButton
                                            action={() => handleDeleteTaxRate(taxRate)}
                                            text={'Delete'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <TaxRateModal
                show={showTaxRateModal}
                onHide={handleCloseTaxRateModal}
                modalText={modalText}
                taxRate={taxRate}
                handleCreateTaxRate={handleCreateTaxRate}
                handleUpdateTaxRate={handleUpdateTaxRate}
                handleChange={handleChange}
            />
        </div>
    );
}

export default TaxRates;
