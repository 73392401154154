import React, { useState } from 'react';
import { Card, Container, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { Steps } from 'antd';
import { useParams } from 'react-router-dom';

import { FileUploader } from '@shared';

import OxButton from '@shared/OxButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PersonalInfoForm, IncomeAndExpenditureForm } from '.';
import { TenantApplicationInfoService } from '@services';
import Logo from '@shared/Logo';
import BlackLogo from '@assets/img/rgs/logo_transparent.png';
import TenantApplicationInfoSchema from './TenantApplicationInfoSchema';

import { ROUTES } from '@enums';
import './TenantInfoApplication.scss';

const { Step } = Steps;

const { DASHBOARD } = ROUTES;

const tenantApplicationInfoService = TenantApplicationInfoService.create();

export function TenantInfoApplication() {
    const { company_id } = useParams();
    const [current, setCurrent] = useState(0);
    const [application, setApplication] = useState({ _id: '64de7c150b75aadf1efac31c' });
    const [isLoading, setLoading] = useState(false);

    const next = (pageNumber = 1) => {
        setCurrent(current + pageNumber);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const initialValues = {
        company: company_id,
        title: '',
        firstName: '',
        lastName: '',
        nationality: '',
        townOfBirth: '',
        countryOfBirth: '',
        identityNumber: '',
        postalAddress: '',
        residentialAddress: '',
        cellPhone: '',
        telephone: '',
        emailAddress: '',
        maritalStatus: '',
        maritalRegime: '',
        spouseFullName: '',
        employmentStatus: '',
        occupation: '',
        employer: '',
        employerTelephone: '',
        employerAddress: '',
        taxRegistrationNumber: '',
        sourceOfFunds: '',
        bankName: '',
        bankAccountHolder: '',
        bankAccountNumber: '',
        branchCode: '',
        propertyDetails: '',
        salaryNet: 0.0,
        otherIncome: 0.0,
        vehicle: 0.0,
        insurance: 0.0,
        medicalAid: 0.0,
        pension: 0.0,
        onlineSubscriptions: 0.0,
        groceries: 0.0,
        surplus: 0.0,
        totalIncome: 0.0,
        totalExpenditure: 0.0,
    };

    const steps = [
        {
            title: 'Personal Information + FIA',
            content(formikProps) {
                return <PersonalInfoForm initialValues={initialValues} formikProps={formikProps} />;
            },
        },
        {
            title: 'Income & Expenditure',
            content(formikProps) {
                return (
                    <IncomeAndExpenditureForm
                        initialValues={initialValues.incomeAndExpenditure}
                        formikProps={formikProps}
                    />
                );
            },
        },
        {
            title: 'Document Uploads',
            content(formikProps) {
                return (
                    <div
                        style={{
                            width: '100%',
                            background: '#f9f9f9',
                            padding: '30px 10px',
                            height: '100%',
                        }}>
                        <Card.Text style={{ color: 'blue' }}>Upload Documents</Card.Text>

                        <FileUploader
                            uploadInstructions={{
                                title: '',
                                description: 'Upload the following documents:',
                                documents: [
                                    'Certified copy of ID (if Namibian Citizen)',
                                    'Certified copy of passport (if Foreign National)',
                                    'Certified copy of marriage certificate',
                                    'Certified copy of Antenuptial Contract',
                                    'Certified copy of COMPANY / CLOSE CORPORATION if self-employed Certified copy of source of funds (if applicable)',
                                    'Investment/Saving etc Copy of latest municipality or electricity bill',
                                ],
                            }}
                            path={DASHBOARD}
                            _id={application._id}
                        />
                    </div>
                );
            },
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const contentStyle = {
        // lineHeight: '10px',
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.45)',
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: 8,
        border: `1px dashed #d9d9d9`,
        marginTop: 16,
        padding: 30,
    };
    const footerStyle = {
        padding: '3% 5% 3% 5%',
        width: '40%',
    };

    const errorResponse = (error, actions) => {
        console.error('archive application creation Error: ', error);
        actions.setSubmitting(false);
    };

    /**
     * @description sends the permit application form to the API
     * @param {Object} values permit form values
     * @param {Object} actions formik actions
     */
    const submitForm = async (values, actions) => {
        try {
            tenantApplicationInfoService.create(values).subscribe(
                (data) => {
                    console.log('Application Submitted: ', data);
                    setTimeout(() => {
                        actions.setSubmitting(false);
                        setLoading(false);
                        setApplication(data);
                        next(1);
                    }, 1000);
                    tenantApplicationInfoService.fetchAndStore();
                },
                (error) => errorResponse(error, actions)
            );

            console.log('Application Values: ', values);
        } catch (error) {
            setLoading(false);
            console.error('Error submitting form:', error);
            throw error;
        }
    };

    return (
        <div className='tenant-info-container'>
            <Container className='border border-info mt-3 mb-3'>
                <Row className='mb-2 mt-2 text-center'>
                    <Col>
                        <Logo src={BlackLogo} width={250} />
                        {/* <p className='mt-3'>System Description Here</p> */}
                    </Col>
                </Row>

                {/* Title */}
                <Row className='mb-4'>
                    <Col className='text-center'>
                        <h2 style={{ color: '#60caed' }}>
                            Tenant Placement Form: Please fill in your details
                        </h2>
                    </Col>
                </Row>
                <Card className='text-center'>
                    <Card.Header>
                        <Steps current={current}>
                            {items.map((item) => (
                                <Step key={item.key} title={item.title} />
                            ))}
                        </Steps>
                    </Card.Header>
                    <Card.Body>
                        <Container fluid>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={TenantApplicationInfoSchema}
                                onSubmit={(values, actions) => {
                                    // Handle form submission
                                    submitForm(values, actions);
                                }}>
                                {(formikProps) => {
                                    return (
                                        <Form>
                                            <div style={contentStyle}>
                                                {steps[current].content(formikProps)}
                                            </div>

                                            <div style={footerStyle}>
                                                {current === 1 && (
                                                    <OxButton
                                                        text='Previous'
                                                        action={() => prev()}
                                                    />
                                                )}
                                                {current === 1 && (
                                                    <OxButton
                                                        text='Submit Form'
                                                        action={formikProps.handleSubmit}
                                                    />
                                                )}
                                                {current === 0 && (
                                                    <OxButton text='Next' action={() => next()} />
                                                )}
                                                {/* {current === 2 && (
                                                    <OxButton text='Finish' action={null} />
                                                )} */}
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}
