/**
 * routes.enums.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */
export const ROUTES = {
    REAL_ESTATE_DASHBOARD: 'rgs-dashboard',
    PROPERTIES: 'properties',
    LEASE_APPLICATION: 'lease-application',
    DEFAULTERS: 'defaulters',
    COMPANIES: 'companies',
    COMPANY_REGISTRATION: 'company-registration',
    REAL_ESTATE_LICENSE_SELECTION: 'license-selection',
    DASHBOARD: 'dashboard',
    MANAGE_TENANT_APPLICANTS: 'manage-tenant-applicants',
    CONTRACTS: 'contracts',
    INDUSTRY_SELECTION: 'industry-selection',
    OXYGEN_LICENSE_SELECTION: 'oxygen-license-selection',
    OXYGEN_COMPANY_REGISTRATION: 'oxygen-company-registration',
};
