import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';
import { toast } from 'react-toastify';

import {
    useGetCompanyItemsQuery,
    useCreateCompanyItemMutation,
    useUpdateCompanyItemMutation,
    useDeleteCompanyItemMutation,
} from '../../../Redux/apiSlice';
import FuelProductModal from './FuelProductModal';
import { Dropdown } from 'react-bootstrap';
import util from '../../../util';

function FuelProducts() {
    const company = useSelector((state) => state.company);

    // get products
    const {
        data,
        error,
        isLoading,
        refetch: refechProducts,
    } = useGetCompanyItemsQuery(company._id);

    // create product
    const [createProduct, createProductResult] = useCreateCompanyItemMutation();

    // update product
    const [updateProduct, updateProductResult] = useUpdateCompanyItemMutation();

    // delete product
    const [deleteProduct, deleteProductResult] = useDeleteCompanyItemMutation();

    // product state
    const [product, setProduct] = React.useState({
        name: '',
        category: '',
        description: '',
        barcode: '',
        is_service: false,
        account_id: '',
        stock_type: '',
        cost_price: 0,
        unit_price: 0,
        quantity: 0,
        reorder_level: 0,
        recieving_quantity: 0,
        supplier: '',
        item_taxes: [],
        company: company._id,
        income_account: '',
        inventory_account: '',
        cost_of_goods_sold_account: '',
    });

    // products array state
    const [products, setProductsArray] = React.useState([]);

    // modal text
    const [modalText, setModalText] = React.useState('New Product');

    // Product Modal state
    const [showProductModal, setShowProductModal] = React.useState(false);

    // handle show product modal
    const handleShowProductModal = () => {
        setShowProductModal(true);
        setModalText('New Product');
        // clear product state
        setProduct({
            name: '',
            category: '',
            description: '',
            barcode: '',
            is_service: false,
            account_id: '',
            stock_type: '',
            cost_price: 0,
            unit_price: 0,
            quantity: 0,
            reorder_level: 0,
            recieving_quantity: 0,
            supplier: '',
            item_taxes: [],
            company: company._id,
            income_account: '',
            inventory_account: '',
            cost_of_goods_sold_account: '',
        });
    };

    // handle close product modal
    const handleCloseProductModal = () => {
        setShowProductModal(false);
    };

    // handle edit product
    const handleEditProduct = (product) => (e) => {
        e.preventDefault();
        setShowProductModal(true);
        setModalText('Edit Product');
        setProduct(product);
    };

    // search text
    const [searchText, setSearchText] = React.useState('');

    // handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    //handel onTaxSelected

    const handleOnTaxSelected = (tax) => (e) => {
        if (e.target.checked) {
            setProduct((prevProduct) => ({
                ...prevProduct,
                item_taxes: [...prevProduct.item_taxes, tax],
            }));
        } else {
            setProduct((prevProduct) => ({
                ...prevProduct,
                item_taxes: prevProduct.item_taxes.filter((item) => item !== tax),
            }));
        }
    };

    // handle is service change

    const handleIsServiceChange = (e) => {
        const { name, checked } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: checked,
        }));
    };

    // handle create product
    const handleCreateProduct = () => {
        //first remove all fields with empty string
        Object.keys(product).forEach((key) => product[key] === '' && delete product[key]);
        createProduct(product);
    };

    // handle update product
    const handleUpdateProduct = () => {
        Object.keys(product).forEach((key) => product[key] === '' && delete product[key]);
        updateProduct(product);
    };

    // handle delete product
    const handleDeleteProduct = (product) => (e) => {
        e.preventDefault();
        deleteProduct(product._id);
    };

    useEffect(() => {
        if (data) {
            setProductsArray(data);
            console.log(data);
        }

        if (createProductResult.data) {
            refechProducts();
            handleCloseProductModal();
            toast.success('Product created successfully');
        }

        if (updateProductResult.data) {
            refechProducts();
            handleCloseProductModal();
            toast.success('Product updated successfully');
        }

        if (deleteProductResult.data) {
            refechProducts();
            handleCloseProductModal();
            toast.success('Product deleted successfully');
        }
    }, [
        data,
        createProductResult.isLoading,
        updateProductResult.isLoading,
        deleteProductResult.isLoading,
    ]);

    if (
        isLoading ||
        createProductResult.isLoading ||
        updateProductResult.isLoading ||
        deleteProductResult.isLoading
    ) {
        return <Spinner />;
    }
    return (
        <div className='card '>
            <CardHeader
                buttonText={'New Product'}
                handleSearch={handleSearch}
                handleShow={handleShowProductModal}
                headerTitle={'PRODUCTS'}
                headerDescription={'Manage Products'}
                placeholder={'Search Products'}
                searchText={searchText}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th width='10%'>Code</th>
                            <th width='20%'>Name</th>
                            <th width='20%'>Category</th>
                            <th width='20%'>Cost Price</th>
                            <th width='20%'>Selling Price</th>

                            <th width='20%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products &&
                            products.map((product) => (
                                <tr key={product._id}>
                                    <td>{product.barcode}</td>
                                    <td>{product.name}</td>
                                    <td>{product.category}</td>
                                    <td>{util.formatCurrency(product.cost_price)}</td>
                                    <td>{util.formatCurrency(product.unit_price)}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleEditProduct(product)}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={handleDeleteProduct(product)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <FuelProductModal
                show={showProductModal}
                onHide={handleCloseProductModal}
                modalText={modalText}
                product={product}
                handleCreateProduct={handleCreateProduct}
                handleUpdateProduct={handleUpdateProduct}
                handleChange={handleChange}
                handleIsServiceChange={handleIsServiceChange}
                onTaxSelected={handleOnTaxSelected}
            />
        </div>
    );
}

export default FuelProducts;
