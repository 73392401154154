/**
 * license_type.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { from } from 'rxjs';
import { APIService, _GET, _POST } from '../api';
import { LOCAL_STORAGE_ENUMS } from '@enums';
const { RGS_LICENSE_TYPES } = LOCAL_STORAGE_ENUMS;

let instance;

export class LicenseTypeService extends APIService {
    constructor() {
        const collection = RGS_LICENSE_TYPES;
        super(collection, RGS_LICENSE_TYPES);
    }

    static create() {
        if (!instance) {
            instance = new LicenseTypeService();
        }
        return instance;
    }
}
