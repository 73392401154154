import React, { useState, useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, FormCheck } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { ENV } from '../../config';
import { toast } from 'react-toastify';

import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useGetRolePermissionsQuery,
  useCreatePermissionMutation,
  useDeletePermissionMutation,
} from '../../Redux/apiSlice';
import Spinner from '../../components/shared/Spinner';

// Placeholder: Replace with your actual API calls
async function fetchFeatures() {
  return await fetch(`${ENV.oxygen_end_point}/features`).then(res => res.json());
}

async function fetchFunctions() {
  return await fetch(`${ENV.oxygen_end_point}/functions`).then(res => res.json());
}

const validationSchema = Yup.object({
  name: Yup.string().required('Role name is required'),
  code: Yup.string().required('Code is required'),
  description: Yup.string().optional(),
});

function RoleForm({ handleClose, roleRefetch, role }) {

  const companyState = useSelector(state => state.company);

  const current_license  = useSelector(state => state.license);

  // RTK Queries
  const [createRole, createRoleResult] = useCreateRoleMutation();
  const [updateRole, updateRoleResult] = useUpdateRoleMutation();
  const { data: permissions, isLoading: permissionsLoading, refetch: refetchPermissions } = useGetRolePermissionsQuery(role?._id);
  const [createPermission, createPermissionResult] = useCreatePermissionMutation();
  const [deletePermission, deletePermissionResult] = useDeletePermissionMutation();
  
  const [features, setFeatures] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(new Set());

  useEffect(() => {
    async function loadData() {
      const fetchedFeatures = await fetchFeatures();
      const fetchedFunctions = await fetchFunctions();

      const filteredFeatures = fetchedFeatures.filter(feature => current_license.license_type.features.includes(feature._id));

      setFeatures(filteredFeatures);
      setFunctions(fetchedFunctions);
    }
    loadData();
  }, []);

  //create permission 

  const onAddPermission = (id, type) => {
    let body = {
      role: role._id,
    };
    switch (type) {
      case 'feature':
        //get feature by id passed

        const feature = features.find(feature => feature._id === id);

        body = {...body, feature: id, code: feature.code, name: feature.name}
        break;
      case 'function':
        //get function by id passed

        const func = functions.find(func => func._id === id);

        body = {...body, function: id, code: func.code, name: func.name}
      
        break;
      default:

    }
    createPermission(body);
  };

  //delete permission

  const onDeletePermission = (id, type) => {
    let permission_id;
    switch (type) {
      case 'feature':
        //get permission id by feature id
        permission_id = permissions.find(permission => permission.feature === id)._id;

        break;
      case 'function':
        //get permission id by function id
        permission_id = permissions.find(permission => permission.function === id)._id;
        break;
      default:

    }
    deletePermission(permission_id);
  }


  useEffect(() => {
     if (permissions) {
       setSelectedPermissions(new Set(permissions.map(permission => permission?.feature || permission?.function)));
     }
  }, [permissionsLoading]);

  useEffect(() => {
    if (createRoleResult.isSuccess) {
      formik.resetForm();
      toast.success('Role created successfully');
      role = createRoleResult.data;
      roleRefetch();
    }

    if (updateRoleResult.isSuccess) {
      formik.resetForm();
      toast.success('Role updated successfully');
      roleRefetch();
    }

    if (createPermissionResult.isSuccess) {
       refetchPermissions();
    }

    if (deletePermissionResult.isSuccess) {
      refetchPermissions();
    }

    if (createRoleResult.isError) {
      toast.error('Role creation failed');
    }

    if (updateRoleResult.isError) {
      toast.error('Role update failed');
    }

    if (createPermissionResult.isError) {
      toast.error('Permission creation failed');
      refetchPermissions();
    }

    if (deletePermissionResult.isError) {
      toast.error('Permission deletion failed');
      refetchPermissions();
    }

  }, [createRoleResult.isLoading, updateRoleResult.isLoading, createPermissionResult.isLoading, deletePermissionResult.isLoading]);

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      description: '',
      company: companyState._id,
      ...role,
    },
    validationSchema,
    onSubmit: (values) => {
      if (role) {
        const body = {
          ...values,
          _id: role._id,
          company: companyState._id,
        };
        updateRole(body);
      } else {
        const body = {
          ...values,
          company: companyState._id,
        };
        createRole(body);
      }
    },
  });

  const handlePermissionChange = (id, type) => {
    setSelectedPermissions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
        onDeletePermission(id, type);
      } else {
        newSet.add(id);
        onAddPermission(id, type);
      }
      return newSet;
    });
  };

  if (createRoleResult.isLoading || updateRoleResult.isLoading || createPermissionResult.isLoading || deletePermissionResult.isLoading) return <Spinner/>;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <fieldset>
        {/* Role Name */}
        <FormGroup className='mb-3'>
          <FormLabel htmlFor='name'>Role Name*</FormLabel>
          <FormControl
            id='name'
            {...formik.getFieldProps('name')}
            placeholder='Role Name'
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </FormGroup>

        {/* Code */}
        <FormGroup className='mb-3'>
          <FormLabel htmlFor='code'>Code*</FormLabel>
          <FormControl
            id='code'
            {...formik.getFieldProps('code')}
            placeholder='Code'
          />
          {formik.touched.code && formik.errors.code ? (
            <div className="text-danger">{formik.errors.code}</div>
          ) : null}
        </FormGroup>

        {/* Description */}
        <FormGroup className='mb-3'>
          <FormLabel htmlFor='description'>Description</FormLabel>
          <FormControl
            id='description'
            {...formik.getFieldProps('description')}
            placeholder='Description'
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="text-danger">{formik.errors.description}</div>
          ) : null}
        </FormGroup>

        {/* Permissions */}
        {role?._id && <FormGroup className='mb-3'>
        <FormLabel>Permissions</FormLabel>
            {features.map((feature) => (
                <div key={feature._id}>
                    <label>
                        <input 
                            type="checkbox"
                            id={feature._id}
                            checked={selectedPermissions.has(feature._id)}
                            onChange={() => handlePermissionChange(feature._id, 'feature')}
                        /> &nbsp;
                       {feature.name}
                    </label>

                    {/* Functions under each feature */}
                    {functions.filter(func => func.feature === feature._id).map((func) => (
                        <div key={func._id} style={{ marginLeft: 20 }}>
                            <label>
                                <input 
                                    type="checkbox"
                                    id={func._id}
                                    checked={selectedPermissions.has(func._id)}
                                    onChange={() => handlePermissionChange(func._id, 'function')}
                                /> &nbsp;
                                {func.name}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </FormGroup>}
        <button type="submit" className="btn btn-primary">{!role ? 'Continue' : 'Submit' }</button>
      </fieldset>
    </Form>
  );
}

export default RoleForm;
