import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Invoices from '../../general/Invoices';
import SaleOrders from '../../sales_and_distributions/SaleOrders';
import Payments from '../../../sales/Payments/Payments';


function WorkshopSaleLanding() {
    return (
        <Tabs>
            <TabList>
                <Tab>INVOICES</Tab>
                <Tab>SALE ORDERS</Tab>
                <Tab>SETTINGS</Tab>
            </TabList>
            <TabPanel>
                <Invoices />
            </TabPanel>
            <TabPanel>
                <SaleOrders />
            </TabPanel>
            
            <TabPanel>
               <Payments/>
            </TabPanel>
        </Tabs>
    );
}

export default WorkshopSaleLanding;
