import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { strings } from '../../../strings/strings';
import Spinner from '../../../components/shared/Spinner';

const PotenciaDashbord = () => {
    const [data, setData] = useState(null);

    const LOCATIONS = [
        'Potencia No. 1 (Freedomland)',
        'Potencia No. 2 (Oshitenda)',
        'Potencia No. 3 (Container)',
    ];

    useEffect(() => {
        const getData = async () => {
            const response = await fetch(strings.ospos_end_point + '/report');
            const data = await response.json();
            setData(data);
        };
        getData().catch((err) => console.log(err));
    }, []);

    if (!data) return <Spinner/>

    // Calculate total revenue and total profit
    const totalRevenue = data.dailyRevenue.reduce(
        (total, revenue) => total + revenue.daily_revenue,
        0
    );
    const totalProfit = data.dailyProfit.reduce((total, profit) => total + profit.daily_profit, 0);

    return (
        <Container>
            <Row>
                {data.dailyRevenue.map((revenue, index) => (
                    <Col key={index}>
                        <Card className='text-center'>
                            <Card.Header>{LOCATIONS[index]} Daily Revenue</Card.Header>
                            <Card.Body>
                                <h2>
                                    <b>
                                        N$
                                        {(revenue.daily_revenue &&
                                            revenue.daily_revenue.toFixed(2)) ||
                                            0}
                                    </b>
                                </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row>
                {data.dailyProfit.map((profit, index) => (
                    <Col key={index}>
                        <Card className='text-center'>
                            <Card.Header>{LOCATIONS[index]} Daily Profit</Card.Header>
                            <Card.Body>
                                <h2>
                                    <b>
                                        N$
                                        {(profit.daily_profit && profit.daily_profit.toFixed(2)) ||
                                            0}
                                    </b>
                                </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col>
                    <Card className='text-center'>
                        <Card.Header>Total Revenue and Profit</Card.Header>
                        <Card.Body>
                            <h2>
                                <b>Total Revenue: N${totalRevenue && totalRevenue.toFixed(2)}</b>
                            </h2>
                            <h2>
                                <b>Total Profit: N${totalProfit && totalProfit.toFixed(2)}</b>
                            </h2>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                {Object.keys(data.topSellingItems).map((location, index) => (
                    <Col key={index}>
                        <Card className='text-center'>
                            <Card.Header>{LOCATIONS[index]} Fast Moving Items</Card.Header>
                            <Card.Body>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th width='10%'>#</th>
                                            <th width='70%'>Item</th>
                                            <th width='20%'>Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.topSellingItems[location].map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <b>{item.total_quantity}</b>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row>
                {Object.keys(data.mostProtitableItems).map((location, index) => (
                    <Col key={index}>
                        <Card className='text-center'>
                            <Card.Header>{LOCATIONS[index]} Most Profitable Items</Card.Header>
                            <Card.Body>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th width='10%'>#</th>
                                            <th width='70%'>Item</th>
                                            <th width='20%'>Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.mostProtitableItems[location].map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <b>
                                                        N$
                                                        {item.total_profit &&
                                                            item.total_profit.toFixed(2)}
                                                    </b>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default PotenciaDashbord;
