/**
 * NewProductForm.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button, FormCheck, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { 
    useCreateCompanyItemMutation,
    useUpdateCompanyItemMutation,
    useGetCompanySuppliersQuery,
    useGetCompanyItemCategoriesQuery,
    useGetTaxesQuery,
    useGetExpenseAccountsQuery,
    useGetAssetsAccountsQuery,
    useGetRevenueAccountsQuery,
} from '@redux/apiSlice';
import { toast } from 'react-toastify';
import Spinner from '@components/shared/Spinner';
import { useSelector } from 'react-redux';
import { OXYGEN_LICENSE_TYPES } from '../../../../config';
import { STOCK, STOCK_TYPES } from '../../../../config/enums/stock-type.enum';

// Validation schema
const productValidationSchema = Yup.object().shape({
  is_service: Yup.boolean(),
  name: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  unit_price: Yup.number().required('Required'),
  barcode: Yup.string(),
  cost_price: Yup.number(),
  stock_type: Yup.string().oneOf(['stock', 'non-stock'], 'Invalid stock type'),
  quantity: Yup.number(),
  reorder_level: Yup.number(),
  receiving_quantity: Yup.number(),
  supplier: Yup.string(),
  item_taxes: Yup.array().of(Yup.string()),
  income_account: Yup.string().required('Required'),
  inventory_account: Yup.string(),
  cost_of_goods_sold_account: Yup.string(),
});

function NewProductForm({ product, handleClose, refetch }) {
    const company = useSelector((state) => state.company);

    const license = useSelector((state) => state.license);

    const {OWS} = OXYGEN_LICENSE_TYPES

    const {ALL_STOCK_TYPES} = STOCK_TYPES

    const service_licences = [OWS]//TODO: add more service licenses here

    const isService = service_licences.includes(license.license_type.code)

    const [createProduct, createProductResult] = useCreateCompanyItemMutation();
    const [updateProduct, updateProductResult] = useUpdateCompanyItemMutation();
    const { data: suppliers, isLoading: isLoadingSuppliers, refetch: refetchSupplier } = useGetCompanySuppliersQuery(company._id);
    const { data: categories, isLoading: isLoadingCategories, refetch: refetchCategories } = useGetCompanyItemCategoriesQuery(company._id);
    const { data: taxes, isLoading: isLoadingTaxes, refetch: refetchTaxes } = useGetTaxesQuery(company._id);
    const { data: expenseAccounts, isLoading: isLoadingExpenseAccounts, refetch: refetchExpenseAccounts } = useGetExpenseAccountsQuery(company._id);
    const { data: assetsAccounts, isLoading: isLoadingAssetsAccounts, refetch: refetchAssetAccount } = useGetAssetsAccountsQuery(company._id);
    const { data: revenueAccounts, isLoading: isLoadingRevenueAccounts, refetch: refetchRevenueAccounts } = useGetRevenueAccountsQuery(company._id);

    useEffect(() => {
        refetchSupplier();
        refetchCategories();
        refetchTaxes();
        refetchExpenseAccounts();
        refetchAssetAccount();
        refetchRevenueAccounts();
    }, [refetchSupplier, refetchCategories, refetchTaxes, refetchExpenseAccounts, refetchAssetAccount, refetchRevenueAccounts]);

    const handleSubmit = (values) => {

        //remove empty values
        Object.keys(values).forEach((key) => {
            if (values[key] === '') {
                delete values[key];
            }
        });

        if (product._id) {
        updateProduct({
            _id: product._id,
            ...values,
        });
        } else {
        createProduct({
            ...values,
            company: company._id,
        });
        }
    };

    const onTaxSelected = (taxId) => (e) => {
        e.preventDefault();
        const checked = e.target.checked;
        if (checked) {
            formik.setFieldValue('item_taxes', [...formik.values.item_taxes, taxId]);
        } else {
            formik.setFieldValue(
                'item_taxes',
                formik.values.item_taxes.filter((id) => id !== taxId)
            );
        }
    }

    const handleIsServiceChange = (e) => {
        const checked = e.target.checked;
        if (checked) {
            formik.setFieldValue('is_service', true);
        } else {
            formik.setFieldValue('is_service', false);
        }
    }

    useEffect(() => {
        if (createProductResult.isSuccess) {
        formik.resetForm();
        toast.success('Product created successfully');
        refetch();
        handleClose();
        }
        if (createProductResult.isError) {
        toast.error('Something went wrong while creating.');
        }
        if (updateProductResult.isSuccess) {
        formik.resetForm();
        toast.success('Product updated successfully');
        refetch();
        handleClose();
        }
        if (updateProductResult.isError) {
        toast.error('Something went wrong while updating.');
        }
    }, [createProductResult, updateProductResult]);

    const formik = useFormik({
        initialValues: {
        name: '',
        category: '',
        description: '',
        account_id: '',
        unit_price: '',
        barcode: '',
        cost_price: '',
        stock_type: '',
        quantity: '',
        reorder_level: '',
        receiving_quantity: '',
        supplier: '',
        is_service: isService,
        item_taxes: [],
        company: company._id,
        income_account: '',
        inventory_account: '',
        cost_of_goods_sold_account: '',
        ...product,
        },
        validationSchema: productValidationSchema,
        onSubmit: handleSubmit,
    });

    if (createProductResult.isLoading || updateProductResult.isLoading) {
        return <Spinner />;
    }

    return (
        <Form onSubmit={formik.handleSubmit}>

            <Row>
                <Col md={12}>
                    {/* Service Check */}
                    <FormGroup className="mb-3">
                        <input
                        type="checkbox"
                        label="Is Service"
                        checked={formik.values.is_service}
                        onChange={handleIsServiceChange}
                        /> <FormLabel htmlFor="barcode">Is Service</FormLabel>
                    </FormGroup>
                   
                </Col>
            </Row>

            <Row>
                {!formik.values.is_service && (<Col>
                   {/* Barcode - Only for non-service */}
                   
                        <FormGroup className="mb-3">
                        <FormLabel htmlFor="barcode">Barcode</FormLabel>
                        <FormControl
                            type="text"
                            id="barcode"
                            {...formik.getFieldProps('barcode')}
                        />
                        {formik.touched.barcode && formik.errors.barcode && (
                            <div className="text-danger">{formik.errors.barcode}</div>
                        )}
                        </FormGroup>
                    
                </Col>)}
                <Col>
                    {/* Name */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <FormControl
                        type="text"
                        id="name"
                        {...formik.getFieldProps('name')}
                        />
                        {formik.touched.name && formik.errors.name && (
                        <div className="text-danger">{formik.errors.name}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    {/* Category */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="category">Category</FormLabel>
                        <FormControl
                            as="select"
                            id="category"
                            {...formik.getFieldProps('category')}
                        >
                            <option value="">Select Category</option>
                            {categories && categories.map((category) => (
                                <option key={category._id} value={category._id}>{category.name}</option>
                            ))}
                        </FormControl>
                        {formik.touched.category && formik.errors.category && (
                            <div className="text-danger">{formik.errors.category}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    {/* Description */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="description">Description</FormLabel>
                        <FormControl
                            type="text"
                            id="description"
                            {...formik.getFieldProps('description')}
                        />
                        {formik.touched.description && formik.errors.description && (
                            <div className="text-danger">{formik.errors.description}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                {!formik.values.is_service && (<Col>
                    {/* Cost Price */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="cost_price">Cost Price</FormLabel>
                        <FormControl
                            type="text"
                            id="cost_price"
                            {...formik.getFieldProps('cost_price')}
                        />
                        {formik.touched.cost_price && formik.errors.cost_price && (
                            <div className="text-danger">{formik.errors.cost_price}</div>
                        )}
                    </FormGroup>
                </Col>)}
                <Col>
                    {/* Unit Price */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="unit_price">Unit Price</FormLabel>
                        <FormControl
                            type="text"
                            id="unit_price"
                            {...formik.getFieldProps('unit_price')}
                        />
                        {formik.touched.unit_price && formik.errors.unit_price && (
                            <div className="text-danger">{formik.errors.unit_price}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            {!formik.values.is_service && (<Row>
                <Col md={6}>
                    {/* Stock Type */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="stock_type">Stock Type</FormLabel>
                        <FormControl
                            as="select"
                            id="stock_type"
                            {...formik.getFieldProps('stock_type')}
                        >
                            <option value="">Select Stock Type</option>
                            {ALL_STOCK_TYPES && ALL_STOCK_TYPES.map((stock_type, indx) => (
                                <option key={indx} value={stock_type}>{stock_type}</option>
                            ))}
                        </FormControl>
                        {formik.touched.stock_type && formik.errors.stock_type && (
                            <div className="text-danger">{formik.errors.stock_type}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col md={6}>
                     {/* Quantity */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="quantity">Quantity</FormLabel>
                        <FormControl
                            type="text"
                            id="quantity"
                            {...formik.getFieldProps('quantity')}
                        />
                        {formik.touched.quantity && formik.errors.quantity && (
                            <div className="text-danger">{formik.errors.quantity}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>)}

            {!formik.values.is_service && (<Row>
                <Col md={6}>
                     {/* Reorder Level */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="reorder_level">Reorder Level</FormLabel>
                        <FormControl
                            type="text"
                            id="reorder_level"
                            {...formik.getFieldProps('reorder_level')}
                        />
                        {formik.touched.reorder_level && formik.errors.reorder_level && (
                            <div className="text-danger">{formik.errors.reorder_level}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    {/* Receiving Quantity */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="receiving_quantity">Receiving Quantity</FormLabel>
                        <FormControl
                            type="text"
                            id="receiving_quantity"
                            {...formik.getFieldProps('receiving_quantity')}
                        />
                        {formik.touched.receiving_quantity && formik.errors.receiving_quantity && (
                            <div className="text-danger">{formik.errors.receiving_quantity}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>)}

            <Row>
                {!formik.values.is_service && (<Col md={6}>
                    {/* Supplier */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="supplier">Supplier</FormLabel>
                        <FormControl
                            as="select"
                            id="supplier"
                            {...formik.getFieldProps('supplier')}
                        >
                            <option value="">Select Supplier</option>
                            {suppliers && suppliers.map((supplier) => (
                                <option key={supplier._id} value={supplier._id}>{supplier.name}</option>
                            ))}
                        </FormControl>
                        {formik.touched.supplier && formik.errors.supplier && (
                            <div className="text-danger">{formik.errors.supplier}</div>
                        )}
                    </FormGroup>
                </Col>)}
                {taxes && taxes.length > 0 && (<Col md={6}>
                     {/* Item Taxes */}
                    <FormGroup className="mb-3">
                        <Form.Label htmlFor='item_taxes'>Item Taxes</Form.Label>
                        <br></br>
                        {taxes &&
                            taxes.map((tax) => (
                                <>
                                    <input
                                        key={tax._id}
                                        type='checkbox'
                                        onChange={onTaxSelected(tax._id)}
                                        id={tax._id}
                                        name={tax.name}
                                        checked={product.item_taxes.includes(tax._id)}
                                    />
                                    <label title={tax.description} htmlFor={tax._id}>
                                        {' '}
                                        &nbsp;
                                        {`${tax.name} (${tax.rate}%)`}
                                    </label>
                                    <br />
                                </>
                        ))}
                    </FormGroup>
                    
                </Col>)}
            </Row>

            <Row>
                <Col>
                    {/* Income Account */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="income_account">Income Account</FormLabel>
                        <FormControl
                            as="select"
                            id="income_account"
                            {...formik.getFieldProps('income_account')}
                        >
                            <option value="">Select Income Account</option>
                            {revenueAccounts && revenueAccounts.map((account) => (
                                <option key={account._id} value={account._id}>{account.name}</option>
                            ))}
                        </FormControl>
                        {formik.touched.income_account && formik.errors.income_account && (
                            <div className="text-danger">{formik.errors.income_account}</div>
                        )}
                    </FormGroup>
                </Col>
                    {!formik.values.is_service && (<Col>
                    {/* Inventory Account */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="inventory_account">Inventory Account</FormLabel>
                        <FormControl
                            as="select"
                            id="inventory_account"
                            {...formik.getFieldProps('inventory_account')}
                        >
                            <option value="">Select Inventory Account</option>
                            {assetsAccounts && assetsAccounts.map((account) => (
                                <option key={account._id} value={account._id}>{account.name}</option>
                            ))}
                        </FormControl>
                        {formik.touched.inventory_account && formik.errors.inventory_account && (
                            <div className="text-danger">{formik.errors.inventory_account}</div>
                        )}
                    </FormGroup>
                </Col>)}
                {!formik.values.is_service && (<Col>
                    {/* Cost of Goods Sold Account */}
                    <FormGroup className="mb-3">
                        <FormLabel htmlFor="cost_of_goods_sold_account">Cost of Goods Sold Account</FormLabel>
                        <FormControl
                            as="select"
                            id="cost_of_goods_sold_account"
                            {...formik.getFieldProps('cost_of_goods_sold_account')}
                        >
                            <option value="">Select Cost of Goods Sold Account</option>
                            {expenseAccounts && expenseAccounts.map((account) => (
                                <option key={account._id} value={account._id}>{account.name}</option>
                            ))}
                        </FormControl>
                        {formik.touched.cost_of_goods_sold_account && formik.errors.cost_of_goods_sold_account && (
                            <div className="text-danger">{formik.errors.cost_of_goods_sold_account}</div>
                        )}
                    </FormGroup>
                </Col>)}
            </Row>
            
            {/* Submit */}
            <Button
                disabled={!formik.isValid}
                type='submit'
            >
                Submit
            </Button>
        </Form>
    );
}

export default NewProductForm;
