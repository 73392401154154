import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';

import {
    useGetTankLevelRecordsQuery,
    useCreateTankLevelRecordMutation,
    useUpdateTankLevelRecordMutation,
    useDeleteTankLevelRecordMutation,
} from '../../../Redux/apiSlice';
import TankLevelRecordModal from './TankLevelRecordModal';
import Moment from 'react-moment';

function TankLevelRecords() {
    const company = useSelector((state) => state.company);

    const employee = useSelector((state) => state.employee);

    // Get tank level records
    const {
        data: records,
        error,
        isLoading,
        refetch: refetchTankLevelRecords,
    } = useGetTankLevelRecordsQuery(company._id);

    // Create tank level record
    const [createTankLevelRecord, createTankLevelRecordResult] = useCreateTankLevelRecordMutation();

    // Update tank level record
    const [updateTankLevelRecord, updateTankLevelRecordResult] = useUpdateTankLevelRecordMutation();

    // Delete tank level record
    const [deleteTankLevelRecord, deleteTankLevelRecordResult] = useDeleteTankLevelRecordMutation();

    // Tank level record state
    const [tankLevelRecord, setTankLevelRecord] = React.useState({
        tank: '',
        volume: 0,
        employee: employee._id,
    });

    // Tank level records array state
    const [tankLevelRecords, setTankLevelRecords] = React.useState([]);

    // Modal text
    const [modalText, setModalText] = React.useState('New Tank Level Record');

    // Tank Level Record Modal state
    const [showTankLevelRecordModal, setShowTankLevelRecordModal] = React.useState(false);

    // Handle show tank level record modal
    const handleShowTankLevelRecordModal = () => {
        setShowTankLevelRecordModal(true);
        setModalText('New Tank Level Record');
        setTankLevelRecord({
            tank: '',
            volume: 0,
            employee: employee._id,
        });
    };

    // Handle close tank level record modal
    const handleCloseTankLevelRecordModal = () => {
        setShowTankLevelRecordModal(false);
    };

    // Handle edit tank level record
    const handleEditTankLevelRecord = (tankLevelRecord) => (e) => {
        e.preventDefault();
        setShowTankLevelRecordModal(true);
        setModalText('Edit Tank Level Record');
        setTankLevelRecord(tankLevelRecord);
    };

    // Search text
    const [searchText, setSearchText] = React.useState('');

    // Handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTankLevelRecord((prevTankLevelRecord) => ({
            ...prevTankLevelRecord,
            [name]: value,
        }));
    };

    // Handle create tank level record
    const handleCreateTankLevelRecord = () => {
        createTankLevelRecord(tankLevelRecord);
    };

    // Handle update tank level record
    const handleUpdateTankLevelRecord = () => {
        updateTankLevelRecord(tankLevelRecord);
    };

    // Handle delete tank level record
    const handleDeleteTankLevelRecord = (tankLevelRecord) => (e) => {
        e.preventDefault();
        deleteTankLevelRecord(tankLevelRecord._id);
    };

    useEffect(() => {
        if (tankLevelRecords) {
            setTankLevelRecords(tankLevelRecords);
        }
        if (createTankLevelRecordResult.data) {
            refetchTankLevelRecords();
            handleCloseTankLevelRecordModal();
        }

        if (updateTankLevelRecordResult.data) {
            refetchTankLevelRecords();
            handleCloseTankLevelRecordModal();
        }

        if (deleteTankLevelRecordResult.data) {
            refetchTankLevelRecords();
        }
    }, [
        tankLevelRecords,
        createTankLevelRecordResult.isLoading,
        updateTankLevelRecordResult.isLoading,
        deleteTankLevelRecordResult.isLoading,
    ]);

    if (
        isLoading ||
        createTankLevelRecordResult.isLoading ||
        updateTankLevelRecordResult.isLoading ||
        deleteTankLevelRecordResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText={'New Tank Level Record'}
                handleSearch={handleSearch}
                handleShow={handleShowTankLevelRecordModal}
                headerTitle={'TANK LEVEL RECORDS'}
                headerDescription={'Manage Tank Level Records'}
                placeholder={'Search Tank Level Records'}
                searchText={searchText}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th width='20%'>Tank</th>
                            <th width='20%'>Volume</th>
                            <th width='20%'>Recorded By</th>
                            <th width='20%'>Date Created</th>
                            <th width='20%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records &&
                            records.map((tankLevelRecord) => (
                                <tr key={tankLevelRecord._id}>
                                    <td>{tankLevelRecord.tank?.name}</td>
                                    <td>{tankLevelRecord.volume}</td>
                                    <td>
                                        {tankLevelRecord.employee?.first_name +
                                            ' ' +
                                            tankLevelRecord.employee?.last_name}
                                    </td>
                                    <td>
                                        <Moment format='YYYY-MM-DD HH:mm'>
                                            {tankLevelRecord.created}
                                        </Moment>
                                    </td>

                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={handleDeleteTankLevelRecord(
                                                        tankLevelRecord
                                                    )}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <TankLevelRecordModal
                show={showTankLevelRecordModal}
                onHide={handleCloseTankLevelRecordModal}
                modalText={modalText}
                tankLevelRecord={tankLevelRecord}
                handleCreateTankLevelRecord={handleCreateTankLevelRecord}
                handleUpdateTankLevelRecord={handleUpdateTankLevelRecord}
                handleChange={handleChange}
            />
        </div>
    );
}

export default TankLevelRecords;
