/**
 * TenantApplicantsTable.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';

import Side_nav from '@shared_components/side_nav';
import Top_nav from '@shared_components/top_nav';
import Footer from '@shared_components/footer';

import { filter } from 'rxjs/operators';
import { LoadingPage, CircularButton, OxModal } from '@shared';
import { SessionService, TenantApplicationInfoService } from '@services';
import { defined } from '@util/array.util';
import { FaEye } from 'react-icons/fa';
import { RiRefreshLine } from 'react-icons/ri';

import { LOCAL_STORAGE_ENUMS } from '@enums/local-storage-variables';

import { TenantApplicantInfoView } from './';
import { TenantOptionsButton } from '@components';

const { TENANT_APPLICATIONS } = LOCAL_STORAGE_ENUMS;

// service instances
const service = TenantApplicationInfoService.create();
const session = SessionService.session;

//subscriptions
let localDataSubscription = session;
let initialSub = session;

export function TenantApplicantsTable() {
    const [applications, setApplications] = useState(undefined);
    const [application, setApplication] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const tableHeads = [
        'Full Name',
        'Id Number',
        'Phone Number',
        'Salary (Net)',
        'E-mail Address',
        'Occupation',
    ];

    useEffect(() => {
        listenToSession();
        getData();
    }, []);

    const handleCloseModal = () => setShowModal(false);

    const prepareData = (values) => {
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === TENANT_APPLICATIONS) {
                setApplications(data);
            }

            setLoading(false);
        }
    };

    const listenToSession = () => {
        const concerns = [TENANT_APPLICATIONS];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const getData = () => {
        initialSub = service.get().subscribe(prepareData, (error) => {
            console.log('Dependencies Get Error: ', error);
        });
    };

    const refresh = () => {
        setLoading(true);
        initialSub = service.fetchAndStore().subscribe(prepareData, (error) => {
            console.log('Dependencies Get Error: ', error);
        });
    };

    /** returns true if all data dependencies have been loaded from the api */
    const isReady = defined([applications]);

    // render table heads from array list
    const renderTableHeads = (head, index) => {
        return <th key={index}>{head}</th>;
    };

    const renderTableRows = (item, index) => {
        const {
            firstName,
            lastName,
            emailAddress,
            salaryNet,
            cellPhone,
            identityNumber,
            occupation,
        } = item;
        const fullName = `${firstName} ${lastName}`;
        return (
            <tr key={index}>
                <td>{fullName}</td>

                <td>{identityNumber}</td>
                <td>{cellPhone}</td>
                <td>{salaryNet}</td>
                <td>{emailAddress}</td>
                <td>{occupation}</td>
                <td>
                    <CircularButton
                        variant='primary'
                        icon={
                            <FaEye
                                onClick={() => {
                                    setShowModal(true);
                                    setApplication(item);
                                }}
                            />
                        }
                    />
                </td>
                <TenantOptionsButton applicant={item} />
                {/* <td>
                    <CircularButton variant='danger' icon={<FaTrash />} />
                </td>
                <td>
                    <CircularButton variant='secondary' icon={<FaEllipsisH />} />
                </td> */}
            </tr>
        );
    };

    const renderView = () => {
        return (
            <div className='content'>
                <div className='container-fluid'>
                    <div className='section'>
                        {' '}
                        <div className='card strpied-tabled-with-hover'>
                            <div className='card-header '>
                                <div className='form-row'>
                                    <div className='col-md-5'>
                                        <h4 className='card-title'>Tenant Applicants</h4>
                                        <p className='card-category'>Manage Applicants</p>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='form-row'>
                                            <div className='col-md-8'>
                                                <div className='form-group'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Search By Id number'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <CircularButton
                                                    variant='primary'
                                                    icon={
                                                        <RiRefreshLine
                                                            onClick={() => {
                                                                refresh();
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body table-full-width table-responsive'>
                                <table className='table table-hover table-striped'>
                                    <thead>
                                        <tr>{tableHeads.map(renderTableHeads)}</tr>
                                    </thead>
                                    <tbody>{applications.map(renderTableRows)}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <OxModal
                        scrollable={true}
                        height='55%'
                        dark={false}
                        title={'APPLICANT DETAILS'}
                        visible={showModal}
                        handleClose={handleCloseModal}
                        children={<TenantApplicantInfoView application={application} />}
                    />
                )}
            </div>
        );
    };

    if (loading || !isReady) {
        return <LoadingPage title='Loading tenant applicants list' />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {renderView()}

                <Footer />
            </div>
        </div>
    );
}
