/**
 * TenantApplicantInfoView.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaUser, FaDollarSign, FaFile } from 'react-icons/fa';
import { colors } from '@strings/colors';
import { formatNumberToPrice } from '@util/number.utils';
import { isEmpty } from '@util/array.util';
import { Spin } from 'antd';

import { SessionService, FilesService } from '@services';
import { OxPdfFileView } from '@shared';

const filesService = FilesService.create();

const session = SessionService.session;

let initialSub = session;

export function TenantApplicantInfoView({ application }) {
    const {
        company,
        title,
        firstName,
        lastName,
        nationality,
        townOfBirth,
        countryOfBirth,
        identityNumber,
        postalAddress,
        residentialAddress,
        cellPhone,
        telephone,
        emailAddress,
        maritalStatus,
        maritalRegime,
        spouseFullName,
        employmentStatus,
        occupation,
        employer,
        employerTelephone,
        employerAddress,
        taxRegistrationNumber,
        sourceOfFunds,
        bankName,
        bankAccountHolder,
        bankAccountNumber,
        branchCode,
        propertyDetails,
        salaryNet,
        otherIncome,
        vehicle,
        insurance,
        medicalAid,
        pension,
        onlineSubscriptions,
        groceries,
        surplus,
        totalIncome,
        totalExpenditure,
    } = application;

    const personalData = [
        { title: 'Full Name', value: `${title} ${firstName} ${lastName}` },
        { title: 'Nationality', value: nationality },
        { title: 'Town of Birth', value: townOfBirth },
        { title: 'Country of Birth', value: countryOfBirth },
        { title: 'Identity Number', value: identityNumber },
        { title: 'Postal Address', value: postalAddress },
        { title: 'Residential Address', value: residentialAddress },
        { title: 'Cell Phone', value: cellPhone },
        { title: 'Telephone', value: telephone },
        { title: 'E-mail Address', value: emailAddress },
        { title: 'Marital Status', value: maritalStatus },
        { title: 'Marital Regime', value: maritalRegime },
        { title: 'Spouse Full Name', value: spouseFullName },
        { title: 'Employment Status', value: employmentStatus },
        { title: 'Occupation', value: occupation },
        { title: 'Employer', value: employer },
        { title: 'Employer Telephone', value: employerTelephone },
        { title: 'Employer Address', value: employerAddress },
        { title: 'Tax Registration Number', value: taxRegistrationNumber },
        { title: 'Source of Funds', value: sourceOfFunds },
        { title: 'Bank Name', value: bankName },
        { title: 'Bank Account Holder', value: bankAccountHolder },
        { title: 'Bank Account Number', value: bankAccountNumber },
        { title: 'Branch Code', value: branchCode },
        { title: 'Property Details', value: propertyDetails },
    ];

    const incomeData = [
        { title: 'Salary (Net)', value: salaryNet },
        { title: 'Other Income', value: otherIncome },
        { title: 'Vehicle', value: vehicle },
        { title: 'Insurance', value: insurance },
        { title: 'Medical Aid', value: medicalAid },
        { title: 'Pension', value: pension },
        { title: 'Online Subscriptions', value: onlineSubscriptions },
        { title: 'Groceries', value: groceries },
        { title: 'Surplus', value: surplus },
        { title: 'Total Income', value: totalIncome },
        { title: 'Total Expenditure', value: totalExpenditure },
    ];

    const style = {
        headerText: {
            color: colors.primary,
        },
    };

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
        return () => {
            initialSub.unsubscribe();
        };
    }, []);

    const prepareData = (values) => {
        console.log('values: ', values);
        setDocuments(values);
        setLoading(false);
    };

    const getData = () => {
        setLoading(true);
        initialSub = filesService.get(application._id).subscribe(prepareData, (error) => {
            if (error.status === 404) {
                setDocuments([]);
            }
            setLoading(false);
            console.log('Dependencies Get Error: ', error);
        });
    };

    return (
        <>
            <Card>
                <Card.Header style={style.headerText}>
                    <FaUser /> Personal Information
                </Card.Header>
                <Card.Body>
                    <Row>
                        {personalData.map((item, index) => (
                            <React.Fragment key={index}>
                                <Col md={3}>
                                    <strong>{item.title}:</strong>
                                </Col>
                                <Col md={3}>{item.value}</Col>
                            </React.Fragment>
                        ))}
                    </Row>
                </Card.Body>
            </Card>
            <Card className='mt-3'>
                <Card.Header style={style.headerText}>
                    <FaDollarSign /> Income & Expenditure
                </Card.Header>
                <Card.Body>
                    <Row>
                        {incomeData.map((item, index) => (
                            <React.Fragment key={index}>
                                <Col md={3}>
                                    <strong>{item.title}:</strong>
                                </Col>
                                <Col md={3}>N$ {formatNumberToPrice(item.value)}</Col>
                            </React.Fragment>
                        ))}
                    </Row>
                </Card.Body>
            </Card>

            <Card>
                <Card.Header style={style.headerText}>
                    <FaFile /> Applicant Documents
                </Card.Header>
                <Card.Body className='mb-3'>
                    <Spin spinning={loading}>
                        <Row>
                            {!isEmpty(documents) &&
                                documents.map((doc, index) => (
                                    <Col key={index} md={4}>
                                        <OxPdfFileView file={doc} deletehandler={() => null} />
                                    </Col>
                                ))}
                        </Row>
                    </Spin>
                </Card.Body>
            </Card>
        </>
    );
}
