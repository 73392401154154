/**
 * OxNotify.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { notification } from 'antd';
import { colors } from '@strings/colors';

const TOASTDURATION = 5;

export const OxNotify = ({
    title = 'Notification',
    type,
    content = '',
    duration = TOASTDURATION,
}) => {
    notification[type]({
        message: title,
        description: content,
        placement: 'topRight',
        duration,
        style: {
            background: colors.primary,
            color: '#000',
        },
        top: 100,
    });
};
