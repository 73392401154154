import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useUpdateDeliveryNoteMutation,
    useGetCompanyCustomersQuery,
    useCreateDeliveryNoteMutation,
} from '../../../Redux/apiSlice';
import CustomerSearchDropDown from '../../../components/shared/CustomerSearchDropDown';

import { toast } from 'react-toastify';
import DeliveryNoteItems from './DeliveryNoteItems';

const DeliveryNoteModal = ({ show, onHide, deliveryNoteData }) => {
    const company = useSelector((state) => state.company);
    const employee = useSelector((state) => state.employee);

    if (!deliveryNoteData._id) {
        deliveryNoteData = {
            _id: '',
            sale_order: '',
            customer: '',
            employee: employee._id,
            delivery_note_no: '',
            fleet_no: '',
            driver_name: '',
            odo_meter_reading: '',
            purchase_order_no: '',
            delivery_date: new Date(),
            company: company._id,
        };
    }

    const [deliveryNote, setDeliveryNote] = useState(deliveryNoteData);
    const [createDeliveryNote, createDeliveryNoteResult] = useCreateDeliveryNoteMutation();
    const [updateDeliveryNote, updateDeliveryNoteResult] = useUpdateDeliveryNoteMutation();
    const {
        data: customers,
        error: customersError,
        isLoading: customersLoading,
        refetch: refetchCustomers,
    } = useGetCompanyCustomersQuery(company._id);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDeliveryNote((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCustomerSelection = (customer) => {
        setDeliveryNote((prevState) => ({
            ...prevState,
            customer: customer.value,
        }));
    };

    const handleCreateDeliveryNote = () => {
        const newDeliveryNote = {
            ...deliveryNote,
            company: company._id,
            customer: deliveryNote.customer,
            employee: employee._id,
        };

        //remove all empty fields
        Object.keys(newDeliveryNote).forEach(
            (key) => newDeliveryNote[key] === '' && delete newDeliveryNote[key]
        );

        createDeliveryNote(newDeliveryNote);
    };

    const handleUpdateDeliveryNote = () => {
        const updatedDeliveryNote = {
            ...deliveryNote,
            company: company._id,
            customer: deliveryNote.customer._id,
            employee: employee._id,
        };
        updateDeliveryNote(updatedDeliveryNote);
    };

    useEffect(() => {
        if (createDeliveryNoteResult.data) {
            setDeliveryNote(createDeliveryNoteResult.data);
        }

        if (createDeliveryNoteResult.isSuccess) {
            toast.success('Delivery Note Created Successfully');
            refetchCustomers();
        }

        if (createDeliveryNoteResult.error) {
            toast.error(createDeliveryNoteResult.error.message);
        }

        if (updateDeliveryNoteResult.data) {
            setDeliveryNote(updateDeliveryNoteResult.data);
        }

        if (updateDeliveryNoteResult.isSuccess) {
            toast.success('Delivery Note Updated Successfully');
        }
    }, [customersLoading, createDeliveryNoteResult.isLoading, updateDeliveryNoteResult.isLoading]);

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {deliveryNote._id
                        ? `EDIT DELIVERY NOTE# ${deliveryNote.delivery_note_no}`
                        : `CREATE NEW DELIVERY NOTE`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Delivery Note Number</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='delivery_note_no'
                                    value={deliveryNote.delivery_note_no}
                                    onChange={handleInputChange}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Customer</Form.Label> <br />
                                <CustomerSearchDropDown
                                    handleCustomerSelection={handleCustomerSelection}
                                />
                                

                            </Form.Group>
                            {deliveryNoteData.customer && (
                                <div>
                                    <p>
                                        <strong>
                                            {deliveryNoteData.customer && deliveryNoteData.customer?.business_name || deliveryNoteData.customer.first_name}{' '}
                                            
                                        </strong>
                                    </p>
                                    <p className='mutted'>{deliveryNoteData.customer.email}</p> 
                                    <p>{deliveryNoteData.customer.phone_number}</p>
                                </div>
                            )}
                        </div>
                        
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Purchase Order No</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='purchase_order_no'
                                    value={deliveryNote.purchase_order_no}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            
                            <Form.Group>
                                <Form.Label>Fleet No</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='fleet_no'
                                    value={deliveryNote.fleet_no}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            
                        </div>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Driver Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='driver_name'
                                    value={deliveryNote.driver_name}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Odo Meter Reading</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='odo_meter_reading'
                                    value={deliveryNote.odo_meter_reading}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Delivery Date</Form.Label>
                                <Form.Control
                                    type='date'
                                    name='delivery_date'
                                    value={deliveryNote.delivery_date}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
                {deliveryNote._id.length > 0 && <DeliveryNoteItems deliveryNoteId={deliveryNoteData._id || deliveryNote._id} />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {deliveryNoteData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdateDeliveryNote}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreateDeliveryNote}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default DeliveryNoteModal;
