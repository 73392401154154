import React from 'react';
import Side_nav from '../../../shared_components/side_nav';
import Top_nav from '../../../shared_components/top_nav';
import Footer from '../../../shared_components/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FuelProducts from './FuelProducts';
import Tanks from './Tanks';
import Terminals from './Terminals';
import PurchaseRequisitions from '../material_management/PurchaseRequisitions';
import PurchaseOrders from '../material_management/PurchaseOrders';
import Receivings from '../material_management/Receivings';
import TankLevelRecords from './TankLevelRecords';
import StockAdjustments from './StockAdjustments';

function FuelLanding() {
    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <Tabs>
                                <TabList>
                                    <Tab>Products</Tab>
                                    <Tab>Purchase Requisitions</Tab>
                                    <Tab>Purchase Orders</Tab>
                                    <Tab>Recievings</Tab>
                                    <Tab>Physical Inventory Counts</Tab>
                                    <Tab>Stock Adjustments</Tab>

                                    <Tab>Settings</Tab>
                                </TabList>
                                <TabPanel>
                                    <FuelProducts />
                                </TabPanel>

                                <TabPanel>
                                    <PurchaseRequisitions />
                                </TabPanel>

                                <TabPanel>
                                    <PurchaseOrders />
                                </TabPanel>

                                <TabPanel>
                                    <Receivings />
                                </TabPanel>
                                <TabPanel>
                                    <TankLevelRecords />
                                </TabPanel>
                                <TabPanel>
                                    <StockAdjustments />
                                </TabPanel>

                                <TabPanel>
                                    <Tabs>
                                        <TabList>
                                            <Tab>Tanks</Tab>
                                            <Tab>Pumps</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Tanks />
                                        </TabPanel>
                                        <TabPanel>
                                            <Terminals />
                                        </TabPanel>
                                    </Tabs>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default FuelLanding;
