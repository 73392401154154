import React, { useEffect, useState } from 'react';

import {
    useGetJobCardItemsQuery,
    useCreateJobCardItemMutation,
    useUpdateJobCardItemMutation,
    useDeleteJobCardItemMutation,
    useChangeJobCardItemStatusMutation,
} from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import CardHeader from '../../../components/shared/CardHeader';
import JobCardItemModal from './JobCardItemModal';
import Spinner from '../../../components/shared/Spinner';
import { toast } from 'react-toastify';
import { strings } from '../../../strings/strings';
import { ENV } from '@config';
const { oxygen_end_point } = ENV;

function JobCardItems({ job_card }) {
    const company = useSelector((state) => state.company);

    const [searchText, setSearchText] = useState('');
    const [jobCardItems, setJobCardItems] = useState([]);

    const [jobCardItem, setJobCardItem] = useState({
        job_card: job_card._id,
        item: '',
        unit_price: 0,
        discount: 0,
        discount_type: 'percentage',
        tax_amount: 0,
        tax_included: false,
        description: '',
        serial_number: '',
        size: '',
        brand: '',
        status: '',
    });

    const [modalText, setModalText] = useState('New Job Card Item');
    const [showModal, setShowModal] = useState(false);

    const { data, isLoading, refetch: refetchJobCardItems } = useGetJobCardItemsQuery(job_card._id);

    const [createJobCardItem, createJobCardItemResult] = useCreateJobCardItemMutation();
    const [updateJobCardItem, updateJobCardItemResult] = useUpdateJobCardItemMutation();
    const [deleteJobCardItem, deleteJobCardItemResult] = useDeleteJobCardItemMutation();
    const [changeJobCardItemStatus, changeJobCardItemStatusResult] =
        useChangeJobCardItemStatusMutation();

    useEffect(() => {
        if (data) {
            setJobCardItems(data);
        }

        if (createJobCardItemResult.data) {
            refetchJobCardItems();
            toast.success('Job Card Item Created Successfully');
            handleCloseModal();
        }

        if (updateJobCardItemResult.data) {
            refetchJobCardItems();
            toast.success('Job Card Item Updated Successfully');
            handleCloseModal();
        }

        if (deleteJobCardItemResult.data) {
            refetchJobCardItems();
            handleCloseModal();
        }

        if (changeJobCardItemStatusResult.data) {
            refetchJobCardItems();
            toast.success('Job Card Item Status Changed Successfully');
            handleCloseModal();
        }
    }, [
        data,
        createJobCardItemResult.isLoading,
        updateJobCardItemResult.isLoading,
        deleteJobCardItemResult.isLoading,
        changeJobCardItemStatusResult.isLoading,
    ]);

    const handleShowModal = () => {
        setShowModal(true);
        setModalText('New Job Card Item');
        setJobCardItem({
            job_card: job_card._id,
            item: '',
            unit_price: 0,
            discount: 0,
            discount_type: 'percentage',
            tax_amount: 0,
            tax_included: false,
            description: '',
            serial_number: '',
            size: '',
            brand: '',
            status: '',
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCreateJobCardItem = () => {
        //remove empty fields

        const jobCardItemCopy = { ...jobCardItem };

        for (const key in jobCardItemCopy) {
            if (jobCardItemCopy.hasOwnProperty(key)) {
                const element = jobCardItemCopy[key];
                if (element === '' || element === null || element === undefined) {
                    delete jobCardItemCopy[key];
                }
            }
        }
        createJobCardItem(jobCardItem);
    };

    //handle item  status change

    const handleItemStatusChange = (jc) => (e) => {
        if (!jc._id) {
            //set job card item status
            setJobCardItem((prevJobCardItem) => ({
                ...prevJobCardItem,
                status: e.target.value,
            }));
            return;
        }

        const { name, value } = e.target;

        changeJobCardItemStatus({
            job_card_item_id: jc._id,
            status_id: value,
        });
    };

    const handleUpdateJobCardItem = () => {
        //remove empty fields
        const jobCardItemCopy = { ...jobCardItem };

        for (const key in jobCardItemCopy) {
            if (jobCardItemCopy.hasOwnProperty(key)) {
                const element = jobCardItemCopy[key];
                if (element === '' || element === null || element === undefined) {
                    delete jobCardItemCopy[key];
                }
            }
        }

        updateJobCardItem(jobCardItemCopy);
    };

    const handleDeleteJobCardItem = () => {
        deleteJobCardItem(jobCardItem._id);
    };

    //handle item  change

    const handleItemChange = async (e) => {
        const { name, value } = e.target;

        //fetch the item by id

        try {
            const item = await fetch(`${oxygen_end_point}/items/${value}`);

            const itemData = await item.json();

            console.log(itemData);

            setJobCardItem((prevJobCardItem) => ({
                ...prevJobCardItem,
                [name]: value,
                unit_price: itemData.unit_price,
                description: itemData.description,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobCardItem((prevJobCardItem) => ({
            ...prevJobCardItem,
            [name]: value,
        }));
    };

    const handleEditJobCardItem = (jobCardItem) => {
        setShowModal(true);

        //make a copy of the jobCardItem

        const jobCardItemCopy = { ...jobCardItem };

        jobCardItemCopy.job_card = jobCardItemCopy.job_card._id;

        jobCardItemCopy.item = jobCardItemCopy.item._id;

        jobCardItemCopy.status = jobCardItemCopy.status._id;

        jobCardItemCopy.brand = jobCardItemCopy.brand._id;

        setModalText('Edit Job Card Item');
        setJobCardItem(jobCardItemCopy);
    };

    const handleSearch = (text) => {
        setSearchText(text);
    };

    if (
        (isLoading,
        createJobCardItemResult.isLoading,
        updateJobCardItemResult.isLoading,
        deleteJobCardItemResult.isLoading,
        changeJobCardItemStatusResult.isLoading)
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Job Card Item'
                handleSearch={handleSearch}
                handleShow={handleShowModal}
                headerTitile='Job Card Items'
                headerDescription='Manage Job Card Items'
            />

            <div className='card-body'>
                <table className='table' style={{ width: '100%' }}>
                    <thead style={{ width: '100%' }}>
                        <tr>
                            <th width='30%'>Service</th>
                            <th width='10%'>Price</th>
                            <th width='10%'>Size</th>
                            <th width='20%'>Brand</th>
                            <th width='20%'>Status</th>
                            <th width='10%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobCardItems &&
                            jobCardItems.map((jobCardItem) => (
                                <tr key={jobCardItem._id}>
                                    <td>{jobCardItem.item.name}</td>
                                    <td>{jobCardItem.unit_price}</td>
                                    <td>{jobCardItem.size}</td>
                                    <td>{jobCardItem.brand.name}</td>
                                    <td>{jobCardItem.status.name}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditJobCardItem(jobCardItem)}
                                            text='Edit'
                                        />{' '}
                                        &nbsp;
                                        <OxButton
                                            action={() => handleDeleteJobCardItem(jobCardItem)}
                                            text='Delete'
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <JobCardItemModal
                show={showModal}
                onHide={handleCloseModal}
                modalText={modalText}
                jobCardItem={jobCardItem}
                handleCreateJobCardItem={handleCreateJobCardItem}
                handleUpdateJobCardItem={handleUpdateJobCardItem}
                handleChange={handleChange}
                handleItemChange={handleItemChange}
                handleItemStatusChange={handleItemStatusChange}
            />
        </div>
    );
}

export default JobCardItems;
