import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import OxygenTable from '../../../components/shared/OxygenTable';
import JobCardItemStatusModal from './JobCardItemStatusModal';
import {
    useGetJobCardItemStatusesQuery,
    useCreateJobCardItemStatusMutation,
    useUpdateJobCardItemStatusMutation,
    useDeleteJobCardItemStatusMutation,
} from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import Spinner from '../../../components/shared/Spinner';

function JobCardItemStatuses() {
    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchStatuses } = useGetJobCardItemStatusesQuery(company._id);
    const [createStatus, createStatusResult] = useCreateJobCardItemStatusMutation();
    const [updateStatus, updateStatusResult] = useUpdateJobCardItemStatusMutation();
    const [deleteStatus, deleteStatusResult] = useDeleteJobCardItemStatusMutation();

    const [status, setStatus] = useState({
        name: '',
        description: '',
        company: company._id,
    });

    const [statuses, setStatusesArray] = useState([]);
    const [modalText, setModalText] = useState('New Job Card Item Status');
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const handleShowStatusModal = () => {
        setShowStatusModal(true);
        setModalText('New Job Card Item Status');
        setStatus({
            name: '',
            description: '',
            company: company._id,
        });
    };

    const handleCloseStatusModal = () => {
        setShowStatusModal(false);
    };

    const handleEditStatus = (status) => {
        setShowStatusModal(true);
        setModalText('Edit Job Card Item Status');
        setStatus(status);
    };

    const handleDeleteStatus = (status) => {
        deleteStatus(status._id);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStatus((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCreateStatus = () => {
        createStatus(status);
    };

    const handleUpdateStatus = () => {
        updateStatus(status);
    };

    const handleSearch = (text) => {
        setSearchText(text);
    };

    const columns = [
        { label: 'Name', field: 'name', width: '40%' },
        { label: 'Description', field: 'description', width: '50%' },
        { label: 'Actions', field: 'actions', width: '10%' },
    ];

    const statusesWithActions = statuses.map((status) => ({
        ...status,
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditStatus(status)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteStatus(status)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        ),
    }));

    useEffect(() => {
        if (data) {
            setStatusesArray(data);
        }
        if (createStatusResult.data || updateStatusResult.data || deleteStatusResult.data) {
            refetchStatuses();
            handleCloseStatusModal();
        }
    }, [data, createStatusResult.data, updateStatusResult.data, deleteStatusResult.data]);

    if (isLoading || createStatusResult.isLoading || updateStatusResult.isLoading || deleteStatusResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Status'}
                handleSearch={handleSearch}
                handleShow={handleShowStatusModal}
                headerTitle={'Job Card Item Statuses'}
                headerDescription={'Manage Job Card Item Statuses'}
                placeholder={'Search Job Card Item Statuses'}
                searchText={searchText}
            />

            <div className='card-body'>
                <OxygenTable
                    data={statusesWithActions}
                    columns={columns}
                    pageSize={10}
                />
            </div>

            <JobCardItemStatusModal
                show={showStatusModal}
                onHide={handleCloseStatusModal}
                modalText={modalText}
                status={status}
                jobCardItemStatus={status}
                handleCreateStatus={handleCreateStatus}
                handleUpdateStatus={handleUpdateStatus}
                handleChange={handleChange}
            />
        </div>
    );
}

export default JobCardItemStatuses;
