import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetCompanyItemsQuery,
    useGetSalesOrderItemsQuery,
    useCreateSalesOrderItemMutation,
    useUpdateSalesOrderItemMutation,
    useDeleteSalesOrderItemMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import SaleOrderItemModal from './SaleOrderItemModal';

import { toast } from 'react-toastify';
import DeletePopupModal from '../general/DeletePopupModal';

const SaleOrderItems = ({ saleOrderId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const { data: items, isLoading: itemsLoading } = useGetCompanyItemsQuery(companyId);
    const {
        data: saleOrderItems,
        isLoading: saleOrderItemsLoading,
        refetch: refetchSaleOrderItems,
    } = useGetSalesOrderItemsQuery(saleOrderId);

    const [newItem, setNewItem] = useState({
        sale_order: saleOrderId,
        item: '',
        quantity: 0,
    });

    const [createSaleOrderItem, createSaleOrderItemResult] = useCreateSalesOrderItemMutation();
    const [updateSaleOrderItem, updateSaleOrderItemResult] = useUpdateSalesOrderItemMutation();
    const [deleteSaleOrderItem, deleteSaleOrderItemResult] = useDeleteSalesOrderItemMutation();

    const [saleOrderItemsArray, setSaleOrderItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalSaleOrderItem, setModalSaleOrderItem] = useState({
        sale_order: saleOrderId,
        item: '',
        quantity: 0,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalSaleOrderItem(item);
    };

    //handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalSaleOrderItem({
            sale_order: saleOrderId,
            item: '',
            quantity: 0,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItemId,
            quantity: 1,
        }));
    };

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalSaleOrderItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    //handle update item
    const handleUpdateItem = () => {
        updateSaleOrderItem(modalSaleOrderItem);
        setShowModal(false);
        setModalSaleOrderItem({
            sale_order: saleOrderId,
            item: '',
            quantity: 0,
        });
    };

    const handleDeleteItem = () => {
        deleteSaleOrderItem(modalSaleOrderItem._id);
        setShowDeleteModal(false);
        setModalSaleOrderItem({
            sale_order: saleOrderId,
            item: '',
            quantity: 0,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            const itemExists = saleOrderItems
                ? saleOrderItems.find((item) => item.item === newItem.item)
                : null;
            if (itemExists) {
                const updatedItem = {
                    ...itemExists,
                    quantity: itemExists.quantity + newItem.quantity,
                };
                updateSaleOrderItem(updatedItem);
            } else {
                createSaleOrderItem(newItem);
            }
            setNewItem({
                sale_order: saleOrderId,
                item: '',
                quantity: 0,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (saleOrderItems) {
            setSaleOrderItemsArray(saleOrderItems);
        }
        if (createSaleOrderItemResult.data) {
            refetchSaleOrderItems();
        }
        if (createSaleOrderItemResult.isSuccess) {
            toast.success('Sale Order Item Created Successfully');
        }
        if (deleteSaleOrderItemResult.data) {
            refetchSaleOrderItems();
        }
        if (deleteSaleOrderItemResult.isSuccess) {
            toast.success('Sale Order Item Deleted Successfully');
        }
        if (updateSaleOrderItemResult.data) {
            refetchSaleOrderItems();
        }
        if (updateSaleOrderItemResult.isSuccess) {
            toast.success('Sale Order Item Updated Successfully');
        }
    }, [
        saleOrderItems,
        saleOrderItemsLoading,
        createSaleOrderItemResult.isLoading,
        updateSaleOrderItemResult.isLoading,
        deleteSaleOrderItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Sale Order Item');
            setModalSaleOrderItem({});
        } else if (modalType === 'edit' && item) {
            const getItemName = (itemId) => {
                const item = items.find((item) => item._id === itemId);
                return item ? item.name : '';
            };
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalSaleOrderItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalSaleOrderItem({
            sale_order: saleOrderId,
            item: '',
            quantity: 0,
        });
    };

    if (
        itemsLoading ||
        saleOrderItemsLoading ||
        createSaleOrderItemResult.isLoading ||
        updateSaleOrderItemResult.isLoading ||
        deleteSaleOrderItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='30%'>Item</th>
                        <th width='20%'>Volume</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {saleOrderItemsArray.map((item, index) => (
                        <tr key={index}>
                            <td>{getItemName(item.item)}</td>
                            <td>{item.quantity}</td>
                            <td>
                                <Button variant='primary' onClick={() => openModal('edit', item)}>
                                    Edit
                                </Button>{' '}
                                &nbsp;
                                <Button
                                    variant='danger'
                                    onClick={() => handleShowDeleteModal(item)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select New Item
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <SaleOrderItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                saleOrderItem={modalSaleOrderItem}
                handleChange={handleChange}
                handleUpdateSaleOrderItem={handleUpdateItem}
            />

            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={'Sale Order Item'}
            />
        </div>
    );
};
export default SaleOrderItems;
