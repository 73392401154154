import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ReceivePOModal({ show, onHide, onReceive, recordName }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Receive {recordName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to receive this {recordName.toLowerCase()}?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                <Button variant='success' onClick={onReceive}>
                    Receive
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReceivePOModal;
