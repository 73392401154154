import { createSlice } from "@reduxjs/toolkit";


export const industrySlice = createSlice({
    name: 'industry',
    initialState: {
        _id: '',
        name: '',
        description: '',
        type: '',
    },
    reducers: {
        setIndustry: (state, action) => {
            state._id = action.payload._id;
            state.name = action.payload.name;
            state.description = action.payload.description;
            state.type = action.payload.type;
        },
        clearIndustry: (state) => {
            state._id = '';
            state.name = '';
            state.description = '';
            state.type = '';
        }
    }

});

export const industryActions = industrySlice.actions;