import { InboxOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { ENV } from '@config';
import axios from 'axios';
import { Card, Button as OXButton, Row, Col } from 'react-bootstrap';

import { Upload, Progress, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Dragger } = Upload;

import { colors } from '@strings/colors';
import { SessionService, FilesService } from '@services';

import { LOCAL_STORAGE_ENUMS } from '@enums';
import { OxPdfFileView, OxNotify } from '@shared';

const { FILES } = LOCAL_STORAGE_ENUMS;

const filesService = FilesService.create();
const session = SessionService.session;

let initialSub = session;
const { oxygen_end_point } = ENV;

export function FileUploader({
    _id = '64de7c150b75aadf1efac31c',
    uploadInstructions = {
        title: 'Property Management Company',
        description: 'Upload the following documents:',
        documents: [
            'Company Registration Documents',
            'Tax Certificate',
            'NEAB Certificate',
            'Endorsement Letter',
        ],
    },
    path = undefined,
    uploadCompleteHandler = undefined,
}) {
    const [filename, setFileName] = useState('');
    const [progress, setProgress] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        console.log('Uploading for', _id);
        getData();
        // listenToSession();
        return () => {
            initialSub.unsubscribe();
        };
    }, []);

    const prepareData = (values) => {
        console.log('values: ', values);
        setDocuments(values);
        setIsUploading(false);
    };

    const getData = () => {
        initialSub = filesService.get(_id).subscribe(prepareData, (error) => {
            if (error.status === 404) {
                setDocuments([]);
            }
            setIsUploading(false);
            console.log('Dependencies Get Error: ', error);
        });
    };

    async function handleDeleteDocument(filename) {
        try {
            setIsUploading(true);
            const uploadUrl = `${oxygen_end_point}/delete-file/${filename}`;

            await axios.post(uploadUrl, {}).finally(() => {
                OxNotify({
                    title: 'Success',
                    content: 'File Deleted Successfully',
                    type: 'success',
                    duration: 10,
                });
                setTimeout(() => {
                    getData();
                }, 1000);
            });
        } catch (error) {
            getData();
            OxNotify({
                title: 'Error',
                content: error.message || 'Error Deleting File',
                type: 'error',
                duration: 10,
            });
        }
    }

    const draggerProps = {
        multiple: true,
        accept: '.pdf',
        name: 'file',
        // listType: 'picture-card',
        itemRender() {
            return null;
        },

        customRequest: async ({ file, onSuccess, onError }) => {
            const formData = new FormData();
            const changedFile = new File([file.slice()], `${_id}_${file.name}`, {
                type: file.type,
            });
            formData.append('file', file);
            // formData.append('file', changedFile);
            formData.append('_id', _id);
            formData.append('size', changedFile.size);

            try {
                setIsUploading(true);
                const uploadUrl = `${oxygen_end_point}/upload/${_id}`;

                const response = await axios
                    .post(uploadUrl, formData, {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setProgress(percentCompleted);
                        },
                        headers: {
                            Accept: 'application/json',
                        },
                    })
                    .finally(() => {
                        setTimeout(() => {
                            getData();
                        }, 1000);
                    });

                console.log('File uploaded successfully: ', response.data);
                onSuccess(response.data);
                setTimeout(() => {
                    getData();
                }, 1000);
            } catch (error) {
                getData();
                console.error('Error uploading file: ', error);
                onError(error);
            }
        },
    };

    const uploadCompleteAction = () => {
        if (path) {
            navigate(`/${path}`);
        }
        if (uploadCompleteHandler) {
            uploadCompleteHandler();
        }
    };

    /**
     * @description render live upload progress of a file to firebase
     * @param {Number} progress file upload progress
     */
    function renderProgress(progress) {
        const uploadStopped = progress === 0;
        const uploadFinished = progress === 100;
        return uploadStopped || uploadFinished ? null : (
            <div as='h4' color='blue'>
                <p>Uploading {filename}</p>
                <Progress
                    strokeColor={{ from: colors.primary, to: 'green' }}
                    percent={progress.toFixed(1)}
                    status='active'
                />
            </div>
        );
    }

    /**
     * @description renders the pdf documents list
     * @param {Object} file pdf file reference
     * @param {*} index loop index
     */

    const renderPDFFiles = (file, index) => {
        return (
            <Col key={index}>
                <OxPdfFileView
                    file={file}
                    deletehandler={() => handleDeleteDocument(file.filename)}
                />
            </Col>
        );
    };

    const render = () => {
        return (
            <div style={{ height: 'auto' }}>
                <Card style={{ width: '100%' }}>
                    <Card.Body>
                        <Card.Text>
                            <span style={{ fontWeight: 'bold' }}>
                                {uploadInstructions.description}
                            </span>
                            <ul>
                                {uploadInstructions.documents.map((doc, index) => (
                                    <li key={index}>{doc}</li>
                                ))}
                            </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br />
                <Spin spinning={isUploading}>
                    <Dragger {...draggerProps}>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    </Dragger>
                    <br />
                    {renderProgress(progress)}
                    <br />

                    <Row>{documents && documents.map(renderPDFFiles)}</Row>
                </Spin>
                <OXButton className='mt-4' onClick={uploadCompleteAction}>
                    Finish
                </OXButton>
            </div>
        );
    };
    return render();
}
