import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import {
    useGetTaxesQuery,
    useGetCompanySuppliersQuery,
    useGetExpenseAccountsQuery,
    useGetAssetsAccountsQuery,
    useGetRevenueAccountsQuery,
} from '../../../Redux/apiSlice';

const FuelProductModal = ({
    show,
    onHide,
    modalText,
    product,
    handleCreateProduct,
    handleUpdateProduct,
    handleChange,
    onTaxSelected,
}) => {
    const company = useSelector((state) => state.company);

    // Get tax rates
    const {
        data: taxes,
        error,
        isLoading,
        refetch: refetchTaxRates,
    } = useGetTaxesQuery(company._id);

    // Get suppliers
    const {
        data: suppliers,
        error: suppliersError,
        isLoading: suppliersLoading,
        refetch: refetchSuppliers,
    } = useGetCompanySuppliersQuery(company._id);

    // Get expense accounts
    const {
        data: expenseAccounts,
        error: expenseAccountsError,
        isLoading: expenseAccountsLoading,
        refetch: refetchExpenseAccounts,
    } = useGetExpenseAccountsQuery(company._id);

    // Get assets accounts
    const {
        data: assetsAccounts,
        error: assetsAccountsError,
        isLoading: assetsAccountsLoading,
        refetch: refetchAssetsAccounts,
    } = useGetAssetsAccountsQuery(company._id);

    // Get revenue accounts
    const {
        data: revenueAccounts,
        error: revenueAccountsError,
        isLoading: revenueAccountsLoading,
        refetch: refetchRevenueAccounts,
    } = useGetRevenueAccountsQuery(company._id);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            name='barcode'
                            value={product.barcode}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name='name'
                            value={product.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            name='category'
                            value={product.category}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            name='description'
                            value={product.description}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Inventory Account</Form.Label>
                        <Form.Control
                            as='select'
                            name='inventory_account'
                            value={product.inventory_account}
                            onChange={handleChange}
                            required>
                            <option value=''>Select Inventory Account</option>
                            {assetsAccounts &&
                                assetsAccounts.map((account) => (
                                    <option key={account._id} value={account._id}>
                                        {account.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Income Account</Form.Label>
                        <Form.Control
                            as='select'
                            name='income_account'
                            value={product.income_account}
                            onChange={handleChange}
                            required>
                            <option value=''>Select Income Account</option>
                            {revenueAccounts &&
                                revenueAccounts.map((account) => (
                                    <option key={account._id} value={account._id}>
                                        {account.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Cost of Good Account</Form.Label>
                        <Form.Control
                            as='select'
                            name='cost_of_goods_sold_account'
                            value={product.cost_of_goods_sold_account}
                            onChange={handleChange}
                            required>
                            <option value=''>Select Cost Of Good Account</option>
                            {expenseAccounts &&
                                expenseAccounts.map((account) => (
                                    <option key={account._id} value={account._id}>
                                        {account.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Cost Price</Form.Label>
                        <Form.Control
                            name='cost_price'
                            value={product.cost_price}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Unit Price</Form.Label>
                        <Form.Control
                            name='unit_price'
                            value={product.unit_price}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor='role_description'>Item Taxes</Form.Label>
                        <br></br>
                        {taxes &&
                            taxes.map((tax) => (
                                <>
                                    <input
                                        key={tax._id}
                                        type='checkbox'
                                        onChange={onTaxSelected(tax._id)}
                                        id={tax._id}
                                        name={tax.name}
                                        checked={product.item_taxes.includes(tax._id)}
                                    />
                                    <label title={tax.description} htmlFor={tax._id}>
                                        {' '}
                                        &nbsp;
                                        {`${tax.name} (${tax.rate}%)`}
                                    </label>
                                    <br />
                                </>
                            ))}
                    </Form.Group>

                    <Form.Group></Form.Group>

                    <Form.Group>
                        <Form.Label>Reorder Level </Form.Label>
                        <Form.Control
                            name='reorder_level'
                            value={product.reorder_level}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Supplier</Form.Label>
                        <Form.Control
                            as='select'
                            name='supplier'
                            value={product.supplier}
                            onChange={handleChange}
                            required>
                            <option value=''>Select Supplier</option>
                            {suppliers &&
                                suppliers.map((supplier) => (
                                    <option key={supplier._id} value={supplier._id}>
                                        {supplier.business_name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText == 'New Product' ? (
                    <OxButton action={handleCreateProduct} text='Create' />
                ) : (
                    <OxButton action={handleUpdateProduct} text='Update' />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default FuelProductModal;
