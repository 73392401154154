import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OxButton from '../../components/shared/OxButton';

import { useGetCompaniesQuery, useGetLicenseTypesQuery } from '../../Redux/apiSlice';

function LicenseModal({
    show,
    onHide,
    modalText,
    license,
    handleChange,
    handleUpdateLicense,
    handleCreateLicense,
}) {
    //get companies
    const {
        data: companyData,
        error,
        isLoading,
        refetch: refechCompanies,
    } = useGetCompaniesQuery();

    //get license types
    const { data: licenseTypeData, refetch: refechLicenseTypes } = useGetLicenseTypesQuery();

    //companies array state

    const [companies, setCompany] = React.useState([]);

    //license types array state

    const [licenseTypes, setLicenseType] = React.useState([]);

    useEffect(() => {
        if (companyData) {
            setCompany(companyData);
        }
        if (licenseTypeData) {
            setLicenseType(licenseTypeData);
        }
    }, [companyData, licenseTypeData]);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group controlId='formBasicLicenseType'>
                        <Form.Label>License Type</Form.Label>
                        <Form.Control
                            as='select'
                            name='license_type'
                            value={license.license_type}
                            onChange={handleChange}>
                            <option value=''>Select License Type</option>
                            {licenseTypes.map((licenseType) => (
                                <option value={licenseType._id}>{licenseType.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='formBasicCompany'>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            as='select'
                            name='company'
                            value={license.company}
                            onChange={handleChange}>
                            <option value=''>Select Company</option>
                            {companies.map((company) => (
                                <option value={company._id}>{company.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='formBasicStartDate'>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                            type='date'
                            name='license_start_date'
                            value={license.license_start_date}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicEndDate'>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                            type='date'
                            name='license_end_date'
                            value={license.license_end_date}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicStatus'>
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as='select'
                            name='license_status'
                            value={license.license_status}
                            onChange={handleChange}>
                            <option value='active'>Active</option>
                            <option value='inactive'>Inactive</option>
                            <option value='expired'>Expired</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                {modalText === 'New License' ? (
                    <OxButton text='Submit' action={handleCreateLicense} />
                ) : (
                    <OxButton text='Update' action={handleUpdateLicense} />
                )}
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LicenseModal;
