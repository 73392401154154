/**
 * Products.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect, useState } from 'react';
import OxygenTable from '@components/shared/OxygenTable';
import {
    useGetCompanyItemsQuery,
    useDeleteCompanyItemMutation,
} from '@redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '@components/shared/CardHeader';
import Spinner from '@components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { OxModal } from '@components';
import NewProductForm from './NewProductForm';
import util from '../../../../util';

function Products() {
    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchProducts } = useGetCompanyItemsQuery(company._id);
    const [deleteProduct, deleteProductResult] = useDeleteCompanyItemMutation();

    const [product, setProduct] = useState({
        name: '',
        category: '',
        description: '',
        company: company._id,
    });

    const [products, setProductsArray] = useState([]);
    const [modalText, setModalText] = useState('New Product');
    const [showProductModal, setShowProductModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'Name', field: 'name', width: '30%' },
        { label: 'Category', field: 'category', width: '20%' },
        { label: 'Description', field: 'description', width: '30%' },
        { label: 'Unit Price', field: 'unit_price', width: '10%'},
        { label: 'Actions', field: 'actions', width: '10%' },
    ];

    const productsWithActions = products.map(prod => ({
        ...prod,
        category: prod.category.name,
        unit_price: util.formatCurrency(prod.unit_price),
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditProduct(prod)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteProduct(prod._id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }));

    const handleShowProductModal = () => {
        setShowProductModal(true);
        setModalText('New Product');
        setProduct({
            name: '',
            category: '',
            description: '',
            company: company._id,
        });
    };

    const handleCloseProductModal = () => {
        setShowProductModal(false);
    };

    const handleEditProduct = (prod) => {
        setShowProductModal(true);
        setModalText('Edit Product');
        setProduct(
            {
                ...prod,
                category: prod.category._id,
            }
        );
    
    };

    const handleDeleteProduct = (productId) => {
        deleteProduct(productId);
    };

    useEffect(() => {
        refetchProducts();
    }, [refetchProducts]);

    useEffect(() => {
        if (data) {
            setProductsArray(data);
        }

        if (deleteProductResult.data) {
            refetchProducts();
            handleCloseProductModal();
        }
    }, [
        data,
        deleteProductResult.isLoading
    ]);

    if (isLoading || deleteProductResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Product'}
                headerTitle={'Products'}
                headerDescription={'Manage Products'}
                placeholder={'Search Products'}
                searchText={searchText}
                handleSearch={setSearchText}
                handleShow={handleShowProductModal}
            />
            <OxygenTable
                data={productsWithActions}
                columns={columns}
                pageSize={10}
            />
            <OxModal
                handleClose={handleCloseProductModal}
                visible={showProductModal}
                title={modalText}
                children={<NewProductForm product={product} handleClose={handleCloseProductModal} refetch={refetchProducts} />}
            />
        </div>
    );
}

export default Products;
