import Side_nav from '../../../shared_components/side_nav';
import Top_nav from '../../../shared_components/top_nav';
import Footer from '../../../shared_components/footer';

import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Table } from 'react-bootstrap';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { strings } from '../../../strings/strings';
import { useSelector } from 'react-redux';
import { useGetPaymentMethodsQuery, useGetCompanyEmployeesQuery } from '../../../Redux/apiSlice';
import InvoiceStatusChart from '../../../components/shared/InvoiceStatusChart';
import PieChartWithPaddingAngle from '../../../components/shared/PieChartWithPaddingAngle';
import JobCardStatusChart from '../../../components/shared/JobCardStatusChart';
import { ENV } from '@config';
const { oxygen_end_point } = ENV;

const WorkshopDashboard = () => {
    //get company id from redux
    const company = useSelector((state) => state.company);

    //get payment methods
    const {
        data: paymentMethods,
        error: paymentMethodsError,
        isLoading: paymentMethodsLoading,
    } = useGetPaymentMethodsQuery(company._id);

    //get employees
    const {
        data: employees,
        error: employeesError,
        isLoading: employeesLoading,
    } = useGetCompanyEmployeesQuery(company._id);

    const [salesData, setSalesData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [pieChatdata, setPieChatdata] = useState(null); // [ { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 }, { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 }
    const [jobCardStatusData, setJobCardStatusData] = useState(null);
    const [jobCardsAssignedData, setJobCardsAssignedData] = useState(null);
    const [recentInvoicesData, setRecentInvoicesData] = useState(null);
    const [salechartData, setSalechartData] = useState([]);
    const [paymentchartData, setPaymentchartData] = useState([]);
    const [jobCardStatuschartData, setJobCardStatuschartData] = useState([]);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const jobCardStatuses = ['Not Started', 'In Progress', 'Done', 'Collected', 'Overdue'];

    // function get date in this format "2023-06-01T00:00"

    const getStartDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${year}-${formattedMonth}-${formattedDay}T00:00`;
    };

    //get end date

    const getEndDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        return `${year}-${formattedMonth}-${formattedDay}T23:59`;
    };

    useEffect(() => {
        const requestBody = {
            company_id: company._id,
            start_date: getStartDate(new Date()),
            end_date: getEndDate(new Date()),
        };
        

        console.log(requestBody);

        // Fetch sales data
        fetch(oxygen_end_point + '/sales/total', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => setSalesData(data))
            .catch((err) => console.error(err));

        // Fetch payment data
        fetch(oxygen_end_point + '/payments/total', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                setPaymentData(data);
            })
            .catch((err) => console.error(err));

        // Fetch job card status data
        fetch(oxygen_end_point + '/jobcards/total', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                setJobCardStatusData(data);
            })
            .catch((err) => console.error(err));

        // Fetch job cards assigned data
        fetch(oxygen_end_point + '/jobcards/total_per_assigned_to', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                setJobCardsAssignedData(data);
            })
            .catch((err) => console.error(err));

        // Fetch recent invoices
        fetch(oxygen_end_point + '/invoices/recent')
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                setRecentInvoicesData(data);
            })
            .catch((err) => console.error(err));

        // Fetch sales chart data
        fetch(oxygen_end_point + '/saleschart/total', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                setSalechartData(data);
            })
            .catch((err) => console.error(err));

        // Fetch payment chart data
        fetch(oxygen_end_point + '/payments/total_per_method', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                setPaymentchartData(data);
            })
            .catch((err) => console.error(err));

        // Fetch job card status chart data
        fetch(oxygen_end_point + '/jobcardscahrt', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setJobCardStatuschartData(data);
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card className='text-center'>
                                    <Card.Body className='text-center'>
                                        <Card.Title>{`INVOICES OVERVIEW (N$ ${
                                            salesData?.totalSales || 0
                                        })`}</Card.Title>
                                        <InvoiceStatusChart data={salechartData} />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className='text-center'>
                                    <Card.Body className='text-center'>
                                        <Card.Title>{`ALL PAYMENTS (N$ ${
                                            paymentData?.totalPayments || 0
                                        })`}</Card.Title>
                                        <PieChartWithPaddingAngle data={paymentchartData} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className='text-center'>
                                    <Card.Header>JOB CARDS PER STATUS</Card.Header>
                                    <Card.Body>
                                        <JobCardStatusChart data={jobCardStatuschartData} />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>JOB CARDS ASSIGNED TO</Card.Header>
                                    <Card.Body>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th width='25%'>Assigned To</th>
                                                    {jobCardStatuses && jobCardStatuses.map((status) => (
                                                        <th width='15%' key={status}>
                                                            {status}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {employees &&
                                                    jobCardsAssignedData &&
                                                    employees.map((employee) => (
                                                        <>
                                                            {jobCardsAssignedData
                                                                .jobCardsPerEmployee[
                                                                employee.first_name +
                                                                    ' ' +
                                                                    employee.last_name
                                                            ] && (
                                                                <tr key={employee._id}>
                                                                    <td>
                                                                        {employee.first_name +
                                                                            ' ' +
                                                                            employee.last_name}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            jobCardsAssignedData
                                                                                .jobCardsPerEmployee[
                                                                                employee.first_name +
                                                                                    ' ' +
                                                                                    employee.last_name
                                                                            ][jobCardStatuses[0]]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            jobCardsAssignedData
                                                                                .jobCardsPerEmployee[
                                                                                employee.first_name +
                                                                                    ' ' +
                                                                                    employee.last_name
                                                                            ][jobCardStatuses[1]]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            jobCardsAssignedData
                                                                                .jobCardsPerEmployee[
                                                                                employee.first_name +
                                                                                    ' ' +
                                                                                    employee.last_name
                                                                            ][jobCardStatuses[2]]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            jobCardsAssignedData
                                                                                .jobCardsPerEmployee[
                                                                                employee.first_name +
                                                                                    ' ' +
                                                                                    employee.last_name
                                                                            ][jobCardStatuses[3]]
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            jobCardsAssignedData
                                                                                .jobCardsPerEmployee[
                                                                                employee.first_name +
                                                                                    ' ' +
                                                                                    employee.last_name
                                                                            ][jobCardStatuses[4]]
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default WorkshopDashboard;
