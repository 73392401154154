/**
 * ItemCategories.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect, useState } from 'react';
import OxygenTable from '@components/shared/OxygenTable';
import { 
    useGetCompanyItemCategoriesQuery,
    useDeleteCompanyItemCategoryMutation
} from '@redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '@components/shared/CardHeader';
import Spinner from '@components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { OxModal } from '@components';
import NewItemCategoryForm from './NewItemCategoryForm';

function ItemCategories() {

    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchCategories } = useGetCompanyItemCategoriesQuery(company._id);
    const [deleteCategory, deleteCategoryResult] = useDeleteCompanyItemCategoryMutation();
    
    const [category, setCategory] = useState({
        name: '',
        description: '',
        company: company._id,
    });

    const [categories, setCategoriesArray] = useState([]);
    const [modalText, setModalText] = useState('New Item Category');
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'Name', field: 'name', width: '40%' },
        { label: 'Description', field: 'description', width: '50%' },
        { label: 'Actions', field: 'actions', width: '100%' }
    ];

    const categoriesWithActions = categories.map(cat => ({
        ...cat,
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditCategory(cat)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteCategory(cat._id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }));

    const handleShowCategoryModal = () => {
        setShowCategoryModal(true);
        setModalText('New Item Category');
        setCategory({
            name: '',
            description: '',
            company: company._id,
        });
    };

    const handleCloseCategoryModal = () => {
        setShowCategoryModal(false);
    };

    const handleEditCategory = (cat) => {
        setShowCategoryModal(true);
        setModalText('Edit Item Category');
        setCategory(cat);
    };

    const handleDeleteCategory = (categoryId) => {
        deleteCategory(categoryId);
    };

    useEffect(() => {
        if (data) {
            setCategoriesArray(data);
            console.log(data);
        }

        if (deleteCategoryResult.data) {
            refetchCategories();
            handleCloseCategoryModal();
        }
    }, [
        data,
        deleteCategoryResult.isLoading
    ]);

    if (isLoading || deleteCategoryResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Item Category'}
                headerTitle={'Item Categories'}
                headerDescription={'Manage Item Categories'}
                placeholder={'Search Item Categories'}
                searchText={searchText}
                handleSearch={setSearchText}
                handleShow={handleShowCategoryModal}
            />
            <OxygenTable
                data={categoriesWithActions}
                columns={columns}
                pageSize={10}
            />
            <OxModal 
                handleClose={handleCloseCategoryModal}
                visible={showCategoryModal}
                title={modalText}
                children={<NewItemCategoryForm itemCategory={category} handleClose={handleCloseCategoryModal} refetch={refetchCategories} />}
            />
        </div>
    );
}

export default ItemCategories;
