/**
 * TenantOptionsButton.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Dropdown, Menu } from 'antd';
import { CircularButton, OxModal } from '@shared';
import { CreateContract } from '@real-estate';
import { FaEllipsisH } from 'react-icons/fa';
import { ROUTES } from '@enums';
import { TenantsService } from '@services';

// service instances
const tenantsService = TenantsService.create();

export const TenantOptionsButton = ({ customer, applicant }) => {
    const navigate = useNavigate();

    const companyState = useSelector((state) => state.company);
    const userState = useSelector((state) => state.user);

    const [showModal, setShowModal] = useState(false);
    const [customerInfo, setCustomerInfo] = useState(customer);

    const items = [
        {
            key: '1',
            label: 'PLACE TENANT',
            place: () => {
                console.log('Customer contract: ', customer);
                console.log('applicant contract: ', applicant);

                if (customer) {
                    setCustomerInfo(customer);
                    handleShowModal();
                }
                if (applicant) {
                    createTenant();
                }
            },
        },
    ];

    const menuProps = {
        items,
        onClick: (e) => {},
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigateToContracts();
    };
    const handleShowModal = () => {
        setTimeout(() => {
            setShowModal(true);
        }, 300);
    };

    const navigateToContracts = () => {
        navigate(`/${ROUTES.CONTRACTS}`);
    };

    const createTenant = () => {
        const tenantFromApplicant = {
            first_name: applicant.firstName,
            last_name: applicant.lastName,
            dob: applicant.dateOfBirth,
            id_number: applicant.identityNumber,
            phone_number: applicant.cellPhone,
            email: applicant.emailAddress,
            residencial_address: applicant.residentialAddress,
            postal_address: applicant.postalAddress,
            country: applicant.countryOfBirth,
            company: companyState._id,
            created_by: userState.user._id,
            customer_type: 'tenant',
        };
        tenantsService.create(tenantFromApplicant).subscribe(
            (response) => {
                // if (response) {
                console.log('response: ', response);
                setCustomerInfo(response);
                console.log('Customer Info 1: ', customerInfo);
                handleShowModal();
                // }
            },
            (error) => {
                console.log('Dependencies Get Error: ', error);
            }
        );
    };

    return (
        <td>
            <Dropdown
                overlay={
                    <Menu onClick={menuProps.onClick}>
                        {menuProps.items.map((item) => (
                            <Menu.Item
                                key={item.key}
                                onClick={() => {
                                    item.place();
                                }}>
                                {item.label}
                            </Menu.Item>
                        ))}
                    </Menu>
                }>
                <CircularButton variant='success' icon={<FaEllipsisH />} />
            </Dropdown>
            {showModal && (
                <OxModal
                    scrollable={true}
                    height='55%'
                    dark={false}
                    title={'CREATE CONTRACT'}
                    visible={showModal}
                    handleClose={handleCloseModal}
                    children={
                        <CreateContract
                            customer={customerInfo}
                            handleCloseModal={handleCloseModal}
                        />
                    }
                />
            )}
        </td>
    );
};
