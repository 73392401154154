import React from 'react';
import { Modal, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';
import Switch from 'react-switch';

function InvoiceItemModal({
    show,
    onHide,
    modalText,
    invoiceItem,
    handleUpdateInvoiceItem,
    handleChange,
}) {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type='text'
                            name='description'
                            value={invoiceItem.description}
                            onChange={handleChange}
                            placeholder='Description'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type='number'
                            name='quantity'
                            value={invoiceItem.quantity}
                            onChange={handleChange}
                            placeholder='Quantity'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor='discount_type'>Discount Type</Form.Label> <br />
                        <Form.Check
                            type='radio'
                            label={<b>{`Percentage (%)`}</b>}
                            id='discount_type_percentage'
                            name='discount_type'
                            value='percentage'
                            onChange={handleChange}
                            checked={invoiceItem.discount_type === 'percentage'}
                            className='form-check-inline'
                        />
                        <Form.Check
                            type='radio'
                            label={<b>{`Amount (N$)`}</b>}
                            id='discount_type_amount'
                            name='discount_type'
                            value='amount'
                            onChange={handleChange}
                            checked={invoiceItem.discount_type === 'amount'}
                            className='form-check-inline'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Discount</Form.Label>
                        <Form.Control
                            type='number'
                            name='discount'
                            value={invoiceItem.discount}
                            onChange={handleChange}
                            placeholder='Discount'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type='number'
                            name='unit_price'
                            value={invoiceItem.unit_price}
                            onChange={handleChange}
                            placeholder='Unit Price'
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>
                    <OxButton text='Update' action={handleUpdateInvoiceItem} />
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default InvoiceItemModal;
