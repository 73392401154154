/**
 * properties.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';
import { from } from 'rxjs';

import { LOCAL_STORAGE_ENUMS } from '@enums';

const { PROPERTIES } = LOCAL_STORAGE_ENUMS;
let instance;

export class PropertiesService extends APIService {
    constructor() {
        const collection = PROPERTIES;
        super(collection, PROPERTIES);
    }

    /**
     * Checks if an username or email exists on the API
     * @param {string} value
     * @returns {Promise<object>}
     */

    async $fetchAll() {
        const url = `${this.url}`;
        try {
            const data = await _GET(url);
            if (data) {
                this.sm.add(this._key, data);
            }
            return Promise.resolve(data);
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }

    fetchAll() {
        return from(this.$fetchAll());
    }

    /**
     * Gets properties belonging to current company
     * @param {string} value
     * @returns {Promise<object>}
     */

    async $fetchAllCompany(company_id) {
        const url = `${this.url}?company=${company_id}`;
        try {
            const data = await _GET(url);
            if (data) {
                this.sm.add(this._key, data);
            }
            return Promise.resolve(data);
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }

    fetchAllCompany(company_id) {
        return from(this.$fetchAllCompany(company_id));
    }

    static create() {
        if (!instance) {
            instance = new PropertiesService();
        }
        return instance;
    }
}
