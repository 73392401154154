/**
 * LoadingPage.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';

export function LoadingPage({ title }) {
    return (
        <Container
            className='d-flex justify-content-center align-items-center'
            style={{ height: '75vh' }}>
            <Row>
                <Col xs='auto'>
                    <Spinner animation='border' role='status' variant='primary' />
                </Col>
                <Col xs='auto'>
                    <span>{title}</span>
                </Col>
            </Row>
        </Container>
    );
}
