import React, { useEffect } from 'react';
import Side_nav from '@shared_components/side_nav';
import Top_nav from '@shared_components/top_nav';
import Footer from '@shared_components/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import OxButton from '@shared/OxButton';

import { Modal, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import {
    useGetCompanyPropertiesQuery,
    useCreateCompanyPropertyMutation,
    useUpdateCompanyPropertyMutation,
    useDeleteCompanyPropertyMutation,
    useGetCompanyCustomerPropertyOwnersQuery,
    useUploadCompanyPropertyImageMutation,
    useGetPropertyImagesQuery,
    useDeletePropertyImageMutation,
} from '@redux/apiSlice';

import RealEstateImagesModal from '@components/industries/real_estate/RealEstateImagesModal';

import { TenantApplicantsTable } from '@real-estate';

import { strings } from '@strings/strings';

function Properties() {
    const dispatch = useDispatch();

    const company = useSelector((state) => state.company);

    const user = useSelector((state) => state.user);

    const [currentProperty, setCurrentProperty] = React.useState({
        _id: '',
    });

    //get properties

    const {
        data: properties,
        error: propertiesError,
        isLoading: propertiesLoading,
        refetch: refetchProperties,
    } = useGetCompanyPropertiesQuery(company._id);

    //get property owners

    const {
        data: propertyOwners,
        error: propertyOwnersError,
        isLoading: propertyOwnersLoading,
    } = useGetCompanyCustomerPropertyOwnersQuery(company._id);

    //create property

    const [createProperty, createPropertyResult] = useCreateCompanyPropertyMutation();

    //update property

    const [updateProperty, updatePropertyResult] = useUpdateCompanyPropertyMutation();

    //delete property

    const [deleteProperty, deletePropertyResult] = useDeleteCompanyPropertyMutation();

    //upload property image

    const [uploadPropertyImage, uploadPropertyImageResult] =
        useUploadCompanyPropertyImageMutation();

    //get property images

    const {
        data: propertyImages,
        error: propertyImagesError,
        isLoading: propertyImagesLoading,
        refetch: propertyImagesRefech,
    } = useGetPropertyImagesQuery(currentProperty._id);

    //delete property image

    const [deletePropertyImage, deletePropertyImageResult] = useDeletePropertyImageMutation();

    //property images list state

    const [propertyImagesList, setPropertyImagesList] = React.useState([]);

    //property images modal state

    const [propertyImagesModal, setPropertyImagesModal] = React.useState(false);

    //handle property images modal show

    const handlePropertyImagesModalShow = (property) => (e) => {
        e.preventDefault();

        setCurrentProperty(property);

        propertyImagesRefech();

        setPropertyImagesModal(true);
    };

    //handle property images modal close

    const handlePropertyImagesModalClose = () => {
        setPropertyImagesModal(false);
    };

    //handle property image delete

    const handlePropertyImageDelete = (image) => {
        deletePropertyImage(image.filename);
        propertyImagesRefech();
    };

    //handle property image upload

    const handlePropertyImageUpload = (file) => {
        const formData = new FormData();

        formData.append('_id', currentProperty._id);

        formData.append('file', file);

        formData.append('size', '' + file.size);

        fetch(strings.oxygen_end_point + '/upload', {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                propertyImagesRefech();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //property list state

    const [propertyList, setPropertyList] = React.useState([]);

    //property owner list state

    const [propertyOwnerList, setPropertyOwnerList] = React.useState([]);

    const PROPERTY_TYPES = [
        { id: 11, name: 'House' },
        { id: 12, name: 'Apartment' },
        { id: 13, name: 'Townhouse' },
        { id: 14, name: 'Flat' },
        { id: 15, name: 'Duplex' },
        { id: 16, name: 'Cottage' },
        { id: 17, name: 'Villa' },
        { id: 18, name: 'Farm' },
        { id: 19, name: 'Commercial' },
        { id: 110, name: 'Industrial' },
        { id: 111, name: 'Office' },
        { id: 112, name: 'Warehouse' },
        { id: 113, name: 'Other' },
    ];

    const PROPERTY_STATUSES = [
        { id: 21, name: 'For Sale' },
        { id: 22, name: 'For Rent' },
        { id: 23, name: 'Sold' },
        { id: 24, name: 'Rented' },
    ];

    const PROPERTY_SIZE_UNITS = [
        { id: 31, name: 'm²' },
        { id: 32, name: 'ha' },
        { id: 33, name: 'acres' },
        { id: 34, name: 'sq ft' },
        { id: 35, name: 'sq yards' },
        { id: 36, name: 'sq miles' },
        { id: 37, name: 'hectares' },
        { id: 38, name: 'other' },
    ];

    const [isPropertyModalOpen, setIsPropertyModalOpen] = React.useState(false);

    const [modalTitle, setModalTitle] = React.useState('New Property');

    //handle modal open and close

    const handlePropertyModalOpen = () => {
        //clear all fields

        set_id('');

        setName('');

        setDescription('');

        setProper_owner('');

        setErf_number('');

        setSurbub('');

        setTown('');

        setRegion('');

        setCountry('');

        setSize('');

        setSize_unit('m²');

        setNumber_of_rooms('');

        setNumber_of_bathrooms('');

        setNumber_of_parking('');

        setNumber_of_garages('');

        setNumber_of_offices('');

        setNumber_of_studios('');

        setGarden(false);

        setPool(false);

        setBraai_area(false);

        setSecurity(false);

        setPets_allowed(false);

        setProperty_type('');

        setProperty_status('');

        setIsPropertyModalOpen(true);

        setModalTitle('New Property');
    };

    const handlePropertyModalClose = () => {
        setIsPropertyModalOpen(false);
    };

    //property state variables

    const [_id, set_id] = React.useState('');
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [proper_owner, setProper_owner] = React.useState('');
    const [erf_number, setErf_number] = React.useState('');
    const [surbub, setSurbub] = React.useState('');
    const [town, setTown] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [size, setSize] = React.useState('');
    const [size_unit, setSize_unit] = React.useState('m²');
    const [number_of_rooms, setNumber_of_rooms] = React.useState('');
    const [number_of_bathrooms, setNumber_of_bathrooms] = React.useState('');
    const [number_of_parking, setNumber_of_parking] = React.useState('');
    const [number_of_garages, setNumber_of_garages] = React.useState('');
    const [number_of_offices, setNumber_of_offices] = React.useState('');
    const [number_of_studios, setNumber_of_studios] = React.useState('');
    const [garden, setGarden] = React.useState(false);
    const [pool, setPool] = React.useState(false);
    const [braai_area, setBraai_area] = React.useState(false);
    const [security, setSecurity] = React.useState(false);
    const [pets_allowed, setPets_allowed] = React.useState(false);
    const [property_type, setProperty_type] = React.useState('');
    const [property_status, setProperty_status] = React.useState('');
    const [property_age, setProperty_age] = React.useState('');
    const [property_price, setProperty_price] = React.useState('');
    const [property_rent, setProperty_rent] = React.useState('');
    const [property_deposit, setProperty_deposit] = React.useState('');

    //handle edit property

    const handleEditProperty = (property) => (e) => {
        //clear all fields

        e.preventDefault();

        set_id('');
        setName('');
        setDescription('');
        setProper_owner('');
        setErf_number('');
        setSurbub('');
        setTown('');
        setRegion('');
        setCountry('');
        setSize('');
        setSize_unit('m²');
        setNumber_of_rooms('');
        setNumber_of_bathrooms('');
        setNumber_of_parking('');
        setNumber_of_garages('');
        setNumber_of_offices('');
        setNumber_of_studios('');
        setGarden(false);
        setPool(false);
        setBraai_area(false);
        setSecurity(false);
        setPets_allowed(false);
        setProperty_type('');
        setProperty_status('');
        setProperty_age('');

        //set fields with property data

        set_id(property._id);
        setName(property.name);
        setDescription(property.description);
        setProper_owner(property.proper_owner);
        setErf_number(property.erf_number);
        setSurbub(property.surbub);
        setTown(property.town);
        setRegion(property.region);
        setCountry(property.country);
        setSize(property.size);
        setSize_unit(property.size_unit);
        setNumber_of_rooms(property.number_of_rooms);
        setNumber_of_bathrooms(property.number_of_bathrooms);
        setNumber_of_parking(property.number_of_parking);
        setNumber_of_garages(property.number_of_garages);
        setNumber_of_offices(property.number_of_offices);
        setNumber_of_studios(property.number_of_studios);
        setGarden(property.garden);
        setPool(property.pool);
        setBraai_area(property.braai_area);
        setSecurity(property.security);
        setPets_allowed(property.pets_allowed);
        setProperty_type(property.property_type);
        setProperty_status(property.property_status);
        setProperty_age(property.property_age);
        setProperty_price(property.property_price);
        setProperty_rent(property.property_rent);
        setProperty_deposit(property.property_deposit);

        setModalTitle('Edit Property');

        setIsPropertyModalOpen(true);
    };

    //handle delete property

    const handleDeleteProperty = (property) => (e) => {
        e.preventDefault();

        const confirmBox = window.confirm('Do you really want to delete this property?');
        if (confirmBox === true) {
            deleteProperty(property._id);
        }
    };

    //handle create property

    const handleCreateProperty = () => {
        createProperty({
            name,
            description,
            proper_owner,
            erf_number,
            surbub,
            town,
            region,
            country,
            size,
            size_unit,
            number_of_rooms,
            number_of_bathrooms,
            number_of_parking,
            number_of_garages,
            number_of_offices,
            number_of_studios,
            garden,
            pool,
            braai_area,
            security,
            pets_allowed,
            property_type,
            property_status,
            property_age,
            property_price,
            property_rent,
            property_deposit,
            company: company._id,
            created_by: user._id,
        });
    };

    //handle update property

    const handleUpdateProperty = () => {
        updateProperty({
            _id,
            name,
            description,
            proper_owner,
            erf_number,
            surbub,
            town,
            region,
            country,
            size,
            size_unit,
            number_of_rooms,
            number_of_bathrooms,
            number_of_parking,
            number_of_garages,
            number_of_offices,
            number_of_studios,
            garden,
            pool,
            braai_area,
            security,
            pets_allowed,
            property_type,
            property_status,
            property_age,
            property_price,
            property_rent,
            property_deposit,
            company: company._id,
            created_by: user._id,
        });
    };

    //image upload

    useEffect(() => {
        if (properties) {
            setPropertyList(properties);
            console.log(properties);
        }
        if (propertyOwners) {
            setPropertyOwnerList(propertyOwners);
        }

        if (createPropertyResult.isSuccess) {
            setIsPropertyModalOpen(false);
            refetchProperties();
        }

        if (updatePropertyResult.isSuccess) {
            setIsPropertyModalOpen(false);
            refetchProperties();
        }

        if (deletePropertyResult.isSuccess) {
            refetchProperties();
        }

        // if (propertyImages) {
        //     setPropertyImagesList(propertyImages);
        // }
    }, [
        propertiesLoading,
        propertyOwnersLoading,
        createPropertyResult.data,
        updatePropertyResult.data,
        deletePropertyResult.data,
        // propertyImages,
        uploadPropertyImageResult.data,
        deletePropertyImageResult.data,
    ]);

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <Tabs>
                                <TabList>
                                    <Tab>Properties</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='card ' style={{ height: '90%' }}>
                                        <div className='card-header '>
                                            <div className='form-row'>
                                                <div className='col-md-5'>
                                                    <h4 className='card-title'>Properties</h4>
                                                    <p className='card-category'>
                                                        Manage Properties
                                                    </p>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='form-row'>
                                                        <div className='col-md-8'>
                                                            <div className='form-group'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    placeholder='Search Property....'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <OxButton
                                                                text='New Property'
                                                                action={handlePropertyModalOpen}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <table className='table table-hover table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Status</th>
                                                        <th>Address</th>
                                                        <th>Size</th>
                                                        <th>Rooms</th>
                                                        <th>Bathrooms</th>
                                                        <th>Rent</th>
                                                        <th>Price</th>
                                                        <th>More</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {propertyList &&
                                                        propertyList.map((property) => (
                                                            <tr key={property._id}>
                                                                <td>{property.name}</td>
                                                                <td>{property.property_status}</td>
                                                                <td>
                                                                    {property.surbub},{' '}
                                                                    {property.town}
                                                                </td>
                                                                <td>
                                                                    {property.size}{' '}
                                                                    {property.size_unit}
                                                                </td>
                                                                <td>{property.number_of_rooms}</td>
                                                                <td>
                                                                    {property.number_of_bathrooms}
                                                                </td>
                                                                <td>
                                                                    {'N$' + property?.property_rent}
                                                                </td>
                                                                <td>
                                                                    {'N$' + property.property_price}
                                                                </td>
                                                                <td>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle
                                                                            variant='primary'
                                                                            id='dropdown-basic'>
                                                                            More
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item
                                                                                onClick={handleEditProperty(
                                                                                    property
                                                                                )}>
                                                                                Edit
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item
                                                                                onClick={handleDeleteProperty(
                                                                                    property
                                                                                )}>
                                                                                Delete
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item
                                                                                onClick={handlePropertyImagesModalShow(
                                                                                    property
                                                                                )}>
                                                                                Images
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item>
                                                                                Rent
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item>
                                                                                Sell
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <Modal
                                        show={isPropertyModalOpen}
                                        onHide={handlePropertyModalClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{modalTitle}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <fieldset>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_name'>
                                                            Property name
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_name'
                                                            placeholder='Name'
                                                            value={name}
                                                            onChange={(e) =>
                                                                setName(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_description'>
                                                            Description
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_description'
                                                            placeholder='Description'
                                                            value={description}
                                                            onChange={(e) =>
                                                                setDescription(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_proper_owner'>
                                                            Owner
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            id='_proper_owner'
                                                            value={proper_owner}
                                                            onChange={(e) =>
                                                                setProper_owner(e.target.value)
                                                            }>
                                                            <option value='0'>Select Owner</option>
                                                            {propertyOwnerList.map((owner) => (
                                                                <option
                                                                    key={owner.id}
                                                                    value={owner._id}>
                                                                    {owner.business_name
                                                                        ? owner.business_name
                                                                        : owner.first_name +
                                                                          ' ' +
                                                                          owner.last_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_erf_number'>
                                                            Erf Number
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_erf_number'
                                                            placeholder='Erf Number'
                                                            value={erf_number}
                                                            onChange={(e) =>
                                                                setErf_number(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_suburb'>
                                                            Suburb
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_suburb'
                                                            placeholder='Suburb'
                                                            value={surbub}
                                                            onChange={(e) =>
                                                                setSurbub(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_town'>
                                                            Town
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_town'
                                                            placeholder='Town'
                                                            value={town}
                                                            onChange={(e) =>
                                                                setTown(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_region'>
                                                            Region
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_region'
                                                            placeholder='Region'
                                                            value={region}
                                                            onChange={(e) =>
                                                                setRegion(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_country'>
                                                            Country
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_country'
                                                            placeholder='Country'
                                                            value={country}
                                                            onChange={(e) =>
                                                                setCountry(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_size'>
                                                            Size
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='_size'
                                                            placeholder='Size'
                                                            value={size}
                                                            onChange={(e) =>
                                                                setSize(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_size_unit'>
                                                            Size Unit
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            id='_size_unit'
                                                            value={size_unit}
                                                            onChange={(e) =>
                                                                setSize_unit(e.target.value)
                                                            }>
                                                            <option value='0'>
                                                                Select Size Unit
                                                            </option>
                                                            {PROPERTY_SIZE_UNITS.map((unit) => (
                                                                <option
                                                                    key={unit.id}
                                                                    value={unit.name}>
                                                                    {unit.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_property_type'>
                                                            Property Type
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            id='_property_type'
                                                            value={property_type}
                                                            onChange={(e) =>
                                                                setProperty_type(e.target.value)
                                                            }>
                                                            <option value='0'>
                                                                Select Property Type
                                                            </option>
                                                            {PROPERTY_TYPES.map((type) => (
                                                                <option
                                                                    key={type.id}
                                                                    value={type.name}>
                                                                    {type.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_property_status'>
                                                            Property Status
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            id='_property_status'
                                                            value={property_status}
                                                            onChange={(e) =>
                                                                setProperty_status(e.target.value)
                                                            }>
                                                            <option value='0'>
                                                                Select Property Status
                                                            </option>
                                                            {PROPERTY_STATUSES.map((status) => (
                                                                <option
                                                                    key={status.id}
                                                                    value={status.name}>
                                                                    {status.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_number_of_rooms'>
                                                            Property Category
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_number_of_rooms'
                                                            placeholder='Number of Rooms'
                                                            value={number_of_rooms}
                                                            onChange={(e) =>
                                                                setNumber_of_rooms(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_number_of_bathrooms'>
                                                            Number of Bathrooms
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_number_of_bathrooms'
                                                            placeholder='Number of Bathrooms'
                                                            value={number_of_bathrooms}
                                                            onChange={(e) =>
                                                                setNumber_of_bathrooms(
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_number_of_parking'>
                                                            Number of Parking
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_number_of_parking'
                                                            placeholder='Number of Parking'
                                                            value={number_of_parking}
                                                            onChange={(e) =>
                                                                setNumber_of_parking(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_number_of_garages'>
                                                            Number of Garages
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_number_of_garages'
                                                            placeholder='Number of Garages'
                                                            value={number_of_garages}
                                                            onChange={(e) =>
                                                                setNumber_of_garages(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_number_of_offices'>
                                                            Number of Offices
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_number_of_offices'
                                                            placeholder='Number of Offices'
                                                            value={number_of_offices}
                                                            onChange={(e) =>
                                                                setNumber_of_offices(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_number_of_studios'>
                                                            Number of Studios
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_number_of_studios'
                                                            placeholder='Number of Studios'
                                                            value={number_of_studios}
                                                            onChange={(e) =>
                                                                setNumber_of_studios(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_garden'>
                                                            Garden
                                                        </Form.Label>
                                                        <Form check>
                                                            <Form.Check
                                                                type='checkbox'
                                                                id='_garden'
                                                                checked={garden}
                                                                onChange={(e) =>
                                                                    setGarden(e.target.checked)
                                                                }
                                                            />
                                                        </Form>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_pool'>
                                                            Pool
                                                        </Form.Label>
                                                        <Form check>
                                                            <Form.Check
                                                                type='checkbox'
                                                                id='_pool'
                                                                checked={pool}
                                                                onChange={(e) =>
                                                                    setPool(e.target.checked)
                                                                }
                                                            />
                                                        </Form>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_security'>
                                                            Security
                                                        </Form.Label>
                                                        <Form check>
                                                            <Form.Check
                                                                type='checkbox'
                                                                id='_security'
                                                                checked={security}
                                                                onChange={(e) =>
                                                                    setSecurity(e.target.checked)
                                                                }
                                                            />
                                                        </Form>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_pets_allowed'>
                                                            Pets Allowed
                                                        </Form.Label>
                                                        <Form check>
                                                            <Form.Check
                                                                type='checkbox'
                                                                id='_pets_allowed'
                                                                checked={pets_allowed}
                                                                onChange={(e) =>
                                                                    setPets_allowed(
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        </Form>
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_property_age'>
                                                            Property Age
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_property_age'
                                                            placeholder='Property Age'
                                                            value={property_age}
                                                            onChange={(e) =>
                                                                setProperty_age(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_property_price'>
                                                            Property Price
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_property_price'
                                                            placeholder='Property Price'
                                                            value={property_price}
                                                            onChange={(e) =>
                                                                setProperty_price(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_property_rent'>
                                                            Property Rent
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_property_rent'
                                                            placeholder='Property Rent'
                                                            value={property_rent}
                                                            onChange={(e) =>
                                                                setProperty_rent(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='_property_deposit'>
                                                            Property Deposit
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='_property_deposit'
                                                            placeholder='Property Deposit'
                                                            value={property_deposit}
                                                            onChange={(e) =>
                                                                setProperty_deposit(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>
                                                </fieldset>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant='secondary'
                                                onClick={handlePropertyModalClose}>
                                                Close
                                            </Button>
                                            {modalTitle === 'New Property' ? (
                                                <OxButton
                                                    text='Submit'
                                                    action={handleCreateProperty}
                                                />
                                            ) : (
                                                <OxButton
                                                    text='Update'
                                                    action={handleUpdateProperty}
                                                />
                                            )}
                                        </Modal.Footer>
                                    </Modal>

                                    <RealEstateImagesModal
                                        show={propertyImagesModal}
                                        onHide={handlePropertyImagesModalClose}
                                        images={propertyImagesList}
                                        onDelete={handlePropertyImageDelete}
                                        onUpload={handlePropertyImageUpload}
                                    />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Properties;
