import React from 'react';

function UnderConstruction({ module }) {
    return (
        <div className='under-construction'>
            <h2>Under Construction</h2>
            <p>
                Sorry, the <b> {module} </b> module is currently under construction. Please check
                back later.
            </p>
        </div>
    );
}

export default UnderConstruction;
