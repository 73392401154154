/**
 * IncomeAndExpenditureForm.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Row, Col, Form, Button as BootstrapButton } from 'react-bootstrap';
import { Field } from 'formik';

export function IncomeAndExpenditureForm({ formikProps }) {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        touched,
        setFieldValue,
    } = formikProps;

    const totalExpenditure =
        parseFloat(values.vehicle) +
        parseFloat(values.insurance) +
        parseFloat(values.medicalAid) +
        parseFloat(values.pension) +
        parseFloat(values.onlineSubscriptions) +
        parseFloat(values.groceries);

    const totalIncome = parseFloat(values.salaryNet) + parseFloat(values.otherIncome);

    const surplus = totalIncome - totalExpenditure;

    return (
        <div
            style={{
                width: '100%',
                background: '#f9f9f9',
                padding: '30px 10px',
                height: '100%',
            }}>
            <Row>
                <Col md={6}>
                    <h4 style={{ color: 'blue' }}>Income</h4>

                    <Form.Group as={Col} controlId='salaryNet'>
                        <Form.Label>Salary Net (N$)</Form.Label>
                        <Field
                            name='salaryNet'
                            as={Form.Control}
                            value={values.salaryNet}
                            type='text'
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId='otherIncome'>
                        <Form.Label>Other Income (N$)</Form.Label>
                        <Field
                            name='otherIncome'
                            as={Form.Control}
                            value={values.otherIncome}
                            type='text'
                        />
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <h4 style={{ color: 'blue' }}>Expenditures</h4>

                    <Form.Group as={Col} controlId='vehicle'>
                        <Form.Label>Vehicle (N$)</Form.Label>
                        <Field
                            name='vehicle'
                            as={Form.Control}
                            value={values.vehicle}
                            type='text'
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId='insurance'>
                        <Form.Label>Insurance (N$)</Form.Label>
                        <Field
                            name='insurance'
                            as={Form.Control}
                            value={values.insurance}
                            type='text'
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId='medicalAid'>
                        <Form.Label>Medical Aid (N$)</Form.Label>
                        <Field
                            name='medicalAid'
                            as={Form.Control}
                            value={values.medicalAid}
                            type='text'
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId='pension'>
                        <Form.Label>Pension (N$)</Form.Label>
                        <Field
                            name='pension'
                            as={Form.Control}
                            value={values.pension}
                            type='text'
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId='onlineSubscriptions'>
                        <Form.Label>online Subscriptions (N$)</Form.Label>
                        <Field
                            name='onlineSubscriptions'
                            as={Form.Control}
                            value={values.onlineSubscriptions}
                            type='text'
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId='groceries'>
                        <Form.Label>Groceries (N$)</Form.Label>
                        <Field
                            name='groceries'
                            as={Form.Control}
                            value={values.groceries}
                            type='text'
                        />
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <h3 as='h4' className='text-center font-weight-bold mb-3'>
                Surplus: N${surplus.toFixed(2)}
            </h3>
        </div>
    );
}
