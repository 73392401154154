import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';

const colors = ['#AFB6BD', '#1E90FF', '#0052A3', '#99CCFF', '#003D7A'];

const data = [
    { status: 'Not Started', total: 10 },
    { status: 'In Progress', total: 5 },
    { status: 'Done', total: 8 },
    { status: 'Collected', total: 3 },
    { status: 'Overdue', total: 2 },
];

const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${
      y + height
  }
  Z`;
};

const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
};

const JobCardStatusChart = ({ data }) => {
    return (
        <BarChart
            width={1000}
            height={300}
            data={data}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='status' />
            <YAxis />
            <Bar dataKey='total' fill='#8884d8' shape={<TriangleBar />} label={{ position: 'top' }}>
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
            </Bar>
        </BarChart>
    );
};

export default JobCardStatusChart;
