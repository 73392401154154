import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row } from 'react-bootstrap';
import PurchaseOrderItems from './PurchaseOrderItems';
import { useSelector } from 'react-redux';
import {
    useCreatePurchaseOrderMutation,
    useUpdatePurchaseOrderMutation,
    useGetCompanySuppliersQuery,
} from '../../../Redux/apiSlice';

const PurchaseOrderModal = ({ show, onHide, purchaseOrderData }) => {
    // Company
    const company = useSelector((state) => state.company);

    //employee
    const employee = useSelector((state) => state.employee);

    // Check if purchase order data has been passed
    if (!purchaseOrderData._id) {
        purchaseOrderData = {
            _id: '',
            company: company._id,
            order_no: '',
            employee: '',
            supplier: '',
            status: 'pending',
        };
    }

    // Get purchase order items
    const [purchaseOrder, setPurchaseOrder] = useState(purchaseOrderData);

    // Create purchase order
    const [createPurchaseOrder, createPurchaseOrderResult] = useCreatePurchaseOrderMutation();

    // Update purchase order
    const [updatePurchaseOrder, updatePurchaseOrderResult] = useUpdatePurchaseOrderMutation();

    // Get suppliers
    const {
        data: suppliers,
        error: suppliersError,
        isLoading: suppliersLoading,
        refetch: refetchSuppliers,
    } = useGetCompanySuppliersQuery(company._id);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPurchaseOrder((prevPurchaseOrder) => {
            return { ...prevPurchaseOrder, [name]: value };
        });
    };

    const handleCreatePurchaseOrder = () => {
        const newPurchaseOrder = {
            company: company._id,
            employee: employee._id,
            supplier: purchaseOrder.supplier,
        };
        createPurchaseOrder(newPurchaseOrder);
    };

    const handleUpdatePurchaseOrder = () => {
        const updatedPurchaseOrder = {
            _id: purchaseOrder._id,
            company: company._id,
            order_no: purchaseOrder.order_no,
            employee: purchaseOrder.employee,
            supplier: purchaseOrder.supplier._id,
            status: purchaseOrder.status,
        };
        updatePurchaseOrder(updatedPurchaseOrder);
    };

    useEffect(() => {
        if (createPurchaseOrderResult.data) {
            setPurchaseOrder(createPurchaseOrderResult.data);
        }
        if (updatePurchaseOrderResult.data) {
            setPurchaseOrder((prevPurchaseOrder) => ({
                ...prevPurchaseOrder,
                status: updatePurchaseOrderResult.data.status,
            }));
        }
    }, [createPurchaseOrderResult.isLoading, updatePurchaseOrderResult.isLoading]);

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {purchaseOrder._id
                        ? `EDIT PURCHASE ORDER #${purchaseOrder.order_no}`
                        : `CREATE NEW PURCHASE ORDER`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Order Number</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='order_no'
                                    value={purchaseOrder.order_no}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Supplier</Form.Label>
                                <Form.Control
                                    as={'select'}
                                    name='supplier'
                                    value={purchaseOrder.supplier}
                                    onChange={handleInputChange}>
                                    <option value=''>Select Supplier</option>
                                    {suppliers &&
                                        suppliers.map((supplier) => (
                                            <option key={supplier._id} value={supplier._id}>
                                                {supplier.business_name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className='col-md-4'>{/* Add fields for employee selection */}</div>
                        <div className='col-md-4'>{/* Add fields for date selection */}</div>
                    </div>
                </Form>
                <Row>
                    {purchaseOrder._id && (
                        <PurchaseOrderItems purchaseOrderId={purchaseOrder._id} />
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {purchaseOrderData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdatePurchaseOrder}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreatePurchaseOrder}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default PurchaseOrderModal;
