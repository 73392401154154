import React, { useState, useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import OxButton from '../components/shared/OxButton';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-dropdown/style.css';

import { Modal, Button, Form } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';

import util from '../util';

//RTK Queries and mutations

import {
    useCreateCompanyEmployeeMutation,
    useUpdateCompanyEmployeeMutation,
    useDeleteCompanyEmployeeMutation,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useGetCompanyPermissionsQuery,
    useGetCompanyEmployeesQuery,
    useGetCompanyRolesQuery,
    useUpdateCompanyMutation,
} from '../Redux/apiSlice';
import Spinner from '../components/shared/Spinner';
import { OxModal } from '../components/shared';
import EmployeeForm from './hr/EmployeeForm';
import RoleForm from './hr/RoleForm';

function Employees() {
    const dispatch = useDispatch();

    const companyState = useSelector((state) => state.company);

    //user state
    const userState = useSelector((state) => state.user);

    //RTK Queries

    const {
        data: employeesData,
        error: employeesError,
        isLoading: employeesLoading,
        refetch: employeeRefetch,
    } = useGetCompanyEmployeesQuery(companyState._id);

    const {
        data: rolesData,
        error: rolesError,
        isLoading: rolesLoading,
        refetch: roleRefetch,
    } = useGetCompanyRolesQuery(companyState._id);

    const {
        data: permissionsData,
        error: permissionsError,
        isLoading: permissionsLoading,
    } = useGetCompanyPermissionsQuery(companyState._id);

    //RTK Query mutations

    const [createCompanyEmployee, createCompanyEmployeeResult] = useCreateCompanyEmployeeMutation();

    const [updateCompanyEmployee, updateCompanyEmployeeResult] = useUpdateCompanyEmployeeMutation();

    const [deleteCompanyEmployee, deleteCompanyEmployeeResult] = useDeleteCompanyEmployeeMutation();

    const [updateCompany, updateCompanyResult] = useUpdateCompanyMutation();

    //const [updatePerson, updatePersonResult] = useUpdatePersonMutation();

    const [createRole, createRoleResult] = useCreateRoleMutation();

    const [updateRole, updateRoleResult] = useUpdateRoleMutation();

    const [deleteRole, deleteRoleResult] = useDeleteRoleMutation();

    //state arrays of roles, employees and permissions

    const [roles, setRoles] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [permissions, setPermissions] = useState([]);

    //singlr state for employee

    const [employee, setEmployee] = useState(null);

    //state for role

    const [role, setRole] = useState(null);

    //role form valiables
    const [role_id, setRole_id] = useState('');
    const [role_name, setRoleName] = useState('');
    const [code, setCode] = useState('');
    const [role_description, setRoleDescription] = useState('');
    const [role_permissions, setRolePermissions] = useState([]);

    //modal states
    const [show, setShow] = useState(false);
    const [showRole, setShowRole] = useState(false);

    //employee_modal_text state
    const [employee_modal_text, setEmployee_modal_text] = useState('');
    const [role_modal_text, setRole_modal_text] = useState('');

    //search text state

    const [searchText, setSearchText] = useState('');

    const handleClose = () => setShow(false);
    const handleCloseRole = () => setShowRole(false);

    //handle delete employee

    const handleDeleteEmployee = (id) => (e) => {
        e.preventDefault();
        deleteCompanyEmployee(id);
    };

    //handle edit person

    const handleEditEmployee = (employee) => (e) => {

        e.preventDefault();

        setEmployee(employee);

        setEmployee((prevEmployee) => ({
            ...prevEmployee,
            role: prevEmployee.role._id,
        }))

        setShow(true);

        setEmployee_modal_text('Update Employee');
    };

    //handle role edit function

    const handleRoleEdit = (role) => (e) => {
        e.preventDefault();
        setRole(role);
        setShowRole(true);
        setRole_modal_text('Update Role');
    };

    //handle role delete function

    const handleRoleDelete = (role) => (e) => {
        e.preventDefault();
        deleteRole(role._id);
    };

    const handleShow = () => {
        setShow(true);

        setEmployee(null)

        setEmployee_modal_text('New Employee');
    };

    const handleShowRole = () => {
        setRole(null);
        setShowRole(true);
        //reset role form
        setRole_modal_text('New Role');
    };

    //handle search employee

    const handleSearchEmployee = (keyword) => {
        setSearchText(keyword);

        if (keyword === '') {
            setEmployees(employeesData);
            return;
        }

        const filteredEmployees = employees.filter(
            (employee) =>
                employee.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
                employee.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
                employee.email.toLowerCase().includes(searchText.toLowerCase()) ||
                employee.phone_number.toLowerCase().includes(searchText.toLowerCase()) ||
                employee.employee_number.toLowerCase().includes(searchText.toLowerCase())
        );

        setEmployees(filteredEmployees);
    };

    useEffect(() => {
        if (companyState._id) {
            roleRefetch();
            employeeRefetch();
        }

        if (rolesData) {
            //console.log(rolesData);
            setRoles(rolesData);
        }

        if (employeesData) {
            //console.log(employeesData);
            setEmployees(employeesData);
        }

        if (permissionsData) {
            //console.log(permissionsData);
            setPermissions(permissionsData);
        }

        if (createRoleResult.data) {
            roleRefetch();
        }

        if (updateRoleResult.data) {
            roleRefetch();
        }

        if (createCompanyEmployeeResult.data) {
            employeeRefetch();
        }

        if (deleteCompanyEmployeeResult.isSuccess) {
            employeeRefetch();
        }

        if (updateCompanyEmployeeResult.data) {
            employeeRefetch();
        }
    }, [
        employeesData,
        rolesData,
        permissionsData,
        createRoleResult.isLoading,
        updateRoleResult.isLoading,
        deleteRoleResult.isLoading,
        createCompanyEmployeeResult.isLoading,
        updateCompanyEmployeeResult.isLoading,
        deleteCompanyEmployeeResult.isLoading,
    ]);

    if (
        rolesLoading ||
        employeesLoading ||
        permissionsLoading ||
        createRoleResult.isLoading ||
        updateRoleResult.isLoading ||
        deleteRoleResult.isLoading ||
        createCompanyEmployeeResult.isLoading ||
        updateCompanyEmployeeResult.isLoading ||
        deleteCompanyEmployeeResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <Tabs>
                                <TabList>
                                    <Tab>Employees</Tab>
                                    <Tab>Roles</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='card strpied-tabled-with-hover'>
                                        <div className='card-header '>
                                            <div className='form-row'>
                                                <div className='col-md-5'>
                                                    <h4 className='card-title'>Employees</h4>
                                                    <p className='card-category'>
                                                        Manage Employees
                                                    </p>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='form-row'>
                                                        <div className='col-md-8'>
                                                            <div className='form-group'>
                                                                <input
                                                                    type='text'
                                                                    value={searchText}
                                                                    onChange={(e) =>
                                                                        handleSearchEmployee(
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    className='form-control'
                                                                    placeholder='Search By First Name'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <OxButton
                                                                text='New Employee'
                                                                action={handleShow}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body table-full-width table-responsive'>
                                            <table className='table table-hover table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>Employee No.</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Phone Number</th>
                                                        <th>E-mail Address</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {employees.map((employee) => (
                                                        <tr key={`${employee._id}123`}>
                                                            <td>{employee.employee_number}</td>
                                                            <td>{employee['first_name']}</td>
                                                            <td>{employee['last_name']}</td>
                                                            <td>{employee['phone_number']}</td>
                                                            <td>{employee.email}</td>
                                                            <td>
                                                                <OxButton
                                                                    text='Edit'
                                                                    action={handleEditEmployee(
                                                                        employee
                                                                    )}
                                                                />
                                                            </td>
                                                            <td>
                                                                <OxButton
                                                                    text='Delete'
                                                                    action={handleDeleteEmployee(
                                                                        employee._id
                                                                    )}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <OxModal 
                                       handleClose={handleClose}
                                       visible={show}
                                       
                                       title={employee_modal_text}
                                       children={<EmployeeForm employee={employee} handleClose={handleClose} employeeRefetch={employeeRefetch}/>}

                                    />

                                </TabPanel>
                                <TabPanel>
                                    <div className='card strpied-tabled-with-hover'>
                                        <div className='card-header '>
                                            <div className='form-row'>
                                                <div className='col-md-5'>
                                                    <h4 className='card-title'>Roles</h4>
                                                    <p className='card-category'>Manage Roles</p>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='form-row'>
                                                        <div className='col-md-8'></div>
                                                        <div className='col'>
                                                            <OxButton
                                                                text='New Role'
                                                                action={handleShowRole}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body table-full-width table-responsive'>
                                            <table className='table table-hover table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {roles.map((role) => (
                                                        <tr key={role._id}>
                                                            <td>{role.name}</td>
                                                            <td>{role.description}</td>
                                                            <td>
                                                                <OxButton
                                                                    text='Edit'
                                                                    action={handleRoleEdit(role)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <OxButton
                                                                    text='Delete'
                                                                    action={handleRoleDelete(role)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <OxModal 
                                       handleClose={handleCloseRole}
                                       visible={showRole}
                                       
                                       title={role_modal_text}
                                       children={<RoleForm role={role} handleClose={handleCloseRole} roleRefetch={roleRefetch}/>}

                                    />

                                   
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Employees;
