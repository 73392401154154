import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import { useGetCompanyItemsQuery } from '../../../Redux/apiSlice';

const SaleAgreementItemModal = ({
    show,
    onHide,
    modalText,
    saleAgreementItem,
    handleUpdateSaleAgreementItem,
    handleChange,
}) => {
    const saleAgreements = useSelector((state) => state.saleAgreements);
    const company = useSelector((state) => state.company);

    const {
        data: itemsData,
        error: itemsError,
        isLoading: itemsLoading,
        refetch: refetchItems,
    } = useGetCompanyItemsQuery(company._id);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Item</Form.Label>
                        <Form.Control
                            as='select'
                            name='item'
                            value={saleAgreementItem.item}
                            onChange={handleChange}>
                            <option value=''>Select Item</option>
                            {itemsData &&
                                itemsData.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Discount</Form.Label>
                        <Form.Control
                            type='number'
                            name='discount'
                            value={saleAgreementItem.discount}
                            onChange={handleChange}
                            placeholder='Discount'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Discount Type</Form.Label>
                        <Form.Control
                            as='select'
                            name='discount_type'
                            value={saleAgreementItem.discount_type}
                            onChange={handleChange}>
                            <option value='percentage'>Percentage</option>
                            <option value='amount'>Amount</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Rebate</Form.Label>
                        <Form.Control
                            type='number'
                            name='rebate'
                            value={saleAgreementItem.rebate}
                            onChange={handleChange}
                            placeholder='Rebate'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Rebate Type</Form.Label>
                        <Form.Control
                            as='select'
                            name='rebate_type'
                            value={saleAgreementItem.rebate_type}
                            onChange={handleChange}>
                            <option value='percentage'>Percentage</option>
                            <option value='amount'>Amount</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Rebate Quantity</Form.Label>
                        <Form.Control
                            type='number'
                            name='rebate_quantity'
                            value={saleAgreementItem.rebate_quantity}
                            onChange={handleChange}
                            placeholder='Rebate Quantity'
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>
                    <OxButton text='Update' action={handleUpdateSaleAgreementItem} />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SaleAgreementItemModal;
