/**
 * date.utils.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2022
 * All rights reserved
 */

import { DateTime } from 'luxon';
import { DATE_SHORT } from '@config';

/**
 * Returns a string indicating the duration from the current date
 * in either seconds, minutes, hours, days, weeks, months or years
 * i.e `3 seconds ago`
 * @param {string} date
 */

function value(value, unit) {
    unit = Math.abs(value) > 1 ? `${unit}s` : unit;
    const tense = value >= 0 ? 'ago' : 'to go';
    return `${Math.abs(value)} ${unit} ${tense}`;
}

/**
 * Sorts an array of objects by date using the specified key
 * @param {string} key
 */
export function sortByDate(key) {
    return function (a, b) {
        return new Date(b[key]) - new Date(a[key]);
    };
}
export function duration(date) {
    const start = DateTime.local();
    const end = parseDate(date);
    const duration = start
        .diff(end, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'])
        .toObject();

    if (duration.years != 0) {
        return value(duration.years, 'year');
    } else if (duration.months != 0) {
        return value(duration.months, 'month');
    } else if (duration.weeks != 0) {
        return value(duration.weeks, 'week');
    } else if (duration.days != 0) {
        return value(duration.days, 'day');
    } else if (duration.hours != 0) {
        return value(duration.hours, 'hour');
    } else if (duration.minutes != 0) {
        return value(duration.minutes, 'minute');
    } else {
        return value(parseInt(duration.seconds), 'sec');
    }
}

/**
 * Formats the JavaScript date object into a
 * Full Date string using locale
 * @param {Date} date - JavaScript Date Object
 */
export function dateFormat(date) {
    return parseDate(date).toLocaleString(DateTime.DATE_FULL);
}

/**
 * Formats the JavaScript date object into a 24 hour Time string using locale
 * @param {Date|String} date - JavaScript Date Object or ISO date string
 */
export function timeFormat(date) {
    return parseDate(date).toLocaleString(DateTime.TIME_24_SIMPLE);
}

/**
 * Formats the JavaScript date object into a DATETIME object using locale
 * @param {Date} date - JavaScript Date Object
 */
export function dateTimeFormat(date) {
    return parseDate(date).toLocaleString(DateTime.DATETIME_MED);
}

export function shortDate(date) {
    console.log('Date to format ', date);
    return parseDate(date).toFormat(DATE_SHORT);
}

function parseDate(date) {
    return typeof date === 'object' ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
}
