import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import { useGetTanksQuery } from '../../../Redux/apiSlice';

const StockAdjustmentModal = ({
    show,
    onHide,
    modalText,
    stockAdjustment,
    handleCreateStockAdjustment,
    handleUpdateStockAdjustment,
    handleChange,
}) => {
    const company = useSelector((state) => state.company);

    // Get tanks
    const {
        data: tanks,
        error: tanksError,
        isLoading: tanksLoading,
        refetch: refetchTanks,
    } = useGetTanksQuery(company._id);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Tank</Form.Label>
                        <Form.Control
                            as='select'
                            name='tank'
                            value={stockAdjustment.tank}
                            onChange={handleChange}
                            required>
                            <option value='' disabled>
                                Select Tank
                            </option>
                            {tanks &&
                                tanks.map((tank) => (
                                    <option key={tank._id} value={tank._id}>
                                        {tank.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Volume (Liters)</Form.Label>
                        <Form.Control
                            type='number'
                            name='volume'
                            value={stockAdjustment.volume}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Reason</Form.Label>
                        <Form.Control
                            type='text'
                            name='reason'
                            value={stockAdjustment.reason}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText === 'New Stock Adjustment' ? (
                    <OxButton action={handleCreateStockAdjustment} text='Create' />
                ) : (
                    <OxButton action={handleUpdateStockAdjustment} text='Update' />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default StockAdjustmentModal;
