/**
 * app-mode.enum.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

const RGS = 'RGS';
const OXYGEN = 'OXYGEN';

export const APP_MODES = {
    RGS,
    OXYGEN,
};
