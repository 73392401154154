import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import { useGetCompanyItemsQuery, useGetTanksQuery } from '../../../Redux/apiSlice';

const ReceivingItemModal = ({
    show,
    onHide,
    modalText,
    receivingItem,
    handleUpdateReceivingItem,
    handleChange,
}) => {
    const company = useSelector((state) => state.company);

    const {
        data: items,
        error,
        isLoading,
        refetch: refetchItems,
    } = useGetCompanyItemsQuery(company._id);

    const {
        data: tanks,
        error: tanksError,
        isLoading: tanksLoading,
        refetch: refetchTanks,
    } = useGetTanksQuery(company._id);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Item</Form.Label>
                        <Form.Control
                            as='select'
                            name='item'
                            value={receivingItem.item}
                            onChange={handleChange}>
                            <option value=''>Select Item</option>
                            {items &&
                                items.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tank</Form.Label>
                        <Form.Control
                            as='select'
                            name='tank'
                            value={receivingItem.tank}
                            onChange={handleChange}>
                            <option value=''>Select Tank</option>
                            {tanks &&
                                tanks.map((tank) => (
                                    <option key={tank._id} value={tank._id}>
                                        {tank.name}
                                    </option>
                                ))}
                            {/* Render options for tanks */}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Volume Ordered</Form.Label>
                        <Form.Control
                            type='number'
                            name='volume_ordered'
                            value={receivingItem.volume_ordered}
                            onChange={handleChange}
                            placeholder='Volume Ordered'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Volume Received</Form.Label>
                        <Form.Control
                            type='number'
                            name='volume_recieved'
                            value={receivingItem.volume_recieved}
                            onChange={handleChange}
                            placeholder='Volume Received'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Invoiced Amount</Form.Label>
                        <Form.Control
                            type='number'
                            name='invoiced_amount'
                            value={receivingItem.invoiced_amount}
                            onChange={handleChange}
                            placeholder='Invoiced Amount'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Actual Amount</Form.Label>
                        <Form.Control
                            type='number'
                            name='actual_amount'
                            value={receivingItem.actual_amount}
                            onChange={handleChange}
                            placeholder='Actual Amount'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Cost Price</Form.Label>
                        <Form.Control
                            type='number'
                            name='cost_price'
                            value={receivingItem.cost_price}
                            onChange={handleChange}
                            placeholder='Cost Price'
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Discount Received</Form.Label>
                        <Form.Control
                            type='number'
                            name='discount_received'
                            value={receivingItem.discount_received}
                            onChange={handleChange}
                            placeholder='Discount Received'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tank Volume Before</Form.Label>
                        <Form.Control
                            type='number'
                            name='tank_volume_before'
                            value={receivingItem.tank_volume_before}
                            onChange={handleChange}
                            placeholder='Tank Volume Before'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tank Volume After</Form.Label>
                        <Form.Control
                            type='number'
                            name='tank_volume_after'
                            value={receivingItem.tank_volume_after}
                            onChange={handleChange}
                            placeholder='Tank Volume After'
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>
                    <OxButton text='Update' action={handleUpdateReceivingItem} />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default ReceivingItemModal;
