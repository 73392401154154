/**
 * LicenseTypeSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const licenseTypeSlice = createSlice({
    name: 'licenseType',
    initialState: {
        _id: '',
        name: '',
        description: '',
    },
    reducers: {
        setLicenseType: (state, action) => {
            state._id = action.payload._id;
            state.name = action.payload.name;
            state.description = action.payload.description;
        },
        clearLicenseType: (state) => {
            state._id = '';
            state.name = '';
            state.description = '';
        }
    }
});

export const licenseTypeActions = licenseTypeSlice.actions;