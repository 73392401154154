import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../../../components/shared/Spinner';
import { 
    useGetSalesOrdersQuery, 
    useDeleteSalesOrderMutation,
    useFulfillSaleOrderWithDeliveryNoteMutation,
} from '../../../Redux/apiSlice';
import CardHeader from '../../../components/shared/CardHeader';
import CustomPagination from '../../../components/shared/OxygenPagination';
import SaleOrderModal from './SaleOrderModal';
import DeletePopupModal from '../general/DeletePopupModal';
import ConfirmPopupModal from '../general/ConfirmPopupModal';

function SaleOrders() {
    const { _id: companyId } = useSelector((state) => state.company);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-secondary';
            case 'approved':
                return 'bg-info';
            case 'viewed':
                return 'bg-warning';
            case 'partial':
                return 'bg-primary';
            case 'invoiced':
                return 'bg-success';
            case 'overdue':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    };

    const {
        data: saleOrders,
        isLoading,
        refetch: refetchSaleOrders,
    } = useGetSalesOrdersQuery(companyId);

    const [deleteSaleOrder, deleteSaleOrderResult] = useDeleteSalesOrderMutation();
    const [fulfillSaleOrderWithDeliveryNote, fulfillSaleOrderWithDeliveryNoteResult] = useFulfillSaleOrderWithDeliveryNoteMutation();

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmOrderFulfillmentModal, setShowConfirmOrderFulfillmentModal] = useState(false);

    const [selectedSaleOrder, setSelectedSaleOrder] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSaleOrders, setFilteredSaleOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    const pageSizes = [25, 50, 100, 500];

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleShowApproveInvoiceModal = (saleOrder) => e => {
        e.preventDefault();
        setSelectedSaleOrder(saleOrder);
        setShowConfirmOrderFulfillmentModal(true);
    };

    const handleEditSaleOrder = (saleOrder) => (e) => {
        e.preventDefault();
        setSelectedSaleOrder({
            ...saleOrder,
        });
        setShowModal(true);
    };

    const handleDeleteSaleOrder = (saleOrder) => (e) => {
        e.preventDefault();
        deleteSaleOrder(saleOrder._id);
    };

    const handleFilterBySearchQuery = () => {
        if (searchQuery) {
            const filteredSaleOrders = saleOrders.filter(
                (saleOrder) =>
                    saleOrder.sale_order_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    saleOrder.customer?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    saleOrder.created_by?.full_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    saleOrder.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredSaleOrders(filteredSaleOrders);
        } else {
            setFilteredSaleOrders(saleOrders);
        }
    };

    const handleFilter = () => {
        handleFilterBySearchQuery();
    };

    const handleSaleOrderModalHide = () => {
        refetchSaleOrders();
        setShowModal(false);
    };

    const handleSaleOrderModalShow = () => {
        setSelectedSaleOrder({});
        setShowModal(true);
    };

    useEffect(() => {
        if (companyId) {
            refetchSaleOrders();
        }

        if (deleteSaleOrderResult.data) {
            toast.success('Sale Order deleted successfully');
            refetchSaleOrders();
            setShowDeleteModal(false);
        }
        if (deleteSaleOrderResult.isError) {
            toast.error('Error deleting Sale Order');
            setShowDeleteModal(false);
        }

        if (fulfillSaleOrderWithDeliveryNoteResult.isSuccess) {
            toast.success('Sale Order fulfilled successfully');
            refetchSaleOrders();
            setShowConfirmOrderFulfillmentModal(false);
        }
        if (fulfillSaleOrderWithDeliveryNoteResult.isError) {
            toast.error('Error fulfilling Sale Order');
            setShowConfirmOrderFulfillmentModal(false);
        }
        
    }, [deleteSaleOrderResult.isLoading, fulfillSaleOrderWithDeliveryNoteResult.isLoading]);

    if (isLoading || deleteSaleOrderResult.isLoading || fulfillSaleOrderWithDeliveryNoteResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Sale Order'
                handleShow={handleSaleOrderModalShow}
                headerTitle='SALE ORDERS'
                headerDescription='Manage Sale Orders'
                searchText={searchQuery}
                handleSearch={handleSearchQueryChange}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Sale Order Number</th>
                            <th>Customer</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {saleOrders &&
                            saleOrders.map((saleOrder) => (
                                <tr key={saleOrder._id}>
                                    <td>{saleOrder.sale_order_no}</td>
                                    <td>
                                        {saleOrder.customer?.business_name ||
                                            saleOrder.customer?.first_name +
                                                ' ' +
                                                saleOrder.customer?.last_name}
                                    </td>
                                    <td>
                                        {saleOrder.employee?.first_name +
                                            ' ' +
                                            saleOrder.employee?.last_name}
                                    </td>
                                    <td>
                                        <Moment format='YYYY-MM-DD'>{saleOrder.created_at}</Moment>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={handleEditSaleOrder(saleOrder)}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item>View</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowApproveInvoiceModal(saleOrder)}>Fullfill</Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={handleDeleteSaleOrder(saleOrder)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showModal && (
                <SaleOrderModal
                    show={showModal}
                    onHide={handleSaleOrderModalHide}
                    saleOrderData={selectedSaleOrder}
                />
            )}
            {showDeleteModal && (
                <DeletePopupModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onDelete={() => handleDeleteSaleOrder(selectedSaleOrder)}
                    recordName={'Sale Order'}
                />
            )}
            {showConfirmOrderFulfillmentModal && (
                <ConfirmPopupModal
                    show={showConfirmOrderFulfillmentModal}
                    onHide={() => setShowConfirmOrderFulfillmentModal(false)}
                    onConfirm={() => fulfillSaleOrderWithDeliveryNote({
                        sale_order:selectedSaleOrder._id
                    })}
                    recordName={'Sale Order Fulfillment'}
                />
            )}
        </div>
    );
}

export default SaleOrders;
