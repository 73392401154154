/**
 * tenants.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';
import { LOCAL_STORAGE_ENUMS } from '@enums';
const { TENANTS, CUSTOMERS } = LOCAL_STORAGE_ENUMS;

let instance;

export class TenantsService extends APIService {
    constructor() {
        const url = CUSTOMERS;
        const collection = TENANTS;
        super(url, collection);
    }

    async $fetchAll(company_id) {
        const url = `${this.url}?company=${company_id}&type=tenant`;
        try {
            const data = await _GET(url);
            if (data) {
                this.sm.add(this._key, data);
            }
            return Promise.resolve(data);
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }

    fetchAll(company_id) {
        return from(this.$fetchAll(company_id));
    }

    static create() {
        if (!instance) {
            instance = new TenantsService();
        }
        return instance;
    }
}
