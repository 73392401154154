import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetEmployeesQuery,
} from '../Redux/apiSlice';
import { companyAction } from '../Redux/companySlice';
import { employeeAction } from '../Redux/employeeSlice';

import Spinner from '../components/shared/Spinner';
import Logo from '../components/shared/Logo';
import BlackLogo from '../assets/img/logo_transparent.png';
import { Button, Card, ListGroup, Container, Row, Col } from 'react-bootstrap';
import { ROUTES } from '../config';

function Companies() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [instructions, setInstructions] = useState('SWITCH COMPANY');
    const userState = useSelector((state) => state.user);
    const employeesQuery = useGetEmployeesQuery(userState.user.email);

    useEffect(() => {
        employeesQuery.refetch();
    }, [employeesQuery.refetch]);

    useEffect(() => {
        if (employeesQuery.isSuccess) {
            setInstructions('SWITCH COMPANY');
        }
    }, [employeesQuery.isLoading]);

    const handleNewCompany = (e) => {
        e.preventDefault();
        navigate(`/${ROUTES.INDUSTRY_SELECTION}`);
    };

    const handleEmployeeSelection = (employee) => {
        dispatch(companyAction.setCompany(employee.company));
        dispatch(employeeAction.setEmployee(employee));
        navigate('/dashboard');
    };

    if (employeesQuery.isLoading) return <Spinner />;

    return (
        <Container className="vh-100 py-5">
            <Row className="d-flex justify-content-center align-items-center h-100">
                <Col xl={5}>
                    <Card style={{ borderRadius: '1rem' }}>
                        <Card.Body>
                            <Logo src={BlackLogo} width={250} />
                            <h5 className="fw-normal mb-3" style={{ color: '#3472F7' }}>
                                {instructions}
                            </h5>
                            <ListGroup variant="flush">
                                {employeesQuery.data.map((employee) => (
                                    <ListGroup.Item
                                        key={employee._id}
                                        action
                                        onClick={() => handleEmployeeSelection(employee)}>
                                        {employee.company?.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            <Button className="mt-3" onClick={handleNewCompany}>
                                CREATE NEW COMPANY
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Companies;
