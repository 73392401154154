import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, FormControl } from 'react-bootstrap';
import { useFormik } from 'formik';
import { filter } from 'rxjs/operators';
import * as Yup from 'yup';
import { Steps } from 'antd';
import { DEFAULTER_STATUS_ENUMS, LOCAL_STORAGE_ENUMS, DEFAULTER_STATUS, ROUTES } from '@enums';
import { OxNotify, FileUploader } from '@shared';
import { SessionService, DefaulterService, PropertiesService } from '@services';

const { DEFAULTERS, PROPERTIES } = LOCAL_STORAGE_ENUMS;

// service instances
const defaulterService = DefaulterService.create();
const propertiesService = PropertiesService.create();
const session = SessionService.session;

//subscriptions
let localDataSubscription = session;
let initialSub = session;

const { Step } = Steps;

const validationSchema = Yup.object().shape({
    id_number: Yup.string().required('ID number is required'),
    full_name: Yup.string().required('Full name is required'),
    contact_number: Yup.string().required('Contact number is required'),
    email_address: Yup.string().email('Invalid email format').required('Email address is required'),
    reason_for_default: Yup.string().required('Reason for default is required'),
    amount_due: Yup.number()
        .required('Amount due is required')
        .positive('Amount should be positive'),
    status: Yup.string()
        .oneOf(DEFAULTER_STATUS_ENUMS, 'Invalid status')
        .required('Status is required'),
    property: Yup.string(),
    property_name: Yup.string(),
    investigation_notes: Yup.string(),
    is_existing_customer: Yup.bool(),
    new_property: Yup.bool(),
});

const displayError = (touched, errors, fieldName) => {
    if (touched[fieldName] && errors[fieldName]) {
        return <div className='text-danger'>{errors[fieldName]}</div>;
    }
    return null;
};

export const DefaulterFormModal = ({ show, handleClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [customProperty, setCustomProperty] = useState('');
    const [properties, setProperties] = useState([]);
    const [defaulter, setDefaulter] = useState({ _id: '' });

    useEffect(() => {
        getData();
        listenToSession();
        return function cleanup() {
            if (initialSub) {
                initialSub.unsubscribe();
            }
        };
    }, []);

    const prepareData = (values) => {
        console.log('values', values);
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === PROPERTIES) {
                setProperties(data);
            }
        }
    };

    const listenToSession = () => {
        const concerns = [PROPERTIES];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const getData = () => {
        initialSub = propertiesService.fetchAll().subscribe(prepareData, (error) => {
            console.log('Dependencies Get Error: ', error);
        });
    };

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const formik = useFormik({
        initialValues: {
            id_number: '',
            full_name: '',
            contact_number: '',
            email_address: '',
            reason_for_default: '',
            amount_due: 0,
            status: DEFAULTER_STATUS.UNDER_INVESTIGATION,
            property: '',
            property_name: '',
            investigation_notes: '',
            is_existing_customer: false,
            new_property: false,
        },
        validationSchema,
        onSubmit: (values, { isSubmitting }) => {
            if (values.property !== '') {
                values.property = properties.find(
                    (property) => property.name === values.property
                )._id;
            } else {
                values.property = undefined;
            }
            values.status = DEFAULTER_STATUS.UNDER_INVESTIGATION;

            defaulterService.create(values).subscribe(
                (response) => {
                    console.log('created defaulter: ', response);
                    OxNotify({
                        title: 'Success',
                        content: 'Successfully created a Defaulter',
                        type: 'success',
                    });
                    setDefaulter(response.data);

                    next();
                },
                (error) => {
                    console.log('Error: ', error);
                    OxNotify({
                        title: 'Error',
                        content: error.message,
                        type: 'error',
                    });
                }
            );
        },
    });

    const contentStyle = {
        // lineHeight: '10px',
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.45)',
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderRadius: 8,
        border: `1px dashed #d9d9d9`,
        marginTop: 16,
        padding: 30,
    };

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>New Defaulter</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Steps current={currentStep}>
                        <Step title='Personal Details' />
                        <Step title='Additional Details' />
                    </Steps>

                    {currentStep === 0 && (
                        <div className='step-content' style={contentStyle}>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>ID Number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='id_number'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.id_number}
                                            isInvalid={
                                                formik.touched.id_number &&
                                                !!formik.errors.id_number
                                            }
                                        />
                                        {displayError(formik.touched, formik.errors, 'id_number')}
                                    </Form.Group>
                                </Col>

                                {/* Full Name */}
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='full_name'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.full_name}
                                            isInvalid={
                                                formik.touched.full_name &&
                                                !!formik.errors.full_name
                                            }
                                        />
                                        {displayError(formik.touched, formik.errors, 'full_name')}
                                    </Form.Group>
                                </Col>

                                {/* Contact Number */}
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Contact Number</Form.Label>
                                        <Form.Control
                                            type='tel'
                                            name='contact_number'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.contact_number}
                                            isInvalid={
                                                formik.touched.contact_number &&
                                                !!formik.errors.contact_number
                                            }
                                        />
                                        {displayError(
                                            formik.touched,
                                            formik.errors,
                                            'contact_number'
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                {/* Email Address */}
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type='email'
                                            name='email_address'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email_address}
                                            isInvalid={
                                                formik.touched.email_address &&
                                                !!formik.errors.email_address
                                            }
                                        />
                                        {displayError(
                                            formik.touched,
                                            formik.errors,
                                            'email_address'
                                        )}
                                    </Form.Group>
                                </Col>

                                {/* Reason for Default */}
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Reason for Default</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='reason_for_default'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason_for_default}
                                            isInvalid={
                                                formik.touched.reason_for_default &&
                                                !!formik.errors.reason_for_default
                                            }
                                        />
                                        {displayError(
                                            formik.touched,
                                            formik.errors,
                                            'reason_for_default'
                                        )}
                                    </Form.Group>
                                </Col>

                                {/* Amount Due */}
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Amount Due</Form.Label>
                                        <Form.Control
                                            type='number'
                                            name='amount_due'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount_due}
                                            isInvalid={
                                                formik.touched.amount_due &&
                                                !!formik.errors.amount_due
                                            }
                                        />
                                        {displayError(formik.touched, formik.errors, 'amount_due')}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label>Property</Form.Label>
                                    <FormControl
                                        as='select'
                                        custom
                                        onChange={(e) => {
                                            if (e.target.value === 'custom') {
                                                console.log('VVV; ', e.target.value);
                                                // setCustomProperty(true);
                                                formik.setFieldValue('new_property', true);
                                            } else {
                                                // setCustomProperty(false);
                                                formik.setFieldValue('new_property', false);
                                                formik.setFieldValue('property', e.target.value);
                                            }
                                        }}>
                                        <option value=''></option>
                                        {properties.map((property) => (
                                            <option
                                                key={property._id}
                                                value={property.id}
                                                name='property'>
                                                {property.name}
                                            </option>
                                        ))}
                                        <option value='custom'>Add new property</option>
                                    </FormControl>
                                </Col>
                                {formik.values.new_property && (
                                    <Col>
                                        <Form.Label>New Property Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            isInvalid={
                                                formik.touched.property_name &&
                                                !!formik.errors.property_name
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name='property_name'
                                        />
                                        {displayError(
                                            formik.touched,
                                            formik.errors,
                                            'property_name'
                                        )}
                                    </Col>
                                )}

                                {/* Is Existing Customer */}
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Does defaulter exist on RGS?</Form.Label>
                                        <Form.Check
                                            type='radio'
                                            label='Yes'
                                            name='is_existing_customer'
                                            id='is_existing_customer_true'
                                            value='true'
                                            checked={formik.values.is_existing_customer === true}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue(
                                                    'is_existing_customer',
                                                    e.target.value === 'true'
                                                );
                                            }}
                                        />
                                        <Form.Check
                                            type='radio'
                                            label='No'
                                            name='is_existing_customer'
                                            id='is_existing_customer_false'
                                            value='false'
                                            checked={formik.values.is_existing_customer === false}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue(
                                                    'is_existing_customer',
                                                    e.target.value === 'true'
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Investigation Notes</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={5}
                                            name='investigation_notes'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.investigation_notes}
                                            isInvalid={
                                                formik.touched.investigation_notes &&
                                                !!formik.errors.investigation_notes
                                            }
                                        />
                                        {displayError(
                                            formik.touched,
                                            formik.errors,
                                            'investigation_notes'
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* ... remaining form fields ... */}

                            <Button
                                className='mt-4 mb-4'
                                type='submit'
                                disabled={!formik.isValid}
                                onClick={formik.handleSubmit}>
                                ADD DEFAULTER
                            </Button>
                        </div>
                    )}

                    {currentStep === 1 && (
                        <div className='step-content' style={contentStyle}>
                            <FileUploader
                                _id={defaulter._id}
                                uploadCompleteHandler={handleClose}
                                uploadInstructions={{
                                    title: '',
                                    description: 'Upload the following documents:',
                                    documents: [
                                        'ID Document of defaulter if possible',
                                        'Any other document to provide proof',
                                    ],
                                }}
                            />{' '}
                        </div>
                    )}
                </Modal.Body>
            </Form>
        </Modal>
    );
};
