import React from 'react';
import { FadeLoader } from 'react-spinners';

function Spinner() {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>
            <FadeLoader color={'#007bff'} />
        </div>
    );
}

export default Spinner;
