import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetSaleAgreementItemsQuery,
    useCreateSaleAgreementItemMutation,
    useUpdateSaleAgreementItemMutation,
    useDeleteSaleAgreementItemMutation,
    useGetCompanyItemsQuery,
} from '@redux/apiSlice';
import Spinner from '@shared/Spinner';
import SaleAgreementItemModal from './SaleAgreementItemModal';

import { toast } from 'react-toastify';
import DeletePopupModal from '../general/DeletePopupModal';

const SaleAgreementItems = ({ saleAgreementId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const {
        data: saleAgreementItems,
        isLoading: saleAgreementItemsLoading,
        refetch: refetchSaleAgreementItems,
    } = useGetSaleAgreementItemsQuery(saleAgreementId);
    const {
        data: items,
        error: itemsError,
        isLoading: itemsLoading,
        refetch: refetchItems,
    } = useGetCompanyItemsQuery(companyId);

    const [newItem, setNewItem] = useState({
        sale_agreement: saleAgreementId,
        item: '',
        discount: 0,
        discount_type: 'percentage',
        rebate: 0,
        rebate_type: 'percentage',
        rebate_quantity: 0,
    });

    const [createSaleAgreementItem, createSaleAgreementItemResult] =
        useCreateSaleAgreementItemMutation();
    const [updateSaleAgreementItem, updateSaleAgreementItemResult] =
        useUpdateSaleAgreementItemMutation();
    const [deleteSaleAgreementItem, deleteSaleAgreementItemResult] =
        useDeleteSaleAgreementItemMutation();

    const [saleAgreementItemsArray, setSaleAgreementItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalSaleAgreementItem, setModalSaleAgreementItem] = useState({
        sale_agreement: saleAgreementId,
        item: '',
        discount: 0,
        discount_type: 'percentage',
        rebate: 0,
        rebate_type: 'percentage',
        rebate_quantity: 0,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalSaleAgreementItem(item);
    };

    // Handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalSaleAgreementItem({
            sale_agreement: saleAgreementId,
            item: '',
            discount: 0,
            discount_type: 'percentage',
            rebate: 0,
            rebate_type: 'percentage',
            rebate_quantity: 0,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItemId,
        }));
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalSaleAgreementItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    // Handle update item
    const handleUpdateItem = () => {
        updateSaleAgreementItem(modalSaleAgreementItem);
        setShowModal(false);
        setModalSaleAgreementItem({
            sale_agreement: saleAgreementId,
            item: '',
            discount: 0,
            discount_type: 'percentage',
            rebate: 0,
            rebate_type: 'percentage',
            rebate_quantity: 0,
        });
    };

    // Handle delete item
    const handleDeleteItem = () => {
        deleteSaleAgreementItem(modalSaleAgreementItem._id);
        setShowDeleteModal(false);
        setModalSaleAgreementItem({
            sale_agreement: saleAgreementId,
            item: '',
            discount: 0,
            discount_type: 'percentage',
            rebate: 0,
            rebate_type: 'percentage',
            rebate_quantity: 0,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            const itemExists = saleAgreementItems.find((item) => item.item === newItem.item);
            if (itemExists) {
                toast.warning('Item already exists');
            } else {
                createSaleAgreementItem(newItem);
            }
            setNewItem({
                sale_agreement: saleAgreementId,
                item: '',
                discount: 0,
                discount_type: 'percentage',
                rebate: 0,
                rebate_type: 'percentage',
                rebate_quantity: 0,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (saleAgreementItems) {
            refetchSaleAgreementItems();
        }
        if (createSaleAgreementItemResult.data) {
            refetchSaleAgreementItems();
        }
        if (createSaleAgreementItemResult.isSuccess) {
            toast.success('Sale Agreement Item Created Successfully');
        }
        if (deleteSaleAgreementItemResult.data) {
            refetchSaleAgreementItems();
        }
        if (deleteSaleAgreementItemResult.isSuccess) {
            toast.success('Sale Agreement Item Deleted Successfully');
        }
        if (updateSaleAgreementItemResult.data) {
            refetchSaleAgreementItems();
        }
        if (updateSaleAgreementItemResult.isSuccess) {
            toast.success('Sale Agreement Item Updated Successfully');
        }
    }, [
        saleAgreementItems,
        saleAgreementItemsLoading,
        createSaleAgreementItemResult.isLoading,
        updateSaleAgreementItemResult.isLoading,
        deleteSaleAgreementItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Sale Agreement Item');
            setModalSaleAgreementItem({});
        } else if (modalType === 'edit' && item) {
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalSaleAgreementItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalSaleAgreementItem({
            sale_agreement: saleAgreementId,
            item: '',
            discount: 0,
            discount_type: 'percentage',
            rebate: 0,
            rebate_type: 'percentage',
            rebate_quantity: 0,
        });
    };

    if (
        saleAgreementItemsLoading ||
        createSaleAgreementItemResult.isLoading ||
        updateSaleAgreementItemResult.isLoading ||
        deleteSaleAgreementItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='20%'>Item</th>
                        <th width='20%'>Discount</th>
                        <th width='20%'>Rebate</th>
                        <th width='20%'>Rebate Quantity</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {saleAgreementItems &&
                        saleAgreementItems.map((item, index) => (
                            <tr key={index}>
                                <td>{getItemName(item.item)}</td>
                                <td>{`${item.discount} ${
                                    item.discount_type == 'percentage' ? '%' : '(NAD)'
                                }`}</td>
                                <td>{`${item.rebate} ${
                                    item.rebate_type == 'percentage' ? '%' : '(NAD)'
                                }`}</td>
                                <td>{item.rebate_quantity}</td>
                                <td>
                                    <Button
                                        variant='primary'
                                        onClick={() => openModal('edit', item)}>
                                        Edit
                                    </Button>{' '}
                                    <Button
                                        variant='danger'
                                        onClick={() => handleShowDeleteModal(item)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select New Item
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <SaleAgreementItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                saleAgreementItem={modalSaleAgreementItem}
                handleChange={handleChange}
                handleUpdateSaleAgreementItem={handleUpdateItem}
            />
            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={'Sale Agreement Item'}
            />
        </div>
    );
};

export default SaleAgreementItems;
