import React from 'react';
import { Modal, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';
import Switch from 'react-switch';
import { useGetCompanyItemsQuery } from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';

function SaleOrderItemModal({
    show,
    onHide,
    modalText,
    saleOrderItem,
    handleUpdateSaleOrderItem,
    handleChange,
}) {
    const company = useSelector((state) => state.company);

    const {
        data: itemsData,
        error: itemsError,
        isLoading: itemsLoading,
        refetch: refetchItems,
    } = useGetCompanyItemsQuery(company._id);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Item</Form.Label>
                        <Form.Control
                            as='select'
                            name='item'
                            value={saleOrderItem.item}
                            onChange={handleChange}>
                            <option value=''>Select Item</option>
                            {itemsData &&
                                itemsData.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Volume</Form.Label>
                        <Form.Control
                            type='number'
                            name='quantity'
                            value={saleOrderItem.quantity}
                            onChange={handleChange}
                            placeholder='Quantity'
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>
                    <OxButton text='Update' action={handleUpdateSaleOrderItem} />
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default SaleOrderItemModal;
