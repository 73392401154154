/**
 * JobCardItemTypes.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import OxygenTable from '@components/shared/OxygenTable';
import { useGetJobCardItemTypesQuery, useDeleteJobCardItemTypeMutation } from '@redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '@components/shared/CardHeader';
import Spinner from '@components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { OxModal } from '@components';
import NewJobCardItemTypeForm from './NewJobCardItemTypeForm';

function JobCardItemTypes() {

    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchTypes } = useGetJobCardItemTypesQuery(company._id);
    const [deleteType, deleteTypeResult] = useDeleteJobCardItemTypeMutation();
    
    const [itemType, setItemType] = useState({
        name: '',
        description: '',
        company: company._id,
    });

    const [itemTypes, setItemTypesArray] = useState([]);
    const [modalText, setModalText] = useState('New Job Card Item Type');
    const [showTypeModal, setShowTypeModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'Name', field: 'name', width: '40%' },
        { label: 'Description', field: 'description', width: '50%' },
        { label: 'Actions', field: 'actions', width: '100%' }
    ];

    const typesWithActions = itemTypes.map(type => ({
        ...type,
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditType(type)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteType(type._id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }));

    const handleShowTypeModal = () => {
        setShowTypeModal(true);
        setModalText('New Job Card Item Type');
        setItemType({
            name: '',
            description: '',
            company: company._id,
        });
    };

    const handleCloseTypeModal = () => {
        setShowTypeModal(false);
    };

    const handleEditType = (type) => {
        setShowTypeModal(true);
        setModalText('Edit Job Card Item Type');
        setItemType(type);
    };

    const handleDeleteType = (typeId) => {
        deleteType(typeId);
    };

    useEffect(() => {
        if (data) {
            setItemTypesArray(data);
            console.log(data);
        }

        if (deleteTypeResult.data) {
            refetchTypes();
            handleCloseTypeModal();
        }
    }, [
        data,
        deleteTypeResult.isLoading
    ]);

    if (isLoading || deleteTypeResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Job Card Item Type'}
                headerTitle={'Job Card Item Types'}
                headerDescription={'Manage Job Card Item Types'}
                placeholder={'Search Job Card Item Types'}
                searchText={searchText}
                handleSearch={setSearchText}
                handleShow={handleShowTypeModal}
            />
            <OxygenTable
                data={typesWithActions}
                columns={columns}
                pageSize={10}
            />
            <OxModal 
                handleClose={handleCloseTypeModal}
                visible={showTypeModal}
                title={modalText}
                children={<NewJobCardItemTypeForm jobCardItemType={itemType} handleClose={handleCloseTypeModal} refetch={refetchTypes} />}
            />
        </div>
    );
}

export default JobCardItemTypes;
