import React, { useEffect, useState } from 'react';
import OxygenTable from '../../../components/shared/OxygenTable';
import BrandModal from './BrandModal';
import {
    useGetBrandsQuery,
    useCreateBrandMutation,
    useUpdateBrandMutation,
    useDeleteBrandMutation,
} from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';

function Brands() {
    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refechBrands } = useGetBrandsQuery(company._id);
    const [createBrand, createBrandResult] = useCreateBrandMutation();
    const [updateBrand, updateBrandResult] = useUpdateBrandMutation();
    const [deleteBrand, deleteBrandResult] = useDeleteBrandMutation();
    const [brand, setBrand] = useState({
        name: '',
        description: '',
        company: company._id,
    });
    const [brands, setBrandsArray] = useState([]);
    const [modalText, setModalText] = useState('New Brand');
    const [showBrandModal, setShowBrandModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'Name', field: 'name', width: '40%' },
        { label: 'Description', field: 'description', width: '50%' },
        { label: 'Actions', field: 'actions', width: '10%' }
    ];

    const brandsWithActions = brands.map(brand => ({
        ...brand,
        actions: (
            <>
                 <Dropdown>
                    <Dropdown.Toggle
                        variant='primary'
                        id='dropdown-basic'>
                        More
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => handleEditBrand(brand)}>
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => handleDeleteBrand(brand)}>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
            
        ),
    }));

    const handleShowBrandModal = () => {
        setShowBrandModal(true);
        setModalText('New Brand');
        setBrand({
            name: '',
            description: '',
            company: company._id,
        });
    };

    const handleCloseBrandModal = () => {
        setShowBrandModal(false);
    };

    const handleEditBrand = (brand) => {
        setShowBrandModal(true);
        setModalText('Edit Brand');
        setBrand(brand);
    };

    const handleSearch = (text) => {
        setSearchText(text);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBrand(prevBrand => ({
            ...prevBrand,
            [name]: value,
        }));
    };

    const handleCreateBrand = () => {
        createBrand(brand);
    };

    const handleUpdateBrand = () => {
        updateBrand(brand);
    };

    const handleDeleteBrand = () => {
        deleteBrand(brand._id);
    };

    useEffect(() => {
        if (data) {
            setBrandsArray(data);
        }

        if (createBrandResult.data || updateBrandResult.data || deleteBrandResult.data) {
            refechBrands();
            handleCloseBrandModal();
        }
    }, [
        data,
        createBrandResult.isLoading,
        updateBrandResult.isLoading,
        deleteBrandResult.isLoading,
    ]);

    if (
        isLoading ||
        createBrandResult.isLoading ||
        updateBrandResult.isLoading ||
        deleteBrandResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Brand'}
                handleSearch={handleSearch}
                handleShow={handleShowBrandModal}
                headerTitle={'Brands'}
                headerDescription={'Manage Brands'}
                placeholder={'Search Brands'}
                searchText={searchText}
            />

            <div className='card-body'>
                <OxygenTable
                    data={brandsWithActions}
                    columns={columns}
                    pageSize={10}
                />
            </div>

            <BrandModal
                show={showBrandModal}
                onHide={handleCloseBrandModal}
                modalText={modalText}
                brand={brand}
                handleCreateBrand={handleCreateBrand}
                handleUpdateBrand={handleUpdateBrand}
                handleChange={handleChange}
            />
        </div>
    );
}

export default Brands;
