import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const InvoiceStatusChart = ({ data }) => {
    return (
        <BarChart width={500} height={300} data={data}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='invoiceStatus' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='amount' fill='#0052A3' />
        </BarChart>
    );
};

export default InvoiceStatusChart;
