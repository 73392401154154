import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import { useGetTanksQuery } from '../../../Redux/apiSlice';

const TerminalModal = ({
    show,
    onHide,
    modalText,
    terminal,
    handleCreateTerminal,
    handleUpdateTerminal,
    handleChange,
}) => {
    const company = useSelector((state) => state.company);

    // Get tanks
    const { data: tanks, error, isLoading, refetch: refetchTanks } = useGetTanksQuery(company._id);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Terminal No</Form.Label>
                        <Form.Control
                            name='terminal_no'
                            value={terminal.terminal_no}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId='tankSelect'>
                        <Form.Label>Tank</Form.Label>
                        <Form.Control
                            as='select'
                            name='tank'
                            value={terminal.tank}
                            onChange={handleChange}>
                            <option value='' disabled>
                                Select Tank
                            </option>
                            {tanks &&
                                tanks.map((tank) => (
                                    <option key={tank._id} value={tank._id}>
                                        {tank.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText == 'New Terminal' ? (
                    <OxButton action={handleCreateTerminal} text='Create' />
                ) : (
                    <OxButton action={handleUpdateTerminal} text='Update' />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default TerminalModal;
