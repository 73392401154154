import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../../../components/shared/Spinner';
import { strings } from '../../../strings/strings';
import {
    useGetRecievingsQuery,
    useDeleteRecievingMutation,
    useConfirmRecievingMutation,
} from '../../../Redux/apiSlice';
import CardHeader from '../../../components/shared/CardHeader';
import CustomPagination from '../../../components/shared/OxygenPagination';
import ReceivingModal from './ReceivingModal';
import DeletePopupModal from '../general/DeletePopupModal';
import ConfirmPopupModal from '../general/ConfirmPopupModal';

function Receivings() {
    const getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return 'bg-secondary';
            case 'recieved':
                return 'bg-info';
            case 'paid':
                return 'bg-success';
            case 'cancelled':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    };

    const { _id: companyId } = useSelector((state) => state.company);

    const { _id: employeeId } = useSelector((state) => state.employee);

    const {
        data: receivings,
        isLoading,
        refetch: refetchReceivings,
    } = useGetRecievingsQuery(companyId);

    const [deleteReceiving, deleteReceivingResult] = useDeleteRecievingMutation();
    const [confirmReceiving, confirmReceivingResult] = useConfirmRecievingMutation();

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [selectedReceiving, setSelectedReceiving] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredReceivings, setFilteredReceivings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Perform any other logic or data fetching based on the new page
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        // Perform any other logic or data fetching based on the new page size
    };

    const pageSizes = [25, 50, 100, 500];

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleEditReceiving = (receiving) => (e) => {
        e.preventDefault();
        setSelectedReceiving({
            ...receiving,
            supplier: receiving.supplier._id,
        });
        setShowModal(true);
    };

    //handle show delete modal
    const handleShowDeleteModal = (receiving) => (e) => {
        e.preventDefault();
        setSelectedReceiving(receiving);
        setShowDeleteModal(true);
    };

    //handle hide delete modal
    const handleHideDeleteModal = () => {
        setSelectedReceiving({});
        setShowDeleteModal(false);
    };

    //handle show confirm modal
    const handleShowConfirmModal = (receiving) => (e) => {
        e.preventDefault();
        setSelectedReceiving(receiving);
        setShowConfirmModal(true);
    };

    //handle hide confirm modal
    const handleHideConfirmModal = () => {
        setSelectedReceiving({});
        setShowConfirmModal(false);
    };

    //handle confirm receiving

    const handleConfirmReceiving = async () => {
        const recieving = selectedReceiving._id;
        confirmReceiving({
            recieving,
            employee: employeeId,
        });
    };

    const handleDeleteReceiving = (receiving) => (e) => {
        e.preventDefault();
        deleteReceiving(receiving._id);
        console.log('Delete receiving:', receiving);
    };

    const handleFilterBySearchQuery = () => {
        if (searchQuery) {
            const filteredReceivings = receivings.filter(
                (receiving) =>
                    receiving.receiving_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    receiving.supplier?.business_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    receiving.created_by?.full_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    receiving.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredReceivings(filteredReceivings);
        } else {
            setFilteredReceivings(receivings);
        }
    };

    const handleFilter = () => {
        handleFilterBySearchQuery();
    };

    const handleReceivingModalHide = () => {
        refetchReceivings();
        setShowModal(false);
    };

    const handleReceivingModalShow = () => {
        setSelectedReceiving({});
        setShowModal(true);
    };

    useEffect(() => {
        if (confirmReceivingResult.isSuccess) {
            toast.success('Receiving confirmed successfully');
            refetchReceivings();
            handleHideConfirmModal();
            refetchReceivings();
        }

        if (confirmReceivingResult.isError) {
            toast.error('Error confirming receiving');
        }

        if (deleteReceivingResult.isSuccess) {
            toast.success('Receiving deleted successfully');
            refetchReceivings();
            handleHideDeleteModal();
            refetchReceivings();
        }

        if (deleteReceivingResult.isError) {
            toast.error('Error deleting receiving');
        }
    }, [isLoading, confirmReceivingResult.isLoading, deleteReceivingResult.isLoading]);

    if (isLoading || confirmReceivingResult.isLoading || deleteReceivingResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Receiving'
                handleShow={handleReceivingModalShow}
                headerTitle='RECEIVINGS'
                headerDescription='Manage Receivings'
                searchText={searchQuery}
                handleSearch={handleSearchQueryChange}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Receiving Number</th>
                            <th>Supplier</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivings &&
                            receivings.map((receiving) => (
                                <tr key={receiving._id}>
                                    <td>
                                        <span
                                            className={`badge ${getStatusBadge(
                                                receiving.status
                                            )} text-white`}>
                                            {receiving.status}
                                        </span>
                                    </td>
                                    <td>{receiving.recieving_number}</td>
                                    <td>{receiving.supplier?.business_name}</td>
                                    <td>
                                        {receiving.employee?.first_name +
                                            ' ' +
                                            receiving.employee?.last_name}
                                    </td>
                                    <td>
                                        <Moment format='YYYY-MM-DD'>{receiving.created}</Moment>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {/* {receiving.status == 'pending' && ( */}
                                                    <Dropdown.Item
                                                        onClick={handleEditReceiving(receiving)}>
                                                        View
                                                    </Dropdown.Item>
                                                {/* )} */}
                                                {/* <Dropdown.Item>View</Dropdown.Item> */}
                                                {receiving.status == 'pending' && (
                                                    <Dropdown.Item
                                                        onClick={handleShowConfirmModal(receiving)}>
                                                        Confirm Recieving
                                                    </Dropdown.Item>
                                                )}
                                                {receiving.status == 'recieved' && (
                                                    <Dropdown.Item>Make Payment</Dropdown.Item>
                                                )}
                                                <Dropdown.Item
                                                    onClick={handleShowDeleteModal(receiving)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showModal && (
                <ReceivingModal
                    show={showModal}
                    onHide={handleReceivingModalHide}
                    receivingData={selectedReceiving}
                />
            )}
            {showDeleteModal && (
                <DeletePopupModal
                    show={showDeleteModal}
                    onHide={handleHideDeleteModal}
                    onDelete={handleDeleteReceiving(selectedReceiving)}
                    recordName={'Receiving'}
                />
            )}

            {showConfirmModal && (
                <ConfirmPopupModal
                    show={showConfirmModal}
                    onHide={handleHideConfirmModal}
                    onConfirm={handleConfirmReceiving}
                    recordName={'Receiving'}
                />
            )}
        </div>
    );
}

export default Receivings;
