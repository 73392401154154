import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import { Dropdown } from 'react-bootstrap';
import { ENV } from '../../../config';
const { oxygen_end_point } = ENV;

import {
    useGetTodayInvoicesQuery,
    useGetCompanyCustomersQuery,
    useGetInvoicesByQueryMutation,
    useCreateSalePaymentMutation,
    useSendInvoiceMutation,
    useApproveInvoiceMutation,
} from '../../../Redux/apiSlice';
import util from '../../../util';
import PreviewInvoice from './PreviewInvoice';
import Spinner from '../../../components/shared/Spinner';
import InvoiceModal from './InvoiceModal';
import SalePaymentModal from './SalePaymentModal';
import { toast } from 'react-toastify';
import OxygenPagination from '../../../components/shared/OxygenPagination';
import ApprovalPopupModal from './ApprovalPopupModal';

function Invoices() {
    // get company id from redux store
    const { _id: companyId } = useSelector((state) => state.company);

    //get employee id from redux store

    const { _id: employeeId } = useSelector((state) => state.employee);

    // get invoices from API
    const {
        data: invoices,
        isLoading,
        refetch: refetchInvoices,
    } = useGetTodayInvoicesQuery(companyId);

    // get customers from API
    const {
        data: customers,
        isLoading: isLoadingCustomers,
        refetch: refetchCustomers,
    } = useGetCompanyCustomersQuery(companyId);

    // get invoice by query

    const [getInvoicesByQuery, getInvoicesByQueruyResult] = useGetInvoicesByQueryMutation();

    // create sale payment

    const [createSalePayment, createSalePaymentResult] = useCreateSalePaymentMutation();

    // send invoice

    const [sendInvoice, sendInvoiceResult] = useSendInvoiceMutation();

    // approve invoice

    const [approveInvoice, approveInvoiceResult] = useApproveInvoiceMutation();

    //Sale Payment Modal

    const [showSalePaymentModal, setShowSalePaymentModal] = useState(false);

    //Approve Invoice Modal

    const [showApproveInvoiceModal, setShowApproveInvoiceModal] = useState(false);

    //sale payment text modal

    const salePaymentModalText = 'New Sale Payment';

    //sale payment state
    const [salePayment, setSalePayment] = useState({
        invoice_id: '',
        payment_method: '',
        amount_tendered: 0,
        amount_change: 0,
        employee: employeeId,
    });

    //handle close sale payment modal
    const handleCloseSalePaymentModal = () => {
        //reset sale payment state
        setSalePayment({
            invoice_id: '',
            payment_method: '',
            amount_tendered: 0,
            amount_change: 0,
            employee: employeeId,
        });
        setShowSalePaymentModal(false);
    };

    //get payment change
    const getPaymentChange = (amount_tendered, total) => {
        return amount_tendered - total;
    };

    //handle show sale payment modal
    const handleShowSalePaymentModal = (sale) => (e) => {
        e.preventDefault();
        //set sale payment state
        setSalePayment((prevState) => ({
            ...prevState,
            invoice_id: sale._id,
            amount_tendered: sale.total,
        }));
        setShowSalePaymentModal(true);
    };

    const handleShowApproveInvoiceModal = (invoice) => (e) => {
        e.preventDefault();
        setSelectedInvoice(invoice);
        setShowApproveInvoiceModal(true);
    }

    const handleCloseApproveInvoiceModal = () => {
        setShowApproveInvoiceModal(false);
    }

    //handle sale payment change

    const handleSalePaymentChange = (e) => {
        const { name, value } = e.target;
        setSalePayment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    //handle create sale payment

    const handleCreateSalePayment = () => {
        //get payment change
        const amount_change = getPaymentChange(salePayment.amount_tendered, salePayment.total);
        //set sale payment state
        setSalePayment((prevState) => ({
            ...prevState,
            amount_change,
        }));
        //create sale payment
        createSalePayment(salePayment);
        //close sale payment modal
        handleCloseSalePaymentModal();
    };

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1); // Replace with the actual total number of pages

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Perform any other logic or data fetching based on the new page
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        // Perform any other logic or data fetching based on the new page size
    };

    const pageSizes = [25, 50, 100, 500];

    const [showPreviewModal, setShowPreviewModal] = useState(false);

    // set state for displaying modal
    const [showModal, setShowModal] = useState(false);

    //invoice link

    const [invoiceLink, setInvoiceLink] = useState('');

    // set state for selected invoice when editing
    const [selectedInvoice, setSelectedInvoice] = useState({});

    // set state for search query
    const [searchQuery, setSearchQuery] = useState('');

    // set state for filtered invoices
    const [filteredInvoices, setFilteredInvoices] = useState([]);

    //status filter
    const [statusFilter, setStatusFilter] = useState('All');

    const [currency, setCurrency] = useState({
        symbol: 'N$',
        name: 'Namibian Dollar',
        code: 'NAD',
    });

    const getStatusBadge = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-secondary';
            case 'sent':
                return 'bg-info';
            case 'viewed':
                return 'bg-warning';
            case 'partial':
                return 'bg-primary';
            case 'paid':
                return 'bg-success';
            case 'overdue':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    };

    //created at filter
    const [createdAtFilter, setCreatedAtFilter] = useState('today');
    // ['draft', 'sent', 'viewed','partial','paid', 'overdue']
    const invoiceStatuses = [
        { label: 'Draft', value: 'draft' },
        { label: 'Sent', value: 'sent' },
        { label: 'Viewed', value: 'viewed' },
        { label: 'Partial', value: 'partial' },
        { label: 'Paid', value: 'paid' },
        { label: 'Overdue', value: 'overdue' },
    ];

    const [query, setQuery] = useState({
        company: companyId,
    });

    const filter = () => {
        setQuery(util.buildQueryParams(createdAtFilter, null, statusFilter, 'object'));
        //set company query
        setQuery((prev) => ({
            ...prev,
            company: companyId,
        }));
    };

    //handle select customer

    const handleCustomerSelection = (customer) => {
        console.log(customer);
        console.log(customer);
        setQuery({
            company: companyId,
            customer: customer.value,
        });
    };

    const handleStatusFilter = (e) => {
        e.preventDefault();
        setStatusFilter(e.target.value);
        filter();
    };

    const handleCreatedAtFilter = (e) => {
        e.preventDefault();
        setCreatedAtFilter(e.target.value);
        filter();
    };

    // refresh invoices after creating, editing or deleting an invoice
    const handleInvoiceModalClose = () => {
        refetchInvoices();
        setShowModal(false);
    };

    // show modal for creating new invoice
    const handleInvoiceModalShow = () => {
        setSelectedInvoice({});
        setShowModal(true);
    };

    // show modal for editing invoice
    const handleEditInvoice = (invoice) => (e) => {
        e.preventDefault();

        setSelectedInvoice({
            ...invoice,
            customer: invoice.customer?._id,
        });
        setShowModal(true);
    };

    //preview invoice modal

    const handlePreviewModalClose = () => {
        setShowPreviewModal(false);
    };

    const handlePreviewModalShow = (invoice) => (e) => {
        e.preventDefault();
        setInvoiceLink(`${oxygen_end_point}/inv/${invoice._id}`);
        setShowPreviewModal(true);
    };

    //handle send invoice

    const handleSendInvoice = (invoice) => (e) => {
        e.preventDefault();
        sendInvoice({
            invoice_id: invoice._id,
        });
    };

    useEffect(() => {
        filter();
    }, [statusFilter, createdAtFilter]);

    useEffect(() => {
        const complete_query = {
            query,
            page: currentPage,
            pageSize,
        };

        console.log(complete_query);

        getInvoicesByQuery(complete_query);
    }, [query, currentPage, pageSize]);

    useEffect(() => {
        if (getInvoicesByQueruyResult.data) {
            console.log(getInvoicesByQueruyResult.data);
            setFilteredInvoices(getInvoicesByQueruyResult.data.invoices);
            setCurrency(getInvoicesByQueruyResult.data.currency);

            const all_pages = Math.ceil(getInvoicesByQueruyResult.data.total_invoicees / pageSize);
            setTotalPages(all_pages);
        }
    }, [getInvoicesByQueruyResult.isLoading]);

    useEffect(() => {
        if (invoices) {
            console.log(invoices);
            setFilteredInvoices(invoices);
        }

        if (createSalePaymentResult.data) {
            setQuery((prev) => ({
                ...prev,
                company: companyId,
            }));
        }

        if (createSalePaymentResult.isSuccess) {
            toast.success('Payment created successfully');
        }

        if (createSalePaymentResult.isError) {
            toast.error('Error creating payment');
            console.log(createSalePaymentResult.data);
        }

        if (sendInvoiceResult.data) {
            refetchInvoices();
        }

        if (sendInvoiceResult.isSuccess) {
            toast.success('Invoice sent successfully');
        }

        if (sendInvoiceResult.isError) {
            toast.error('Error sending invoice');
            console.log(sendInvoiceResult.data);
        }

        if (approveInvoiceResult.isSuccess) {
            toast.success('Invoice approved successfully');
            handleCloseApproveInvoiceModal();
            refetchInvoices();
        }

        if (approveInvoiceResult.isError) {
            toast.error('Error approving invoice');
            handleCloseApproveInvoiceModal();
            console.log(approveInvoiceResult.data);
        }

    }, [
        invoices,
        searchQuery,
        isLoading,
        createSalePaymentResult.isLoading,
        sendInvoiceResult.isLoading,
        createSalePaymentResult.isLoading,
        approveInvoiceResult.isLoading,
    ]);

    if (
        (isLoading ||
            getInvoicesByQueruyResult.isLoading ||
            createSalePaymentResult.isLoading ||
            sendInvoiceResult.isLoading ||
            createSalePaymentResult.isLoading ||
            approveInvoiceResult.isLoading
        )
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText={'New Invoice'}
                handleShow={handleInvoiceModalShow}
                headerTitle={'INVOICES'}
                headerDescription={'Manage Invoices'}
                searchText={searchQuery}
                handleSearch={setSearchQuery}
                //filters inputes
                handleDateFilterChange={handleCreatedAtFilter}
                handleStatusFilterChange={handleStatusFilter}
                statusFilters={invoiceStatuses}
                handleCustomerSelect={handleCustomerSelection}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Invoice No.</th>
                            <th>Customer</th>
                            <th>Invoice Date</th>
                            <th>Due Date</th>
                            <th>Amount Due</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredInvoices.map((invoice) => (
                            <tr key={invoice._id}>
                                <td>
                                    <span
                                        className={`badge ${getStatusBadge(
                                            invoice.invoice_status
                                        )} text-white`}>
                                        {invoice.invoice_status}
                                    </span>
                                </td>
                                <td>{invoice.invoice_number}</td>
                                <td>
                                    {invoice.customer &&
                                        invoice.customer.first_name +
                                            ' ' +
                                            invoice.customer.last_name}
                                </td>
                                <td>
                                    <Moment calendar>{invoice.invoice_date}</Moment>
                                </td>
                                <td>
                                    <Moment fromNow>{invoice.due_date}</Moment>
                                </td>
                                <td>
                                    <b>{`${util.formatCurrency(invoice.total)} (${currency?.code || 'NAD'})`}</b>
                                </td>

                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                            More
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {!invoice.is_tax_invoice && (
                                                <Dropdown.Item onClick={handleEditInvoice(invoice)}>
                                                    Edit
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Item
                                                onClick={handlePreviewModalShow(invoice)}>
                                                Preview
                                            </Dropdown.Item>
                                            {!invoice.is_tax_invoice && (
                                                <Dropdown.Item onClick={handleShowApproveInvoiceModal(invoice)}>
                                                    Approve
                                                </Dropdown.Item>
                                            )}
                                            {invoice.invoice_status === 'draft' && (
                                                <Dropdown.Item onClick={handleSendInvoice(invoice)}>
                                                    Send
                                                </Dropdown.Item>
                                            )}
                                            {invoice.invoice_status !== 'draft' && (
                                                <Dropdown.Item onClick={handleSendInvoice(invoice)}>
                                                    Resend
                                                </Dropdown.Item>
                                            )}
                                            {invoice.invoice_status !== 'draft' && (
                                                <Dropdown.Item
                                                    onClick={handleShowSalePaymentModal(invoice)}>
                                                    Record Payment
                                                </Dropdown.Item>
                                            )}
                                            {invoice.invoice_status === 'overdue' && (
                                                <Dropdown.Item
                                                    disabled
                                                    onClick={(e) => console.log(e)}>
                                                    Send Reminder
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Item onClick={(e) => console.log(e)} disabled>
                                                Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <OxygenPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showPreviewModal && (
                <PreviewInvoice
                    link={invoiceLink}
                    show={showPreviewModal}
                    onHide={handlePreviewModalClose}
                />
            )}

            {showModal && (
                <InvoiceModal
                    show={showModal}
                    onHide={handleInvoiceModalClose}
                    invoiceData={selectedInvoice}
                />
            )}

            {showSalePaymentModal && (
                <SalePaymentModal
                    show={showSalePaymentModal}
                    onHide={handleCloseSalePaymentModal}
                    modalText={salePaymentModalText}
                    salePayment={salePayment}
                    handleChange={handleSalePaymentChange}
                    handleCreatePayment={handleCreateSalePayment}
                />
            )}

            {showApproveInvoiceModal && (
                <ApprovalPopupModal
                    show={showApproveInvoiceModal}
                    onHide={handleCloseApproveInvoiceModal}
                    recordName={'Invoice'}
                    onApprove={() => approveInvoice(
                       {
                        invoice_id: selectedInvoice._id,
                       }
                    )}
                />
            )}

        </div>
    );
}

export default Invoices;
