import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { ENV } from '../../config';
const { oxygen_end_point } = ENV;

const SupplierSearchDropDown = ({ handleSupplierSelection }) => {
    const company = useSelector((state) => state.company);

    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const loadOptions = async (inputValue) => {
        setIsLoading(true);

        try {
            
            const response = await fetch(
                `${oxygen_end_point}/suppliers?company=${company._id}`
            );
            const data = await response.json();

            // Transform the data to match React Select's expected format
            const transformedOptions = data.map((option) => ({
                value: option._id,
                label: `${option?.business_name || option.first_name + ' ' + option.last_name}`
            }));

            setOptions(transformedOptions);
        } catch (error) {
            console.error('Error fetching supplier options:', error);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        loadOptions('');
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '25px', // Apply border-radius of 25
        }),
    };

    return (
        <Select
            id='supplier-search-dropdown'
            options={options}
            isLoading={isLoading}
            onInputChange={setInputValue}
            inputValue={inputValue}
            onMenuOpen={() => loadOptions(inputValue)}
            placeholder='Search...'
            isClearable
            onChange={(selectedOption) => {
                if (selectedOption) {
                    handleSupplierSelection(selectedOption);
                    setInputValue('');
                } else {
                    handleSupplierSelection(null);
                }
            }}
            styles={customStyles} // Apply custom styles
        />
    );
};

export default SupplierSearchDropDown;
