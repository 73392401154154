import React, { useEffect, useState } from 'react';
import { useSignupMutation } from '@redux/apiSlice';
import { useDispatch } from 'react-redux';
import { signedUserAction } from '@redux/signedUserScilce';
import { useNavigate } from 'react-router-dom';
import Logo from '@shared/Logo';
import BlackLogo from '@assets/img/logo_transparent.png';
import RGSlogo from '@assets/img/rgs/logo_transparent.png';
import Spinner from '@shared/Spinner';

import { Form, Button, Card, Container, Row, Col, Image } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import { LOCAL_STORAGE_ENUMS, ROUTES, APP_MODES } from '@enums';
import { ENV } from '@config';
import { OxNotify } from '@shared';
import { isEmpty } from '@util/array.util';
import { UserService } from '@services';
import EmailConfirmation from './EmailConfirmation';

const userService = UserService.create();

const { COMPANIES, REAL_ESTATE_LICENSE_SELECTION } = ROUTES;
const { AUTH_TOKEN, USER } = LOCAL_STORAGE_ENUMS;
const { APP_MODE } = ENV;

const isOxygen = APP_MODE === APP_MODES.OXYGEN;

function Signup() {
    //state
    const [signup, result] = useSignupMutation();
    const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (result.isSuccess) {
            console.log(result.data);
            successResponse();
        }
        if (result.isError) {
            errorResponse();
        }
    }, [result.isLoading]);

    //other hooks

    const validationSchema = yup.object({
        username: yup
            .string()
            .required('Username is required')
            .test('checkUsername', 'Username already exists', async (value) => {
                const check = await userService.checkExists(value);
                return isEmpty(check);
            }),
        email: yup
            .string()
            .email('Invalid email format')
            .required('Email is required')
            .test('checkEmail', 'Email already exists', async (value) => {
                const check = await userService.checkExists(value, 'email');
                return isEmpty(check);
            }),
        password: yup
            .string()
            .required('Password is required')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            ),
    });

    // Add this function to handle OTP verification
    const handleOTPVerified = () => {
        // Navigate to another page after successful OTP verification
        const navigationUrl = isOxygen ? `/${COMPANIES}` : `/${REAL_ESTATE_LICENSE_SELECTION}`;
        navigate(navigationUrl);
    };

    // handle success response from the api after sign up
    const successResponse = (response) => {
        console.log('Signup Response: ', response);
        localStorage.setItem(AUTH_TOKEN, result.data.token);
        localStorage.setItem(USER, result.data.user);
        dispatch(signedUserAction.setSignedUser(result.data));
        // If signup is successful or if username exists but is unverified
        setShowEmailConfirmation(true);
    };

    const errorResponse = () => {
        const { status, data } = result.error;
        switch (status) {
            case 401:
                const { message } = data;
                OxNotify({
                    title: 'Registration Error',
                    content: message,
                    type: 'error',
                });
                break;

            default:
                break;
        }
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: (values) => {
            setUserEmail(values.email);
            signup(values);
        },
    });

    const renderSignUpForm = () => {
        return (
            <Form onSubmit={formik.handleSubmit}>
                {/* Add your logo here */}
                <Logo src={isOxygen ? BlackLogo : RGSlogo} width={250} />
                <br />
                <h5 className='fw-normal mb-3'>Create A New User Account</h5>

                <Form.Group className='mb-3'>
                    <Form.Label>
                        <FaUser /> Username
                    </Form.Label>
                    <Form.Control
                        type='text'
                        id='user_name'
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className='text-danger'>{formik.errors.username}</div>
                    ) : null}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>
                        <FaEnvelope /> Email
                    </Form.Label>
                    <Form.Control type='email' id='email' {...formik.getFieldProps('email')} />
                    {formik.touched.email && formik.errors.email ? (
                        <div className='text-danger'>{formik.errors.email}</div>
                    ) : null}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>
                        <FaLock /> Password
                    </Form.Label>
                    <Form.Control
                        type='password'
                        id='password'
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='text-danger'>{formik.errors.password}</div>
                    ) : null}
                </Form.Group>

                {/* <OxButton text='CONTINUE' action={handleSignup} /> */}

                <Button disabled={!formik.isValid} type='submit'>
                    CONTINUE
                </Button>
            </Form>
        );
    };

    if (result.isLoading) {
        return <Spinner />;
    }

    return (
        <section>
            <Container>
                <Row
                    className='justify-content-center align-items-center'
                    style={{ marginTop: '70px' }}>
                    <Col xl={10}>
                        <Card style={{ borderRadius: '1rem' }}>
                            <Row className='g-0'>
                                <Col md={6} lg={5}>
                                    <Image
                                        src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp'
                                        alt='login form'
                                        className='img-fluid'
                                        style={{ borderRadius: '1rem 0 0 1rem' }}
                                    />
                                </Col>
                                <Col md={6} lg={7} className='d-flex align-items-center'>
                                    <Card.Body>
                                        {showEmailConfirmation ? (
                                            <EmailConfirmation
                                                onOTPVerified={handleOTPVerified}
                                                email={userEmail}
                                            />
                                        ) : (
                                            renderSignUpForm()
                                        )}
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Signup;
