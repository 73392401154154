import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import OxButton from '../../components/shared/OxButton';

const IndustryModal = ({
    show,
    onHide,
    modalText,
    industry,
    handleCreateIndustry,
    handleUpdateIndustry,
    handleChange,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name='name'
                            value={industry.name}
                            onChange={handleChange}
                            placeholder='Industry Name'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <div>
                            <Form.Check
                                inline
                                type='radio'
                                label='Public'
                                name='type'
                                id='public'
                                checked={industry.type === 'public'}
                                onChange={() =>
                                    handleChange({ target: { name: 'type', value: 'public' } })
                                }
                            />
                            <Form.Check
                                inline
                                type='radio'
                                label='Private'
                                name='type'
                                id='private'
                                checked={industry.type === 'private'}
                                onChange={() =>
                                    handleChange({ target: { name: 'type', value: 'private' } })
                                }
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            name='description'
                            value={industry.description}
                            onChange={handleChange}
                            placeholder='Optional'
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText === 'New Industry' ? (
                    <OxButton text='Submit' action={handleCreateIndustry} />
                ) : (
                    <OxButton text='Update' action={handleUpdateIndustry} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default IndustryModal;
