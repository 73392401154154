import React from 'react';
import { colors } from '../../strings/colors';
import { Button } from 'react-bootstrap';

function OxButton({ action, text }) {
    return (
        <Button
            onClick={action}
            className='btn btn-primary btn-block'
            style={{ backgroundColor: colors.primary, color: 'white' }}>
            {text}
        </Button>
    );
}

export default OxButton;
