import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    useGetCompanyCustomersQuery,
    useCreateSaleAgreementMutation,
    useUpdateSaleAgreementMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import util from '../../../util';
import SaleAgreementItems from './SaleAgreementItems';

const SaleAgreementModal = ({ show, onHide, saleAgreementData }) => {
    // Company
    const company = useSelector((state) => state.company);

    // Employee
    const employee = useSelector((state) => state.employee);

    // Check if sale agreement data has been passed
    if (!saleAgreementData._id) {
        saleAgreementData = {
            _id: '',
            sale_agreement_number: '',
            customer: '',
            company: company._id,
            start_date: new Date(),
            end_date: new Date(),
            status: 'Active',
            created_by: employee._id,
        };
    }

    // Get customers
    const {
        data: customers,
        error: customersError,
        isLoading: customersLoading,
        refetch: refetchCustomers,
    } = useGetCompanyCustomersQuery(company._id);

    // Sale agreement state
    const [saleAgreement, setSaleAgreement] = useState(saleAgreementData);

    // Create sale agreement
    const [createSaleAgreement, createSaleAgreementResult] = useCreateSaleAgreementMutation();

    // Update sale agreement
    const [updateSaleAgreement, updateSaleAgreementResult] = useUpdateSaleAgreementMutation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSaleAgreement((prevSaleAgreement) => ({
            ...prevSaleAgreement,
            [name]: value,
        }));
    };

    const handleCreateSaleAgreement = () => {
        createSaleAgreement(saleAgreement);
    };

    const handleUpdateSaleAgreement = () => {
        updateSaleAgreement(saleAgreement);
    };

    useEffect(() => {
        if (createSaleAgreementResult.data) {
            setSaleAgreement(createSaleAgreementResult.data);
            toast.success('Sale Agreement created successfully');
        }
        if (updateSaleAgreementResult.data) {
            setSaleAgreement((prevSaleAgreement) => ({
                ...prevSaleAgreement,
                status: updateSaleAgreementResult.data.status,
            }));
            toast.success('Sale Agreement updated successfully');
        }
    }, [createSaleAgreementResult.isLoading, updateSaleAgreementResult.isLoading]);

    if (createSaleAgreementResult.isLoading) {
        return <Spinner />;
    }

    return (
        <Modal show={show} onHide={onHide} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {saleAgreement._id
                        ? `EDIT SALE AGREEMENT #${saleAgreement.sale_agreement_number}`
                        : 'CREATE NEW SALE AGREEMENT'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {updateSaleAgreementResult.isLoading ? (
                    <Spinner />
                ) : (
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Sale Agreement Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='sale_agreement_number'
                                        value={saleAgreement.sale_agreement_number}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Customer</Form.Label>
                                    <Form.Control
                                        as='select'
                                        name='customer'
                                        value={saleAgreement.customer}
                                        onChange={handleInputChange}
                                        required>
                                        <option value=''>Select Customer</option>
                                        {customers &&
                                            customers.map((customer) => (
                                                <option key={customer._id} value={customer._id}>
                                                    {customer?.business_name ||
                                                        customer?.first_name +
                                                            ' ' +
                                                            customer?.last_name}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type='date'
                                        name='start_date'
                                        value={util.formatDate(saleAgreement.start_date)}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        type='date'
                                        name='end_date'
                                        value={util.formatDate(saleAgreement.end_date)}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        as='select'
                                        name='status'
                                        value={saleAgreement.status}
                                        onChange={handleInputChange}
                                        required>
                                        <option value='Active'>Active</option>
                                        <option value='Inactive'>Inactive</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <h4>SALE AGREEMENT ITEMS</h4>
                            {saleAgreement._id && (
                                <SaleAgreementItems saleAgreementId={saleAgreement._id} />
                            )}
                        </Row>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {saleAgreementData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdateSaleAgreement}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreateSaleAgreement}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SaleAgreementModal;
