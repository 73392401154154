import React from 'react';

function footer() {
    return (
        <footer className='footer'>
            <div className='container-fluid'>
                <nav>
                    <ul className='footer-menu'>
                        <li>
                            <a href='#!'>Powered by Oxygen ERP</a>
                        </li>
                    </ul>
                    <p className='copyright text-center'>
                        Developed by{' '}
                        <a href='http://sit.siskus.com' target='blank'>
                            Siskus Infinity Technology
                        </a>
                    </p>
                </nav>
            </div>
        </footer>
    );
}

export default footer;
