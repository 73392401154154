/**
 * NewFeatureForm.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
  icon: Yup.string().required('Required'),
  link: Yup.string().required('Required'),
  sequence: Yup.number().required('Required').min(0),
  description: Yup.string().required('Required'),
  type: Yup.string().oneOf(['module', 'capability', 'functionality', 'limit']).required('Required'),
  visibility: Yup.string().oneOf(['public', 'private']).required('Required'),
});

import {
    useCreateFeatureMutation,
    useUpdateFeatureMutation,
} from '@redux/apiSlice';
import { toast } from 'react-toastify';
import Spinner from '@components/shared/Spinner';
import { useSelector } from 'react-redux';

function NewFeatureForm({ feature, handleClose, refetch }) {

  const company = useSelector((state) => state.company);

  const [createFeature, createFeatureResult] = useCreateFeatureMutation();
  const [updateFeature, updateFeatureResult] = useUpdateFeatureMutation();

  const handleSubmit = (values) => {
    if (feature._id) {
      updateFeature({
        _id: feature._id,
        ...values
      });
    } else {
      createFeature({
        ...values,
        company: company._id,
      });
    }
  };

  useEffect(() => {
    if (createFeatureResult.isSuccess) {
      formik.resetForm();
      toast.success('Feature created successfully');
      refetch();
      handleClose();
    }

    if (createFeatureResult.isError) {
      toast.error('Something went wrong');
    }

    if (updateFeatureResult.isSuccess) {
      formik.resetForm();
      toast.success('Feature updated successfully');
      refetch();
      handleClose();
    }

    if (updateFeatureResult.isError) {
      toast.error('Something went wrong');
    }
  }, [createFeatureResult.isLoading, updateFeatureResult.isLoading]);

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      icon: '',
      link: '',
      sequence: 0,
      description: '',
      type: '',
      visibility: '',
      ...feature,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  if (createFeatureResult.isLoading || updateFeatureResult.isLoading) {
    return <Spinner />;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup className="mb-3">
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormControl
          type="text"
          id="name"
          {...formik.getFieldProps('name')}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-danger">{formik.errors.name}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="code">Code</FormLabel>
        <FormControl
          type="text"
          id="code"
          {...formik.getFieldProps('code')}
        />
        {formik.touched.code && formik.errors.code ? (
          <div className="text-danger">{formik.errors.code}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="icon">Icon</FormLabel>
        <FormControl
          type="text"
          id="icon"
          {...formik.getFieldProps('icon')}
        />
        {formik.touched.icon && formik.errors.icon ? (
          <div className="text-danger">{formik.errors.icon}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="link">Link</FormLabel>
        <FormControl
          type="text"
          id="link"
          {...formik.getFieldProps('link')}
        />
        {formik.touched.link && formik.errors.link ? (
          <div className="text-danger">{formik.errors.link}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="sequence">Sequence</FormLabel>
        <FormControl
          type="number"
          id="sequence"
          {...formik.getFieldProps('sequence')}
        />
        {formik.touched.sequence && formik.errors.sequence ? (
          <div className="text-danger">{formik.errors.sequence}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="description">Description</FormLabel>
        <FormControl
          type="text"
          id="description"
          {...formik.getFieldProps('description')}
        />
        {formik.touched.description && formik.errors.description ? (
          <div className="text-danger">{formik.errors.description}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="type">Type</FormLabel>
        <FormControl
          as="select"
          id="type"
          {...formik.getFieldProps('type')}
        >
          <option value="" label="Select type" />
          <option value="module" label="Module" />
          <option value="capability" label="Capability" />
          <option value="functionality" label="Functionality" />
          <option value="limit" label="Limit" />
        </FormControl>
        {formik.touched.type && formik.errors.type ? (
          <div className="text-danger">{formik.errors.type}</div>
        ) : null}
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel htmlFor="visibility">Visibility</FormLabel>
        <FormControl
          as="select"
          id="visibility"
          {...formik.getFieldProps('visibility')}
        >
          <option value="" label="Select visibility" />
          <option value="public" label="Public" />
          <option value="private" label="Private" />
        </FormControl>
        {formik.touched.visibility && formik.errors.visibility ? (
          <div className="text-danger">{formik.errors.visibility}</div>
        ) : null}
      </FormGroup>

      <Button type="submit">Submit</Button>
    </Form>
  );
}

export default NewFeatureForm;
