/**
 * MultiStepForm.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { Field } from 'formik';
import 'bootstrap/dist/css/bootstrap.min.css';

const displayError = (touched, errors, fieldName) => {
    if (touched[fieldName] && errors[fieldName]) {
        return <div className='text-danger'>{errors[fieldName]}</div>;
    }
    return null;
};

export function PersonalInfoForm({ initialValues, formikProps }) {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        touched,
        setFieldValue,
    } = formikProps;
    return (
        <>
            <Card.Text style={{ color: 'blue' }}>Personal Information</Card.Text>

            {/* Personal Information */}
            <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridEmail'>
                    <Form.Label>Title</Form.Label>
                    <Field name='title' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'title')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridFirstName'>
                    <Form.Label>First Name</Form.Label>
                    <Field name='firstName' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'firstName')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridLastName'>
                    <Form.Label>Last Name</Form.Label>
                    <Field name='lastName' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'lastName')}
                </Form.Group>
            </Row>
            {/* ... (repeating the rows you already have) ... */}

            <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridNationality'>
                    <Form.Label>Nationality</Form.Label>
                    <Field name='nationality' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'nationality')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridTownOfBirth'>
                    <Form.Label>Town of Birth</Form.Label>
                    <Field name='townOfBirth' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'townOfBirth')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridCountryOfBirth'>
                    <Form.Label>Country of Birth</Form.Label>
                    <Field name='countryOfBirth' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'countryOfBirth')}
                </Form.Group>
            </Row>

            <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridIdentityNumber'>
                    <Form.Label>Identity Number</Form.Label>
                    <Field name='identityNumber' as={Form.Control} type='tel' />
                    {displayError(touched, errors, 'identityNumber')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridPostalAddress'>
                    <Form.Label>Postal Address</Form.Label>
                    <Field name='postalAddress' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'postalAddress')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridResidentialAddress'>
                    <Form.Label>Residential Address</Form.Label>
                    <Field name='residentialAddress' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'residentialAddress')}
                </Form.Group>
            </Row>

            <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridCellPhone'>
                    <Form.Label>CellPhone</Form.Label>
                    <Field name='cellPhone' as={Form.Control} type='tel' />
                    {displayError(touched, errors, 'cellPhone')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridTelephone'>
                    <Form.Label>Telephone</Form.Label>
                    <Field name='telephone' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'telephone')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridEmailAddress'>
                    <Form.Label>Email Address</Form.Label>
                    <Field name='emailAddress' as={Form.Control} type='email' />
                    {displayError(touched, errors, 'emailAddress')}
                </Form.Group>
            </Row>
            <Row>
                {/* Marital & Spouse Details */}
                <Col sm={6}>
                    <br />
                    <br />
                    <Card.Text style={{ color: 'blue' }}>Marital & Spouse Details</Card.Text>
                    <Row className='mb-3'>
                        <Form.Group as={Col} controlId='formGridMaritalStatus'>
                            <Form.Label>Marital Status</Form.Label>

                            <Form.Select
                                name='maritalStatus'
                                aria-label='Marital Status'
                                onChange={(event) => {
                                    console.log('event: ', event);
                                    setFieldValue('maritalStatus', event.target.value);
                                }}>
                                <option value='' label='Select option' />
                                <option value='Single' label='Single' />
                                <option value='Married' label='Married' />
                            </Form.Select>
                            {displayError(touched, errors, 'maritalStatus')}
                        </Form.Group>

                        <Form.Group as={Col} controlId='formGridMaritalRegime'>
                            <Form.Label>Marital Regime</Form.Label>
                            <Field name='maritalRegime' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'maritalRegime')}
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col} controlId='formGridSpouseFullName'>
                            <Form.Label>Spouse Full Name</Form.Label>
                            <Field name='spouseFullName' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'spouseFullName')}
                        </Form.Group>
                    </Row>
                </Col>
                {/* Marital & Spouse Details */}
                <Col sm={6}>
                    <br />
                    <br />
                    {/* Employment Details */}
                    <Card.Text style={{ color: 'blue' }}>Employment Details</Card.Text>
                    <Row className='mb-3'>
                        <Form.Group as={Col} controlId='formGridEmploymentStatus'>
                            <Form.Label>Employment Status</Form.Label>

                            <Form.Select
                                name='employmentStatus'
                                aria-label='Employment Status'
                                onChange={(event) => {
                                    console.log('event: ', event);
                                    setFieldValue('employmentStatus', event.target.value);
                                }}>
                                <option value='' label='Select option' />
                                <option value='Employed' label='Employed' />
                                <option value='Unemployed' label='Unemployed' />
                                <option value='Sole Trader' label='Sole Trader' />
                            </Form.Select>
                            {displayError(touched, errors, 'employmentStatus')}
                        </Form.Group>

                        <Form.Group as={Col} controlId='formGridOccupation'>
                            <Form.Label>Occupation</Form.Label>
                            <Field name='occupation' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'occupation')}
                        </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                        <Form.Group as={Col} controlId='formGridEmployer'>
                            <Form.Label>Employer</Form.Label>
                            <Field name='employer' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'employer')}
                        </Form.Group>

                        <Form.Group as={Col} controlId='formGridEmployerTelephone'>
                            <Form.Label>Employer Telephone</Form.Label>
                            <Field name='employerTelephone' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'employerTelephone')}
                        </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                        <Form.Group as={Col} controlId='formGridEmployerAddress'>
                            <Form.Label>Employer Address</Form.Label>
                            <Field name='employerAddress' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'employerAddress')}
                        </Form.Group>

                        <Form.Group as={Col} controlId='formGridTaxRegistrationNumber'>
                            <Form.Label>Tax Registration Number</Form.Label>
                            <Field name='taxRegistrationNumber' as={Form.Control} type='text' />
                            {displayError(touched, errors, 'taxRegistrationNumber')}
                        </Form.Group>
                    </Row>
                </Col>
            </Row>

            {/* Transaction Details */}
            <Card.Text style={{ color: 'blue' }}>
                TRANSACTION FUNDS / PROPERTY DETAILS / BANK ACCOUNT DETAILS
            </Card.Text>
            <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridSourceOfFunds'>
                    <Form.Label>Source of funds (Loan/Savings/Shares/inheritance etc)</Form.Label>
                    <Field name='sourceOfFunds' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'sourceOfFunds')}
                </Form.Group>
                <Form.Group as={Col} controlId='formGridBankName'>
                    <Form.Label>Bank Name</Form.Label>
                    <Field name='bankName' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'bankName')}
                </Form.Group>
                <Form.Group as={Col} controlId='formGridBankAccountHolder'>
                    <Form.Label>Bank account holder</Form.Label>
                    <Field name='bankAccountHolder' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'bankAccountHolder')}
                </Form.Group>
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col} controlId='formGridBankAccountNumber'>
                    <Form.Label>Bank account number</Form.Label>
                    <Field name='bankAccountNumber' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'bankAccountNumber')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridBranchCode'>
                    <Form.Label>Branch code</Form.Label>
                    <Field name='branchCode' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'branchCode')}
                </Form.Group>

                <Form.Group as={Col} controlId='formGridPropertyDetails'>
                    <Form.Label>Property details</Form.Label>
                    <Field name='propertyDetails' as={Form.Control} type='text' />
                    {displayError(touched, errors, 'propertyDetails')}
                </Form.Group>
            </Row>
        </>
    );
}
