
/**
 * IndustrySelection.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect } from 'react'; // <-- Import useEffect
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Logo from '../../../components/shared/Logo';
import BlackLogo from '@assets/img/logo_transparent.png';
import {
    useGetPublicIndustriesQuery
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import { useDispatch } from 'react-redux';
import { industryActions } from '../../../Redux/industrySlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config';

const {OXYGEN_LICENSE_SELECTION} = ROUTES;

const IndustrySelection = () => {

  const { data: industriesData, isLoading, isError, refetch } = useGetPublicIndustriesQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [refetch]); 

  //handle onIndustry selected 

  const handleIndustrySelected = (industry) => {
    dispatch(industryActions.setIndustry(industry));
    navigate(`/${OXYGEN_LICENSE_SELECTION}`);
  }

  if (isLoading) return <Spinner/>

  return (
    <Container>
      {/* Logo and Heading */}
      <Row className="justify-content-center mt-4">
        <Col xs={12} className="text-center">
           <Logo width={250} src={BlackLogo} />
        </Col>
        <Col xs={12} className="text-center mt-3">
          <h2>Welcome to Oxygen ERP, Please select an Industry</h2>
        </Col>
      </Row>

      {/* Industry Cards */}
      <Row className="mt-5">
        {industriesData && industriesData.map((industry) => (
          <Col xs={12} sm={6} md={4} lg={3} key={industry._id} className="mb-4">
            <Card className="rounded shadow-sm h-100">
              <Card.Body className="d-flex flex-column justify-content-between">
                <div>
                  <Card.Title className="text-center">{industry.name}</Card.Title>
                  <Card.Text className="text-center mb-3">{industry.description}</Card.Text>
                </div>
                <Button variant="primary" onClick={() => handleIndustrySelected(industry)} block>Select</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default IndustrySelection;
