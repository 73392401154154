import React, { useEffect } from 'react';
import CardHeader from '../../components/shared/CardHeader';
import LicenseTypeModal from './LicenseTypeModal';

import {
    useGetLicenseTypesQuery,
    useCreateLicenseTypeMutation,
    useUpdateLicenseTypeMutation,
    useDeleteLicenseTypeMutation,
} from '../../Redux/apiSlice';
import OxButton from '../../components/shared/OxButton';

function LicenseTypes() {
    //get license types
    const { data, error, isLoading, refetch: refechLicenseTypes } = useGetLicenseTypesQuery();

    //create license type
    const [createLicenseType, createLicenseTypeResult] = useCreateLicenseTypeMutation();

    //update license type
    const [updateLicenseType, updateLicenseTypeResult] = useUpdateLicenseTypeMutation();

    //delete license type
    const [deleteLicenseType, deleteLicenseTypeResult] = useDeleteLicenseTypeMutation();

    //license type state
    const [licenseType, setLicenseType] = React.useState({
        name: '',
        description: '',
        code: '',
        features: [],
        price: 0,
        industry: '',
    });

    //license types array state
    const [licenseTypes, setLicenseTypesArray] = React.useState([]);

    //modal text
    const [modalText, setModalText] = React.useState('New License Type');

    //LicenseType Modal state
    const [showLicenseTypeModal, setShowLicenseTypeModal] = React.useState(false);

    //handle show license type modal
    const handleShowLicenseTypeModal = () => {
        setShowLicenseTypeModal(true);
        setModalText('New License Type');
        //clear license type state
        setLicenseType({
            name: '',
            description: '',
            code: '',
            features: [],
            price: 0,
            industry: '',
        });
    };

    //on feature select

    const onFeatureSelect = (feature) => (e) => {
        e.preventDefault();

        const features = [...licenseType.features];
        const index = features.indexOf(feature);
        if (index === -1) {
            features.push(feature);
        } else {
            features.splice(index, 1);
        }
        setLicenseType((prevLicenseType) => ({
            ...prevLicenseType,
            features,
        }));
    };

    //handle close license type modal
    const handleCloseLicenseTypeModal = () => {
        setShowLicenseTypeModal(false);
    };

    //handle edit license type
    const handleEditLicenseType = (licenseType) => {
        setShowLicenseTypeModal(true);
        setModalText('Edit License Type');
        setLicenseType(licenseType);
    };

    //search text
    const [searchText, setSearchText] = React.useState('');

    //handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLicenseType((prevLicenseType) => ({
            ...prevLicenseType,
            [name]: value,
        }));
    };

    //handle create license type
    const handleCreateLicenseType = () => {
        createLicenseType(licenseType);
    };

    //handle update license type
    const handleUpdateLicenseType = () => {
        updateLicenseType(licenseType);
    };

    //handle delete license type
    const handleDeleteLicenseType = () => {
        deleteLicenseType(licenseType._id);
    };

    useEffect(() => {
        if (data) {
            setLicenseTypesArray(data);
        }

        if (createLicenseTypeResult.data) {
            refechLicenseTypes();
            handleCloseLicenseTypeModal();
        }

        if (updateLicenseTypeResult.data) {
            refechLicenseTypes();
            handleCloseLicenseTypeModal();
        }

        if (deleteLicenseTypeResult.data) {
            refechLicenseTypes();
            handleCloseLicenseTypeModal();
        }
    }, [
        data,
        createLicenseTypeResult.isLoading,
        updateLicenseTypeResult.isLoading,
        deleteLicenseTypeResult.isLoading,
    ]);

    return (
        <div className='card strpied-tabled-with-hover'>
            <CardHeader
                buttonText={'New License Type'}
                handleSearch={handleSearch}
                handleShow={handleShowLicenseTypeModal}
                headerTitile={'License Types'}
                headerDescription={'Manage License Types'}
                placeholder={'Search License Types'}
                searchText={searchText}
            />

            <div className='card-body table-full-width table-responsive'>
                <table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {licenseTypes &&
                            licenseTypes.map((licenseType) => (
                                <tr key={licenseType._id}>
                                    <td>{licenseType.name}</td>
                                    <td>{licenseType.code}</td>
                                    <td>{licenseType.price}</td>
                                    <td>{licenseType.description}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditLicenseType(licenseType)}
                                            text={'Edit'}
                                        />{' '}
                                        &nbsp;
                                        <OxButton
                                            action={() => handleDeleteLicenseType(licenseType)}
                                            text={'Delete'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <LicenseTypeModal
                show={showLicenseTypeModal}
                onHide={handleCloseLicenseTypeModal}
                modalText={modalText}
                licenseType={licenseType}
                handleCreateLicenseType={handleCreateLicenseType}
                handleUpdateLicenseType={handleUpdateLicenseType}
                handleChange={handleChange}
                onFeatureSelect={onFeatureSelect}
            />
        </div>
    );
}

export default LicenseTypes;
