import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import util from '../../../util';
import { saveAs } from 'file-saver';

const MeterReadingReportModal = ({ show, handleClose, data, reportName, dateRange, reportTime, employee }) => {
    const exportToExcel = () => {
        let csvContent = "Delivery Note No,Pump,Product,Volume,Meter Readings Before,Meter Readings After,Difference\n";
    
        data.forEach((record) => {
          csvContent += `${record.delivery_note.delivery_note_no},${record.terminal.terminal_no},${record.item.name},${util.formatVolumeInLiters(record.quantity)},${util.formatVolumeInLiters(record.meter_readings_before)},${util.formatVolumeInLiters(record.meter_readings_after)},${util.formatVolumeInLiters(record.difference)}\n`;
        });
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, "MeterReadingReport.csv");
    };
  return (
    <Modal show={show} size='xl' fullscreen='md-down' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{reportName} Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Date Range: {dateRange}</p>
        <p>Report Pulled: {reportTime}</p>
        <p>Employee: {employee}</p>

        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Delivery Note No</th>
              <th>Pump</th>
              <th>Product</th>
              <th>Volume</th>
              <th>Meter Readings Before</th>
              <th>Meter Readings After</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>
            {data.map((record, index) => (
              <tr key={index}>
                <td>{record.delivery_note.delivery_note_no}</td>
                <td>{record.terminal.terminal_no}</td>
                <td>{record.item.name}</td>
                <td>{util.formatVolumeInLiters(record.quantity)}</td>
                <td>{util.formatVolumeInLiters(record.meter_readings_before)}</td>
                <td>{util.formatVolumeInLiters(record.meter_readings_after)}</td>
                <td>{util.formatVolumeInLiters(record.difference)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="success" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MeterReadingReportModal;
