import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import SaleOrderItems from './SaleOrderItems';
import { useSelector } from 'react-redux';
import {
    useUpdateSalesOrderMutation,
    useGetCompanyCustomersQuery,
    useCreateSalesOrderMutation,
} from '../../../Redux/apiSlice';
import CustomerSearchDropDown from '../../../components/shared/CustomerSearchDropDown';

import { toast } from 'react-toastify';

const SaleOrderModal = ({ show, onHide, saleOrderData }) => {
    const company = useSelector((state) => state.company);

    console.log('saleOrderData', saleOrderData);

    const employee = useSelector((state) => state.employee);

    if (!saleOrderData._id) {
        saleOrderData = {
            _id: '',
            customer: '',
            employee: employee._id,
            sale_order_number: '',
            comment: '',
            company: company._id,
        };
    }
    const [saleOrder, setSaleOrder] = useState(saleOrderData);
    const [createSaleOrder, createSaleOrderResult] = useCreateSalesOrderMutation();
    const [updateSaleOrder, updateSaleOrderResult] = useUpdateSalesOrderMutation();
    const {
        data: customers,
        error: customersError,
        isLoading: customersLoading,
        refetch: refetchCustomers,
    } = useGetCompanyCustomersQuery(company._id);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSaleOrder((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleCustomerSelection = (customer) => {
        console.log(customer.value);
        setSaleOrder((prevState) => ({
            ...prevState,
            customer: customer.value,
        }));
    };

    const handleCreateSaleOrder = () => {
        const newSaleOrder = {
            company: company._id,
            customer: saleOrder.customer,
            employee: employee._id,
            comment: saleOrder.comment,
        };
        createSaleOrder(newSaleOrder);
    };

    const handleUpdateSaleOrder = () => {
        const updatedSaleOrder = {
            _id: saleOrder._id,
            company: company._id,
            customer: saleOrder.customer._id,
            employee: employee._id,
            comment: saleOrder.comment,
        };
        updateSaleOrder(updatedSaleOrder);
    };

    useEffect(() => {
        if (createSaleOrderResult.data) {
            setSaleOrder(createSaleOrderResult.data);
        }

        if (createSaleOrderResult.isSuccess) {
            toast.success('Sale Order Created Successfully');
            refetchCustomers();
        }

        if (createSaleOrderResult.error) {
            toast.error(createSaleOrderResult.error.message);
        }

        if (updateSaleOrderResult.data) {
            setSaleOrder(updateSaleOrderResult.data);
        }

        if (updateSaleOrderResult.isSuccess) {
            toast.success('Sale Order Updated Successfully');
        }
    }, [customersLoading, createSaleOrderResult.isLoading, updateSaleOrderResult.isLoading]);

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {saleOrder._id
                        ? `EDIT SALE ORDER# ${saleOrder.sale_order_no}`
                        : `CREATE NEW SALE ORDER`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Sale Order Number</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={saleOrder.sale_order_no}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Customer</Form.Label> <br />
                                <CustomerSearchDropDown
                                    handleCustomerSelection={handleCustomerSelection}
                                />
                               
                            </Form.Group>
                            {saleOrderData.customer && (
                                <div>
                                    <p>
                                        <strong>
                                            {saleOrderData.customer && saleOrderData.customer?.business_name || saleOrderData.customer.first_name}{' '}
                                            
                                        </strong>
                                    </p>
                                    <p className='mutted'>{saleOrder.customer.email}</p> 
                                    <p>{saleOrderData.customer.phone_number}</p>
                                </div>
                            )}
                        </div>
                        <div className='col-md-8'>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    name='comment'
                                    value={saleOrder.comment}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    {saleOrder._id.length > 0 && <SaleOrderItems saleOrderId={saleOrderData._id || saleOrder._id} />}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {saleOrderData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdateSaleOrder}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreateSaleOrder}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SaleOrderModal;
