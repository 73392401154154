/**
 * OxModal.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import { FaUser, FaDollarSign, FaFile } from 'react-icons/fa';

import { colors } from '../../../strings/colors';

export function OxModal(props) {
    const {
        handleClose,
        visible,
        title = '',
        children,
        size = 'lg',
        height = 'auto',
        backdrop = 'static',
        dark = false,
        scrollable = false,
        centered = true,
        customStyles = {},
        ...rest
    } = props;

    const styles = {
        modal: {
            backgroundColor: dark ? '#000' : '#fff',
            height,
        },
        header: {
            background: 'transparent',
            backgroundColor: 'transparent',
        },
        headerText: {
            color: colors.primary,
        },

        content: {
            backgroundColor: dark ? '#000' : '#fff',
            height: '100%',
        },
        closingButton: {
            color: colors.primary,
            fontSize: '20px',
        },
        ...customStyles,
    };

    return (
        <Modal
            show={visible}
            onHide={handleClose}
            size={size}
            backdrop={backdrop}
            centered={centered}
            scrollable={scrollable}
            keyboard={true}>
            <Modal.Header closeButton style={styles.header}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={styles.content}>{children}</Modal.Body>
        </Modal>
    );
}
