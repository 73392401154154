import React from 'react';
import ReactPaginate from 'react-paginate';
import './OxygenPagination.css';

const OxygenPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    pageSizes,
    pageSize,
    onPageSizeChange,
}) => {
    const handlePageChange = ({ selected }) => {
        onPageChange(selected + 1);
    };

    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value);
        onPageSizeChange(newSize);
    };

    return (
        <div className='oxygen-pagination'>
            <ReactPaginate
                previousLabel={<i className='fas fa-chevron-left' />}
                nextLabel={<i className='fas fa-chevron-right' />}
                breakLabel={<span className='pagination-break'>...</span>}
                pageCount={totalPages}
                forcePage={currentPage - 1}
                onPageChange={handlePageChange}
                containerClassName='pagination'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                disabledClassName='disabled'
                activeClassName='active'
            />

            <div className='page-size-select'>
                <span>Items per page:</span>
                <select value={pageSize} onChange={handlePageSizeChange}>
                    {pageSizes.map((size) => (
                        <option key={size} value={size}>
                            {size}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default OxygenPagination;
