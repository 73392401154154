/**
 * ViewContract.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */
import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    Font,
} from '@react-pdf/renderer';
import { formatNumberToPrice } from '@util/number.utils';
import { shortDate } from '@util/date.utils';

import RGSlogo from '@assets/img/rgs/logo_transparent.png';

const logo = 'https://rgs.oxygen-erp.com/static/media/logo_transparent.e519fee22cf7e15c79b8.png';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
Font.register({
    family: 'HelveticaW01-Roman',
    src: 'http://db.onlinewebfonts.com/t/c5bd605b45237a12c99133fa9d7cae49.ttf',
});
Font.register({
    family: 'BelindaW00-Regular',
    src: '//db.onlinewebfonts.com/t/f6a8b2423790ec81bbbd18662bbc7f03.ttf',
});

const styles = StyleSheet.create({
    pdfViewer: {
        width: '100%',
        height: '80vh',
    },

    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'HelveticaW01-Roman',
    },

    logoImage: {
        height: '70px',
        width: '180px',
        marginVertical: 10,
        //marginHorizontal: 15,
    },

    headerTitle: {
        fontSize: 18,
        textAlign: 'center',
        color: '#60caed',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    logoTableColumn: {
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'center',
        alignItems: 'center',
        //color: 'black',
        fontSize: 11,
    },
    titleTableColumn: {
        color: 'black',
        fontSize: 11,
        //textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        // width: '50%',
    },
    table: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        overflow: 'none',
    },
    firstColumn: {
        display: 'flex',
        //  flex: '0 0 auto',
        color: 'black',
        fontSize: 11,
        width: '34%',
    },
    secondColumn: {
        //  display: 'flex',
        // flex: '0 0 auto',
        // color: '#2185D0',

        fontSize: 11,
        width: '66%',
    },
    columnText: {
        fontSize: 10,
    },
    innerText: {
        flexDirection: 'row',
        marginTop: 5,
    },
});

export const ViewContract = ({ contract }) => {
    const { tenant, property, agent, agreed_rent, placement_start_date, placement_end_date } =
        contract;
    const ContractDocument = () => {
        return (
            <Document>
                <Page style={styles.body}>
                    <View
                        style={{
                            height: 150,
                        }}>
                        <View style={{ ...styles.logoTableColumn }}>
                            <Image style={styles.logoImage} src={RGSlogo} />
                        </View>
                    </View>

                    <View style={{ marginHorizontal: 40, fontSize: 11 }}>
                        <View style={{ marginBottom: 1, textAlign: 'center' }}>
                            <Text style={{ fontSize: 13, color: '#60caed' }}>
                                LEASE AGREEMENT CONTRACT
                            </Text>
                        </View>

                        <View style={{ marginBottom: 15 }}>
                            <Text style={{ fontSize: 10 }}>
                                _______________________________________________________________________________________
                            </Text>
                        </View>

                        <View style={{ ...styles.innerText, marginBottom: 10, marginTop: 10 }}>
                            <Text></Text>
                            <Text style={{ marginLeft: 20 }}>
                                This Lease Agreement (the "Agreement") is entered into on this ___
                                day of ____________, 20__, by and between ____________________, with
                                a mailing address of ______________________ (the "Landlord"), and
                                ____________________, with a mailing address of
                                ______________________ (the "Tenant").
                            </Text>
                        </View>

                        <View style={styles.innerText}>
                            <Text>1</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Original surveyed stand beacons (Surveyor pegs) must be shown by the
                                owner when foundation excavations are inspected.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>2</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Term: The lease will start on ____________ and end on ____________.
                                Tenant must vacate the Property by 12:00 noon on the end date unless
                                both parties agree, in writing, to extend this Agreement.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>3</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Rent: Tenant agrees to pay Landlord $______ per month in rent, due
                                and payable in advance on the first day of each month. The first
                                month's rent is due upon the execution of this Agreement. If Tenant
                                fails to pay the rent in full before the end of the fifth day of the
                                month, Tenant will pay a late fee of $______.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>4</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Security Deposit: Tenant must pay a security deposit of $______
                                before taking possession of the Property. Landlord will refund the
                                security deposit within ____ days after Tenant vacates the Property,
                                less any deductions for damages and unpaid obligations.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>5</Text>

                            <Text style={{ marginLeft: 20 }}>
                                Maintenance and Repairs: Tenant must keep the Property clean and in
                                good condition. Tenant is responsible for all routine maintenance
                                and minor repairs. If major repairs are needed, Tenant must notify
                                Landlord immediately.
                            </Text>
                        </View>

                        <View style={styles.innerText}>
                            <Text>6</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Pets: No pets are allowed on the Property without the written
                                consent of the Landlord. If Landlord gives consent, Tenant must pay
                                a non-refundable pet fee of $______.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>7</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Governing Law: This Agreement will be governed by and construed in
                                accordance with the laws of the state of ______________________.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>8</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Entire Agreement: This Agreement contains the entire agreement
                                between the parties and supersedes all prior agreements and
                                understandings, if any, relating to the subject matter hereof.
                            </Text>
                        </View>
                        <View style={styles.innerText}>
                            <Text>9</Text>
                            <Text style={{ marginLeft: 20 }}>
                                Amendments: This Agreement may be amended only by a written
                                agreement signed by both parties.
                            </Text>
                        </View>

                        <View style={styles.innerText}>
                            <Text>10</Text>
                            <Text style={{ marginLeft: 15 }}>
                                Severability: If any provision of this Agreement is held to be
                                invalid or unenforceable, the remaining provisions will continue to
                                be valid and enforceable.
                            </Text>
                        </View>

                        <View
                            style={{
                                display: 'flex',
                                position: 'relative',
                                overflow: 'none',
                                marginBottom: 30,
                                marginTop: 30,
                            }}>
                            <View style={styles.table}>
                                <View style={{ ...styles.firstColumn, marginRight: 30 }}>
                                    <View style={{ marginBottom: 2 }}>
                                        <Text
                                            style={{
                                                fontSize: 20,
                                                fontFamily: 'BelindaW00-Regular',
                                                color: '#3673B1',
                                            }}>
                                            {property.name}
                                        </Text>
                                        <Text style={{ fontSize: 10 }}>
                                            -------------------------------------------------------------
                                        </Text>
                                    </View>
                                    <View style={{ marginBottom: 15 }}>
                                        <Text style={{ fontSize: 10 }}>RESPONSIBLE AGENT</Text>
                                    </View>
                                    <View style={{ marginBottom: 30 }}>
                                        <Text style={{ fontSize: 10 }}>Dated:</Text>
                                        <Text style={{ fontSize: 10, color: '#3673B1' }}>
                                            15 August 2023
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.secondColumn, marginLeft: 30 }}>
                                    <View style={{ marginBottom: 2 }}>
                                        <Text
                                            style={{
                                                fontSize: 20,
                                                fontFamily: 'BelindaW00-Regular',
                                                color: '#3673B1',
                                            }}>
                                            {tenant.first_name} {tenant.last_name}
                                        </Text>
                                        <Text style={{ fontSize: 10 }}>
                                            -------------------------------------------------------------
                                        </Text>
                                    </View>
                                    <View style={{ marginBottom: 15 }}>
                                        <Text style={{ fontSize: 10 }}>TENANT</Text>
                                    </View>
                                    <View style={{ marginBottom: 30 }}>
                                        <Text style={{ fontSize: 10 }}>Dated:</Text>
                                        <Text style={{ fontSize: 10, color: '#3673B1' }}>
                                            15 August 2023
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    };
    return (
        <PDFViewer style={styles.pdfViewer}>
            <ContractDocument />
        </PDFViewer>
    );
};
