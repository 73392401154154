import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OxButton from '../../components/shared/OxButton';
import { useGetIndustriesQuery, useGetFeaturesQuery } from '../../Redux/apiSlice';

function LicenseTypeModal({
    show,
    onHide,
    modalText,
    licenseType,
    handleCreateLicenseType,
    handleUpdateLicenseType,
    handleChange,
    handleFeatureChange,
    onFeatureSelect,
}) {
    //get industries
    const {
        data: industryData,
        error,
        isLoading,
        refetch: refechIndustries,
    } = useGetIndustriesQuery();

    //get features

    const { data: featureData, refetch: refechFeatures } = useGetFeaturesQuery();

    //industries array state
    const [industries, setIndustry] = React.useState([]);

    //features array state

    const [features, setFeature] = React.useState([]);

    useEffect(() => {
        if (industryData) {
            setIndustry(industryData);
        }
        if (featureData) {
            setFeature(featureData);
        }
    }, [industryData, featureData]);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group controlId='formBasicName'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter name'
                            name='name'
                            value={licenseType.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicDescription'>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter description'
                            name='description'
                            value={licenseType.description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicCode'>
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter Code'
                            name='code'
                            value={licenseType.code}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicPrice'>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Enter price'
                            name='price'
                            value={licenseType.price}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId='formBasicIndustry'>
                        <Form.Label>Industry</Form.Label>
                        <Form.Control
                            as='select'
                            name='industry'
                            value={licenseType.industry}
                            onChange={handleChange}>
                            {industries.map((industry) => (
                                <option value={industry._id}>{industry.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <br />

                    <Form.Group controlId='formBasicFeatures'>
                        <Form.Label>Features</Form.Label>
                        <br />
                        {features.map((feature) => (
                            <>
                                <lable>{feature.name}</lable>
                                <input
                                    type='checkbox'
                                    label={feature.name}
                                    name='features'
                                    value={feature._id}
                                    checked={licenseType.features.includes(feature._id)}
                                    onChange={onFeatureSelect(feature._id)}
                                />
                            </>
                        ))}
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <OxButton
                    action={licenseType._id ? handleUpdateLicenseType : handleCreateLicenseType}
                    text={licenseType._id ? 'Update' : 'Create'}
                />
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LicenseTypeModal;
