import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetCompanyItemsQuery,
    useGetRequisitionOrderItemsQuery,
    useCreateRequisitionOrderItemMutation,
    useUpdateRequisitionOrderItemMutation,
    useDeleteRequisitionOrderItemMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import PurchaseRequisitionItemModal from './PurchaseRequisitionItemModal';

import { toast } from 'react-toastify';
import DeletePopupModal from '../general/DeletePopupModal';

const PurchaseRequisitionItems = ({ purchaseRequisitionId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const { data: items, isLoading: itemsLoading } = useGetCompanyItemsQuery(companyId);
    const {
        data: purchaseRequisitionItems,
        isLoading: purchaseRequisitionItemsLoading,
        refetch: refetchPurchaseRequisitionItems,
    } = useGetRequisitionOrderItemsQuery(purchaseRequisitionId);

    const [newItem, setNewItem] = useState({
        requisition_order: purchaseRequisitionId,
        item: '',
        cost_price: 0,
        quantity: 1,
    });

    const [createPurchaseRequisitionItem, createPurchaseRequisitionItemResult] =
        useCreateRequisitionOrderItemMutation();
    const [updatePurchaseRequisitionItem, updatePurchaseRequisitionItemResult] =
        useUpdateRequisitionOrderItemMutation();
    const [deletePurchaseRequisitionItem, deletePurchaseRequisitionItemResult] =
        useDeleteRequisitionOrderItemMutation();

    const [purchaseRequisitionItemsArray, setPurchaseRequisitionItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalPurchaseRequisitionItem, setModalPurchaseRequisitionItem] = useState({
        requisition_order: purchaseRequisitionId,
        item: '',
        cost_price: 0,
        quantity: 1,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalPurchaseRequisitionItem(item);
    };

    // Handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalPurchaseRequisitionItem({
            requisition_order: purchaseRequisitionId,
            item: '',
            cost_price: 0,
            quantity: 1,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        const item = items.find((item) => item._id === selectedItemId);

        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItemId,
            cost_price: item.cost_price,
        }));
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalPurchaseRequisitionItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    // Handle update item
    const handleUpdateItem = () => {
        updatePurchaseRequisitionItem(modalPurchaseRequisitionItem);
        setShowModal(false);
        setModalPurchaseRequisitionItem({
            requisition_order: purchaseRequisitionId,
            item: '',
            quantity: 1,
        });
    };

    // Handle delete item
    const handleDeleteItem = () => {
        deletePurchaseRequisitionItem(modalPurchaseRequisitionItem._id);
        setShowDeleteModal(false);
        setModalPurchaseRequisitionItem({
            requisition_order: purchaseRequisitionId,
            item: '',
            quantity: 1,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            const itemExists = purchaseRequisitionItems.find((item) => item.item === newItem.item);
            if (itemExists) {
                const updatedItem = {
                    ...itemExists,
                    quantity: itemExists.quantity + newItem.quantity,
                };
                updatePurchaseRequisitionItem(updatedItem);
            } else {
                createPurchaseRequisitionItem(newItem);
            }
            setNewItem({
                requisition_order: purchaseRequisitionId,
                item: '',
                quantity: 1,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (purchaseRequisitionItems) {
            refetchPurchaseRequisitionItems();
        }
        if (createPurchaseRequisitionItemResult.data) {
            refetchPurchaseRequisitionItems();
        }
        if (createPurchaseRequisitionItemResult.isSuccess) {
            toast.success('Purchase Requisition Item Created Successfully');
        }
        if (deletePurchaseRequisitionItemResult.data) {
            refetchPurchaseRequisitionItems();
        }
        if (deletePurchaseRequisitionItemResult.isSuccess) {
            toast.success('Purchase Requisition Item Deleted Successfully');
        }
        if (updatePurchaseRequisitionItemResult.data) {
            refetchPurchaseRequisitionItems();
        }
        if (updatePurchaseRequisitionItemResult.isSuccess) {
            toast.success('Purchase Requisition Item Updated Successfully');
        }
    }, [
        purchaseRequisitionItems,
        purchaseRequisitionItemsLoading,
        createPurchaseRequisitionItemResult.isLoading,
        updatePurchaseRequisitionItemResult.isLoading,
        deletePurchaseRequisitionItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Purchase Requisition Item');
            setModalPurchaseRequisitionItem({});
        } else if (modalType === 'edit' && item) {
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalPurchaseRequisitionItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalPurchaseRequisitionItem({
            requisition_order: purchaseRequisitionId,
            item: '',
            quantity: 1,
        });
    };

    if (
        itemsLoading ||
        purchaseRequisitionItemsLoading ||
        createPurchaseRequisitionItemResult.isLoading ||
        updatePurchaseRequisitionItemResult.isLoading ||
        deletePurchaseRequisitionItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='20%'>Item</th>
                        <th width='20%'>Volume</th>
                        <th width='20%'>Cost Price</th>
                        <th width='20%'>Total</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {purchaseRequisitionItems &&
                        purchaseRequisitionItems.map((item, index) => (
                            <tr key={index}>
                                <td>{getItemName(item.item)}</td>
                                <td>{item.quantity}L</td>
                                <td>N${item.cost_price}</td>
                                <td>N${item.cost_price * item.quantity}</td>

                                <td>
                                    <Button
                                        variant='primary'
                                        onClick={() => openModal('edit', item)}>
                                        Edit
                                    </Button>{' '}
                                    <Button
                                        variant='danger'
                                        onClick={() => handleShowDeleteModal(item)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select New Item
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <PurchaseRequisitionItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                purchaseRequisitionItem={modalPurchaseRequisitionItem}
                handleChange={handleChange}
                handleUpdatePurchaseRequisitionItem={handleUpdateItem}
            />
            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={'Purchase Requisition Item'}
            />
        </div>
    );
};

export default PurchaseRequisitionItems;
