/**
 * local-storage-variables.ts
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

const USER = 'user';
const AUTH_TOKEN = 'token';
const LOGOUT = 'logout';
const DEFAULTERS = 'defaulters';
const FILES = 'files';
const TENANT_APPLICATIONS = 'tenant_application';
const LICENSE_TYPES = 'license_types';
const RGS_LICENSE_TYPES = 'rgs_license_types';
const REGISTRATION_LICENSE_TYPE = 'registration_license_type';
const COMPANIES = 'companies';
const PROPERTIES = 'properties';
const TENANTS = 'tenants';
const CUSTOMERS = 'customers';
const CONTRACTS = 'contracts';

export const LOCAL_STORAGE_ENUMS = {
    DEFAULTERS,
    FILES,
    LOGOUT,
    USER,
    AUTH_TOKEN,
    TENANT_APPLICATIONS,
    LICENSE_TYPES,
    RGS_LICENSE_TYPES,
    REGISTRATION_LICENSE_TYPE,
    COMPANIES,
    PROPERTIES,
    TENANTS,
    CUSTOMERS,
    CONTRACTS,
};
