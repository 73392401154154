import React from 'react';
import Side_nav from '../../../shared_components/side_nav';
import Top_nav from '../../../shared_components/top_nav';
import Footer from '../../../shared_components/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function FuelManagement() {
    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <Tabs>
                                <TabList>
                                    <Tab>Delivery Notes</Tab>
                                    <Tab>Purchase Orders</Tab>
                                    <Tab>Recievings</Tab>
                                </TabList>
                                <TabPanel></TabPanel>
                                <TabPanel></TabPanel>
                                <TabPanel></TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default FuelManagement;
