/**
 * NewPaymentForm.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  account: Yup.string().required('Required'), // Assuming account is passed as an ID (string)
});

import { 
    useCreatePaymentMethodMutation,
    useUpdatePaymentMethodMutation,
    useGetAssetsAccountsQuery,
    
} from '@redux/apiSlice';
import { toast } from 'react-toastify';
import Spinner from '@components/shared/Spinner';
import { useSelector } from 'react-redux';

function NewPaymentForm({payment, handleClose, refetch }) {

  const company = useSelector((state) => state.company);

  const [createPayment, createPaymentResult] = useCreatePaymentMethodMutation();
  const [updatePayment, updatePaymentResult] = useUpdatePaymentMethodMutation();
  const { data: assetsAccounts, isLoading: isLoadingAssetsAccounts } = useGetAssetsAccountsQuery(company._id);

  const handleSubmit = (values) => {
    if (payment._id) {
        updatePayment({
            _id: payment._id,
            name: values.name,
            description: values.description,
            account: values.account,
        });
    } else {
        createPayment({
            name: values.name,
            description: values.description,
            account: values.account,
            company: company._id,
        });
    }
  };

  useEffect(() => {
    if (createPaymentResult.isSuccess) {
        formik.resetForm();
        toast.success('Payment created successfully');
        refetch();
        handleClose();
    }

    if (createPaymentResult.isError) {
        toast.error('Something went wrong');
    }

    if (updatePaymentResult.isSuccess) {
        formik.resetForm();
        toast.success('Payment updated successfully');
        refetch();
        handleClose();
    }

    if (updatePaymentResult.isError) {
        toast.error('Something went wrong');
    }
  }, [createPaymentResult.isLoading, updatePaymentResult.isLoading]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      account: '',
      ...payment,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  if (createPaymentResult.isLoading || updatePaymentResult.isLoading) {
    return <Spinner />;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      {/* Name */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormControl
          type="text"
          id="name"
          {...formik.getFieldProps('name')}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-danger">{formik.errors.name}</div>
        ) : null}
      </FormGroup>

      {/* Description */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="description">Description</FormLabel>
        <FormControl
          type="text"
          id="description"
          {...formik.getFieldProps('description')}
        />
        {formik.touched.description && formik.errors.description ? (
          <div className="text-danger">{formik.errors.description}</div>
        ) : null}
      </FormGroup>

     
       {/* Destionation Account */}
       <FormGroup className="mb-3">
        <FormLabel htmlFor="account">Destination Account</FormLabel>
        <FormControl
            as="select"
            id="account"
            {...formik.getFieldProps('account')}
        >
            <option value="">Select Destination Account</option>
            {assetsAccounts && assetsAccounts.map((account) => (
                <option key={account._id} value={account._id}>{account.name}</option>
            ))}
        </FormControl>
        {formik.touched.account && formik.errors.account && (
            <div className="text-danger">{formik.errors.account}</div>
        )}
    </FormGroup>

      <Button type="submit">Submit</Button>
    </Form>
  );
}

export default NewPaymentForm;
