import React, { useState, useMemo } from 'react';
import { Table, Button, Pagination } from 'react-bootstrap';
import { saveAs } from 'file-saver';

const OxygenTable = ({ data, columns, pageSize = 10 }) => {
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const sortedData = useMemo(() => {
    const sortableData = [...data];
    if (sortField) {
      sortableData.sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sortableData;
  }, [data, sortField, sortDirection]);

  const exportToCsv = () => {
    let csvContent = columns.map(col => col.label).join(',') + "\n";

    sortedData.forEach(row => {
      csvContent += columns.map(col => row[col.field]).join(',') + "\n";
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "OxygenTable.csv");
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const paginatedData = sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div>
      <Button onClick={exportToCsv}>Export to CSV</Button>
      <br />
      <hr />
      <Table striped responsive>
        <thead>
          <tr>
            {columns.map(col => (
              <th 
                key={col.field} 
                onClick={() => handleSort(col.field)}
                style={{ width: col.width || 'auto' }}  // Set the width here
              >
                {col.label} {sortField === col.field ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((row, index) => (
            <tr key={index}>
              {columns.map(col => 
                <td key={col.field} style={{ width: col.width || 'auto' }}>{row[col.field]}</td>  // Set the width here
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {[...Array(Math.ceil(data.length / pageSize))].map((_, index) => (
          <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => setCurrentPage(index + 1)}>
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default OxygenTable;
