/**
 * number.utils.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

'use strict';

export function formatNumberToPrice(number) {
    const formattedNumber = parseFloat(number)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return formattedNumber;
}
