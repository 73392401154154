import React from 'react';
import { Modal, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';
import { useGetCompanyItemsQuery, useGetTerminalsQuery } from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';

function DeliveryNoteItemModal({
    show,
    onHide,
    modalText,
    deliveryNoteItem,
    handleUpdateDeliveryNoteItem,
    handleChange,
}) {
    const company = useSelector((state) => state.company);

    const {

        data: itemsData,
        error: itemsError,
        isLoading: itemsLoading,
        refetch: refetchItems,

    } = useGetCompanyItemsQuery(company._id);
    
    const {
        data: terminalsData,
        error: terminalsError,
        isLoading: terminalsLoading,
        refetch: refetchTerminals,
    } = useGetTerminalsQuery(company._id);
    /**
    meter_readings_before:0,
            meter_readings_after:0,
             */
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Pump</Form.Label>
                        <Form.Control
                            as='select'
                            name='terminal'
                            value={deliveryNoteItem.terminal}
                            onChange={handleChange}>
                            <option value=''>Select Pump</option>
                            {terminalsData &&
                                terminalsData.map((pump) => (
                                    <option key={pump._id} value={pump._id}>
                                        {pump.terminal_no}
                                    </option>
                                ))}
                            {/* Render options for tanks */}
                        </Form.Control>
                    </Form.Group>
                   <Form.Group>
                        <Form.Label>Meter Readings Before</Form.Label>
                        <Form.Control
                            type='number'
                            name='meter_readings_before'
                            value={deliveryNoteItem.meter_readings_before}
                            onChange={handleChange}
                            placeholder='Meter Readings Before'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Volume</Form.Label>
                        <Form.Control
                            type='number'
                            name='quantity'
                            value={deliveryNoteItem.quantity}
                            onChange={handleChange}
                            placeholder='Quantity'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Meter Readings After</Form.Label>
                        <Form.Control
                            type='number'
                            name='meter_readings_after'
                            value={deliveryNoteItem.meter_readings_after}
                            onChange={handleChange}
                            placeholder='Meter Readings Before'
                        />
                    </Form.Group>
            
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>
                    <OxButton text='Update' action={handleUpdateDeliveryNoteItem} />
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default DeliveryNoteItemModal;
