/**
 * defaulter-status.enum.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

const UNDER_INVESTIGATION = 'UNDER INVESTIGATION';
const ACTIVE = 'ACTIVE';
const WHITELIST = 'RESOLVED';

export const DEFAULTER_STATUS = {
    UNDER_INVESTIGATION,
    ACTIVE,
    WHITELIST,
};

export const DEFAULTER_STATUS_ENUMS = [UNDER_INVESTIGATION, ACTIVE, WHITELIST];
