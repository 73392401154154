import { createSlice } from '@reduxjs/toolkit';

export const personSlice = createSlice({
    name: 'person',
    initialState: {
        first_name: '',
        last_name: '',
        gender: '',
        dob: undefined,
        id_number: '',
        phone_number: '',
        email: '',
        residencial_address: '',
        postal_address: '',
        country: '',
        created_at: undefined,
        deleted: false,
    },
    reducers: {
        setPerson: (state, action) => {
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.gender = action.payload.gender;
            state.dob = action.payload.dob;
            state.id_number = action.payload.id_number;
            state.phone_number = action.payload.phone_number;
            state.email = action.payload.email;
            state.residencial_address = action.payload.residencial_address;
            state.postal_address = action.payload.postal_address;
            state.country = action.payload.country;
            state.created_at = action.payload.created_at;
            state.deleted = action.payload.deleted;
        },

        removePerson: (state) => {
            state.first_name = '';
            state.last_name = '';
            state.gender = '';
            state.dob = undefined;
            state.id_number = '';
            state.phone_number = '';
            state.email = '';
            state.residencial_address = '';
            state.postal_address = '';
            state.country = '';
            state.created_at = undefined;
            state.deleted = false;
        },
    },
});

export const personAction = personSlice.actions;
