import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import { companySlice } from './companySlice';
import { personSlice } from './personSlice';
import { employeeSlice } from './employeeSlice';
import { signedUserSlice } from './signedUserScilce';
import { licenseSlice } from './licenseSlice';
import { industrySlice } from './industrySlice';
import { licenseTypeSlice } from './licenseTypeSlice';

// Load the serialized state from localStorage, if available
const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('reduxState');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

// Save the state to localStorage
const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reduxState', serializedState);
    } catch (err) {
        // Handle error if needed
    }
};

// Create the Redux store with the initial state
const initialState = loadStateFromLocalStorage();

const store = configureStore({
    reducer: {
        // Add your reducers here
        user: signedUserSlice.reducer,
        company: companySlice.reducer,
        person: personSlice.reducer,
        employee: employeeSlice.reducer,
        license: licenseSlice.reducer,
        industry: industrySlice.reducer,
        licenseType: licenseTypeSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    preloadedState: initialState, // Set the initial state


});

// Save the state to localStorage on unload
window.addEventListener('beforeunload', () => {
    const state = store.getState();
    saveStateToLocalStorage(state);
});

//clear local storage on afterload
window.addEventListener('load', () => {
    localStorage.clear('reduxState');
});

export default store;
