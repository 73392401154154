import React, { useEffect, useState } from 'react';
import OxButton from '../components/shared/OxButton';
import { useLoginMutation } from '../Redux/apiSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signedUserAction } from '../Redux/signedUserScilce';
import Logo from '../components/shared/Logo';
import BlackLogo from '../assets/img/logo_transparent.png';
import Spinner from '../components/shared/Spinner';

import RGSlogo from '@assets/img/rgs/logo_transparent.png';

import { APP_MODES } from '@enums';
import { ENV } from '@config';

const { APP_MODE } = ENV;
const isOxygen = APP_MODE === APP_MODES.OXYGEN;

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, result] = useLoginMutation();
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const { username, password } = credentials;

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setCredentials((prevState) => ({ ...prevState, [id]: value }));
    };

    const handleLogin = (e) => {
        e.preventDefault();
        login(credentials);
    };

    useEffect(() => {
        localStorage.clear();

        if (result.isSuccess && result.data.success) {
            localStorage.setItem('token', result.data.token);
            localStorage.setItem('user', result.data.user);
            dispatch(signedUserAction.setSignedUser(result.data));
            navigate('/companies');
        }

        if (result.isError) {
            console.error('Login error', result);
        }
    }, [result]);

    if (result.isLoading) return <Spinner />;

    return (
        <section className='container' style={{ margin: 'auto' }}>
            <div className='row d-flex justify-content-center align-items-center'>
                <div className='col col-xl-10' style={{ margin: 'auto', marginTop: 70 }}>
                    <div className='card' style={{ borderRadius: '1rem' }}>
                        <div className='row g-0'>
                            <div className='col-md-6 col-lg-5 '>
                                <img
                                    src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp'
                                    alt='login form'
                                    className='img-fluid'
                                    width={600}
                                    style={{ borderRadius: '1rem 0 0 1rem' }}
                                />
                            </div>
                            <div className='col-md-6 col-lg-7 d-flex align-items-center'>
                                <div className='card-body p-4 p-lg-5 text-black'>
                                    <form onSubmit={handleLogin}>
                                        <div className='d-flex align-items-center mb-3'>
                                            <Logo
                                                src={isOxygen ? BlackLogo : RGSlogo}
                                                width={250}
                                            />
                                        </div>
                                        <h5 className='fw-normal mb-3'>Sign into your account</h5>
                                        <div>
                                            <label className='form-label' htmlFor='username'>
                                                Username
                                            </label>
                                            <input
                                                type='text'
                                                id='username'
                                                className='form-control form-control-lg rounded-text-input'
                                                value={username}
                                                onChange={handleInputChange}
                                                placeholder='Your username here...'
                                            />
                                        </div>
                                        <div>
                                            <label className='form-label' htmlFor='password'>
                                                Password
                                            </label>
                                            <input
                                                type='password'
                                                id='password'
                                                className='form-control form-control-lg rounded-text-input'
                                                value={password}
                                                onChange={handleInputChange}
                                                placeholder='Your password here...'
                                            />
                                        </div>
                                        <br />
                                        <div className='mb-4'>
                                            <OxButton text='LOGIN' action={handleLogin} />
                                        </div>
                                        <div className='mb-4'>
                                            <OxButton
                                                text='CREATE NEW USER ACCOUNT'
                                                action={() => navigate('/signup')}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
