/**
 * Payments.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import OxygenTable from '@components/shared/OxygenTable';
import { 
    useGetPaymentMethodsQuery,
    useDeletePaymentMethodMutation, } from '@redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '@components/shared/CardHeader';
import Spinner from '@components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { OxModal } from '@components';
import NewPaymentForm from './NewPaymentForm';

function Payments() {

    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchPayments } = useGetPaymentMethodsQuery(company._id);
    const [deletePayment, deletePaymentResult] = useDeletePaymentMethodMutation();

    const [payment, setPayment] = useState({
        name: '',
        description: '',
        account: '',
    });

    const [payments, setPaymentsArray] = useState([]);
    const [modalText, setModalText] = useState('New Payment');
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'Name', field: 'name', width: '40%' },
        { label: 'Description', field: 'description', width: '40%' },
        { label: 'Account', field: 'account', width: '10%' },
        { label: 'Actions', field: 'actions', width: '10%' }
    ];

    const paymentsWithActions = payments.map(payment => ({
        ...payment,
        account: payment.account.name,
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditPayment(payment)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeletePayment(payment._id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }));

    const handleShowPaymentModal = () => {
        setShowPaymentModal(true);
        setModalText('New Payment');
        setPayment({
            name: '',
            description: '',
            account: '',
        });
    };

    const handleClosePaymentModal = () => {
        setShowPaymentModal(false);
    };

    const handleEditPayment = (payment) => {
        setShowPaymentModal(true);
        setModalText('Edit Payment');
        setPayment(payment);
    };

    const handleDeletePayment = (paymentId) => {
        deletePayment(paymentId);
    };

    useEffect(() => {
        refetchPayments();
    }, [refetchPayments]);

    useEffect(() => {
        if (data) {
            setPaymentsArray(data);
        }

        if (deletePaymentResult.data) {
            refetchPayments();
            handleClosePaymentModal();
        }
    }, [
        data,
        deletePaymentResult.isLoading
    ]);

    if (isLoading || deletePaymentResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Payment'}
                headerTitle={'Payments'}
                headerDescription={'Manage Payments'}
                placeholder={'Search Payments'}
                searchText={searchText}
                handleSearch={setSearchText}
                handleShow={handleShowPaymentModal}
            />
            <OxygenTable
                data={paymentsWithActions}
                columns={columns}
                pageSize={10}
            />
            <OxModal 
                handleClose={handleClosePaymentModal}
                visible={showPaymentModal}
                title={modalText}
                children={<NewPaymentForm payment={payment} handleClose={handleClosePaymentModal} refetch={refetchPayments} />}
            />
        </div>
    );
}

export default Payments;
