import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const RejectPopup = ({ show, onHide, handleRejectionReasonChange, onReject, recordName }) => {
    const [reason, setReason] = useState('');

    const handleReasonChange = (e) => {
        const { value } = e.target;
        setReason(value);
        handleRejectionReasonChange(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform any necessary logic with the rejection reason
        onReject();

        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{`Reject ${recordName}`} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='reason'>
                        <Form.Label>Reason for Rejection</Form.Label>
                        <Form.Control
                            as='textarea'
                            rows={3}
                            value={reason}
                            onChange={handleReasonChange}
                            required
                        />
                    </Form.Group>
                    <br />
                    <Button variant='primary' type='submit'>
                        Reject
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RejectPopup;
