import { createSlice } from '@reduxjs/toolkit';

export const signedUserSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
        token: null,
    },

    reducers: {
        setSignedUser: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        removeSignedUser: (state) => {
            state.user = null;
            state.token = null;
        },
    },
});

export const signedUserAction = signedUserSlice.actions;
