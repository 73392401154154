/**
 * misc.util.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetters(stringText) {
    stringText = stringText.replaceAll('_', ' ');
    return stringText
        .replace(/[_\s]+/g, ' ')
        .trim()
        .toLowerCase()
        .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
}
