import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function CancelPOModal({ show, onHide, onCancel, recordName }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel {recordName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to cancel this {recordName.toLowerCase()}?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                <Button variant='danger' onClick={onCancel}>
                    Cancel {recordName}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CancelPOModal;
