import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';

const TaxRateModal = ({
    show,
    onHide,
    modalText,
    taxRate,
    handleCreateTaxRate,
    handleUpdateTaxRate,
    handleChange,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name='name'
                            value={taxRate.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            name='description'
                            value={taxRate.description}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Rate</Form.Label>
                        <Form.Control
                            name='rate'
                            type='number'
                            value={taxRate.rate}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as='select'
                            name='type'
                            value={taxRate.type}
                            onChange={handleChange}
                            required>
                            <option value='percentage'>Percentage</option>
                            <option value='amount'>Amount</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText === 'New Tax Rate' ? (
                    <OxButton action={handleCreateTaxRate} text='Create' />
                ) : (
                    <OxButton action={handleUpdateTaxRate} text='Update' />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default TaxRateModal;
