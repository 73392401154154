import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import JobCardModal from './JobCardModal';
import { toast } from 'react-toastify';

import {
    useGetCompanyEmployeesQuery,
    useGetJobCardByJobCardNoQuery,
    useGetJobCardsByQueryQuery,
    useCreateJobCardMutation,
    useUpdateJobCardMutation,
    useDeleteJobCardMutation,
    useCreateInvoiceFromJobCardMutation,
} from '../../../Redux/apiSlice';
import Side_nav from '../../../shared_components/side_nav';
import Top_nav from '../../../shared_components/top_nav';
import Footer from '../../../shared_components/footer';
import Spinner from '../../../components/shared/Spinner';
import { Modal, Form, Button, Dropdown, Badge } from 'react-bootstrap';
import Moment from 'react-moment';
import { companyAction } from '../../../Redux/companySlice';
import DeletePopupModal from '../general/DeletePopupModal';
import util from '../../../util';

function JobCards() {
    const dispatch = useDispatch();

    const company = useSelector((state) => state.company);
    const [searchText, setSearchText] = useState('');

    const [statusFilter, setStatusFilter] = useState('All');
    const [assignedToFilter, setAssignedToFilter] = useState('All');
    const [createdAtFilter, setCreatedAtFilter] = useState('today');
    const [customerFilter, setCustomerFilter] = useState('All');

    const jobCardStatuses = [
        { label: 'Not Started', value: 'Not Started' },
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Done', value: 'Done' },
        { label: 'Collected', value: 'Collected' },
        { label: 'Overdue', value: 'Overdue' },
    ];

    const default_query = util.buildQueryParams(createdAtFilter, assignedToFilter, statusFilter);

    const [filterQuery, setFilterQuery] = useState(default_query);

    const handleStatusFilter = (e) => {
        e.preventDefault();
        setStatusFilter(e.target.value);
        filter();
    };

    const handleCreatedAtFilter = (e) => {
        e.preventDefault();
        setCreatedAtFilter(e.target.value);
        filter();
    };

    const handleAssignedToFilter = (e) => {
        e.preventDefault();
        setAssignedToFilter(e.target.value);
        filter();
    };

    //handle filter function

    const {
        data: companyEmployees,
        error: companyEmployeesError,
        isLoading: companyEmployeesLoading,
        refetch: refetchCompanyEmployees,
    } = useGetCompanyEmployeesQuery(company._id);
    const {
        data: jobCardsByQuery,
        error: jobCardsByQueryError,
        isLoading: jobCardsByQueryLoading,
        refetch: refetchJobCardsByQuery,
    } = useGetJobCardsByQueryQuery({ company: company._id, query: filterQuery });
    const {
        data: jobCardByJobCardNo,
        error: jobCardByJobCardNoError,
        isLoading: jobCardByJobCardNoLoading,
        refetch: refetchJobCardByJobCardNo,
    } = useGetJobCardByJobCardNoQuery({ company: company._id, job_card_no: searchText });

    const filter = () => {
        const query = util.buildQueryParams(createdAtFilter, assignedToFilter, statusFilter);
        setFilterQuery(query);
    };

    const [createJobCard, createJobCardResult] = useCreateJobCardMutation();
    const [updateJobCard, updateJobCardResult] = useUpdateJobCardMutation();
    const [deleteJobCard, deleteJobCardResult] = useDeleteJobCardMutation();
    const [createInvoiceFromJobCard, createInvoiceFromJobCardResult] =
        useCreateInvoiceFromJobCardMutation();

    //selected job card

    const [selectedJobCard, setSelectedJobCard] = useState(null);

    //delete job card modal

    const [showDeleteJobCardModal, setShowDeleteJobCardModal] = useState(false);

    //handle show delete job card modal

    const handleShowDeleteJobCardModal = (jobCard) => (e) => {
        e.preventDefault();
        setSelectedJobCard(jobCard);
        setShowDeleteJobCardModal(true);
    };

    //handle close delete job card modal

    const handleCloseDeleteJobCardModal = () => {
        setShowDeleteJobCardModal(false);
    };

    const handleCustomerSelection = (customer) => {
        console.log(customer);

        setFilterQuery(`&&customer=${customer.value}`);
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'Not Started':
                return 'bg-secondary';
            case 'In Progress':
                return 'bg-info';
            case 'Done':
                return 'bg-success';
            case 'Collected':
                return 'bg-primary';
            case 'Overdue':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    };

    //filters for job cards

    //status filter

    const [assignedToOptions, setAssignedToOptions] = useState([]);

    const [jobCard, setJobCard] = useState({
        customer: '',
        assigned_to: '',
        job_card_no: '',
        due_date: '',
        status: 'Not Started',
        comment: 'Job Card Created Manually',
    });

    const [jobCardsArray, setJobCardsArray] = useState([]);

    const [modalText, setModalText] = useState('New Job Card');
    const [showJobCardModal, setShowJobCardModal] = useState(false);

    const handleShowJobCardModal = () => {
        setShowJobCardModal(true);
        setModalText('New Job Card');
        setJobCard({
            customer: '',
            assigned_to: '',
            job_card_no: '',
            due_date: '',
            status: 'Not Started',
            comment: 'Job Card Created Manually',
        });
    };

    const handleCloseJobCardModal = () => {
        refetchJobCardsByQuery();
        setShowJobCardModal(false);
    };

    const handleEditJobCard = (jobCard) => (e) => {
        e.preventDefault();
        setShowJobCardModal(true);
        setModalText('Edit Job Card');

        //make a copy of the jobCard object

        const jobCardCopy = { ...jobCard };

        jobCardCopy.customer = jobCard.customer._id;
        jobCard.assigned_to
            ? (jobCardCopy.assigned_to = jobCard.assigned_to._id)
            : (jobCardCopy.assigned_to = '');

        setJobCard(jobCardCopy);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        console.log(name, value);

        setJobCard((prevJobCard) => ({
            ...prevJobCard,
            [name]: value,
        }));
    };

    const handleCreateJobCard = () => {
        createJobCard({ ...jobCard, company: company._id });
    };

    const handleUpdateJobCard = () => {
        updateJobCard(jobCard);
    };

    const handleDeleteJobCard = () => {
        deleteJobCard(selectedJobCard._id);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    //seach use effect

    useEffect(() => {
        refetchJobCardByJobCardNo();
    }, [searchText]);

    useEffect(() => {
        if (jobCardByJobCardNo && searchText !== '') {
            setJobCardsArray(jobCardByJobCardNo);
        } else {
            setJobCardsArray(jobCardsByQuery);
        }
    }, [jobCardByJobCardNo]);

    //handle create invoice from job card

    const handleCreateInvoice = (jobCard) => (e) => {
        e.preventDefault();

        createInvoiceFromJobCard({
            job_card: jobCard._id,
        });
    };

    useEffect(() => {
        filter();
    }, [statusFilter, assignedToFilter, createdAtFilter, customerFilter]);

    useEffect(() => {
        console.log(filterQuery);
        refetchJobCardsByQuery();
    }, [filterQuery, createJobCardResult.data]);

    useEffect(() => {
        if (company._id) {
            refetchJobCardsByQuery();
        }
        if (jobCardsByQuery) {
            setJobCardsArray(jobCardsByQuery);
        }

        if (companyEmployees) {
            setAssignedToOptions(companyEmployees);
        }

        if (createJobCardResult.data) {
            refetchJobCardsByQuery();
            handleCloseJobCardModal();
        }

        if (createJobCardResult.error) {
            toast.error(createJobCardResult.error.message);
        }

        if (createJobCardResult.isSuccess) {
            toast.success('Job Card Successfully Created!', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }

        if (updateJobCardResult.data) {
            refetchJobCardsByQuery();

            handleCloseJobCardModal();
        }

        if (updateJobCardResult.error) {
            toast.error('Job Card Update Failed!');
        }

        if (updateJobCardResult.isSuccess) {
            toast.success('Job Card Successfully Updated!', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }

        if (deleteJobCardResult.data) {
            refetchJobCardsByQuery();
            handleCloseDeleteJobCardModal();
        }

        if (deleteJobCardResult.error) {
            toast.error('Job Card Delete Failed!');
        }

        if (deleteJobCardResult.isSuccess) {
            toast.success('Job Card Successfully Deleted!', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }

        if (createInvoiceFromJobCardResult.data) {
            console.log(createInvoiceFromJobCardResult.data);
        }

        if (createInvoiceFromJobCardResult.error) {
            toast.error('Invoice Creation Failed!');
        }

        if (createInvoiceFromJobCardResult.isSuccess) {
            if (createInvoiceFromJobCardResult.data.success) {
                toast.success('Invoice Successfully Created!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                toast.error(createInvoiceFromJobCardResult.data.message);
            }
        }
    }, [
        createJobCardResult.isLoading,
        updateJobCardResult.isLoading,
        deleteJobCardResult.isLoading,
        createInvoiceFromJobCardResult.isLoading,
        jobCardsByQueryLoading,
    ]);

    if (
        createJobCardResult.isLoading ||
        updateJobCardResult.isLoading ||
        deleteJobCardResult.isLoading ||
        createInvoiceFromJobCardResult.isLoading ||
        jobCardsByQueryLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div
                        className='card '
                        style={{
                            height: '100vh',
                        }}>
                        <CardHeader
                            buttonText={'New Job Card'}
                            handleSearch={handleSearch}
                            handleShow={handleShowJobCardModal}
                            handleDateFilterChange={handleCreatedAtFilter}
                            headerTitle={'JOB CARDS'}
                            statusFilters={jobCardStatuses}
                            employeeFilters={assignedToOptions}
                            statusFilterValue={statusFilter}
                            employeeFilterValue={assignedToFilter}
                            handleStatusFilterChange={handleStatusFilter}
                            handleEmployeeFilterChange={handleAssignedToFilter}
                            headerDescription={'Manage Job Cards'}
                            placeholder={'Job Card No'}
                            searchText={searchText}
                            handleCustomerSelect={handleCustomerSelection}
                        />

                        <div className='card-body table-full-width table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th width='10%'>Status</th>
                                        <th width='10%'>Job Card No</th>
                                        <th width='15%'>Customer</th>
                                        <th width='20%'>Assigned To</th>
                                        <th width='20%'>Created</th>
                                        <th width='20%'>Due</th>
                                        <th width='5%'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobCardsByQuery &&
                                        jobCardsByQuery.map((jobCard) => (
                                            <tr key={jobCard._id}>
                                                <td>
                                                    <span
                                                        className={`badge ${getStatusBadge(
                                                            jobCard.status
                                                        )} text-white`}>
                                                        {jobCard.status}
                                                    </span>
                                                </td>
                                                <td>{jobCard.job_card_no}</td>
                                                <td>
                                                    {jobCard.customer &&
                                                        jobCard.customer.first_name +
                                                            ' ' +
                                                            jobCard.customer.last_name}
                                                </td>
                                                <td>
                                                    {jobCard.assigned_to &&
                                                        jobCard.assigned_to.first_name +
                                                            ' ' +
                                                            jobCard.assigned_to.last_name}
                                                </td>

                                                <td>
                                                    <Moment calendar>{jobCard.created_at}</Moment>
                                                </td>
                                                <td>
                                                    <Moment calendar>{jobCard.due_date}</Moment>
                                                </td>

                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant='primary'
                                                            id='dropdown-basic'>
                                                            More
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={handleEditJobCard(
                                                                    jobCard
                                                                )}>
                                                                Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={handleCreateInvoice(
                                                                    jobCard
                                                                )}>
                                                                Convert To Invoice
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={handleShowDeleteJobCardModal(
                                                                    jobCard
                                                                )}>
                                                                Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                        <JobCardModal
                            show={showJobCardModal}
                            onHide={handleCloseJobCardModal}
                            modalText={modalText}
                            jobCard={jobCard}
                            handleCreateJobCard={handleCreateJobCard}
                            handleUpdateJobCard={handleUpdateJobCard}
                            handleChange={handleChange}
                        />

                        <DeletePopupModal
                            show={showDeleteJobCardModal}
                            onHide={handleCloseDeleteJobCardModal}
                            recordName={'Job Card'}
                            onDelete={handleDeleteJobCard}
                        />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default JobCards;
