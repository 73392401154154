/**
 * dates.constants.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

export const DATE_FORMAT = 'ccc, dd LLLL yyyy, t';
export const DATE_SHORT = 'ccc, dd LLL';
export const DATE_MID = 'ccc, dd LLLL';
export const DATE_HOUR = 't';
export const DATE_MONTH_YEAR = 'LLLL yyyy';
export const DATE_DPO_SERVICE = 'yyyy-LL-dd t';
