import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';

const JobCardItemStatusModal = ({
    show,
    onHide,
    modalText,
    jobCardItemStatus,
    handleCreateJobCardItemStatus,
    handleUpdateJobCardItemStatus,
    handleChange,
}) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        if (jobCardItemStatus._id) {
            handleUpdateJobCardItemStatus();
        } else {
            handleCreateJobCardItemStatus();
        }
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='name'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter name'
                            name='name'
                            value={jobCardItemStatus.name}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId='description'>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter description'
                            name='description'
                            value={jobCardItemStatus.description}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText === 'New Job Card Item Status' ? (
                    <OxButton text='Submit' action={handleCreateJobCardItemStatus} />
                ) : (
                    <OxButton text='Update' action={handleUpdateJobCardItemStatus} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default JobCardItemStatusModal;
