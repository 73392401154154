/**
 * side_nav.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import WhiteLogo from '../assets/img/logo_transparent-white.png';

import Logo from '@shared/Logo';
import RGSLogo from '@assets/img/rgs/logo_transparent.png';
import { ENV } from '@config';
import { APP_MODES } from '@enums';

import { useGetLicensesByCompanyQuery, useGetFeaturesQuery, useGetRolePermissionsQuery } from '../Redux/apiSlice';

import { useSelector, useDispatch } from 'react-redux';
import { licenseAction } from '../Redux/licenseSlice';
import { Button } from 'react-bootstrap';

const { APP_MODE } = ENV;

const isOxygen = APP_MODE === APP_MODES.OXYGEN;

function NavLink({ href, icon, text, onClick, isActive }) {
    return (
        <li className={`nav-item ${isActive ? 'active' : ''}`}>
            <a className='nav-link' onClick={onClick}>
                <i className={`nc-icon ${icon}`} />
                <p>{text}</p>
            </a>
        </li>
    );
}

function Side_nav() {
    const [menuVisible, setMenuVisible] = useState(true);
    const company = useSelector((state) => state.company);
    const current_employee = useSelector((state) => state.employee);
    const dispatch = useDispatch();

    const { data: permissions, refetch: refetchPermissions } = useGetRolePermissionsQuery(current_employee.role);
    const { data: licenses, refetch: refecthLicense } = useGetLicensesByCompanyQuery(company._id);
    const { data: features, refetch: refecthFeatures } = useGetFeaturesQuery();
    const [companyFeatures, setCompanyFeatures] = React.useState([]);
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (company._id) {
            refecthFeatures();
            refecthLicense();
        }

        setCompanyFeatures([]);

        if (features && licenses && permissions) {
            const employee_permssion_features_ids = permissions.map((permission) => permission.feature);
            features.map((feature) => {
                if (employee_permssion_features_ids.includes(feature._id)) {
                    setCompanyFeatures((companyFeatures) => [...companyFeatures, feature]);
                }
            });

            // sort features by sequence
            setCompanyFeatures((companyFeatures) =>
                companyFeatures.sort((a, b) => (a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0))
            );

            dispatch(licenseAction.setLicense(licenses[0]));
        }
    }, [features, licenses]);

    return (
        <div className='sidebar' data-color='blue' data-image='../assets/img/sidebar-5.jpg'>
            <div className={`sidebar-wrapper`}>
                <div className='logo'>
                    <a href='#!' className='simple-text'>
                        <Logo src={isOxygen ? WhiteLogo : RGSLogo} width={150} />
                    </a>
                </div>
                <ul className='nav'>
                    {companyFeatures.map(({ link, icon, name, _id }) => (
                        <NavLink
                            key={_id}
                            href={link}
                            icon={icon}
                            text={name}
                            onClick={() => navigate(link)}
                            isActive={location.pathname === link}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Side_nav;
