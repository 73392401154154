import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const PieChartWithPaddingAngle = ({ data }) => {
    const COLORS = ['#1E90FF', '#0052A3', '#99CCFF', '#003D7A'];
    return (
        <PieChart width={500} height={300}>
            <Pie
                data={data}
                cx={'50%'}
                cy={'50%'}
                innerRadius={60}
                outerRadius={80}
                dataKey='amount'
                nameKey='paymentMethod'>
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend />
            <Tooltip />
        </PieChart>
    );
};

export default PieChartWithPaddingAngle;
