import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OxButton from '../../../components/shared/OxButton';

import { useGetCompanyCustomersQuery, useGetCompanyEmployeesQuery } from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';
import JobCardItems from './JobCardItems';

function JobCardModal(props) {
    const {
        show,
        onHide,
        modalText,
        jobCard,
        handleCreateJobCard,
        handleUpdateJobCard,
        handleChange,
    } = props;

    const company = useSelector((state) => state.company);

    const {
        data: customerData,
        error: customerError,
        isLoading: customerLoading,
    } = useGetCompanyCustomersQuery(company._id);

    const {
        data: employeeData,
        error: employeeError,
        isLoading: employeeLoading,
    } = useGetCompanyEmployeesQuery(company._id);

    const [customers, setCustomers] = React.useState([]);

    const [employees, setEmployees] = React.useState([]);

    const [formErrors, setFormErrors] = React.useState({});

    React.useEffect(() => {
        if (customerData) {
            setCustomers(customerData);
        }

        if (employeeData) {
            setEmployees(employeeData);
        }
    }, [customerData, employeeData]);

    function formatDateForDateTimeLocal(dateString) {
        const dateObject = new Date(dateString);

        // Calculate the timezone offset in minutes
        const timezoneOffset = dateObject.getTimezoneOffset();

        // Add the timezone offset back to the date object
        const adjustedDateObject = new Date(dateObject.getTime() + timezoneOffset * 60 * 1000);

        // Convert the adjusted date object to an ISO 8601 string
        const adjustedISOString = adjustedDateObject.toISOString();

        // Remove milliseconds from the ISO 8601 string
        const localISOString = adjustedISOString.substring(0, 19);

        return localISOString;
    }

    const dateStr = jobCard._id ? jobCard.due_date : new Date();
    const formattedDate = formatDateForDateTimeLocal(dateStr);

    function validateForm() {
        const errors = {};

        if (!jobCard.customer) {
            errors.customer = 'Customer is required';
        }

        if (!jobCard.assigned_to) {
            errors.assigned_to = 'Assigned to is required';
        }

        if (!jobCard.due_date) {
            errors.due_date = 'Due date is required';
        }

        setFormErrors(errors);

        // If no errors, return true
        return Object.keys(errors).length === 0;
    }

    function handleCreateJobCardValidated() {
        if (validateForm()) {
            handleCreateJobCard();
        }
    }

    function handleUpdateJobCardValidated() {
        if (validateForm()) {
            handleUpdateJobCard();
        }
    }

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col-md-5'>
                            <Form.Group controlId='formCustomer'>
                                <Form.Label>Customer</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='customer'
                                    value={jobCard.customer}
                                    onChange={handleChange}
                                    className={formErrors.customer ? 'is-invalid' : ''}>
                                    <option value=''>Select customer</option>
                                    {customers.map((customer) => (
                                        <option key={customer._id} value={customer._id}>
                                            {customer.first_name + ' ' + customer.last_name}
                                        </option>
                                    ))}
                                </Form.Control>
                                {formErrors.customer && (
                                    <div className='invalid-feedback'>{formErrors.customer}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className='col-md-5'>
                            <Form.Group controlId='formAssignedTo'>
                                <Form.Label>Assigned To</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='assigned_to'
                                    value={jobCard.assigned_to}
                                    onChange={handleChange}
                                    className={formErrors.assigned_to ? 'is-invalid' : ''}>
                                    <option value='' disabled>
                                        Select employee
                                    </option>
                                    {employees.map((employee) => (
                                        <option key={employee._id} value={employee._id}>
                                            {employee.first_name + ' ' + employee.last_name}
                                        </option>
                                    ))}
                                </Form.Control>
                                {formErrors.assigned_to && (
                                    <div className='invalid-feedback'>{formErrors.assigned_to}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className='col-md-2'>
                            <Form.Group controlId='formJobCardNo'>
                                <Form.Label>Job Card No</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='job_card_no'
                                    disabled
                                    value={jobCard.job_card_no}
                                    onChange={handleChange}
                                    placeholder='JCB No'
                                    required
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group controlId='formAssignedTo'>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='status'
                                    value={jobCard.status}
                                    onChange={handleChange}>
                                    <option value='' disabled>
                                        Change Status
                                    </option>
                                    <option value='Not Started' disabled>
                                        Not Started
                                    </option>
                                    <option value='In Progress' disabled>
                                        In Progress
                                    </option>
                                    <option value='Done' disabled>
                                        Done
                                    </option>
                                    <option value='Overdue' disabled>
                                        Overdue
                                    </option>
                                    <option value='Collected' disabled={jobCard.status != 'Done'}>
                                        Collected
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group controlId='formJobCardNo'>
                                <Form.Label>Due date</Form.Label>
                                <Form.Control
                                    type='datetime-local'
                                    name='due_date'
                                    value={formattedDate}
                                    onChange={handleChange}
                                    className={formErrors.due_date ? 'is-invalid' : ''}
                                    required
                                />
                                {formErrors.due_date && (
                                    <div className='invalid-feedback'>{formErrors.due_date}</div>
                                )}
                            </Form.Group>
                        </div>
                    </div>
                    <br />
                    <Form.Group controlId='formComment'>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                            as='textarea'
                            name='comment'
                            rows={5}
                            value={jobCard.comment}
                            onChange={handleChange}
                            placeholder='Enter Comment'
                        />
                    </Form.Group>
                </Form>
                <br />
                {jobCard._id && <JobCardItems job_card={jobCard} />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {modalText === 'New Job Card' ? (
                    <OxButton text='Submit' action={handleCreateJobCardValidated} />
                ) : (
                    <OxButton text='Update' action={handleUpdateJobCardValidated} />
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default JobCardModal;
