import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../../../components/shared/Spinner';
import { strings } from '../../../strings/strings';
import { useGetSaleAgreementsQuery, useDeleteSaleAgreementMutation } from '../../../Redux/apiSlice';
import CardHeader from '../../../components/shared/CardHeader';
import CustomPagination from '../../../components/shared/OxygenPagination';
import SaleAgreementModal from './SaleAgreementModal';
import DeletePopupModal from '../general/DeletePopupModal';

function SaleAgreements() {
    const { _id: companyId } = useSelector((state) => state.company);

    const {
        data: saleAgreements,
        isLoading,
        refetch: refetchSaleAgreements,
    } = useGetSaleAgreementsQuery(companyId);

    const [deleteSaleAgreement, deleteSaleAgreementResult] = useDeleteSaleAgreementMutation();

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [selectedSaleAgreement, setSelectedSaleAgreement] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSaleAgreements, setFilteredSaleAgreements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Perform any other logic or data fetching based on the new page
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        // Perform any other logic or data fetching based on the new page size
    };

    const pageSizes = [25, 50, 100, 500];

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleEditSaleAgreement = (saleAgreement) => (e) => {
        e.preventDefault();

        setSelectedSaleAgreement({
            ...saleAgreement,
            customer: saleAgreement.customer?._id,
        });
        setShowModal(true);
    };

    const handleDeleteSaleAgreement = (saleAgreement) => (e) => {
        e.preventDefault();
        deleteSaleAgreement(saleAgreement._id);
    };

    const handleFilterBySearchQuery = () => {
        if (searchQuery) {
            const filteredSaleAgreements = saleAgreements.filter(
                (saleAgreement) =>
                    saleAgreement.sale_agreement_number
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    saleAgreement.customer?.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    saleAgreement.created_by?.full_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    saleAgreement.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredSaleAgreements(filteredSaleAgreements);
        } else {
            setFilteredSaleAgreements(saleAgreements);
        }
    };

    const handleFilter = () => {
        handleFilterBySearchQuery();
    };

    const handleSaleAgreementModalHide = () => {
        refetchSaleAgreements();
        setShowModal(false);
    };

    const handleSaleAgreementModalShow = () => {
        setSelectedSaleAgreement({});
        setShowModal(true);
    };

    useEffect(() => {
        if (deleteSaleAgreementResult.data) {
            toast.success('Sale Agreement deleted successfully');
            refetchSaleAgreements();
            setShowDeleteModal(false);
        }
        if (deleteSaleAgreementResult.isError) {
            toast.error('Error deleting Sale Agreement');
        }
    }, [deleteSaleAgreementResult.isLoading]);

    if (isLoading || deleteSaleAgreementResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Sale Agreement'
                handleShow={handleSaleAgreementModalShow}
                headerTitle='SALE AGREEMENTS'
                headerDescription='Manage Sale Agreements'
                searchText={searchQuery}
                handleSearch={handleSearchQueryChange}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Sale Agreement Number</th>
                            <th>Customer</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {saleAgreements &&
                            saleAgreements.map((saleAgreement) => (
                                <tr key={saleAgreement._id}>
                                    <td>{saleAgreement.sale_agreement_number}</td>
                                    <td>{saleAgreement.customer?.business_name}</td>
                                    <td>
                                        {saleAgreement.created_by?.first_name +
                                            ' ' +
                                            saleAgreement.created_by?.last_name}
                                    </td>
                                    <td>
                                        <Moment format='YYYY-MM-DD'>
                                            {saleAgreement.created_at}
                                        </Moment>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={handleEditSaleAgreement(
                                                        saleAgreement
                                                    )}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item>View</Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={handleDeleteSaleAgreement(
                                                        saleAgreement
                                                    )}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showModal && (
                <SaleAgreementModal
                    show={showModal}
                    onHide={handleSaleAgreementModalHide}
                    saleAgreementData={selectedSaleAgreement}
                />
            )}
            {showDeleteModal && (
                <DeletePopupModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onDelete={() => handleDeleteSaleAgreement(selectedSaleAgreement)}
                    recordName={'Sale Agreement'}
                />
            )}
        </div>
    );
}

export default SaleAgreements;
