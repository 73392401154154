import React, { useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import UnderConstruction from '../shared_components/UnderConstruction';
import { useSelector } from 'react-redux';
import WorkshopProducts from './industries/workshop/WorkshopProducts';
import FuelLanding from './industries/fuel/FuelLanding';

function Produts() {
    const license = useSelector((state) => state.license);

    switch (license.license_type.code) {
        case 'OWS':
            return <WorkshopProducts />;

        case 'OFD':
            return <FuelLanding />;

        default:
            return (
                <div className='wrapper'>
                    <Side_nav />
                    <div className='main-panel'>
                        {/* Navbar */}
                        <Top_nav />
                        {/* End Navbar */}
                        <div className='content'>
                            <UnderConstruction module={'Products'} />
                        </div>
                        <Footer />
                    </div>
                </div>
            );
    }
}

export default Produts;
