import { createSlice } from '@reduxjs/toolkit';

export const licenseSlice = createSlice({
    name: 'license',
    initialState: {
        license_type: {},
        company: {},
        license_start_date: '',
        license_end_date: '',
        license_status: '',
        created_at: '',
        deleted: false,
    },
    reducers: {
        setLicense: (state, action) => {
            state.license_type = action.payload.license_type;
            state.company = action.payload.company;
            state.license_start_date = action.payload.license_start_date;
            state.license_end_date = action.payload.license_end_date;
            state.license_status = action.payload.license_status;
            state.created_at = action.payload.created_at;
            state.deleted = action.payload.deleted;
        },

        removeLicense: (state) => {
            state.license_type = {};
            state.company = {};
            state.license_start_date = '';
            state.license_end_date = '';
            state.license_status = '';
            state.created_at = '';
            state.deleted = false;
        },
    },
});

export const licenseAction = licenseSlice.actions;
