import React, { useState, useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import { Modal, Button, Form } from 'react-bootstrap';

import OxButton from '../components/shared/OxButton';

import 'react-tabs/style/react-tabs.css';

import { useSelector } from 'react-redux';

import {
    useCreateCompanyExpenseMutation,
    useUpdateCompanyExpenseMutation,
    useDeleteCompanyExpenseMutation,
    useGetCompanyExpensesQuery,
    useGetExpenseAccountsQuery,
    useGetAssetsAccountsQuery,
    useGetCompanyExpenseSuppliersQuery,
    useCreateAccountMutation,
} from '../Redux/apiSlice';

import Moment from 'react-moment';
import Spinner from '../components/shared/Spinner';

function Expenses() {
    //company details
    const company = useSelector((state) => state.company);
    //user details
    const user = useSelector((state) => state.user);

    //get company expenses

    const {
        data: expensesData,
        error: expensesError,
        isLoading: expensesLoading,
        refetch: refetchExpenses,
    } = useGetCompanyExpensesQuery(company._id);

    //get company expense accounts

    const {
        data: expenseAccountsData,
        error: expenseAccountsError,
        isLoading: expenseAccountsLoading,
    } = useGetExpenseAccountsQuery(company._id);

    //get company asset accounts

    const {
        data: assetAccountsData,
        error: assetAccountsError,
        isLoading: assetAccountsLoading,
    } = useGetAssetsAccountsQuery(company._id);

    //get company expense suppliers

    const {
        data: expenseSuppliersData,
        error: expenseSuppliersError,
        isLoading: expenseSuppliersLoading,
    } = useGetCompanyExpenseSuppliersQuery(company._id);

    //expenses array

    const [expensesArray, setExpensesArray] = useState([]);

    //expense accounts array

    const [expenseAccountsArray, setExpenseAccountsArray] = useState([]);

    //asset accounts array

    const [assetAccountsArray, setAssetAccountsArray] = useState([]);

    //expense suppliers array

    const [expenseSuppliersArray, setExpenseSuppliersArray] = useState([]);

    //create company expense

    const [createCompanyExpense, createCompanyExpenseResult] = useCreateCompanyExpenseMutation();

    //update company expense

    const [updateCompanyExpense, updateCompanyExpenseResult] = useUpdateCompanyExpenseMutation();

    //delete company expense

    const [deleteCompanyExpense, deleteCompanyExpenseResult] = useDeleteCompanyExpenseMutation();

    //create transaction

    const [createTransaction, createTransactionResult] = useCreateAccountMutation();

    //state for expense form

    //expense valiables
    const [expenseId, setExpenseId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [expenseCategory, setExpenseCategory] = useState('');
    const [sourceAccount, setSourceAccount] = useState('');
    const [employee, setEmployee] = useState('');
    const [expenseCompany, setExpenseCompany] = useState('');
    const [supplier, setSupplier] = useState('');

    //expnse modal variables

    const [showExpenseModal, setShowExpenseModal] = useState(false);
    const [expenseModalText, setExpenseModalText] = useState('New Expense');

    //expense modal functions

    const handleShowExpenseModal = () => {
        //reset expense form

        setExpenseId('');
        setName('');
        setDescription('');
        setAmount('');
        setExpenseCategory('');
        setSourceAccount('');
        setEmployee('');
        setExpenseCompany('');

        //set modal text

        setExpenseModalText('New Expense');

        setShowExpenseModal(true);
    };
    const handleCloseExpenseModal = () => setShowExpenseModal(false);

    //handle edit expense

    const handleEditExpense = (expense) => (e) => {
        e.preventDefault();

        //reset expense form

        setExpenseId('');
        setName('');
        setDescription('');
        setAmount('');
        setExpenseCategory('');
        setSourceAccount('');
        setEmployee('');
        setExpenseCompany('');
        setSupplier('');

        //set expense form

        setExpenseId(expense._id);
        setName(expense.name);
        setDescription(expense.description);
        setAmount(expense.amount);
        setExpenseCategory(expense.expense_category._id);
        setSourceAccount(expense.source_account._id);
        setEmployee(expense.employee);
        setSupplier(expense.supplier);
        setExpenseCompany(expense.company);

        //set modal text

        setExpenseModalText('Edit Expense');

        //show modal

        setShowExpenseModal(true);
    };

    //handle delete expense

    const handleDeleteExpense = (expense) => (e) => {
        e.preventDefault();
        //delete expense
        deleteCompanyExpense(expense._id);
    };

    //handle create expense

    const handleCreateExpense = (e) => {
        e.preventDefault();

        //create expense

        createCompanyExpense({
            name,
            description,
            amount,
            expense_category: expenseCategory,
            source_account: sourceAccount,
            employee: user._id,
            company: company._id,
            supplier,
        });
        //close modal
        handleCloseExpenseModal();
    };

    //handle update expense

    const handleUpdateExpense = (e) => {
        e.preventDefault();

        //update expense

        updateCompanyExpense({
            _id: expenseId,
            name,
            description,
            amount,
            expense_category: expenseCategory,
            source_account: sourceAccount,
            employee: user._id,
            company: company._id,
            supplier,
        });

        //close modal

        handleCloseExpenseModal();
    };

    useEffect(() => {
        if (expensesData) {
            setExpensesArray(expensesData);
        }

        if (assetAccountsData) {
            setAssetAccountsArray(assetAccountsData);
        }

        if (expenseAccountsData) {
            setExpenseAccountsArray(expenseAccountsData);
        }

        if (expenseSuppliersData) {
            setExpenseSuppliersArray(expenseSuppliersData);
        }

        if (createCompanyExpenseResult.data) {
            refetchExpenses();
        }

        if (updateCompanyExpenseResult.data) {
            refetchExpenses();
        }

        if (deleteCompanyExpenseResult.data) {
            refetchExpenses();
        }
    }, [
        expensesLoading,
        assetAccountsLoading,
        expenseAccountsLoading,
        createCompanyExpenseResult.isLoading,
        updateCompanyExpenseResult.isLoading,
        deleteCompanyExpenseResult.isLoading,
    ]);

    if (
        expensesLoading ||
        assetAccountsLoading ||
        expenseAccountsLoading ||
        expenseSuppliersLoading ||
        createCompanyExpenseResult.isLoading ||
        updateCompanyExpenseResult.isLoading ||
        deleteCompanyExpenseResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <div className='card strpied-tabled-with-hover'>
                                <div className='card-header '>
                                    <div className='form-row'>
                                        <div className='col-md-5'>
                                            <h4 className='card-title'>Expenses</h4>
                                            <p className='card-category'>Manage Expenses</p>
                                        </div>
                                        <div className='col-md-7'>
                                            <div className='form-row'>
                                                <div className='col-md-8'>
                                                    <div className='form-group'>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Search By Expense Name'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <OxButton
                                                        text='New Expense'
                                                        action={handleShowExpenseModal}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body table-full-width table-responsive'>
                                    <table className='table table-hover table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Amount</th>
                                                <th>Expense Category</th>
                                                <th>Source Of Fund</th>
                                                <th>Created By</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expensesArray.map((expense) => (
                                                <tr key={expense._id}>
                                                    <td>
                                                        <Moment format='YYYY-MM-DD HH:mm'>
                                                            {expense.created_at}
                                                        </Moment>
                                                    </td>
                                                    <td>{expense.name}</td>
                                                    <td>{expense.description}</td>
                                                    <td>{expense.amount}</td>
                                                    <td>{expense.expense_category.name}</td>
                                                    <td>{expense.source_account.name}</td>
                                                    <td>{expense.employee}</td>
                                                    <td>
                                                        <OxButton
                                                            text='View'
                                                            action={handleEditExpense(expense)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <Modal show={showExpenseModal} onHide={handleCloseExpenseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{expenseModalText}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <fieldset>
                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='expense_name'>
                                                    Expense Name
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='expense_name'
                                                    placeholder='Expense Name'
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='expense_description'>
                                                    Expense Description
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    id='expense_description'
                                                    placeholder='Expense Description'
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='expense_amount'>
                                                    Expense Amount
                                                </Form.Label>
                                                <input
                                                    className='form-control'
                                                    type='number'
                                                    id='expense_amount'
                                                    placeholder='Expense Amount'
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='expense_category'>
                                                    Expense Category
                                                </Form.Label>
                                                <select
                                                    className='form-control'
                                                    id='expense_category'
                                                    value={expenseCategory}
                                                    onChange={(e) =>
                                                        setExpenseCategory(e.target.value)
                                                    }>
                                                    <option value='Select Expense Category'>
                                                        Select Expense Category
                                                    </option>
                                                    {expenseAccountsArray.map((expenseCategory) => (
                                                        <option
                                                            key={expenseCategory._id}
                                                            value={expenseCategory._id}>
                                                            {expenseCategory.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='expense_supplier'>
                                                    Expense Supplier
                                                </Form.Label>
                                                <select
                                                    className='form-control'
                                                    id='expense_supplier'
                                                    value={supplier}
                                                    onChange={(e) => setSupplier(e.target.value)}>
                                                    <option value='Select Expense Supplier'>
                                                        Select Expense Supplier
                                                    </option>
                                                    {expenseSuppliersArray.map(
                                                        (expenseSupplier) => (
                                                            <option
                                                                key={expenseSupplier._id}
                                                                value={expenseSupplier._id}>
                                                                {expenseSupplier.business_name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </Form.Group>

                                            {/**Source of fund for expense */}

                                            <Form.Group className='mb-3'>
                                                <Form.Label htmlFor='expense_source_of_fund'>
                                                    Expense Source of Fund
                                                </Form.Label>
                                                <select
                                                    className='form-control'
                                                    id='expense_source_of_fund'
                                                    value={sourceAccount}
                                                    onChange={(e) =>
                                                        setSourceAccount(e.target.value)
                                                    }>
                                                    <option value='Select Expense Source of Fund'>
                                                        Select Expense Source of Fund
                                                    </option>
                                                    {assetAccountsArray.map((assetAccount) => (
                                                        <option
                                                            key={assetAccount._id}
                                                            value={assetAccount._id}>
                                                            {assetAccount.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                        </fieldset>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='secondary' onClick={handleCloseExpenseModal}>
                                        Close
                                    </Button>
                                    {expenseModalText === 'New Expense' ? (
                                        <OxButton text='Submit' action={handleCreateExpense} />
                                    ) : (
                                        <></>
                                    )}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Expenses;
