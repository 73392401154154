import React, { useEffect } from 'react';
import { useResetPasswordMutation } from '../Redux/apiSlice';
import { useSelector } from 'react-redux';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import OxButton from '../components/shared/OxButton';

function User() {
    //reset password variables
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const userState = useSelector((state) => state.user);

    const [resetPassword, resetPasswordResult] = useResetPasswordMutation();

    const handleResetPassword = (e) => {
        e.preventDefault();

        //check if passwords match
        if (password === confirmPassword) {
            resetPassword({
                username: userState.user.username,
                password,
            });
        } else {
            alert('Passwords do not match');
        }
    };

    useEffect(() => {
        if (resetPasswordResult.isSuccess) {
            alert('Password reset successful');
        }
    }, [resetPasswordResult.isLoading]);

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='col-md-6' style={{ margin: 'auto' }}>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-12 text-center'>
                                        <h4>{userState.user.username}</h4>
                                        <hr />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <form>
                                            <div className='form-group row'>
                                                <label
                                                    htmlFor='website'
                                                    className='col-4 col-form-label'>
                                                    New Password
                                                </label>
                                                <div className='col-8'>
                                                    <input
                                                        id='website'
                                                        name='website'
                                                        value={password}
                                                        onChange={(e) =>
                                                            setPassword(e.target.value)
                                                        }
                                                        placeholder='New Password'
                                                        className='form-control here'
                                                        type='password'
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <label
                                                    htmlFor='website'
                                                    className='col-4 col-form-label'>
                                                    New Password
                                                </label>
                                                <div className='col-8'>
                                                    <input
                                                        id='website'
                                                        name='website'
                                                        value={confirmPassword}
                                                        onChange={(e) =>
                                                            setConfirmPassword(e.target.value)
                                                        }
                                                        placeholder='Confirm Password'
                                                        className='form-control here'
                                                        type='password'
                                                    />
                                                </div>
                                            </div>

                                            <div className='form-group row'>
                                                <div className='offset-4 col-8'>
                                                    <OxButton
                                                        action={handleResetPassword}
                                                        text='Reset Password'
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default User;
