import React from 'react';
import { Modal } from 'react-bootstrap';

const PreviewInvoice = ({ link, show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size='xl' centered>
            <Modal.Header closeButton>
                <Modal.Title>Preview Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe src={link} title='Full Page' width='100%' height='1000px' />
            </Modal.Body>
        </Modal>
    );
};

export default PreviewInvoice;
