import React from 'react';

function Logo({ src, width = 200 }) {
    return (
        <div>
            <img src={src} alt='logo' style={{ width }} />
        </div>
    );
}

export default Logo;
