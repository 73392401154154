import React, { useState, useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

import { Modal, Button, Form, ModalFooter } from 'react-bootstrap';

import OxButton from '../components/shared/OxButton';

import { useSelector } from 'react-redux';

import Moment from 'react-moment';
import {
    useGetAccountTypesQuery,
    useGetCompanyAccountsByAccountTypeQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    useDeleteAccountMutation,
    useGetCompanyTransactionsQuery,
    useCreateTransactionMutation,
    useGenerateAccountStatementMutation,
} from '../Redux/apiSlice';
import Spinner from '../components/shared/Spinner';
import util from '../util';

function Accounting() {
    const companyState = useSelector((state) => state.company);
    const userState = useSelector((state) => state.user);

    const [accountTypes, setAccountTypes] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [transactions, setTransactions] = useState([]);

    //get account types

    const {
        data: accountTypesData,
        error: accountTypesError,
        isLoading: accountTypesLoading,
    } = useGetAccountTypesQuery(companyState._id);

    //get transactions

    const {
        data: transactionsData,
        error: transactionsError,
        isLoading: transactionsLoading,
        refetch: refethTransactions,
    } = useGetCompanyTransactionsQuery(companyState._id);

    //generate account statement

    const [generateAccountStatement, generateAccountStatementResult] =
        useGenerateAccountStatementMutation();

    //date rnage variables

    const [startDate, setStartDate] = useState('');

    const [endDate, setEndDate] = useState('');

    //set show date range modal

    const [showDateRangeModal, setShowDateRangeModal] = useState(false);

    //handle close date range modal

    const handleCloseDateRangeModal = () => setShowDateRangeModal(false);

    //handle show date range modal

    const handleShowDateRangeModal = (account) => (e) => {
        e.preventDefault();

        //set selected account

        setSelectedAccount(account);

        setShowDateRangeModal(true);
    };

    //showStatmentModal

    const [showStatementModal, setShowStatementModal] = useState(false);

    //handle close statement modal

    const handleCloseStatementModal = () => setShowStatementModal(false);

    //handle show statement modal

    const handleShowStatementModal = () => setShowStatementModal(true);

    //handle generate account statement

    //acount statement

    const [accountStatement, setAccountStatement] = useState([]);

    const handleGenerateAccountStatement = (e) => {
        e.preventDefault();

        //close date range modal

        handleCloseDateRangeModal();

        handleShowStatementModal();

        generateAccountStatement({
            account_id: selectedAccount._id,
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
        });
    };

    //create a function that converts a given date to this format: 2020-12-31

    const formatDate = (d) => {
        const date = new Date(d);
        const year = date.getFullYear();

        const month = date.getMonth() + 1;

        const day = date.getDate();

        //add 0 to month and day if they are less than 10
        let stringMonth = `${month}`;
        let stringDay = `${day}`;
        if (month < 10) {
            stringMonth = `0${month}`;
        }

        if (day < 10) {
            stringDay = `0${day}`;
        }

        return `${year}-${stringMonth}-${stringDay}`;
    };

    //acount variables

    const [account_id, setAccountId] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountDescription, setAccountDescription] = useState('');
    const [accountBalance, setAccountBalance] = useState(0);
    const [accountBlocked, setAccountBlocked] = useState(false);
    const [accountType, setAccountType] = useState('');
    const [accountOwner, setAccountOwner] = useState('');

    //transaction variables

    /**
     * sale_id: {type: mongoose.Schema.Types.ObjectId, ref: 'Sale'},
    description: {type: String},
    amount: {type: Number, required: true},
    account_credited_id: {type: mongoose.Schema.Types.ObjectId, ref: 'Account'},
    account_debited_id: {type: mongoose.Schema.Types.ObjectId, ref: 'Account'},
    transaction_trigger: {type: String, required: true},
    company: {type: mongoose.Schema.Types.ObjectId, ref: 'Company'},
    created_at: {type: Date, default: () => { return new Date() }},
    deleted: {type:Boolean, default: false}
     */

    const [transaction_id, setTransactionId] = useState('');
    const [transactionDescription, setTransactionDescription] = useState('');
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [transactionAccountCreditedId, setTransactionAccountCreditedId] = useState('');
    const [transactionAccountDebitedId, setTransactionAccountDebitedId] = useState('');
    const [transactionTrigger, setTransactionTrigger] = useState('Manual Entry');
    const [transactionCompanyId, setTransactionCompanyId] = useState('');

    //account modal variables

    const [showAccountModal, setShowAccountModal] = useState(false);
    const [accountModalText, setAccountModalText] = useState('Create Account');

    //transaction modal variables

    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [transactionModalText, setTransactionModalText] = useState('Create Transaction');

    //handle close account modal

    const handleCloseAccountModal = () => setShowAccountModal(false);

    //handle close transaction modal

    const handleCloseTransactionModal = () => setShowTransactionModal(false);

    //selected account

    const [selectedAccount, setSelectedAccount] = useState({});

    //handle show transaction modal

    const handleShowTransactionModal = () => {
        setShowTransactionModal(true);
        setTransactionModalText('New Transaction');

        //reset transaction variables

        setTransactionDescription('');
        setTransactionAmount(0);
        setTransactionAccountCreditedId('');
        setTransactionAccountDebitedId('');
        setTransactionCompanyId('');
    };

    //handle create transaction

    const [createTransaction, createTransactionResult] = useCreateTransactionMutation();

    const handleCreateTransaction = (e) => {
        e.preventDefault();

        createTransaction({
            description: transactionDescription,
            amount: transactionAmount,
            account_credited_id: transactionAccountCreditedId,
            account_debited_id: transactionAccountDebitedId,
            transaction_trigger: transactionTrigger,
            company: companyState._id,
            user: userState._id,
        });
    };

    //handle show account modal

    const handleShowAccountModal = () => {
        setShowAccountModal(true);
        setAccountModalText('New Account');

        //reset account variables

        setAccountName('');
        setAccountDescription('');
        setAccountBalance(0);
        setAccountBlocked(false);
        setAccountType('');
        setAccountOwner('');
    };

    //handle edit account modal

    const handleEditAccountModal = (account) => (e) => {
        e.preventDefault();

        setShowAccountModal(true);
        setAccountModalText('Edit Account');

        //reset account variables

        setAccountName('');
        setAccountDescription('');
        setAccountBalance(0);
        setAccountBlocked(false);
        setAccountType('');
        setAccountOwner('');

        //set account variables

        setAccountName(account.name);
        setAccountDescription(account.description);
        setAccountBalance(account.balance);
        setAccountBlocked(account.blocked);
        setAccountType(account.account_type._id);
        setAccountOwner(account.owner);
    };

    //handle create account

    const [createAccount, createAccountResult] = useCreateAccountMutation();

    const handleCreateAccount = () => {
        //create account

        createAccount({
            name: accountName,
            description: accountDescription,
            balance: accountBalance,
            blocked: accountBlocked,
            account_type: accountType,
            owner: userState._id,
            company: companyState._id,
        });

        handleCloseAccountModal();
    };

    //handle update account

    const [updateAccount, updateAccountResult] = useUpdateAccountMutation();

    const handleUpdateAccount = () => {
        //update account

        updateAccount({
            _id: account_id,
            name: accountName,
            description: accountDescription,
            balance: accountBalance,
            blocked: accountBlocked,
            accountType,
            owner: accountOwner,
            company: companyState._id,
        });

        handleCloseAccountModal();
    };

    //handle delete account

    const [deleteAccount, deleteAccountResult] = useDeleteAccountMutation();

    const handleDeleteAccount = (id) => (e) => {
        //delete account

        e.preventDefault();

        deleteAccount(id);
    };

    //get accounts

    //account type id

    const [accountTypeId, setAccountTypeId] = useState('');

    const {
        data: accountsData,
        error: accountsError,
        isLoading: accountsLoading,
        refetch: refetchAccounts,
    } = useGetCompanyAccountsByAccountTypeQuery({
        account_type: accountTypeId,
        company: companyState._id,
    });

    useEffect(() => {
        if (companyState._id) {
            refetchAccounts();
            refethTransactions();
        }

        if (accountTypesData) {
            setAccountTypes(accountTypesData);

            //set account type id

            setAccountTypeId(accountTypesData[0]._id);

            console.log(accountTypesData);

            refetchAccounts();
        }

        if (accountsData) {
            console.log(accountsData);
            setAccounts(accountsData);
        }

        if (createAccountResult.data) {
            console.log(createAccountResult.data);
            refetchAccounts();
        }

        if (updateAccountResult.data) {
            console.log(updateAccountResult.data);
            refetchAccounts();
        }

        if (deleteAccountResult.data) {
            console.log(deleteAccountResult.data);
            refetchAccounts();
        }

        if (transactionsData) {
            console.log(transactionsData);
            setTransactions(transactionsData);
        }

        if (createTransactionResult.data) {
            console.log(createTransactionResult.data);
            refethTransactions();
            refetchAccounts();
            handleCloseTransactionModal();
        }

        if (generateAccountStatementResult.data) {
            console.log(generateAccountStatementResult.data);
            setAccountStatement(generateAccountStatementResult.data);
        }
    }, [
        accountTypesData,
        accountsData,
        transactionsData,
        createAccountResult.isLoading,
        updateAccountResult.isLoading,
        deleteAccountResult.isLoading,
        createTransactionResult.isLoading,
        generateAccountStatementResult.isLoading,
        refetchAccounts,
        refethTransactions,
    ]);

    if (
        accountTypesLoading ||
        accountsLoading ||
        transactionsLoading ||
        createAccountResult.isLoading ||
        updateAccountResult.isLoading ||
        deleteAccountResult.isLoading ||
        createTransactionResult.isLoading ||
        generateAccountStatementResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <Tabs>
                                <TabList variant='tabs'>
                                    <Tab>Transactions</Tab>
                                    <Tab>Charts Of Accounts</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='card strpied-tabled-with-hover'>
                                        <div className='card-header '>
                                            <div className='form-row'>
                                                <div className='col-md-5'>
                                                    <h4 className='card-title'>Transactions</h4>
                                                    <p className='card-category'>
                                                        NB: only create your transactions manually
                                                        if you know what you are doing!
                                                    </p>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='form-row'>
                                                        <div className='col-md-9'></div>
                                                        <div className='col-md-3'>
                                                            <OxButton
                                                                text='New Transaction'
                                                                action={handleShowTransactionModal}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <table className='table table-hover '>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Description</th>
                                                        <th>Acc Debited</th>
                                                        <th>Acc Credited</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactions.map((transaction) => (
                                                        <tr key={transaction._id}>
                                                            <td>
                                                                <Moment format='YYYY-MM-DD HH:mm'>
                                                                    {transaction.created_at}
                                                                </Moment>
                                                            </td>
                                                            <td>{transaction.description}</td>
                                                            <td>
                                                                {
                                                                    transaction.account_debited_id
                                                                        .name
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    transaction.account_credited_id
                                                                        .name
                                                                }
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {util.formatCurrency(
                                                                        transaction.amount
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <Modal
                                        show={showTransactionModal}
                                        onHide={handleCloseTransactionModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{transactionModalText}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <fieldset>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='trans_description'>
                                                            Description
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='trans_description'
                                                            placeholder='Transaction Decription'
                                                            value={transactionDescription}
                                                            onChange={(e) =>
                                                                setTransactionDescription(
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='trans_amount'>
                                                            Amount
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='number'
                                                            id='trans_amount'
                                                            placeholder='Transaction Amount'
                                                            value={transactionAmount}
                                                            onChange={(e) =>
                                                                setTransactionAmount(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_debited'>
                                                            Account Debited
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            id='account_debited'
                                                            value={transactionAccountDebitedId}
                                                            onChange={(e) =>
                                                                setTransactionAccountDebitedId(
                                                                    e.target.value
                                                                )
                                                            }>
                                                            {accounts.map((account) => (
                                                                <option
                                                                    key={account._id}
                                                                    value={account._id}>
                                                                    {account.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>

                                                    {/**account credited */}

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_credited'>
                                                            Account Credited
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            id='account_credited'
                                                            value={transactionAccountCreditedId}
                                                            onChange={(e) =>
                                                                setTransactionAccountCreditedId(
                                                                    e.target.value
                                                                )
                                                            }>
                                                            {accounts.map((account) => (
                                                                <option
                                                                    key={account._id}
                                                                    value={account._id}>
                                                                    {account.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant='secondary'
                                                onClick={handleCloseTransactionModal}>
                                                Close
                                            </Button>
                                            <OxButton
                                                text='Submit'
                                                action={handleCreateTransaction}
                                            />
                                        </Modal.Footer>
                                    </Modal>
                                </TabPanel>
                                <TabPanel>
                                    <div className='card strpied-tabled-with-hover'>
                                        <div className='card-header '>
                                            <div className='form-row'>
                                                <div className='col-md-5'>
                                                    <h4 className='card-title'> Accounts</h4>
                                                    <p className='card-category'></p>
                                                </div>
                                                <div className='col-md-7'>
                                                    <div className='form-row'>
                                                        <div className='col-md-9'></div>
                                                        <div className='col-md-3'>
                                                            <OxButton
                                                                text='New Account'
                                                                action={handleShowAccountModal}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body table-full-width table-responsive'>
                                            <table className='table table-hover table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Account Type</th>
                                                        <th>Balance</th>
                                                        <th>Description</th>
                                                        <th>Statement</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/**map accounts */}

                                                    {accounts && accounts.map((account) => (
                                                        <tr key={account._id}>
                                                            <td>{account.name}</td>
                                                            <td>{account.account_type?.name}</td>
                                                            <td>{account.balance}</td>
                                                            <td>{account.description}</td>
                                                            <td>
                                                                <OxButton
                                                                    text='Statement'
                                                                    action={handleShowDateRangeModal(
                                                                        account
                                                                    )}
                                                                />
                                                            </td>
                                                            <td>
                                                                <OxButton
                                                                    text='Edit'
                                                                    action={handleEditAccountModal(
                                                                        account
                                                                    )}
                                                                />
                                                            </td>
                                                            <td>
                                                                <OxButton
                                                                    text='Delete'
                                                                    action={handleDeleteAccount(
                                                                        account._id
                                                                    )}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <Modal show={showAccountModal} onHide={handleCloseAccountModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{accountModalText}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <fieldset>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='role_name'>
                                                            Account Name*
                                                        </Form.Label>
                                                        <Form.Control
                                                            id='role_name'
                                                            value={accountName}
                                                            onChange={(e) =>
                                                                setAccountName(e.target.value)
                                                            }
                                                            placeholder='Account name'
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_description'>
                                                            Account Description
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            id='role_description'
                                                            value={accountDescription}
                                                            onChange={(e) =>
                                                                setAccountDescription(
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder='Description'
                                                        />
                                                    </Form.Group>

                                                    {/**Account blocked field */}

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_blocked'>
                                                            Account Blocked
                                                        </Form.Label>
                                                        <input
                                                            type='checkbox'
                                                            id='role_description'
                                                            value={accountBlocked}
                                                            onChange={(e) =>
                                                                setAccountBlocked(e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>

                                                    {/**Account type field */}

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_type'>
                                                            Account Type
                                                        </Form.Label>
                                                        <select
                                                            className='form-control'
                                                            aria-label='Account Type'
                                                            value={accountType}
                                                            onChange={(e) =>
                                                                setAccountType(e.target.value)
                                                            }>
                                                            <option value=''></option>
                                                            {accountTypes.map((account_type) => (
                                                                <option
                                                                    key={`${account_type._id}drop-down`}
                                                                    value={account_type._id}>
                                                                    {account_type.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>

                                                    {/**account balance  */}
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_balance'>
                                                            Account Balance
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            disabled
                                                            type='number'
                                                            id='role_description'
                                                            value={accountBalance}
                                                            placeholder='Balance'
                                                        />
                                                    </Form.Group>

                                                    {/**account owner  with an input text*/}

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='account_owner'>
                                                            Account Owner
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            disabled
                                                            type='text'
                                                            id='role_description'
                                                            value={accountOwner}
                                                            placeholder='Account Owner'
                                                        />
                                                    </Form.Group>
                                                </fieldset>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant='secondary'
                                                onClick={handleCloseAccountModal}>
                                                Close
                                            </Button>
                                            {accountModalText === 'New Account' ? (
                                                <OxButton
                                                    text='Submit'
                                                    action={handleCreateAccount}
                                                />
                                            ) : (
                                                <OxButton
                                                    text='Update'
                                                    action={handleUpdateAccount}
                                                />
                                            )}
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal
                                        show={showDateRangeModal}
                                        onHide={handleCloseDateRangeModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Period</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <fieldset>
                                                    {/**start date */}
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='start_date_'>
                                                            Start Date
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='date'
                                                            id='start_date_'
                                                            value={startDate}
                                                            onChange={(e) =>
                                                                setStartDate(e.target.value)
                                                            }
                                                            placeholder='Start Date'
                                                        />
                                                    </Form.Group>

                                                    {/**end date */}

                                                    <Form.Group className='mb-3'>
                                                        <Form.Label htmlFor='end_date_'>
                                                            End Date
                                                        </Form.Label>
                                                        <input
                                                            className='form-control'
                                                            type='date'
                                                            id='end_date_'
                                                            value={endDate}
                                                            onChange={(e) =>
                                                                setEndDate(e.target.value)
                                                            }
                                                            placeholder='End Date'
                                                        />
                                                    </Form.Group>
                                                </fieldset>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant='secondary'
                                                onClick={handleCloseDateRangeModal}>
                                                Close
                                            </Button>
                                            <OxButton
                                                text='Submit'
                                                action={handleGenerateAccountStatement}
                                            />
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal
                                        show={showStatementModal}
                                        onHide={() => setShowStatementModal(false)}
                                        dialogClassName='modal-90w'
                                        size='lg'
                                        aria-labelledby='example-custom-modal-styling-title'>
                                        <Modal.Header closeButton>
                                            <Modal.Title id='example-custom-modal-styling-title'>
                                                Account Statement
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='float-end'>
                                                                        <button className='btn btn-primary'>
                                                                            Print
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                {/* check if selected account is not an empty object and display account details  */}

                                                                {Object.keys(selectedAccount)
                                                                    .length !== 0 && (
                                                                    <div className='col-md-6 '>
                                                                        <h6>
                                                                            Account Name:{' '}
                                                                            {selectedAccount.name}
                                                                        </h6>
                                                                        <h6>
                                                                            Account Type:{' '}
                                                                            {
                                                                                selectedAccount
                                                                                    .account_type
                                                                                    .name
                                                                            }
                                                                        </h6>
                                                                        <h6>
                                                                            Account Balance:{' '}
                                                                            {
                                                                                selectedAccount.balance
                                                                            }
                                                                        </h6>
                                                                    </div>
                                                                )}

                                                                <div className='col-md-6'>
                                                                    <div className='float-end'>
                                                                        <h6>From: {startDate}</h6>
                                                                        <h6>: {endDate}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <table className='table table-bordered'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                <th>
                                                                                    Transaction
                                                                                    Description
                                                                                </th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {accountStatement.map(
                                                                                (statement) => (
                                                                                    <tr
                                                                                        key={
                                                                                            statement.transaction_id
                                                                                        }>
                                                                                        <td>
                                                                                            {
                                                                                                statement.transaction_date
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                statement.transaction_description
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            N$
                                                                                            {
                                                                                                statement.transaction_amount
                                                                                            }{' '}
                                                                                            {
                                                                                                statement.transaction_type
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant='secondary'
                                                onClick={() => setShowStatementModal(false)}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Accounting;
