import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../../../components/shared/Spinner';
import { 
    useGetDeliveryNotesQuery, 
    useDeleteDeliveryNoteMutation, 
    useApproveDeliveryNoteMutation,
    useCreateInvoiceFromDeliveryNoteMutation,
} from '../../../Redux/apiSlice';
import CardHeader from '../../../components/shared/CardHeader';
import CustomPagination from '../../../components/shared/OxygenPagination';
import DeliveryNoteModal from './DeliveryNoteModal';
import DeletePopupModal from '../general/DeletePopupModal';
import ApprovalPopupModal from '../general/ApprovalPopupModal';
import GenerateConfirmation from '../general/GenerateConfirmation';

function DeliveryNotes() {
    const { _id: companyId } = useSelector((state) => state.company);
    const { _id: employeeId } = useSelector((state) => state.employee);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-secondary';
            case 'approved':
                return 'bg-info';
            case 'viewed':
                return 'bg-warning';
            case 'partial':
                return 'bg-primary';
            case 'invoiced':
                return 'bg-success';
            case 'overdue':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    };

    const {
        data: deliveryNotes,
        isLoading,
        refetch: refetchDeliveryNotes,
    } = useGetDeliveryNotesQuery(companyId);

    const [deleteDeliveryNote, deleteDeliveryNoteResult] = useDeleteDeliveryNoteMutation();
    const [approveDeliveryNote, approveDeliveryNoteResult] = useApproveDeliveryNoteMutation();
    const [createInvoiceFromDeliveryNote, createInvoiceFromDeliveryNoteResult] = useCreateInvoiceFromDeliveryNoteMutation();

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showGenerateInvoiceConfirmModal, setShowGenerateInvoiceConfirmModal] = useState(false); 

    const [selectedDeliveryNote, setSelectedDeliveryNote] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredDeliveryNotes, setFilteredDeliveryNotes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    const pageSizes = [25, 50, 100, 500];

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleEditDeliveryNote = (deliveryNote) => (e) => {
        e.preventDefault();
        setSelectedDeliveryNote({
            ...deliveryNote
        });
        setShowModal(true);
    };

    const handleDeleteDeliveryNote = (deliveryNote) => (e) => {
        e.preventDefault();
        setSelectedDeliveryNote(deliveryNote);
        setShowDeleteModal(true);
    };

    const handleApproveDeliveryNote = (deliveryNote) => (e) => {
        e.preventDefault();
        setSelectedDeliveryNote(deliveryNote);
        setShowApproveModal(true);
    }

    const handleGenerateInvoice = (deliveryNote) => (e) => {
        e.preventDefault();
        setSelectedDeliveryNote(deliveryNote);
        setShowGenerateInvoiceConfirmModal(true);
    }

    const handleFilterBySearchQuery = () => {
        if (searchQuery) {
            const filteredDeliveryNotes = deliveryNotes.filter(
                (deliveryNote) =>
                    deliveryNote.delivery_note_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    deliveryNote.customer?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    deliveryNote.created_by?.full_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    deliveryNote.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredDeliveryNotes(filteredDeliveryNotes);
        } else {
            setFilteredDeliveryNotes(deliveryNotes);
        }
    };

    const handleFilter = () => {
        handleFilterBySearchQuery();
    };

    const handleDeliveryNoteModalHide = () => {
        refetchDeliveryNotes();
        setShowModal(false);
    };

    const handleDeliveryNoteModalShow = () => {
        setSelectedDeliveryNote({});
        setShowModal(true);
    };

    useEffect(() => {
        if (companyId) {
            refetchDeliveryNotes();
        }

        if (deleteDeliveryNoteResult.data) {
            refetchDeliveryNotes();
            setShowDeleteModal(false);
            toast.success('Delivery Note deleted successfully');
        }

        if (approveDeliveryNoteResult.isSuccess) {
            refetchDeliveryNotes();
            toast.success('Delivery Note approved successfully');
            setShowApproveModal(false);
        }

        if (createInvoiceFromDeliveryNoteResult.isSuccess) {
            refetchDeliveryNotes();
            toast.success('Invoice generated successfully');
            setShowGenerateInvoiceConfirmModal(false);
        }

        if (createInvoiceFromDeliveryNoteResult.isError) {
            toast.error('Error generating invoice');
            setShowGenerateInvoiceConfirmModal(false);
        }

        if (approveDeliveryNoteResult.isError) {

            toast.error('Error approving Delivery Note');
            setShowApproveModal(false);


        }
       
        if (deleteDeliveryNoteResult.isError) {
            toast.error('Error deleting Delivery Note');
            setShowDeleteModal(false);
        }
        
    }, [deleteDeliveryNoteResult.isLoading, approveDeliveryNoteResult.isLoading, createInvoiceFromDeliveryNoteResult.isLoading]);

    if (isLoading || deleteDeliveryNoteResult.isLoading || approveDeliveryNoteResult.isLoading || createInvoiceFromDeliveryNoteResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Delivery Note'
                handleShow={handleDeliveryNoteModalShow}
                headerTitle='DELIVERY NOTES'
                headerDescription='Manage Delivery Notes'
                searchText={searchQuery}
                handleSearch={handleSearchQueryChange}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>No#</th>
                            <th>Customer</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveryNotes &&
                            deliveryNotes.map((deliveryNote) => (
                                <tr key={deliveryNote._id}>
                                    <td>
                                        <span
                                            className={`badge ${getStatusBadge(
                                                deliveryNote.status
                                            )} text-white`}>
                                            {deliveryNote.status}
                                        </span>
                                    </td>
                                    <td>{deliveryNote.delivery_note_no}</td>
                                    <td>
                                        {deliveryNote.customer?.business_name ||
                                            deliveryNote.customer?.first_name +
                                                ' ' +
                                                deliveryNote.customer?.last_name}
                                    </td>
                                    <td>
                                        {deliveryNote.employee?.first_name +
                                            ' ' +
                                            deliveryNote.employee?.last_name}
                                    </td>
                                    <td>
                                        <Moment format='YYYY-MM-DD'>{deliveryNote.created_at}</Moment>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {deliveryNote.status === 'draft' && <Dropdown.Item
                                                    onClick={handleEditDeliveryNote(deliveryNote)}>
                                                    Edit
                                                </Dropdown.Item>}
                                                { deliveryNote.status === 'draft' && <Dropdown.Item
                                                    onClick={handleApproveDeliveryNote(deliveryNote)}>
                                                    Approve
                                                </Dropdown.Item> }
                                                { deliveryNote.status === 'approved' && <Dropdown.Item
                                                    onClick={handleGenerateInvoice(deliveryNote)}>
                                                    Generate Invoice
                                                </Dropdown.Item> }
                                                <Dropdown.Item>View</Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={handleDeleteDeliveryNote(deliveryNote)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showModal && (
                <DeliveryNoteModal
                    show={showModal}
                    onHide={handleDeliveryNoteModalHide}
                    deliveryNoteData={selectedDeliveryNote}
                />
            )}
            {showDeleteModal && (
                <DeletePopupModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    onDelete={() => deleteDeliveryNote(selectedDeliveryNote._id)}
                    recordName={'Delivery Note'}
                />
            )}

            {showApproveModal && (
                <ApprovalPopupModal
                    show={showApproveModal}
                    onHide={() => setShowApproveModal(false)}
                    onApprove={() => approveDeliveryNote({
                        delivery_note:selectedDeliveryNote._id,
                        employee:employeeId
                    })}
                    recordName={'Delivery Note'}
                />
            )}
            {showGenerateInvoiceConfirmModal && (
                <GenerateConfirmation
                    show={showGenerateInvoiceConfirmModal}
                    onHide={() => setShowGenerateInvoiceConfirmModal(false)}
                    onConfirm={() => createInvoiceFromDeliveryNote({
                            delivery_note:selectedDeliveryNote._id,
                    })}
                    recordName={'Invoice'}
                />
            )}
        </div>
    );
}

export default DeliveryNotes;
