/**
 * rgs-license-types.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

const RGS_PMC = 'RGS_PMC';
const RGS_REA = 'RGS_REA';
const RGS_LL = 'RGS_LL';
const RGS_ADM = 'RGS_ADM';

export const RGS_LICENSE_TYPES = {
    RGS_PMC,
    RGS_REA,
    RGS_LL,
    RGS_ADM,
};
