import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
    useGetStockAdjustmentsQuery,
    useCreateStockAdjustmentMutation,
    useUpdateStockAdjustmentMutation,
    useDeleteStockAdjustmentMutation,
    useApproveStockAdjustmentMutation,
} from '../../../Redux/apiSlice';
import StockAdjustmentModal from './StockAdjustmentModal';
import Moment from 'react-moment';
import ApprovalPopupModal from '../general/ApprovalPopupModal';

function StockAdjustments() {
    const company = useSelector((state) => state.company);

    const employee = useSelector((state) => state.employee);

    // Get stock adjustments
    const {
        data: adjustments,
        error,
        isLoading,
        refetch: refetchStockAdjustments,
    } = useGetStockAdjustmentsQuery(company._id);

    // Create stock adjustment
    const [createStockAdjustment, createStockAdjustmentResult] = useCreateStockAdjustmentMutation();

    // Update stock adjustment
    const [updateStockAdjustment, updateStockAdjustmentResult] = useUpdateStockAdjustmentMutation();

    // Delete stock adjustment
    const [deleteStockAdjustment, deleteStockAdjustmentResult] = useDeleteStockAdjustmentMutation();

    // Approve stock adjustment
    const [approveStockAdjustment, approveStockAdjustmentResult] =
        useApproveStockAdjustmentMutation();

    // Stock adjustment state
    const [stockAdjustment, setStockAdjustment] = React.useState({
        tank: '',
        volume: 0,
        reason: '',
    });

    // Stock adjustments array state
    const [stockAdjustments, setStockAdjustments] = React.useState([]);

    // Modal text
    const [modalText, setModalText] = React.useState('New Stock Adjustment');

    // Stock Adjustment Modal state
    const [showStockAdjustmentModal, setShowStockAdjustmentModal] = React.useState(false);

    //set show Approve modal

    const [showApproveModal, setShowApproveModal] = React.useState(false);

    // Handle show stock adjustment modal
    const handleShowStockAdjustmentModal = () => {
        setShowStockAdjustmentModal(true);
        setModalText('New Stock Adjustment');
        setStockAdjustment({
            tank: '',
            volume: 0,
            reason: '',
        });
    };

    // Handle close stock adjustment modal
    const handleCloseStockAdjustmentModal = () => {
        setShowStockAdjustmentModal(false);
    };

    // Handle edit stock adjustment
    const handleEditStockAdjustment = (stockAdjustment) => (e) => {
        e.preventDefault();
        setShowStockAdjustmentModal(true);
        setModalText('Edit Stock Adjustment');
        setStockAdjustment(stockAdjustment);
    };

    // Search text
    const [searchText, setSearchText] = React.useState('');

    // Handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    const handleShowApproveModal = (stockAdjustment) => (e) => {
        e.preventDefault();
        setShowApproveModal(true);
        setStockAdjustment(stockAdjustment);
    };

    const handleCloseApproveModal = () => {
        setShowApproveModal(false);
    };

    // Handle approve stock adjustment

    const handleApproveStockAdjustment = () => {
        approveStockAdjustment({
            stock_adjustment: stockAdjustment._id,
            employee: employee._id,
        });
        handleCloseApproveModal();
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStockAdjustment((prevStockAdjustment) => ({
            ...prevStockAdjustment,
            [name]: value,
        }));
    };

    // Handle create stock adjustment
    const handleCreateStockAdjustment = () => {
        createStockAdjustment(stockAdjustment);
    };

    // Handle update stock adjustment
    const handleUpdateStockAdjustment = () => {
        updateStockAdjustment(stockAdjustment);
    };

    // Handle delete stock adjustment
    const handleDeleteStockAdjustment = (stockAdjustment) => (e) => {
        e.preventDefault();
        deleteStockAdjustment(stockAdjustment._id);
    };

    useEffect(() => {
        if (company._id) {
            refetchStockAdjustments();
        }

        if (approveStockAdjustmentResult.isSuccess) {
            refetchStockAdjustments();
            toast.success('Stock Adjustment approved successfully');
        }

        if (adjustments) {
            setStockAdjustments(adjustments);
        }
        if (createStockAdjustmentResult.isSuccess) {
            refetchStockAdjustments();
            handleCloseStockAdjustmentModal();
            toast.success('Stock Adjustment created successfully');
        }

        if (createStockAdjustmentResult.isError) {
            toast.error('Stock Adjustment could not be created');
        }

        if (updateStockAdjustmentResult.isSuccess) {
            refetchStockAdjustments();
            handleCloseStockAdjustmentModal();
            toast.success('Stock Adjustment updated successfully');
        }

        if (approveStockAdjustmentResult.isError) {
            toast.error('Stock Adjustment could not be approved');
        }

        if (deleteStockAdjustmentResult.isSuccess) {
            refetchStockAdjustments();
            toast.success('Stock Adjustment deleted successfully');
        }

        if (deleteStockAdjustmentResult.isError) {
            toast.error('Stock Adjustment could not be deleted');
        }
    }, [
        stockAdjustments,
        createStockAdjustmentResult.isLoading,
        updateStockAdjustmentResult.isLoading,
        deleteStockAdjustmentResult.isLoading,
        approveStockAdjustmentResult.isLoading,
    ]);

    if (
        isLoading ||
        createStockAdjustmentResult.isLoading ||
        updateStockAdjustmentResult.isLoading ||
        deleteStockAdjustmentResult.isLoading ||
        approveStockAdjustmentResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText={'New Stock Adjustment'}
                handleSearch={handleSearch}
                handleShow={handleShowStockAdjustmentModal}
                headerTitle={'STOCK ADJUSTMENTS'}
                headerDescription={'Manage Stock Adjustments'}
                placeholder={'Search Stock Adjustments'}
                searchText={searchText}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th width='10%'>Status</th>
                            <th width='10%'>Tank</th>
                            <th width='10%'>Volume</th>
                            <th width='20%'>Reason</th>
                            <th width='20%'>Recorded By</th>
                            <th width='20%'>Date Created</th>
                            <th width='20%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {adjustments &&
                            adjustments.map((stockAdjustment) => (
                                <tr key={stockAdjustment._id}>
                                    {stockAdjustment.approved ? (
                                        <td>
                                            <span className='badge badge-success'>Approved</span>
                                        </td>
                                    ) : (
                                        <td>
                                            <span className='badge badge-warning'>Pending</span>
                                        </td>
                                    )}
                                    <td>{stockAdjustment.tank?.name}</td>
                                    <td>{stockAdjustment.volume}</td>
                                    <td>{stockAdjustment.reason}</td>
                                    <td>
                                        {stockAdjustment.employee?.first_name +
                                            ' ' +
                                            stockAdjustment.employee?.last_name}
                                    </td>
                                    <td>
                                        <Moment format='YYYY-MM-DD HH:mm'>
                                            {stockAdjustment.created}
                                        </Moment>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                disabled={stockAdjustment.approved}
                                                variant='primary'
                                                id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {stockAdjustment.approved === false && (
                                                    <Dropdown.Item
                                                        onClick={handleEditStockAdjustment(
                                                            stockAdjustment
                                                        )}>
                                                        Edit
                                                    </Dropdown.Item>
                                                )}
                                                <Dropdown.Item
                                                    onClick={handleDeleteStockAdjustment(
                                                        stockAdjustment
                                                    )}>
                                                    Delete
                                                </Dropdown.Item>
                                                {stockAdjustment.approved === false && (
                                                    <Dropdown.Item
                                                        onClick={handleShowApproveModal(
                                                            stockAdjustment
                                                        )}>
                                                        Approve
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <StockAdjustmentModal
                show={showStockAdjustmentModal}
                onHide={handleCloseStockAdjustmentModal}
                modalText={modalText}
                stockAdjustment={stockAdjustment}
                handleCreateStockAdjustment={handleCreateStockAdjustment}
                handleUpdateStockAdjustment={handleUpdateStockAdjustment}
                handleChange={handleChange}
            />

            <ApprovalPopupModal
                show={showApproveModal}
                onHide={handleCloseApproveModal}
                onApprove={handleApproveStockAdjustment}
                recordName={'Stock Adjustment'}
            />
        </div>
    );
}

export default StockAdjustments;
