import React from 'react';
import { Routes, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import './App.css';
import Signup from './pages/user-registration/Signup';
import Companies from './pages/Companies';
import Company from './pages/Company';
import User from './pages/User';
import Employees from './pages/Employees';
import Suppliers from './pages/Suppliers';
import Accounting from './pages/Accounting';
import Expenses from './pages/Expenses';
import Properties from './pages/industries/real_estate_stay/Properties';
import Licenses from './pages/oxygen/Licenses';
import Billing from './pages/Billing';
import Reports from './pages/Reports';
import Sales from './pages/Sales';
import Produts from './pages/Products';
import JobCards from './pages/industries/workshop/JobCards';
import FuelManagement from './pages/industries/fuel/FuelManagement';
import RealEstateDashboard from '@real-estate/RealEstateDashboard';
import {
    LicenseSelection,
    DefaultersTable,
    TenantInfoApplication,
    CompanyRegistration,
    TenantApplicantsTable,
    ContractsTable,
} from '@real-estate';
import { ROUTES } from '@enums';
import IndustrySelection from './pages/oxygen/company_registration/IndustrySelection';
import OxygenLicenseSelection from './pages/oxygen/company_registration/OxygenLicenseSelection';
import OxygenCompanyRegistration from './pages/oxygen/company_registration/OxygenCompanyRegistration';
import Customers from './pages/customers/Customers';
const {
    REAL_ESTATE_DASHBOARD,
    PROPERTIES,
    LEASE_APPLICATION,
    DEFAULTERS,
    REAL_ESTATE_LICENSE_SELECTION,
    COMPANY_REGISTRATION,
    DASHBOARD,
    MANAGE_TENANT_APPLICANTS,
    CONTRACTS,
    INDUSTRY_SELECTION,
    OXYGEN_LICENSE_SELECTION,
    OXYGEN_COMPANY_REGISTRATION,
} = ROUTES;

function App() {
    return (
        <>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path={DASHBOARD} element={<Dashboard />} />
                <Route path='signup' element={<Signup />} />
                <Route path='companies' element={<Companies />} />
                <Route path='company' element={<Company />} />
                <Route path='user' element={<User />} />
                <Route path='employees' element={<Employees />} />
                <Route path='customers' element={<Customers />} />
                <Route path='suppliers' element={<Suppliers />} />
                <Route path='accounting' element={<Accounting />} />
                <Route path='expenses' element={<Expenses />} />
                <Route path={PROPERTIES} element={<Properties />} />
                <Route path='licenses' element={<Licenses />} />
                <Route path='billing' element={<Billing />} />
                <Route path='reports' element={<Reports />} />
                <Route path='sales' element={<Sales />} />
                <Route path='products' element={<Produts />} />
                <Route path='jobs' element={<JobCards />} />
                <Route path='fuel' element={<FuelManagement />} />
                <Route path={DEFAULTERS} element={<DefaultersTable />} />
                <Route path={REAL_ESTATE_DASHBOARD} element={<RealEstateDashboard />} />
                <Route
                    path={`${LEASE_APPLICATION}/:company_id`}
                    element={<TenantInfoApplication />}
                />
                <Route path={REAL_ESTATE_LICENSE_SELECTION} element={<LicenseSelection />} />
                <Route path={COMPANY_REGISTRATION} element={<CompanyRegistration />} />
                <Route path={MANAGE_TENANT_APPLICANTS} element={<TenantApplicantsTable />} />
                <Route path={CONTRACTS} element={<ContractsTable />} />
                <Route path={INDUSTRY_SELECTION} element={<IndustrySelection />} />
                <Route path={OXYGEN_LICENSE_SELECTION} element={<OxygenLicenseSelection />} />
                <Route path={OXYGEN_COMPANY_REGISTRATION} element={<OxygenCompanyRegistration />} />
            </Routes>
            <ToastContainer
                position='bottom-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
        </>
    );
}

export default App;
