import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetCompanyItemsQuery,
    useGetRecievingItemsQuery,
    useCreateRecievingItemMutation,
    useUpdateRecievingItemMutation,
    useDeleteRecievingItemMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import ReceivingItemModal from './ReceivingItemModal';

import { toast } from 'react-toastify';
import DeletePopupModal from '../general/DeletePopupModal';
import util from '../../../util';

const ReceivingItems = ({ receivingId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const { data: items, isLoading: itemsLoading } = useGetCompanyItemsQuery(companyId);
    const {
        data: receivingItems,
        isLoading: receivingItemsLoading,
        refetch: refetchReceivingItems,
    } = useGetRecievingItemsQuery(receivingId);

    const [newItem, setNewItem] = useState({
        item: '',
        tank: null,
        volume_ordered: 0,
        volume_recieved: 0,
        invoiced_amount: 0,
        actual_amount: 0,
        cost_price: 0,
        discount_received: 0,
        tank_volume_before: 0,
        tank_volume_after: 0,
        recieving: receivingId,
    });

    const [createReceivingItem, createReceivingItemResult] = useCreateRecievingItemMutation();
    const [updateReceivingItem, updateReceivingItemResult] = useUpdateRecievingItemMutation();
    const [deleteReceivingItem, deleteReceivingItemResult] = useDeleteRecievingItemMutation();

    const [receivingItemsArray, setReceivingItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalReceivingItem, setModalReceivingItem] = useState({
        item: '',
        tank: null,
        volume_ordered: 0,
        volume_recieved: 0,
        invoiced_amount: 0,
        actual_amount: 0,
        cost_price: 0,
        discount_received: 0,
        tank_volume_before: 0,
        tank_volume_after: 0,
        recieving: receivingId,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalReceivingItem(item);
    };

    // Handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalReceivingItem({
            item: '',
            tank: null,
            volume_ordered: 0,
            volume_recieved: 0,
            invoiced_amount: 0,
            actual_amount: 0,
            cost_price: 0,
            discount_received: 0,
            tank_volume_before: 0,
            tank_volume_after: 0,
            recieving: receivingId,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        const item = items.find((item) => item._id === selectedItemId);

        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItemId,
            cost_price: item.cost_price,
            volume_ordered: 1,
            volume_recieved: 1,
        }));
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalReceivingItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    // Handle update item
    const handleUpdateItem = () => {
        updateReceivingItem(modalReceivingItem);
        setShowModal(false);
        setModalReceivingItem({
            item: '',
            tank: null,
            volume_ordered: 0,
            volume_recieved: 0,
            invoiced_amount: 0,
            actual_amount: 0,
            cost_price: 0,
            discount_received: 0,
            tank_volume_before: 0,
            tank_volume_after: 0,
            recieving: receivingId,
        });
    };

    // Handle delete item
    const handleDeleteItem = () => {
        deleteReceivingItem(modalReceivingItem._id);
        setShowDeleteModal(false);
        setModalReceivingItem({
            item: '',
            tank: null,
            volume_ordered: 0,
            volume_recieved: 0,
            invoiced_amount: 0,
            actual_amount: 0,
            cost_price: 0,
            discount_received: 0,
            tank_volume_before: 0,
            tank_volume_after: 0,
            recieving: receivingId,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            const itemExists = receivingItems.find((item) => item.item === newItem.item);
            if (itemExists) {
                const updatedItem = {
                    ...itemExists,
                    volume_recieved: itemExists.volume_recieved + 1,
                };
                updateReceivingItem(updatedItem);
            } else {
                createReceivingItem(newItem);
            }
            setNewItem({
                item: '',
                tank: null,
                volume_ordered: 0,
                volume_recieved: 0,
                invoiced_amount: 0,
                actual_amount: 0,
                cost_price: 0,
                discount_received: 0,
                tank_volume_before: 0,
                tank_volume_after: 0,
                recieving: receivingId,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (receivingItems) {
            refetchReceivingItems();
        }
        if (createReceivingItemResult.data) {
            refetchReceivingItems();
        }
        if (createReceivingItemResult.isSuccess) {
            toast.success('Receiving Item Created Successfully');
        }
        if (deleteReceivingItemResult.data) {
            refetchReceivingItems();
        }
        if (deleteReceivingItemResult.isSuccess) {
            toast.success('Receiving Item Deleted Successfully');
        }
        if (updateReceivingItemResult.data) {
            refetchReceivingItems();
        }
        if (updateReceivingItemResult.isSuccess) {
            toast.success('Receiving Item Updated Successfully');
        }
    }, [
        receivingItems,
        receivingItemsLoading,
        createReceivingItemResult.isLoading,
        updateReceivingItemResult.isLoading,
        deleteReceivingItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Receiving Item');
            setModalReceivingItem({});
        } else if (modalType === 'edit' && item) {
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalReceivingItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalReceivingItem({
            item: '',
            tank: null,
            volume_ordered: 0,
            volume_recieved: 0,
            invoiced_amount: 0,
            actual_amount: 0,
            cost_price: 0,
            discount_received: 0,
            tank_volume_before: 0,
            tank_volume_after: 0,
            recieving: receivingId,
        });
    };

    if (
        itemsLoading ||
        receivingItemsLoading ||
        createReceivingItemResult.isLoading ||
        updateReceivingItemResult.isLoading ||
        deleteReceivingItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='20%'>Item</th>
                        <th width='20%'>Volume</th>
                        <th width='20%'>Cost Price</th>
                        <th width='20%'>Total</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {receivingItems &&
                        receivingItems.map((item, index) => (
                            <tr key={index}>
                                <td>{getItemName(item.item)}</td>
                                <td>{`${item.volume_recieved} (Liters)`}</td>
                                <td>{util.formatCurrency(item.cost_price)}</td>
                                <td>
                                    {util.formatCurrency(item.volume_recieved * item.cost_price)}
                                </td>

                                <td>
                                    <Button
                                        variant='primary'
                                        onClick={() => openModal('edit', item)}>
                                        Edit
                                    </Button>{' '}
                                    <Button
                                        variant='danger'
                                        onClick={() => handleShowDeleteModal(item)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select New Item
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <ReceivingItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                receivingItem={modalReceivingItem}
                handleChange={handleChange}
                handleUpdateReceivingItem={handleUpdateItem}
            />
            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={'Receiving Item'}
            />
        </div>
    );
};

export default ReceivingItems;
