import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetCompanyItemsQuery,
    useGetInvoiceItemsQuery,
    useGetTaxesQuery,
    useCreateInvoiceItemMutation,
    useUpdateInvoiceItemMutation,
    useDeleteInvoiceItemMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import InvoiceItemModal from './InvoiceItemModal';
import DeletePopupModal from './DeletePopupModal';
import { toast } from 'react-toastify';

const InvoiceItems = ({ invoiceId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const { data: items, isLoading: itemsLoading } = useGetCompanyItemsQuery(companyId);
    const {
        data: invoiceItems,
        isLoading: invoiceItemsLoading,
        refetch: refetchInvoiceItems,
    } = useGetInvoiceItemsQuery(invoiceId);
    const { data: taxes, isLoading: taxesLoading } = useGetTaxesQuery(companyId);

    const [newItem, setNewItem] = useState({
        sale: invoiceId,
        item: '',
        barcode: '',
        description: '',
        quantity: 0,
        cost_price: 0,
        unit_price: 0,
        discount: 0,
        discount_type: 'percentage',
        tax_amount: 0,
    });

    const [createInvoiceItem, createInvoiceItemResult] = useCreateInvoiceItemMutation();
    const [updateInvoiceItem, updateInvoiceItemResult] = useUpdateInvoiceItemMutation();
    const [deleteInvoiceItem, deleteInvoiceItemResult] = useDeleteInvoiceItemMutation();

    const [invoiceItemsArray, setInvoiceItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalInvoiceItem, setModalInvoiceItem] = useState({
        sale: invoiceId,
        item: '',
        barcode: '',
        description: '',
        quantity: 0,
        cost_price: 0,
        unit_price: 0,
        discount: 0,
        discount_type: 'percentage',
        tax_amount: 0,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalInvoiceItem(item);
    };

    //handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalInvoiceItem({
            sale: invoiceId,
            item: '',
            barcode: '',
            description: '',
            quantity: 0,
            cost_price: 0,
            unit_price: 0,
            discount: 0,
            discount_type: 'percentage',
            tax_amount: 0,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        const selectedItem = items.find((item) => item._id === selectedItemId);
        let tax_amount = 0;
        if (selectedItem.item_taxes.length > 0) {
            selectedItem.item_taxes.forEach((taxId) => {
                const taxRate = taxes.find((taxItem) => taxItem._id === taxId);
                tax_amount += (selectedItem.unit_price * taxRate.rate) / 100;
            });
        }
        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItem._id,
            barcode: selectedItem.barcode,
            description: selectedItem.description,
            cost_price: selectedItem.cost_price,
            unit_price: selectedItem.unit_price,
            quantity: 1,
            tax_amount,
        }));
    };

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalInvoiceItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    //handle update item
    const handleUpdateItem = () => {
        updateInvoiceItem(modalInvoiceItem);
        setShowModal(false);
        setModalInvoiceItem({
            sale: invoiceId,
            item: '',
            barcode: '',
            description: '',
            quantity: 0,
            cost_price: 0,
            unit_price: 0,
            discount: 0,
            discount_type: 'percentage',
            tax_amount: 0,
        });
    };

    const handleDeleteItem = () => {
        deleteInvoiceItem(modalInvoiceItem._id);
        setShowDeleteModal(false);
        setModalInvoiceItem({
            sale: invoiceId,
            item: '',
            barcode: '',
            description: '',
            quantity: 0,
            cost_price: 0,
            unit_price: 0,
            discount: 0,
            discount_type: 'percentage',
            tax_amount: 0,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            const itemExists = invoiceItems ? invoiceItems.find((item) => item.item === newItem.item) : false;
            if (itemExists) {
                const updatedItem = {
                    ...itemExists,
                    quantity: itemExists.quantity + newItem.quantity,
                };
                updateInvoiceItem(updatedItem);
            } else {
                createInvoiceItem(newItem);
            }
            setNewItem({
                sale: invoiceId,
                item: '',
                barcode: '',
                description: '',
                quantity: 0,
                cost_price: 0,
                unit_price: 0,
                discount: 0,
                discount_type: 'percentage',
                tax_amount: 0,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (invoiceItems) {
            setInvoiceItemsArray(invoiceItems);
        }
        if (createInvoiceItemResult.data) {
            refetchInvoiceItems();
        }
        if (createInvoiceItemResult.isSuccess) {
            toast.success('Invoice Item Created Successfully');
        }
        if (deleteInvoiceItemResult.data) {
            refetchInvoiceItems();
        }
        if (deleteInvoiceItemResult.isSuccess) {
            toast.success('Invoice Item Deleted Successfully');
        }
        if (updateInvoiceItemResult.data) {
            refetchInvoiceItems();
        }
        if (updateInvoiceItemResult.isSuccess) {
            toast.success('Invoice Item Updated Successfully');
        }
    }, [
        invoiceItems,
        invoiceItemsLoading,
        createInvoiceItemResult.isLoading,
        updateInvoiceItemResult.isLoading,
        deleteInvoiceItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Invoice Item');
            setModalInvoiceItem({});
        } else if (modalType === 'edit' && item) {
            const getItemName = (itemId) => {
                const item = items.find((item) => item._id === itemId);
                return item ? item.name : '';
            };
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalInvoiceItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalInvoiceItem({
            sale: invoiceId,
            item: '',
            barcode: '',
            description: '',
            quantity: 0,
            cost_price: 0,
            unit_price: 0,
            discount: 0,
            discount_type: 'percentage',
            tax_amount: 0,
        });
    };

    //calculate total

    const calculateTotal = (invoiceItem) => {
        let total = 0;
        const item_discount =
            invoiceItem.discount_type === 'percentage'
                ? invoiceItem.discount / 100
                : invoiceItem.discount;
        total += invoiceItem.quantity * invoiceItem.unit_price * (1 - item_discount);
        total += invoiceItem.tax_amount;
        return total;
    };

    if (
        itemsLoading ||
        invoiceItemsLoading ||
        taxesLoading ||
        createInvoiceItemResult.isLoading ||
        updateInvoiceItemResult.isLoading ||
        deleteInvoiceItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='30%'>Item</th>
                        <th width='20%'>Description</th>
                        <th width='5%'>Quantity</th>
                        <th width='10%'>Unit Price</th>
                        <th width='5%'>Discount</th>
                        <th width='5%'>Total</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceItemsArray.map((item, index) => (
                        <tr key={index}>
                            <td>{getItemName(item.item)}</td>
                            <td>{item.description}</td>
                            <td>{item.quantity}</td>
                            <td>{item.unit_price}</td>
                            <td>{item.discount}</td>
                            <td>{calculateTotal(item)}</td>
                            <td>
                                <Button variant='primary' onClick={() => openModal('edit', item)}>
                                    Edit
                                </Button>{' '}
                                &nbsp;
                                <Button
                                    variant='danger'
                                    onClick={() => handleShowDeleteModal(item)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select New Item
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <InvoiceItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                invoiceItem={modalInvoiceItem}
                handleChange={handleChange}
                handleUpdateInvoiceItem={handleUpdateItem}
            />
            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={'Invoice Item'}
            />
        </div>
    );
};

export default InvoiceItems;
