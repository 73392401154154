import React, { useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
import SaleLanding from './industries/general/SaleLanding';
import WorkshopSaleLanding from './industries/workshop/workshop_sales/WorkshopSaleLanding';
import { useSelector } from 'react-redux';
function Sales() {
    
        const license = useSelector((state) => state.license);

        switch (license.license_type.code) {
            case 'OWS':
                return (
                    <div className='wrapper'>
                        <Side_nav />
                        <div className='main-panel'>
                            {/* Navbar */}
                            <Top_nav />
                            {/* End Navbar */}
                            <div className='content'>
                                <WorkshopSaleLanding />
                            </div>
                            <Footer />
                        </div>
                    </div>
                );
            default:
                return (
                    <div className='wrapper'>
                        <Side_nav />
                        <div className='main-panel'>
                            {/* Navbar */}
                            <Top_nav />
                            {/* End Navbar */}
                            <div className='content'>
                                <SaleLanding />
                            </div>
                            <Footer />
                        </div>
                    </div>
                );
        }
}

export default Sales;
