/**
 * session-keys.js
 *
 * @author Erastus Nathingo <erastus.nathingo@standardbnak.com.na>
 * @copyright (c) 2020 Standard bank Namibia
 * All rights reserved
 */

export const SESSION_KEYS = ['user', 'token'];

export const LOGIN_KEYS = ['user', 'token'];
