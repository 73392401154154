import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Modal, Button, Form } from 'react-bootstrap';
import { useDeliveryNotesItemsMeterReportMutation } from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';
import MeterReadingReportModal from './MeterReadingReportModal';

const FuelSaleReport = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  //start date and end date states

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //show delivery notes items meter report modal state

  const [showDeliveryNotesItemsMeterReportModal, setShowDeliveryNotesItemsMeterReportModal] = useState(false);
  
  const company = useSelector((state) => state.company);

  const employee = useSelector((state) => state.employee);

//get meter readings
  const [getDeliveryNotesItemsMeterReport, getDeliveryNotesItemsMeterReportResult] = useDeliveryNotesItemsMeterReportMutation();

  const [deliveryNotesItemsMeterReport, setDeliveryNotesItemsMeterReport] = useState(null);

  const reports = [
    'Meter Readings',
    'Sales',
    'Delivery Notes',
    'Sales Orders',
    'Rebates',
  ];

  const handleItemClick = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReport(null);
  };

  const handleChange = (e) => {
    //set start date and end date
    const { name, value } = e.target;

    if (name === 'start_date') {
        setStartDate(value);
        return;
    }

    setEndDate(value);

  }

  const handleApply = () => {

    //validate start date and end date

    if (!startDate || !endDate) {
        return;
    }

    //close modal
    setShowModal(false);

    //apply filter to report
    switch (selectedReport) {
        case reports[0]:
            //get meter readings
            getDeliveryNotesItemsMeterReport({
                companyId: company._id,
                startDate,
                endDate,
            });
            break;
        case reports[1]:
            //get sales
            break;
        case reports[2]:
            //get delivery notes
            break;
        case reports[3]:
            //get sales orders
            break;
        case reports[4]:
    }
  }

  useEffect(() => {

    if (getDeliveryNotesItemsMeterReportResult.data) {
       setDeliveryNotesItemsMeterReport(getDeliveryNotesItemsMeterReportResult.data);
         setShowDeliveryNotesItemsMeterReportModal(true);
    }

  }, [getDeliveryNotesItemsMeterReportResult]);

  return (
    <>
      <Card style={{ borderRadius: '15px', width:"40%", margin:'auto' }}>
        <Card.Body>
          <Card.Title>Sale Reports</Card.Title>
          <ListGroup>
            {reports.map((report, index) => (
              <ListGroup.Item key={index} action onClick={() => handleItemClick(report)}>
                {report}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

   {
         showDeliveryNotesItemsMeterReportModal && (
            <MeterReadingReportModal
              show={showDeliveryNotesItemsMeterReportModal}
              handleClose={() => setShowDeliveryNotesItemsMeterReportModal(false)}
              data={deliveryNotesItemsMeterReport}
              dateRange={`${startDate} - ${endDate}`}
              reportTime={new Date().toLocaleString()}
              employee={`${employee.first_name} ${employee.last_name}`}
              reportName='Meter Readings'
            />
         )
   }

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedReport}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Form>
                <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                        type='datetime-local'
                        name='start_date'
                        value={startDate}
                        onChange={handleChange}
                        placeholder='Start Date'
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                        type='datetime-local'
                        name='end_date'
                        value={endDate}
                        onChange={handleChange}
                        placeholder='End Date'
                    />  
                </Form.Group>
           </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FuelSaleReport;
