/**
 * api.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { SessionService } from '../';
import { from } from 'rxjs';
import { _POST, _PATCH, _GET, _DELETE } from './';

export class APIService {
    /** @protected */
    sm = SessionService.create();
    /**
     * @param {string} url
     * @param {string} key
     * @param {string} id
     */
    constructor(url, key, id = 'id') {
        /** @protected */
        this.url = url;
        /** @protected */
        this._key = key;
        this.id = id;
    }

    /**
     * Adds a new item a collection and updates session
     * @param {*} item
     */
    add(item) {
        const items = this.sm.get(this._key) || [];

        if (Array.isArray(item)) {
            item = [...items, ...item]; // add multiple items
        } else {
            items.push(item);
        }
        this.update(items);
    }

    /**
     * Updates the session with new or updated content/collection
     * @param {*} items
     */
    update(items) {
        this.sm
            .$add(this._key, items)
            .then(() => {
                console.log(`${this._key} added to session`);
            })
            .catch((error) => {
                console.info(`${this._key} could not be added to session`, error);
            });
    }

    /**
     * Modifies(replaces) an existing item with new content
     * @param {*} item
     * @param {string} key
     */
    modify(item, key) {
        key = key || this.id;
        let items = this.sm.get(this._key) || [];
        if (Array.isArray(item)) {
            /** modify multiple items */
            const ids = item.map((item) => item[key]);
            items = items.filter((i) => !ids.includes(i[key]));
            items = [...items, ...item];
        } else {
            items = items.filter((i) => i[key] !== item[key]);
            items.push(item);
        }
        this.update(items);
    }

    /**
     * Updates session subject without pushing to async storage
     * @param {*} items
     */
    dispatch(items) {
        this.sm.dispatch(this._key, items);
    }

    /**
     * Stores data to the session without notifying subscribers
     * @param {*} items
     */
    store(items) {
        this.sm
            .$store(this._key, items)
            .then(() => {
                console.info(`${this._key} stored to session - no updates`);
            })
            .catch(() => {
                console.info(`${this._key} failed to store to session`);
            });
    }

    /**
     * Fetch data from the api
     */
    async $fetch(url = this.url) {
        const pattern = /\?/;
        if (!pattern.test(url)) {
            url = `${this.url}?deleted=false`;
        }
        return _GET(url);
    }

    /**
     * Fetch data from the api
     */
    fetch(url = this.url) {
        return from(this.$fetch(url));
    }

    /**
     * Async Gets data from session or fetches data from api
     */
    async $get() {
        const items = this.sm.get(this._key);
        return items
            ? {
                  key: this._key,
                  data: items,
              }
            : this.$fetchAndStore();
    }

    /**
     * Gets data from session or fetches data from api
     */
    get() {
        return from(this.$get());
    }

    /**
     * Async deletes data from session & deletes data on api
     */
    async $delete(id) {
        try {
            const deleteResponse = await _DELETE(`${this.url}/${id}`);
            console.log('DELETE URL: ', this.url);
            return deleteResponse;
        } catch (error) {
            console.warn('Delete Error response: ', error);
            return Promise.reject(error);
        }
    }

    /**
     * Deletes data from session and deletes data on api
     */
    delete(id) {
        return from(this.$delete(id));
    }

    /**
     * Async - Fetch fresh data from the api and updates the store
     */
    async $fetchAndStore() {
        try {
            const data = await this.$fetch();
            this.store(data);
            return {
                key: this._key,
                data,
            };
        } catch (error) {
            console.warn(`Getting ${this._key} error`, error);
            return Promise.reject(error);
        }
    }

    /**
     * fetch fresh data from the api and updates the store
     */
    fetchAndStore() {
        return from(this.$fetchAndStore());
    }

    async $fetchAndUpdate() {
        try {
            const data = await this.$fetch();
            if (data) {
                this.update(data);
            }
            console.info(`${this._key} data`, data);
            return data;
        } catch (error) {
            console.warn(`Getting ${this._key} error`, error);
            return Promise.reject(error);
        }
    }

    fetchAndUpdate() {
        return from(this.$fetchAndUpdate());
    }

    /**
     * Async - Adds a new item and updates session
     * @param {*} raw
     */
    async $create(raw) {
        console.info(`${this._key} url`, this.url);
        try {
            const data = await _POST(this.url, raw);
            if (data) {
                this.add(data);
            }
            return data;
        } catch (error) {
            console.warn(`${this._key} creation error`, error);
            return Promise.reject(error);
        }
    }

    /**
     * Adds a new item and updates session
     * @param {*} raw
     */
    create(raw) {
        return from(this.$create(raw));
    }

    async $patch(id, raw) {
        const url = `${this.url}/${id}`;
        try {
            const data = await _PATCH(url, raw);
            if (data) {
                this.modify(data);
            }
            // return data;
            return Promise.resolve(data);
        } catch (error) {
            console.warn(`${this._key} update error`, error);
            return Promise.reject(error);
        }
    }

    patch(id, raw) {
        return from(this.$patch(id, raw));
    }

    /**
     * Async - Fetches an item from the api and updates the session
     * @param {string|number} id
     */
    async $fetchOne(id) {
        const url = `${this.url}/${id}`;
        try {
            const data = await _GET(url);
            if (data) {
                this.modify(data);
            }
            return data;
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }

    /**
     * Fetches an item from the api and updates the session
     * @param {string|number} id
     */
    fetchOne(id) {
        return from(this.$fetchOne(id));
    }

    /**
     * Async - Returns an item if it exist in session otherwise fetches it from the api
     * @param {string|number} id
     * @param {string} key
     */
    async $getOne(id, key) {
        key = key || this.id;
        const items = this.sm.get(this._key) || [];
        const item = items.find((i) => i[key] === id);
        return item ? item : this.$fetchOne(id);
    }

    /**
     * Returns an item if it exist in session otherwise fetches it from the api
     * @param {string|number} id
     * @param {string} key
     */
    getOne(id, key) {
        return from(this.$getOne(id, key));
    }

    async $fetchSome(ids, key) {
        try {
            key = key || this.id;
            const param = ids.join(',');
            const url = `${this.url}?match=${key}=${param}`;
            const data = await _GET(url);
            if (data) {
                this.modify(data);
            }
            return data;
        } catch (error) {
            console.warn(`${this._key} fetch some error`, error);
            return Promise.reject(error);
        }
    }

    fetchSome(ids, key) {
        return from(this.$fetchSome(ids, key));
    }

    async $fetchCustom(key, param) {
        try {
            key = key || this.id;
            const url = `${this.url}?match=${key}=${param}`;
            const data = await _GET(url);
            if (data) {
                this.modify(data);
            }
            return data;
        } catch (error) {
            console.warn(`${this._key} fetch some error`, error);
            return Promise.reject(error);
        }
    }

    fetchCustom(key, param) {
        return from(this.$fetchCustom(key, param));
    }

    /**
     * Async - Fetch fresh data from the api and updates the store
     */
    async $fetchAndStoreCustom(key, param) {
        try {
            const url = `${this.url}?match=${key}=${param}`;
            const data = await this.$fetch(url);
            this.store(data);
            return {
                key: this._key,
                data,
            };
        } catch (error) {
            console.warn(`Getting ${this._key} error`, error);
            return Promise.reject(error);
        }
    }

    /**
     * fetch fresh data from the api and updates the store
     */
    fetchAndStoreCustom(key, param) {
        return from(this.$fetchAndStoreCustom(key, param));
    }

    /**
     * Async - Fetch fresh data from the api and updates the store
     */
    async $fetchAndUpdateCustom(key, param) {
        try {
            const url = `${this.url}?match=${key}=${param}`;
            const data = await this.$fetch(url);
            if (data) {
                this.update(data);
            }
            console.info(`${this._key} data`, data);
            return {
                key: this._key,
                data,
            };
        } catch (error) {
            console.warn(`Getting ${this._key} error`, error);
            return Promise.reject(error);
        }
    }

    /**
     * fetch fresh data from the api and updates the store
     */
    fetchAndUpdateCustom(key, param) {
        return from(this.$fetchAndUpdateCustom(key, param));
    }

    async $fetchByQuery(match) {
        const url = `${this.url}/custom`;
        try {
            const data = await _POST(url, match);
            if (data) {
                this.update(data);
            }
            return data;
        } catch (error) {
            console.warn(`${this._key} fetch populated error`, error);
            return Promise.reject(error);
        }
    }

    fetchByQuery(match = {}) {
        return from(this.$fetchByQuery(match));
    }
}
