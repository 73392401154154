/**
 * array.util.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

/**
 * @description sorts strings in alphabetic order by key "name"
 * @param {Array<Object>} collection array of objects to be sorted
 * @returns {Array<Object>} returns sorted array of objects
 */
export function sortByname(collection) {
    return collection.sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });
}
/**
 * @description returns true if all collections are not empty
 * @param {Array} items
 */
export function notEmpty(items) {
    items = Array.isArray(items) ? items : toArray(items);
    return items.every((item) => item.length !== 0);
}

/**
 * @description returns true if collection is empty
 * @param {Array} items
 */
export function isEmpty(collection) {
    return collection.length === 0;
}

/**
 *
 * @description groups objects in an array based on given attribute
 * @param {Array} collection array ungrouped items
 * @returns {String} attribute to be grouped by
 */
export function groupByAttribute(collection, attribute) {
    const result = collection.reduce(function (r, a) {
        r[a[attribute]] = r[a[attribute]] || [];
        r[a[attribute]].push(a);
        return r;
    }, Object.create(null));

    return result;
}

// this function allows us to const pluck = property => element => element[property]

export const pluck = (property) => (element) => element[property];

/**
 * Returns true if all items in the array are defined
 * @param {Array} items
 */
export function defined(items) {
    return items.every((item) => item !== undefined);
}

/**
 *
 * @param {Object} obj object to test emptyness for
 * @returns {Boolean} returns true if object is empty and false if object is not empty
 */
export function isObjectEmpty(obj) {
    for (const i in obj) return false;
    return true;
}
/**
 * @name sortArrayByProperty
 * @param {Array} array array to be sorted
 * @param {String} sortProperty array sort property
 * @returns {Array} returns array sorted by property
 */
export function sortArrayByProperty(array, sortProperty) {
    return array.sort((a, b) => {
        if (a[sortProperty] < b[sortProperty]) {
            return -1;
        }
        if (a[sortProperty] > b[sortProperty]) {
            return 1;
        }
        return 0;
    });
}

/**
 * @description converts Map into array
 * @param {Map} collection map collection to be converted
 * @returns {Array} array type of the Map given
 */
export function toArray(collection) {
    return Array.from(collection.values());
}

/**
 * Finds item details with given id
 * @param {string} id id value used for matching
 * @param {Array<Object>} collection collection containing the data
 */
export function findById(id, collection) {
    return Array.isArray(collection)
        ? collection.find((item) => item.id === id)
        : toArray(collection).find((item) => item.id === id);
}
