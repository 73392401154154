import React from 'react';
import { Card } from 'react-bootstrap';

function DashboardCard({ header, title, description }) {
    return (
        <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>
                <Card.Title style={{ fontSize: 50, fontWeight: 900 }}>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
            </Card.Body>
        </Card>
    );
}

export default DashboardCard;
