/**
 * company.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';
import { LOCAL_STORAGE_ENUMS } from '@enums';
const { COMPANIES } = LOCAL_STORAGE_ENUMS;

let instance;

export class CompaniesService extends APIService {
    constructor() {
        const collection = COMPANIES;
        super(collection, COMPANIES);
    }

    static create() {
        if (!instance) {
            instance = new CompaniesService();
        }
        return instance;
    }
}
