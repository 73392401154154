/**
 * files.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';

import { LOCAL_STORAGE_ENUMS } from '@enums';
import { from } from 'rxjs';

let instance;

const { FILES } = LOCAL_STORAGE_ENUMS;

export class FilesService extends APIService {
    constructor() {
        const collection = FILES;
        super(collection, FILES);
    }

    async $get(_id) {
        const url = `${this.url}/${_id}`;
        try {
            const data = await _GET(url);
            if (data) {
                this.sm.add(this._key, data);
            }
            return Promise.resolve(data);
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }

    get(_id) {
        return from(this.$get(_id));
    }

    static create() {
        if (!instance) {
            instance = new FilesService();
        }
        return instance;
    }
}
