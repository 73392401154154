/**
 * TenantApplicationInfoSchema.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import * as yup from 'yup';

const TenantApplicationInfoSchema = yup.object().shape({
    company: yup.string().required('Company is required'),
    title: yup.string(),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    nationality: yup.string().required('Nationality is required'),
    townOfBirth: yup.string().required('Town of birth is required'),
    countryOfBirth: yup.string(),
    identityNumber: yup.string().required('Identity number is required'),
    postalAddress: yup.string(),
    residentialAddress: yup.string(),
    cellPhone: yup.string().required('Cell phone is required'),
    telephone: yup.string(),
    emailAddress: yup.string().email('Invalid email').required('Email is required'),
    maritalStatus: yup.string(),
    maritalRegime: yup.string(),
    spouseFullName: yup.string(),
    employmentStatus: yup.string(),
    occupation: yup.string().required('Occupation is required'),
    employer: yup.string().required('Employer is required'),
    employerTelephone: yup.string().required('Employer telephone is required'),
    employerAddress: yup.string().required('Employer address is required'),
    taxRegistrationNumber: yup.string(),
    sourceOfFunds: yup.string(),
    bankName: yup.string().required('Bank name is required'),
    bankAccountHolder: yup.string().required('Bank account holder is required'),
    bankAccountNumber: yup.string().required('Bank account number is required'),
    branchCode: yup.string().required('Branch code is required'),
    propertyDetails: yup.string().required('Property details are required'),
    salaryNet: yup.number(),
    otherIncome: yup.number(),
    vehicle: yup.number(),
    insurance: yup.number(),
    medicalAid: yup.number(),
    pension: yup.number(),
    onlineSubscriptions: yup.number(),
    groceries: yup.number(),
    surplus: yup.number(),
    totalIncome: yup.number(),
    totalExpenditure: yup.number(),
    created_by: yup.string(),
    created_at: yup.date(),
    deleted: yup.boolean(),
});

export default TenantApplicationInfoSchema;
