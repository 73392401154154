/**
 * Customers.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect, useState } from 'react';
import OxygenTable from '@components/shared/OxygenTable';
import {
    useGetCompanyCustomersQuery,
    useDeleteCompanyCustomerMutation,
} from '@redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '@components/shared/CardHeader';
import Spinner from '@components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { OxModal } from '@components';
import NewCustomerForm from './NewCustomerForm';
import Side_nav from '../../shared_components/side_nav';
import Top_nav from '../../shared_components/top_nav';
import Footer from '../../shared_components/footer';

function Customers() {
    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchCustomers } = useGetCompanyCustomersQuery(company._id);
    const [deleteCustomer, deleteCustomerResult] = useDeleteCompanyCustomerMutation();

    const [customer, setCustomer] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        company: company._id,
    });

    const [customers, setCustomersArray] = useState([]);
    const [modalText, setModalText] = useState('New Customer');
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'First Name', field: 'first_name', width: '20%' },
        { label: 'Last Name', field: 'last_name', width: '20%' },
        { label: 'Email', field: 'email', width: '30%' },
        { label: 'Phone', field: 'phone_number', width: '20%'},
        { label: 'Actions', field: 'actions', width: '10%' },
    ];

    const customersWithActions = customers.map(cust => ({
        ...cust,
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditCustomer(cust)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteCustomer(cust._id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }));

    const handleShowCustomerModal = () => {
        setShowCustomerModal(true);
        setModalText('New Customer');
        setCustomer({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            company: company._id,
        });
    };

    const handleCloseCustomerModal = () => {
        setShowCustomerModal(false);
    };

    const handleEditCustomer = (cust) => {
        setShowCustomerModal(true);
        setModalText('Edit Customer');
        setCustomer(cust);
    };

    const handleDeleteCustomer = (customerId) => {
        deleteCustomer(customerId);
    };

    useEffect(() => {
        refetchCustomers();
    }, [refetchCustomers]);

    useEffect(() => {
        if (data) {
            setCustomersArray(data);
        }

        if (deleteCustomerResult.data) {
            refetchCustomers();
            handleCloseCustomerModal();
        }
    }, [
        data,
        deleteCustomerResult.isLoading
    ]);

    if (isLoading || deleteCustomerResult.isLoading) {
        return <Spinner />;
    }

    return (
        
         <div className='wrapper'>
         <Side_nav />
         <div className='main-panel'>
             {/* Navbar */}
             <Top_nav />
             {/* End Navbar */}
             <div className='content'>
                    <div className='container'>
                        <div className='row card'>
                        <CardHeader
                            buttonText={'New Customer'}
                            headerTitle={'Customers'}
                            headerDescription={'Manage Customers'}
                            placeholder={'Search Customers'}
                            searchText={searchText}
                            handleSearch={setSearchText}
                            handleShow={handleShowCustomerModal}
                        />
                        <OxygenTable
                            data={customersWithActions}
                            columns={columns}
                            pageSize={10}
                        />
                        <OxModal
                            handleClose={handleCloseCustomerModal}
                            visible={showCustomerModal}
                            title={modalText}
                            children={<NewCustomerForm customer={customer} handleClose={handleCloseCustomerModal} refetch={refetchCustomers} />}
                        />
                 </div>
                </div>
                </div>
             <Footer />
         </div>
     </div>
    );
}

export default Customers;
