/**
 * OxLazyImage.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const OxLazyImage = ({
    effect = 'blur',
    className = '',
    spaced = 'right',
    keyValue = null,
    src = '',
    style = {},
    onClickHandler,
    alt = '',
    width = '',
    height = '',
}) => {
    return (
        <LazyLoadImage
            effect={effect}
            className={className}
            spaced={spaced}
            key={keyValue}
            src={src}
            style={{ ...style }}
            onClick={onClickHandler ? onClickHandler : null}
            alt={alt}
            width={width}
            height={height}
        />
    );
};

OxLazyImage.propTypes = {
    effect: PropTypes.string,
    className: PropTypes.string,
    spaced: PropTypes.string,
    keyValue: PropTypes.any,
    src: PropTypes.any.isRequired,
    style: PropTypes.object,
    onClickHandler: PropTypes.func,
    alt: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any,
};
