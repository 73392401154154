import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        _id: '',
        name: '',
        registration_number: '',
        vat_number: '',
        phone_number: '',
        email: '',
        address: '',
        country: '',
        website: '',
        postal_address: '',
        employee_number_counter: 0,
        employee_number_prefix: '',
        customer_number_counter: 0,
        customer_number_prefix: '',
        supplier_number_counter: 0,
        supplier_number_prefix: '',
        job_card_number_counter: 0,
        job_card_number_prefix: '',
        invoice_number_counter: 0,
        invoice_number_prefix: '',
        quote_number_counter: 0,
        quote_number_prefix: '',
        sale_number_counter: 0,
        sale_number_prefix: '',
        purchase_order_number_counter: 0,
        purchase_order_number_prefix: '',
        default_receivable_account: '',
        default_payable_account: '',
        default_cash_account: '',
        default_bank_account: '',
        default_tax_account: '',
        default_inventory_account: '',
        default_cost_of_goods_sold_account: '',
        default_sales_account: '',
        tax_included: false,
        bulk_sms_username: '',
        bulk_sms_password: '',
        job_card_done_sms_template: '',
        bank_name: '',
        bank_branch: '',
        bank_account_number: '',
        bank_account_holder: '',
        bank_account_type: '',
        branch_code: '',
        default_invoice_comment: '',
        new_invoice_sms_template: '',
        fisical_year_start: '',
        created_by: '',
        created_at: '',
        deleted: false,
    },
    reducers: {
        setCompany: (state, action) => {
            state._id = action.payload._id;
            state.name = action.payload.name;
            state.registration_number = action.payload.registration_number;
            state.vat_number = action.payload.vat_number;
            state.phone_number = action.payload.phone_number;
            state.email = action.payload.email;
            state.address = action.payload.address;
            state.country = action.payload.country;
            state.website = action.payload.website;
            state.postal_address = action.payload.postal_address;
            state.employee_number_counter = action.payload.employee_number_counter;
            state.employee_number_prefix = action.payload.employee_number_prefix;
            state.customer_number_counter = action.payload.customer_number_counter;
            state.customer_number_prefix = action.payload.customer_number_prefix;
            state.supplier_number_counter = action.payload.supplier_number_counter;
            state.supplier_number_prefix = action.payload.supplier_number_prefix;
            state.fisical_year_start = action.payload.fisical_year_start;
            state.job_card_number_counter = action.payload.job_card_number_counter;
            state.job_card_number_prefix = action.payload.job_card_number_prefix;
            state.invoice_number_counter = action.payload.invoice_number_counter;
            state.invoice_number_prefix = action.payload.invoice_number_prefix;
            state.quote_number_counter = action.payload.quote_number_counter;
            state.quote_number_prefix = action.payload.quote_number_prefix;
            state.sale_number_counter = action.payload.sale_number_counter;
            state.sale_number_prefix = action.payload.sale_number_prefix;
            state.purchase_order_number_counter = action.payload.purchase_order_number_counter;
            state.purchase_order_number_prefix = action.payload.purchase_order_number_prefix;
            state.default_receivable_account = action.payload.default_receivable_account;
            state.default_payable_account = action.payload.default_payable_account;
            state.default_cash_account = action.payload.default_cash_account;
            state.default_bank_account = action.payload.default_bank_account;
            state.default_tax_account = action.payload.default_tax_account;
            state.default_inventory_account = action.payload.default_inventory_account;
            state.default_cost_of_goods_sold_account =
                action.payload.default_cost_of_goods_sold_account;
            state.default_sales_account = action.payload.default_sales_account;
            state.tax_included = action.payload.tax_included;
            state.bulk_sms_username = action.payload.bulk_sms_username;
            state.bulk_sms_password = action.payload.bulk_sms_password;
            state.job_card_done_sms_template = action.payload.job_card_done_sms_template;
            state.bank_name = action.payload.bank_name;
            state.bank_branch = action.payload.bank_branch;
            state.bank_account_number = action.payload.bank_account_number;
            state.bank_account_holder = action.payload.bank_account_holder;
            state.bank_account_type = action.payload.bank_account_type;
            state.branch_code = action.payload.branch_code;
            state.default_invoice_comment = action.payload.default_invoice_comment;
            state.new_invoice_sms_template = action.payload.new_invoice_sms_template;
            state.created_by = action.payload.created_by;
            state.created_at = action.payload.created_at;
            state.deleted = action.payload.deleted;
        },
        //set each field individually
        setCompanyId: (state, action) => {
            state._id = action.payload;
        },
        setCompanyName: (state, action) => {
            state.name = action.payload;
        },
        setCompanyRegistrationNumber: (state, action) => {
            state.registration_number = action.payload;
        },
        setCompanyVatNumber: (state, action) => {
            state.vat_number = action.payload;
        },
        setCompanyPhoneNumber: (state, action) => {
            state.phone_number = action.payload;
        },
        setCompanyEmail: (state, action) => {
            state.email = action.payload;
        },

        setCompanyAddress: (state, action) => {
            state.address = action.payload;
        },

        setCompanyCountry: (state, action) => {
            state.country = action.payload;
        },
        setCompanyWebsite: (state, action) => {
            state.website = action.payload;
        },
        setCompanyPostalAddress: (state, action) => {
            state.postal_address = action.payload;
        },
        setCompanyEmployeeNumberCounter: (state, action) => {
            state.employee_number_counter = action.payload;
        },
        setCompanyEmployeeNumberPrefix: (state, action) => {
            state.employee_number_prefix = action.payload;
        },
        setCompanyCustomerNumberCounter: (state, action) => {
            state.customer_number_counter = action.payload;
        },
        setCompanyCustomerNumberPrefix: (state, action) => {
            state.customer_number_prefix = action.payload;
        },
        setCompanySupplierNumberCounter: (state, action) => {
            state.supplier_number_counter = action.payload;
        },
        setCompanySupplierNumberPrefix: (state, action) => {
            state.supplier_number_prefix = action.payload;
        },
        setCompanyFisicalYearStart: (state, action) => {
            state.fisical_year_start = action.payload;
        },
        setCompanyCreatedBy: (state, action) => {
            state.created_by = action.payload;
        },
        setCompanyCreatedAt: (state, action) => {
            state.created_at = action.payload;
        },
        setCompanyDeleted: (state, action) => {
            state.deleted = action.payload;
        },
        setCompanyJobCardNumberCounter: (state, action) => {
            state.job_card_number_counter = action.payload;
        },
        setCompanyJobCardNumberPrefix: (state, action) => {
            state.job_card_number_prefix = action.payload;
        },
        setCompanyInvoiceNumberCounter: (state, action) => {
            state.invoice_number_counter = action.payload;
        },
        setCompanyInvoiceNumberPrefix: (state, action) => {
            state.invoice_number_prefix = action.payload;
        },
        setCompanyQuoteNumberCounter: (state, action) => {
            state.quote_number_counter = action.payload;
        },
        setCompanyQuoteNumberPrefix: (state, action) => {
            state.quote_number_prefix = action.payload;
        },
        setCompanyPurchaseOrderNumberCounter: (state, action) => {
            state.purchase_order_number_counter = action.payload;
        },
        setCompanyPurchaseOrderNumberPrefix: (state, action) => {
            state.purchase_order_number_prefix = action.payload;
        },
        setCompanyDefaultPayableAccount: (state, action) => {
            state.default_payable_account = action.payload;
        },
        setCompanyDefaultReceivableAccount: (state, action) => {
            state.default_receivable_account = action.payload;
        },
        setCompanyDefaultTaxAccount: (state, action) => {
            state.default_tax_account = action.payload;
        },
        setCompanyDefaultCostOfGoodsSoldAccount: (state, action) => {
            state.default_cost_of_goods_sold_account = action.payload;
        },
        setCompanyDefaultSalesAccount: (state, action) => {
            state.default_sales_account = action.payload;
        },
        setCompanyDefaultInvoiceComment: (state, action) => {
            state.default_invoice_comment = action.payload;
        },
        setCompanyNewInvoiceSmsTemplate: (state, action) => {
            state.new_invoice_sms_template = action.payload;
        },
        setCompanyBankName: (state, action) => {
            state.bank_name = action.payload;
        },
        setCompanyBankBranch: (state, action) => {
            state.bank_branch = action.payload;
        },
        setCompanyBankAccountNumber: (state, action) => {
            state.bank_account_number = action.payload;
        },
        removeCompany: (state) => {
            state._id = '';
            state.name = '';
            state.registration_number = '';
            state.vat_number = '';
            state.phone_number = '';
            state.email = '';
            state.address = '';
            state.country = '';
            state.website = '';
            state.postal_address = '';
            state.employee_number_counter = 0;
            state.employee_number_prefix = '';
            state.customer_number_counter = 0;
            state.customer_number_prefix = '';
            state.supplier_number_counter = 0;
            state.supplier_number_prefix = '';
            state.fisical_year_start = '';
            state.job_card_number_counter = 0;
            state.job_card_number_prefix = '';
            state.invoice_number_counter = 0;
            state.invoice_number_prefix = '';
            state.quote_number_counter = 0;
            state.quote_number_prefix = '';
            state.sale_number_counter = 0;
            state.sale_number_prefix = '';
            state.purchase_order_number_counter = 0;
            state.purchase_order_number_prefix = '';
            state.default_receivable_account = '';
            state.default_payable_account = '';
            state.default_cash_account = '';
            state.default_bank_account = '';
            state.default_tax_account = '';
            state.default_inventory_account = '';
            state.default_cost_of_goods_sold_account = '';
            state.default_sales_account = '';
            state.tax_included = false;
            state.bulk_sms_username = '';
            state.bulk_sms_password = '';
            state.job_card_done_sms_template = '';
            state.bank_name = '';
            state.bank_branch = '';
            state.bank_account_number = '';
            state.bank_account_holder = '';
            state.bank_account_type = '';
            state.branch_code = '';
            state.default_invoice_comment = '';
            state.new_invoice_sms_template = '';
            state.created_by = '';
            state.created_at = '';
            state.deleted = false;
        },
    },
});

export const companyAction = companySlice.actions;
