/**
 * OxygenLicenseSelection.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '@shared/Logo';
import BlackLogo from '@assets/img/logo_transparent.png';

import { useGetIndustryLicenseTypesQuery } from '@redux/apiSlice';
import Spinner from '@shared/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../../config';
import './LicenseSelection.scss';
import { colors } from '../../../strings/colors';
import util from '../../../util';
import { licenseTypeActions } from '../../../Redux/licenseTypeSlice';

const { OXYGEN_COMPANY_REGISTRATION } = ROUTES;

export const OxygenLicenseSelection = () => {

    const industry = useSelector((state) => state.industry);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { data: licenseTypes, isLoading, isError, refetch } = useGetIndustryLicenseTypesQuery(industry._id);

    useEffect(() => {
        if (isLoading) refetch();
    }, [isLoading, refetch]);

    const setLicenseType = (licenseType) => {
        dispatch(licenseTypeActions.setLicenseType(licenseType));
        navigate(`/${OXYGEN_COMPANY_REGISTRATION}`);
    };

    if (isLoading) {
        return <Spinner title='Loading License Types' />;
    }

    return (
        <Container>
            {/* Logo and Heading */}
            <Row className="justify-content-center border border-primary mt-3 pb-5">
                <Col xs={12} className="text-center">
                    <Logo width={250} src={BlackLogo} />
                </Col>
                <Col xs={12} className="text-center mt-3">
                    <h2>Pick A License For Your Business</h2>
                </Col>
            </Row>

            {/* License Cards */}
            <Row className="mt-5 d-flex justify-content-center">
                {licenseTypes && licenseTypes.map((license) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={license._id} className="mb-4">
                        <Card className="border border-primary licenseCard">
                            <Card.Header className="text-center">
                                <i className="fas fa-certificate fa-4x" style={{ color: colors.primary }}></i>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column justify-content-between">
                                <div>
                                    <Card.Title className="text-center font-weight-bold">{license.name}</Card.Title>
                                    <Card.Text className="text-center mb-3">{license.description}</Card.Text>
                                    <ul>
                                        {license.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature.description}</li>
                                        ))}
                                    </ul>
                                </div>
                                <Button variant="primary" block onClick={() => setLicenseType(license)}>
                                    Select License
                                </Button>
                            </Card.Body>
                            <Card.Footer className="text-center">
                            <p className='h4 text-center pb-2'>{`${util.formatCurrency(license.price)}`} <small className='mute'>/ Monthly</small></p> 
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default OxygenLicenseSelection;
