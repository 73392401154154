/**
 * OxPdfViewer.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { OxModal } from '../OxModal/OxModal';
import { ENV } from '@config';

const { oxygen_end_point } = ENV;

export function OxPdfViewer(props) {
    const { pdf, previewVisible, handleClose, blurContent = false } = props;
    const { filename = 'Document Name' } = pdf;
    const url = `${oxygen_end_point}/stream/${filename}`;
    return (
        <OxModal
            scrollable={false}
            title={pdf.filename}
            visible={previewVisible}
            handleClose={handleClose}
            size={'lg'}
            blurContent={blurContent}
            children={
                <IframeViewerComponent url={url} filename={filename} blurContent={blurContent} />
            }
        />
    );
}

const IframeViewerComponent = ({ url, filename, blurContent }) => {
    return (
        <iframe
            style={{
                filter: blurContent ? `blur(3px)` : '0px',
                pointerEvents: blurContent ? 'none' : 'inherit',
            }}
            id='pdfPreview'
            src={url}
            title={filename}
            height='780'
            width='100%'
            allow='fullscreen'
            align='centre'
            loading='lazy'>
            <p>Your browser does not support this view.</p>
        </iframe>
    );
};
