import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row } from 'react-bootstrap';
import ReceivingItems from './ReceivingItems';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    useGetCompanySuppliersQuery,
    useCreateRecievingMutation,
    useUpdateRecievingMutation,
    useDeleteRecievingMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import util from '../../../util';

const ReceivingModal = ({ show, onHide, receivingData }) => {
    // Company
    const company = useSelector((state) => state.company);

    // Employee
    const employee = useSelector((state) => state.employee);

    // Check if receiving data has been passed
    if (!receivingData._id) {
        receivingData = {
            _id: '',
            receiving_number: '',
            supplier: '',
            company: company._id,
            purchase_order: '',
            employee: employee._id,
            transporter: '',
            transporter_driver: '',
            fleet_no: '',
            vehicle_registration: '',
            recieving_date: new Date(),
        };
    }

    // Get suppliers
    const {
        data: suppliers,
        error: suppliersError,
        isLoading: suppliersLoading,
        refetch: refetchSuppliers,
    } = useGetCompanySuppliersQuery(company._id);

    // Receiving state
    const [receiving, setReceiving] = useState(receivingData);

    // Create receiving
    const [createReceiving, createReceivingResult] = useCreateRecievingMutation();

    // Update receiving
    const [updateReceiving, updateReceivingResult] = useUpdateRecievingMutation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReceiving((prevReceiving) => ({
            ...prevReceiving,
            [name]: value,
        }));
    };

    const handleCreateReceiving = () => {
        createReceiving(receiving);
    };

    const handleUpdateReceiving = () => {
        updateReceiving(receiving);
    };

    useEffect(() => {
        if (createReceivingResult.data) {
            setReceiving(createReceivingResult.data);
            toast.success('Receiving created successfully');
        }
        if (updateReceivingResult.data) {
            setReceiving((prevReceiving) => ({
                ...prevReceiving,
                status: updateReceivingResult.data.status,
            }));
            toast.success('Receiving updated successfully');
        }
    }, [createReceivingResult.isLoading, updateReceivingResult.isLoading]);

    if (createReceivingResult.isLoading) {
        return <Spinner />;
    }

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {receiving._id
                        ? `EDIT RECEIVING #${receiving.recieving_number}`
                        : 'CREATE NEW RECEIVING'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {updateReceivingResult.isLoading ? (
                        <Spinner />
                    ) : (
                        <Form>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <Form.Group>
                                        <Form.Label>Receiving Number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='recieving_number'
                                            disabled
                                            value={receiving.recieving_number}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Supplier</Form.Label>
                                        <Form.Control
                                            as='select'
                                            name='supplier'
                                            value={receiving.supplier}
                                            onChange={handleInputChange}
                                            required>
                                            <option value=''>Select Supplier</option>
                                            {suppliers &&
                                                suppliers.map((supplier) => (
                                                    <option key={supplier._id} value={supplier._id}>
                                                        {supplier.business_name}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className='col-md-4'>
                                    {/* Add fields for transporter, transporter_driver, fleet_no */}
                                    <Form.Group>
                                        <Form.Label>Transporter</Form.Label>
                                        <Form.Control
                                            as='select'
                                            name='transporter'
                                            value={receiving.transporter}
                                            onChange={handleInputChange}
                                            required>
                                            <option value=''>Select Transporter</option>
                                            {suppliers &&
                                                suppliers.map((supplier) => (
                                                    <option key={supplier._id} value={supplier._id}>
                                                        {supplier.business_name}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Transporter Driver</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='transporter_driver'
                                            value={receiving.transporter_driver}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-md-4'>
                                    <Form.Group>
                                        <Form.Label>Fleet No</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='fleet_no'
                                            value={receiving.fleet_no}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Vehicle Registration</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='vehicle_registration'
                                            value={receiving.vehicle_registration}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Receiving Date</Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='recieving_date'
                                            value={util.formatDate(receiving.recieving_date)}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <Row>
                                {receiving._id && <ReceivingItems receivingId={receiving._id} />}
                            </Row>
                        </Form>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {receivingData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdateReceiving}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreateReceiving}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ReceivingModal;
