/**
 * Features.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import OxygenTable from '@components/shared/OxygenTable';
import { useGetFeaturesQuery, useDeleteFeatureMutation } from '@redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '@components/shared/CardHeader';
import Spinner from '@components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';
import { OxModal } from '@components';
import NewFeatureForm from './NewFeatureForm';

function Features() {

    const company = useSelector((state) => state.company);

    const { data, error, isLoading, refetch: refetchFeatures } = useGetFeaturesQuery(company._id);
    const [deleteFeature, deleteFeatureResult] = useDeleteFeatureMutation();
    
    const [feature, setFeature] = useState({
        name: '',
        code: '',
        icon: '',
        link: '',
        sequence: 0,
        description: '',
        type: '',
        visibility: '',
    });

    const [features, setFeaturesArray] = useState([]);
    const [modalText, setModalText] = useState('New Feature');
    const [showFeatureModal, setShowFeatureModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const columns = [
        { label: 'Name', field: 'name', width: '10%' },
        { label: 'Code', field: 'code', width: '10%' },
        { label: 'Icon', field: 'icon', width: '10%' },
        { label: 'Link', field: 'link', width: '10%' },
        { label: 'Sequence', field: 'sequence', width: '5%' },
        { label: 'Description', field: 'description', width: '25%' },
        { label: 'Type', field: 'type', width: '10%' },
        { label: 'Visibility', field: 'visibility', width: '10%' },
        { label: 'Actions', field: 'actions', width: '10%' }
    ];

    const featuresWithActions = features.map(feature => ({
        ...feature,
        actions: (
            <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEditFeature(feature)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteFeature(feature._id)}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }));

    const handleShowFeatureModal = () => {
        setShowFeatureModal(true);
        setModalText('New Feature');
        setFeature({
            name: '',
            code: '',
            icon: '',
            link: '',
            sequence: 0,
            description: '',
            type: '',
            visibility: '',
            company: company._id,
        });
    };

    const handleCloseFeatureModal = () => {
        setShowFeatureModal(false);
    };

    const handleEditFeature = (feature) => {
        setShowFeatureModal(true);
        setModalText('Edit Feature');
        setFeature(feature);
    };

    const handleDeleteFeature = (featureId) => {
        deleteFeature(featureId);
    };

    useEffect(() => {
        if (data) {
            setFeaturesArray(data);
        }

        if (deleteFeatureResult.data) {
            refetchFeatures();
            handleCloseFeatureModal();
        }
    }, [
        data,
        deleteFeatureResult.isLoading
    ]);

    if (isLoading || deleteFeatureResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <CardHeader
                buttonText={'New Feature'}
                headerTitle={'Features'}
                headerDescription={'Manage Features'}
                placeholder={'Search Features'}
                searchText={searchText}
                handleSearch={setSearchText}
                handleShow={handleShowFeatureModal}
            />
            <OxygenTable
                data={featuresWithActions}
                columns={columns}
                pageSize={25}
            />
            <OxModal 
                handleClose={handleCloseFeatureModal}
                visible={showFeatureModal}
                title={modalText}
                children={<NewFeatureForm feature={feature} handleClose={handleCloseFeatureModal} refetch={refetchFeatures} />}
            />
        </div>
    );
}

export default Features;
