import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetCompanyItemsQuery,
    useGetPurchaseOrderItemsQuery,
    useCreatePurchaseOrderItemMutation,
    useUpdatePurchaseOrderItemMutation,
    useDeletePurchaseOrderItemMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import PurchaseOrderItemModal from './PurchaseOrderItemModal';

import { toast } from 'react-toastify';
import DeletePopupModal from '../general/DeletePopupModal';

const PurchaseOrderItems = ({ purchaseOrderId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const { data: items, isLoading: itemsLoading } = useGetCompanyItemsQuery(companyId);
    const {
        data: purchaseOrderItems,
        isLoading: purchaseOrderItemsLoading,
        refetch: refetchPurchaseOrderItems,
    } = useGetPurchaseOrderItemsQuery(purchaseOrderId);

    const [newItem, setNewItem] = useState({
        purchase_order: purchaseOrderId,
        item: '',
        cost_price: 0,
        quantity: 1,
    });

    const [createPurchaseOrderItem, createPurchaseOrderItemResult] =
        useCreatePurchaseOrderItemMutation();
    const [updatePurchaseOrderItem, updatePurchaseOrderItemResult] =
        useUpdatePurchaseOrderItemMutation();
    const [deletePurchaseOrderItem, deletePurchaseOrderItemResult] =
        useDeletePurchaseOrderItemMutation();

    const [purchaseOrderItemsArray, setPurchaseOrderItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalPurchaseOrderItem, setModalPurchaseOrderItem] = useState({
        purchase_order: purchaseOrderId,
        item: '',
        cost_price: 0,
        quantity: 1,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalPurchaseOrderItem(item);
    };

    // Handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalPurchaseOrderItem({
            purchase_order: purchaseOrderId,
            item: '',
            cost_price: 0,
            quantity: 1,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        const item = items.find((item) => item._id === selectedItemId);

        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItemId,
            cost_price: item.cost_price,
        }));
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalPurchaseOrderItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    // Handle update item
    const handleUpdateItem = () => {
        updatePurchaseOrderItem(modalPurchaseOrderItem);
        setShowModal(false);
        setModalPurchaseOrderItem({
            purchase_order: purchaseOrderId,
            item: '',
            quantity: 1,
            cost_price: 0,
        });
    };

    // Handle delete item
    const handleDeleteItem = () => {
        deletePurchaseOrderItem(modalPurchaseOrderItem._id);
        setShowDeleteModal(false);
        setModalPurchaseOrderItem({
            purchase_order: purchaseOrderId,
            item: '',
            quantity: 1,
            cost_price: 0,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            const itemExists = purchaseOrderItems.find((item) => item.item === newItem.item);
            if (itemExists) {
                const updatedItem = {
                    ...itemExists,
                    quantity: itemExists.quantity + newItem.quantity,
                };
                updatePurchaseOrderItem(updatedItem);
            } else {
                createPurchaseOrderItem(newItem);
            }
            setNewItem({
                purchase_order: purchaseOrderId,
                item: '',
                quantity: 1,
                cost_price: 0,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (purchaseOrderItems) {
            refetchPurchaseOrderItems();
        }
        if (createPurchaseOrderItemResult.data) {
            refetchPurchaseOrderItems();
        }
        if (createPurchaseOrderItemResult.isSuccess) {
            toast.success('Purchase Order Item Created Successfully');
        }
        if (deletePurchaseOrderItemResult.data) {
            refetchPurchaseOrderItems();
        }
        if (deletePurchaseOrderItemResult.isSuccess) {
            toast.success('Purchase Order Item Deleted Successfully');
        }
        if (updatePurchaseOrderItemResult.data) {
            refetchPurchaseOrderItems();
        }
        if (updatePurchaseOrderItemResult.isSuccess) {
            toast.success('Purchase Order Item Updated Successfully');
        }
    }, [
        purchaseOrderItems,
        purchaseOrderItemsLoading,
        createPurchaseOrderItemResult.isLoading,
        updatePurchaseOrderItemResult.isLoading,
        deletePurchaseOrderItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Purchase Order Item');
            setModalPurchaseOrderItem({});
        } else if (modalType === 'edit' && item) {
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalPurchaseOrderItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalPurchaseOrderItem({
            purchase_order: purchaseOrderId,
            item: '',
            quantity: 1,
            cost_price: 0,
        });
    };

    if (
        itemsLoading ||
        purchaseOrderItemsLoading ||
        createPurchaseOrderItemResult.isLoading ||
        updatePurchaseOrderItemResult.isLoading ||
        deletePurchaseOrderItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='20%'>Item</th>
                        <th width='20%'>Quantity</th>
                        <th width='20%'>Cost Price</th>
                        <th width='20%'>Total</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {purchaseOrderItems &&
                        purchaseOrderItems.map((item, index) => (
                            <tr key={index}>
                                <td>{getItemName(item.item)}</td>
                                <td>{item.quantity}</td>
                                <td>{item.cost_price}</td>
                                <td>{item.quantity * item.cost_price}</td>

                                <td>
                                    <Button
                                        variant='primary'
                                        onClick={() => openModal('edit', item)}>
                                        Edit
                                    </Button>{' '}
                                    <Button
                                        variant='danger'
                                        onClick={() => handleShowDeleteModal(item)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select New Item
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <PurchaseOrderItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                purchaseOrderItem={modalPurchaseOrderItem}
                handleChange={handleChange}
                handleUpdatePurchaseOrderItem={handleUpdateItem}
            />
            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={'Purchase Order Item'}
            />
        </div>
    );
};

export default PurchaseOrderItems;
