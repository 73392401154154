import React, {useEffect} from 'react';
import { Form, FormGroup, FormCheck, FormLabel, FormControl, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
    useCreateCompanyEmployeeMutation,
    useUpdateCompanyEmployeeMutation,
    useGetCompanyRolesQuery,
} from '../../Redux/apiSlice';

import {toast} from 'react-toastify';
import Spinner from '../../components/shared/Spinner';

// Validation schema
const validationSchema = Yup.object({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  role: Yup.string().required('Required'),
});

function EmployeeForm({ employee, handleClose, employeeRefetch }) {
    
    const companyState = useSelector((state) => state.company);

    //RTK Queries

    const {
        data: roles,
        error: rolesError,
        isLoading: rolesLoading,
        refetch: roleRefetch,
    } = useGetCompanyRolesQuery(companyState._id);
    // Create employee

    const [createEmployee, createEmployeeResult] = useCreateCompanyEmployeeMutation();

    // Update employee

    const [updateEmployee, updateEmployeeResult] = useUpdateCompanyEmployeeMutation();

    const handleSubmit = (values) => {
        console.log(values);
        formik.resetForm();

        if (employee) {
            const body = {
                ...values,
                _id: employee._id,
                company: companyState._id,
            };
            updateEmployee(body);
        } else {
            const body = {
                ...values,
                company: companyState._id,
            };
            createEmployee(body);
        }
    };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      gender: '',
      id_number: '',
      phone_number: '',
      email: '',
      postal_address: '',
      residential_address: '',
      joined_at: '',
      social_security_number: '',
      role: '',
      ...employee,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (createEmployeeResult.isSuccess) {
      formik.resetForm();
      toast.success('Employee created successfully');
      employeeRefetch();
      handleClose();
    }

    if (updateEmployeeResult.isSuccess) {
        formik.resetForm();
        toast.success('Employee updated successfully');
        employeeRefetch();
        handleClose();
    }

    if (createEmployeeResult.isError) {
        console.log(createEmployeeResult.error);
    }

    if (updateEmployeeResult.isError) {
        console.log(updateEmployeeResult.error);
    }

  }, [createEmployeeResult.isLoading, updateEmployeeResult.isLoading])

  if (createEmployeeResult.isLoading || updateEmployeeResult.isLoading)  {
        return <Spinner />;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      {/* First Name */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="first_name">First Name</FormLabel>
        <FormControl
          type="text"
          id="first_name"
          {...formik.getFieldProps('first_name')}
        />
        {formik.touched.first_name && formik.errors.first_name ? (
          <div className="text-danger">{formik.errors.first_name}</div>
        ) : null}
      </FormGroup>

      {/* Last Name */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="last_name">Last Name</FormLabel>
        <FormControl
          type="text"
          id="last_name"
          {...formik.getFieldProps('last_name')}
        />
        {formik.touched.last_name && formik.errors.last_name ? (
          <div className="text-danger">{formik.errors.last_name}</div>
        ) : null}
      </FormGroup>

      {/* Gender */}
      <FormGroup className="mb-3">
        <FormLabel>Gender</FormLabel>
        <FormCheck
          type="radio"
          label="Male"
          id="gender_male"
          name="gender"
          value="male"
          checked={formik.values.gender === 'male'}
          onChange={formik.handleChange}
        />
        <FormCheck
          type="radio"
          label="Female"
          id="gender_female"
          name="gender"
          value="female"
          checked={formik.values.gender === 'female'}
          onChange={formik.handleChange}
        />
        {formik.touched.gender && formik.errors.gender ? (
          <div className="text-danger">{formik.errors.gender}</div>
        ) : null}
      </FormGroup>

      {/* ID Number */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="id_number">ID Number</FormLabel>
        <FormControl
          type="text"
          id="id_number"
          {...formik.getFieldProps('id_number')}
        />
        {formik.touched.id_number && formik.errors.id_number ? (
          <div className="text-danger">{formik.errors.id_number}</div>
        ) : null}
      </FormGroup>

      {/* Phone Number */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="phone_number">Phone Number</FormLabel>
        <FormControl
          type="tel"
          id="phone_number"
          {...formik.getFieldProps('phone_number')}
        />
        {formik.touched.phone_number && formik.errors.phone_number ? (
          <div className="text-danger">{formik.errors.phone_number}</div>
        ) : null}
      </FormGroup>

      {/* Email */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="email">E-mail Address</FormLabel>
        <FormControl
          type="email"
          id="email"
          {...formik.getFieldProps('email')}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="text-danger">{formik.errors.email}</div>
        ) : null}
      </FormGroup>

      {/* Postal Address */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="postal_address">Postal Address</FormLabel>
        <FormControl
          type="text"
          id="postal_address"
          {...formik.getFieldProps('postal_address')}
        />
        {formik.touched.postal_address && formik.errors.postal_address ? (
          <div className="text-danger">{formik.errors.postal_address}</div>
        ) : null}
      </FormGroup>

      {/* Residential Address */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="residential_address">Residential Address</FormLabel>
        <FormControl
          type="text"
          id="residential_address"
          {...formik.getFieldProps('residential_address')}
        />
        {formik.touched.residential_address && formik.errors.residential_address ? (
          <div className="text-danger">{formik.errors.residential_address}</div>
        ) : null}
      </FormGroup>

      {/* Joined At */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="joined_at">Employment Date</FormLabel>
        <FormControl
          type="date"
          id="joined_at"
          {...formik.getFieldProps('joined_at')}
        />
        {formik.touched.joined_at && formik.errors.joined_at ? (
          <div className="text-danger">{formik.errors.joined_at}</div>
        ) : null}
      </FormGroup>

      {/* Social Security Number */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="social_security_number">Social Security Number</FormLabel>
        <FormControl
          type="text"
          id="social_security_number"
          {...formik.getFieldProps('social_security_number')}
        />
        {formik.touched.social_security_number && formik.errors.social_security_number ? (
          <div className="text-danger">{formik.errors.social_security_number}</div>
        ) : null}
      </FormGroup>

      {/* Roles */}
      <FormGroup className="mb-3">
        <FormLabel htmlFor="role">Roles</FormLabel>
        <FormControl
          as="select"
          id="role"
          {...formik.getFieldProps('role')}
        >
          <option value="" disabled>Select Role</option>
          {roles && roles.map((role) => (
            <option key={role._id} value={role._id}>{role.name}</option>
          ))}
        </FormControl>
        {formik.touched.role && formik.errors.role ? (
          <div className="text-danger">{formik.errors.role}</div>
        ) : null}
      </FormGroup>

      <Button type="submit">Submit</Button>
    </Form>
  );
}

export default EmployeeForm;
