import React from 'react';
import UnderConstruction from '../../../shared_components/UnderConstruction';
import Invoices from './Invoices';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PaymentMethods from './PaymentMethods';
import SaleAgreementItems from '../sales_and_distributions/SaleAgreementItems';
import SaleAgreements from '../sales_and_distributions/SaleAgreements';
import SaleOrders from '../sales_and_distributions/SaleOrders';
import DeliveryNotes from '../sales_and_distributions/DeliveryNotes';
import FuelSaleReport from '../fuel/FuelSaleReport';
import Payments from '../../sales/Payments/Payments';

function SaleLanding() {
    return (
        <Tabs>
            <TabList>
                <Tab>SALE ORDERS</Tab>
                <Tab>DELIVERY NOTES</Tab>
                <Tab>INVOICES</Tab>
                <Tab>SALE AGREEMENTS</Tab>
                <Tab>REPORTS</Tab>
                <Tab>SETTINGS</Tab>
            </TabList>
            <TabPanel>
                <SaleOrders />
            </TabPanel>
            <TabPanel>
                <DeliveryNotes />
            </TabPanel>
            <TabPanel>
                <Invoices />
            </TabPanel>
            <TabPanel>
                <SaleAgreements />
            </TabPanel>
            <TabPanel>
                <FuelSaleReport />
            </TabPanel>
            <TabPanel>
               <Payments/>
            </TabPanel>
        </Tabs>
    );
}

export default SaleLanding;
