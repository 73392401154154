import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OxButton from '../../../components/shared/OxButton';
import { useGetCompanyItemsQuery } from '../../../Redux/apiSlice';

const TankModal = ({
    show,
    onHide,
    modalText,
    tank,
    handleCreateTank,
    handleUpdateTank,
    handleChange,
    onItemSelected,
}) => {
    const company = useSelector((state) => state.company);

    // Get items
    const {
        data: items,
        error,
        isLoading,
        refetch: refetchItems,
    } = useGetCompanyItemsQuery(company._id);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name='name'
                            value={tank.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tank No</Form.Label>
                        <Form.Control
                            name='tank_no'
                            value={tank.tank_no}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Capacity</Form.Label>
                        <Form.Control
                            name='capacity'
                            value={tank.capacity}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Volume (Liters)</Form.Label>
                        <Form.Control
                            disabled
                            name='volume'
                            value={tank.volume}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Item</Form.Label>
                        <Form.Control
                            as='select'
                            name='item'
                            value={tank.item}
                            onChange={handleChange}>
                            <option value=''>Select Item</option>
                            {items &&
                                items.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                {modalText == 'New Tank' ? (
                    <OxButton action={handleCreateTank} text='Create' />
                ) : (
                    <OxButton action={handleUpdateTank} text='Update' />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default TankModal;
