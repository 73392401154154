import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../src/assets/css/bootstrap.min.css';
import './assets/css/light-bootstrap-dashboard.css?v=2.0.1';
import './assets/css/demo.css';
import 'reactjs-toastr/lib/toast.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter, BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './Redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
