import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function GenerateConfirmation({ show, onHide, onConfirm, recordName }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm {recordName} Generation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to confirm the generation for an {recordName.toLowerCase()}?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                <Button variant='success' onClick={onConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default GenerateConfirmation;
