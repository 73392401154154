/**
 * NewCustomerForm.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button, FormCheck, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Validation schema
const validationSchema = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string(),
    gender: Yup.string(),
    dob: Yup.string(),
    id_number: Yup.string(),
    phone_number: Yup.string(),
    email: Yup.string().email('Invalid email format'),
    residencial_address: Yup.string(),
    postal_address: Yup.string(),
    discount: Yup.number(),
    discount_type: Yup.string(),
    points: Yup.number(),
    business_name: Yup.string(),
    business_registation_number: Yup.string(),
});

import { 
    useCreateCompanyCustomerMutation,
    useUpdateCompanyCustomerMutation,
} from '@redux/apiSlice';
import Spinner from '@components/shared/Spinner';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function NewCustomerForm({ customer, handleClose, refetch }) {
    // Sample state selector for company
    const company = useSelector((state) => state.company);

    const [createCustomer, createCustomerResult] = useCreateCompanyCustomerMutation();
    const [updateCustomer, updateCustomerResult] = useUpdateCompanyCustomerMutation();

    const handleSubmit = (values) => {

        //remove empty fields
        Object.keys(values).forEach(key => {
            if (values[key] === '') {
                delete values[key];
            }
        });

        if (customer._id) {
            updateCustomer({ ...values, _id: customer._id });
        } else {
            createCustomer({ 
                ...values, 
                company: 
                company._id,
                customer_type: 'external',
            });
        }
    };

    useEffect(() => {
        if (createCustomerResult.isSuccess) {
            formik.resetForm();
            toast.success('Customer created successfully');
            refetch();
            handleClose();
        }

        if (createCustomerResult.isError || updateCustomerResult.isError) {
            toast.error('Something went wrong');
        }

        if (updateCustomerResult.isSuccess) {
            formik.resetForm();
            toast.success('Customer updated successfully');
            refetch();
            handleClose();
        }
    }, [createCustomerResult, updateCustomerResult]);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            gender: '',
            dob: '',
            id_number: '',
            phone_number: '',
            email: '',
            residencial_address: '',
            postal_address: '',
            country: '',
            company: company._id,
            discount: '',
            discount_type: '',
            points: '',
            business_name: '',
            business_registation_number: '',
            ...customer,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    if (createCustomerResult.isLoading || updateCustomerResult.isLoading) {
        return <Spinner />;
    }

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>First Name</FormLabel>
                        <FormControl
                            type="text"
                            id="first_name"
                            {...formik.getFieldProps('first_name')}
                            />
                        {formik.touched.first_name && formik.errors.first_name && (
                            <div className="text-danger">{formik.errors.first_name}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Last Name</FormLabel>
                        <FormControl
                            type="text"
                            id="last_name"
                            {...formik.getFieldProps('last_name')}
                            />
                        {formik.touched.last_name && formik.errors.last_name && (
                            <div className="text-danger">{formik.errors.last_name}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel htmlFor="category">Gender</FormLabel>
                        <FormControl
                            as="select"
                            id="gender"
                            {...formik.getFieldProps('gender')}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                            
                        </FormControl>
                        {formik.touched.gender && formik.errors.gender && (
                            <div className="text-danger">{formik.errors.gender}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Date of Birth</FormLabel>
                        <FormControl
                            type="date"
                            id="dob"
                            {...formik.getFieldProps('dob')}
                            />
                        {formik.touched.dob && formik.errors.dob && (
                            <div className="text-danger">{formik.errors.dob}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>ID Number</FormLabel>
                        <FormControl
                            type="text"
                            id="id_number"
                            {...formik.getFieldProps('id_number')}
                            />
                        {formik.touched.id_number && formik.errors.id_number && (
                            <div className="text-danger">{formik.errors.id_number}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Phone Number</FormLabel>
                        <FormControl
                            type="text"
                            id="phone_number"
                            {...formik.getFieldProps('phone_number')}
                            />
                        {formik.touched.phone_number && formik.errors.phone_number && (
                            <div className="text-danger">{formik.errors.phone_number}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <FormControl
                            type="email"
                            id="email"
                            {...formik.getFieldProps('email')}
                            />
                        {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">{formik.errors.email}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Residencial Address</FormLabel>
                        <FormControl
                            type="text"
                            id="residencial_address"
                            {...formik.getFieldProps('residencial_address')}
                            />
                        {formik.touched.residencial_address && formik.errors.residencial_address && (
                            <div className="text-danger">{formik.errors.residencial_address}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Postal Address</FormLabel>
                        <FormControl
                            type="text"
                            id="postal_address"
                            {...formik.getFieldProps('postal_address')}
                            />
                        {formik.touched.postal_address && formik.errors.postal_address && (
                            <div className="text-danger">{formik.errors.postal_address}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Country</FormLabel>
                        <FormControl
                            type="text"
                            id="country"
                            {...formik.getFieldProps('country')}
                            />
                        {formik.touched.country && formik.errors.country && (
                            <div className="text-danger">{formik.errors.country}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Discount</FormLabel>
                        <FormControl
                            type="number"
                            id="discount"
                            {...formik.getFieldProps('discount')}
                            />
                        {formik.touched.discount && formik.errors.discount && (
                            <div className="text-danger">{formik.errors.discount}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Discount Type</FormLabel>
                        <FormControl
                            as="select"
                            id="discount_type"
                            {...formik.getFieldProps('discount_type')}
                            >
                            <option value="">Select Discount Type</option>
                            <option value="percentage">Percentage</option>
                            <option value="amount">Amount</option>
                            </FormControl>

                        {formik.touched.discount_type && formik.errors.discount_type && (
                            <div className="text-danger">{formik.errors.discount_type}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Points</FormLabel>
                        <FormControl
                            type="number"
                            id="points"
                            {...formik.getFieldProps('points')}
                            />
                        {formik.touched.points && formik.errors.points && (
                            <div className="text-danger">{formik.errors.points}</div>
                        )}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Business Name</FormLabel>
                        <FormControl
                            type="text"
                            id="business_name"
                            {...formik.getFieldProps('business_name')}
                            />
                        {formik.touched.business_name && formik.errors.business_name && (
                            <div className="text-danger">{formik.errors.business_name}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Business Registration Number</FormLabel>
                        <FormControl
                            type="text"
                            id="business_registation_number"
                            {...formik.getFieldProps('business_registation_number')}
                            />
                        {formik.touched.business_registation_number && formik.errors.business_registation_number && (
                            <div className="text-danger">{formik.errors.business_registation_number}</div>
                        )}
                    </FormGroup>
                </Col>
            </Row>
            <br />
            <Button type="submit">Submit</Button>
        </Form>
    );
}

export default NewCustomerForm;
