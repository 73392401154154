/**
 * stock-type.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const STOCK = 'stock';
const NON_STOCK = 'non-stock';

export const  STOCK_TYPES  = {
    STOCK,
    NON_STOCK,
    ALL_STOCK_TYPES: [STOCK, NON_STOCK],
};