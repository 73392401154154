/**
 * tenant_application_info.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService } from '../api/api.service';

let instance;

export class TenantApplicationInfoService extends APIService {
    constructor() {
        const storageKey = 'tenant_application';
        const apiPath = 'tenant_application';
        super(storageKey, apiPath);
    }
    static create() {
        if (!instance) {
            instance = new TenantApplicationInfoService();
        }
        return instance;
    }
}
