/**
 * OxPdfFileView.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState } from 'react';
import PDF_ICON from '@assets/img/PDF_ICON.webp';
import { OxPdfViewer } from '@shared';
import { Container, Row } from 'react-bootstrap';
import { Tooltip } from 'antd';
import { OxLazyImage } from './OxLazyImage';
import './OxPdfFileView.scss';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';

/**
 * @description renders pdf file
 * @param {Object} file municipality selected
 */
export function OxPdfFileView({
    file,
    blurContent = false,
    isWatermarked = false,
    enhancePdf = false,
    pdfWidth = 2,
    columnMargin,
    deletehandler,
}) {
    const [viewPDF, setViewPDF] = useState(false);
    const [selectedPDF, setSelectedPDF] = useState(null);

    return (
        <>
            <Container
                stretched
                style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    margin: columnMargin,
                }}
                width={pdfWidth}
                key={file.id}>
                <OxLazyImage
                    effect='blur'
                    src={PDF_ICON}
                    className='oxygen-pdf'
                    centered
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 50,
                    }}
                    onClickHandler={(e) => {
                        setSelectedPDF(file);
                        setViewPDF(true);
                    }}
                />{' '}
                {/* <Tooltip title={file.filename}> */}
                <p
                    style={{
                        fontSize: '10px',
                        textAlign: 'center',
                        width: 50,
                        margin: '0px 0px 0.5em',
                        overflow: 'hidden',
                    }}
                    className='pdfFileName '>
                    {file.filename}
                    <br />

                    <DeleteOutlined
                        onClick={deletehandler}
                        style={{ fontSize: '18px', color: 'red' }}
                    />
                </p>
                {/* </Tooltip> */}
            </Container>
            {viewPDF && (
                <OxPdfViewer
                    blurContent={blurContent}
                    isWatermarked={isWatermarked}
                    previewVisible={viewPDF}
                    handleClose={() => {
                        setViewPDF(!viewPDF);
                    }}
                    pdf={file}
                    enhancePdf={enhancePdf}
                />
            )}
        </>
    );
}
