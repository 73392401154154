import React, { useEffect } from 'react';
import { Modal, Form, Button, Image, InputGroup } from 'react-bootstrap';
import OxButton from '../../../components/shared/OxButton';

import { useSelector } from 'react-redux';

import {
    useGetCompanyItemsQuery,
    useGetJobCardItemStatusesQuery,
    useGetBrandsQuery,
    useGetPropertyImagesQuery,
} from '../../../Redux/apiSlice';
import { strings } from '../../../strings/strings';

function JobCardItemModal({
    show,
    onHide,
    modalText,
    jobCardItem,
    handleCreateJobCardItem,
    handleUpdateJobCardItem,
    handleChange,
    handleItemChange,
    handleItemStatusChange,
}) {
    const company = useSelector((state) => state.company);

    const {
        data: items,
        error: itemsError,
        isLoading: itemsLoading,
    } = useGetCompanyItemsQuery(company._id);

    const {
        data: statuses,
        error: statusesError,
        isLoading: statusesLoading,
    } = useGetJobCardItemStatusesQuery(company._id);

    const {
        data: brands,
        error: brandsError,
        isLoading: brandsLoading,
    } = useGetBrandsQuery(company._id);

    const {
        data: images,
        error: imagesError,
        isLoading: imagesLoading,
        refetch,
    } = useGetPropertyImagesQuery(jobCardItem._id);

    const [imageUrl, setImageUrl] = React.useState('');
    const baseUrl = strings.oxygen_end_point + '/stream/';

    const handleItemImageUpload = (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('_id', jobCardItem._id);

        formData.append('file', file);

        formData.append('size', '' + file.size);

        fetch(strings.oxygen_end_point + '/upload', {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                refetch();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (images && images.length > 0) {
            setImageUrl(baseUrl + images[images.length - 1].filename);
        }
    }, [images]);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalText}</Modal.Title>
            </Modal.Header>
            {jobCardItem._id && (
                <Form>
                    {imageUrl != '' && (
                        <Image src={imageUrl} rounded style={{ width: 200, height: 200 }} />
                    )}
                    <Form.Group controlId='formFile'>
                        <Form.Label>Item Image</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type='file'
                                name='sampleFile'
                                onChange={handleItemImageUpload}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            )}
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Service</Form.Label>
                        <Form.Control
                            as='select'
                            name='item'
                            value={jobCardItem.item}
                            onChange={handleItemChange}>
                            <option value='' disabled>
                                --Select Service--
                            </option>
                            {items &&
                                items.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type='text'
                            name='description'
                            value={jobCardItem.description}
                            onChange={handleChange}
                            placeholder='Description'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type='text'
                            name='unit_price'
                            value={jobCardItem.unit_price}
                            onChange={handleChange}
                            placeholder='Description'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Serial Number</Form.Label>
                        <Form.Control
                            type='text'
                            name='serial_number'
                            value={jobCardItem.serial_number}
                            onChange={handleChange}
                            placeholder='Serial Number'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Size</Form.Label>
                        <Form.Control
                            type='text'
                            name='size'
                            value={jobCardItem.size}
                            onChange={handleChange}
                            placeholder='Size'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Brand</Form.Label>
                        <Form.Control
                            as='select'
                            name='brand'
                            value={jobCardItem.brand}
                            onChange={handleChange}>
                            <option value='' disabled>
                                --Select Brand--
                            </option>
                            {brands &&
                                brands.map((brand) => (
                                    <option key={brand._id} value={brand._id}>
                                        {brand.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as='select'
                            name='status'
                            value={jobCardItem.status}
                            onChange={handleItemStatusChange(jobCardItem)}>
                            <option value='' disabled>
                                --Select Status--
                            </option>
                            {statuses &&
                                statuses.map((status) => (
                                    <option key={status._id} value={status._id}>
                                        {status.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>

                    {modalText === 'New Job Card Item' ? (
                        <OxButton text='Submit' action={handleCreateJobCardItem} />
                    ) : (
                        <OxButton text='Update' action={handleUpdateJobCardItem} />
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default JobCardItemModal;
