/**
 * http.service.js
 *
 * @description API calls service
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2020
 * All rights reserved
 *
 */

import { ENV } from '../../config';
import { SessionService } from '../session.service';
import axios from 'axios';

// constants
const api = axios.create({
    baseURL: ENV.oxygen_end_point,
});

/**
 * @name _POST
 * @param {string} url API url path
 * @param {Object} data data object to be sent to the API
 * @description sends POST request to the API
 * @returns {Promise} Promise with appropriate Object response @see WIKI
 */
export const _POST = async (url, data) => {
    try {
        const response = await api.post(url, data, await getHeaders());
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

/**
 * @name _PATCH
 * @param {string} url API url path
 * @param {Object} data data object to be sent to the API
 * @description sends PATCH request to the API
 * @returns {Promise} Promise with appropriate Object response @see WIKI
 */
export const _PATCH = async (url, data) => {
    try {
        const response = await api.put(url, data, await getHeaders());
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

/**
 * GET's data from an API
 * @param {string} url API url path
 */
export const _GET = async (url) => {
    try {
        const response = await api.get(url, await getHeaders());
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

/**
 * @name _DELETE
 * @param {string} url API url path
 * @description sends DELETE request to the API
 * @returns {Promise} Promise with appropriate Object response @see WIKI
 */
export const _DELETE = async (url) => {
    try {
        const response = await api.delete(url, await getHeaders());
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

/**
 * @name _PUT
 * @param {string} url API url path
 * @param {Object} data data object to be sent to the API
 * @description sends PUT request to the API
 * @returns {Promise} Promise with appropriate Object response @see WIKI
 */
export const _PUT = async (url, data) => {
    try {
        const response = await api.put(url, data, await getHeaders());
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

export async function getToken() {
    const sm = SessionService.create();
    return Promise.resolve(`Bearer ${await sm.token()}`);
}

function handleError(error) {
    if (error.response) {
        // Request made and server responded
        const sm = SessionService.create();
        const extractError = { ...error.response.data, status: error.response.status };

        // if user is not authenticated while logged in, do logout
        if (extractError.code === 41) {
            sm.set('logout', true);
        }
        console.error('API Call Error: ', extractError);
        return Promise.reject(extractError);
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        return Promise.reject();
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        return Promise.reject();
    }
}

async function getHeaders() {
    const token = await getToken();
    return { headers: { Authorization: `${token}`, 'OXYGEN-TOKEN': 'OXYGENDEVSSO' } };
}
