/**
 * ContractsTable.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';

import Side_nav from '@shared_components/side_nav';
import Top_nav from '@shared_components/top_nav';
import Footer from '@shared_components/footer';
import { formatNumberToPrice } from '@util/number.utils';
import { shortDate } from '@util/date.utils';

import { Popconfirm, Button as AntButton, Dropdown, Space, Menu } from 'antd';
import { filter } from 'rxjs/operators';
import OxButton from '@shared/OxButton';
import { LoadingPage, CircularButton, OxNotify, OxModal } from '@shared';
import { SessionService, ContractsService } from '@services';
import { defined } from '@util/array.util';
import { FaEye, FaEllipsisH } from 'react-icons/fa';

import { LOCAL_STORAGE_ENUMS } from '@enums';
import { ViewContract } from './ViewContract';
import './Contracts.scss';

import { DeleteTwoTone } from '@ant-design/icons';

const { CONTRACTS } = LOCAL_STORAGE_ENUMS;

// service instances
const contractsService = ContractsService.create();
const session = SessionService.session;

//subscriptions
let localDataSubscription = session;
let initialSub = session;

export const ContractsTable = () => {
    const [contracts, setContracts] = useState(undefined);
    const [contract, setContract] = useState(undefined);
    const [showModal, setShowModal] = React.useState(false);
    const [viewModal, setViewModal] = useState(false);
    const tableHeads = [
        'Tenant Name',
        'Id Number',
        'Property',
        'Agent',
        'Rental Amount',
        'Start Date',
        'End Date',
    ];

    useEffect(() => {
        listenToSession();
        getData();
    }, []);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        refresh();
    };

    const prepareData = (values) => {
        console.log('values', values);
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === CONTRACTS) {
                setContracts(data);
            }
        }
    };

    const listenToSession = () => {
        const concerns = [CONTRACTS];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const getData = () => {
        initialSub = contractsService.get().subscribe(prepareData, (error) => {
            console.log('Dependencies Get Error: ', error);
        });
    };
    const refresh = () => {
        initialSub = contractsService.fetchAndStore().subscribe(prepareData, (error) => {
            console.log('Dependencies fetch Error: ', error);
        });
    };

    const confirmDelete = (item) => {
        contractsService.patch(item._id, { deleted: true }).subscribe(
            (data) => {
                console.log('Deleted: ', data);

                OxNotify({
                    title: 'Success',
                    content: 'Delete Success',
                    type: 'success',
                    duration: 10,
                });
                refresh();
            },
            (error) => {
                console.log('Error: ', error);
                OxNotify({ title: 'Error', content: error.message, type: 'error', duration: 10 });
            }
        );
    };

    const items = [
        {
            key: '1',
            label: 'WHITELIST',
            update: (contract) => {
                update(contract, { status: 'RESOLVED' });
            },
        },
        {
            key: '2',
            label: 'BLACKLIST',
            update: (contract) => {
                update(contract, { status: 'ACTIVE' });
            },
        },
        {
            key: '3',
            label: 'UNDER INVESTIGATION',
            update: (contract) => {
                update(contract, { status: 'UNDER INVESTIGATION' });
            },
        },
    ];

    const menuProps = {
        items,
        onClick: (e) => {},
    };

    const update = (item, data) => {
        contractsService.patch(item._id, data).subscribe(
            (data) => {
                console.log('Deleted: ', data);

                OxNotify({
                    title: 'Success',
                    content: 'Contract Updated',
                    type: 'success',
                    duration: 10,
                });
                refresh();
            },
            (error) => {
                console.log('Error: ', error);
                OxNotify({ title: 'Error', content: error.message, type: 'error', duration: 10 });
            }
        );
    };

    /** returns true if all data dependencies have been loaded from the api */
    const isReady = defined([contracts]);

    // render table heads from array list
    const renderTableHeads = (head, index) => {
        return <th key={index}>{head}</th>;
    };

    const renderTableRows = (contract, index) => {
        const { tenant, property, agent, agreed_rent, placement_start_date, placement_end_date } =
            contract;
        return (
            <tr key={index}>
                <td>
                    {tenant.first_name} {tenant.last_name}
                </td>

                <td>{tenant.id_number}</td>
                <td>{property.name}</td>
                <td>{agent.email}</td>
                <td>N${formatNumberToPrice(agreed_rent)}</td>
                <td>{shortDate(placement_start_date)}</td>
                <td>{shortDate(placement_end_date)}</td>
                <td>
                    <CircularButton
                        variant='primary'
                        onClick={() => {
                            setContract(contract);
                            setViewModal(true);
                        }}
                        icon={<FaEye />}
                    />
                </td>
                {/* <td>
                    <CircularButton variant='success' icon={<FaEdit />} />
                </td> */}
                <td>
                    <Popconfirm
                        placement='top'
                        title='Confirm Delete'
                        okText='Yes'
                        cancelText='No'
                        onConfirm={() => confirmDelete(contract)}>
                        <AntButton
                            className='tableDeleteButton'
                            shape='circle'
                            icon={
                                <DeleteTwoTone
                                    key='delete'
                                    twoToneColor='red'
                                    style={{ fontSize: '15px' }}
                                />
                            }
                        />
                        {/* <CircularButton variant='danger' icon={<FaTrash />} /> */}
                    </Popconfirm>
                </td>
                {/* <td>
                    <Dropdown
                        overlay={
                            <Menu onClick={menuProps.onClick}>
                                {menuProps.items.map((item) => (
                                    <Menu.Item
                                        key={item.key}
                                        onClick={() => {
                                            item.update(contract);
                                        }}>
                                        {item.label}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }>
                        <CircularButton variant='success' icon={<FaEllipsisH />} />
                    </Dropdown>
                </td> */}
            </tr>
        );
    };

    const renderView = () => {
        return (
            <div className='content'>
                <div className='container-fluid'>
                    <div className='section'>
                        {' '}
                        <div className='card strpied-tabled-with-hover'>
                            <div className='card-header '>
                                <div className='form-row'>
                                    <div className='col-md-5'>
                                        <h4 className='card-title'>Contracts</h4>
                                        <p className='card-category'>Manage contracts</p>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='form-row'>
                                            <div className='col-md-8'>
                                                <div className='form-group'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Search By Id number'
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className='col'>
                                                <OxButton
                                                    text='New Contract'
                                                    action={handleOpenModal}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body table-full-width table-responsive'>
                                <table className='table table-hover table-striped'>
                                    <thead>
                                        <tr>{tableHeads.map(renderTableHeads)}</tr>
                                    </thead>
                                    <tbody>{contracts.map(renderTableRows)}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return isReady ? (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {renderView()}

                {viewModal && (
                    <OxModal
                        scrollable={true}
                        size='lg'
                        dark={false}
                        title={'CONTRACT DETAILS'}
                        visible={viewModal}
                        handleClose={() => setViewModal(false)}
                        children={<ViewContract contract={contract} />}
                    />
                )}

                <Footer />
            </div>
        </div>
    ) : (
        <LoadingPage title={'Loading contracts list'} />
    );
};
