/**
 * LicenseSelection.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { filter } from 'rxjs/operators';
import { Link, useNavigate } from 'react-router-dom';

import { SessionService, LicenseTypeService } from '@services';
import { LoadingPage } from '@shared';
import { defined } from '@util/array.util';
import { LOCAL_STORAGE_ENUMS, ROUTES, RGS_LICENSE_TYPES as RGS_LICENSE_TYPES_ } from '@enums';

import Logo from '@shared/Logo';
import BlackLogo from '@assets/img/rgs/logo_transparent.png';

import './LicenseSelection.scss';

const { RGS_LICENSE_TYPES, REGISTRATION_LICENSE_TYPE } = LOCAL_STORAGE_ENUMS;
const { COMPANY_REGISTRATION } = ROUTES;

const { RGS_ADM } = RGS_LICENSE_TYPES_;

const licenseTypervice = LicenseTypeService.create();
const session = SessionService.session;

let localDataSubscription = session;
let initialSub = session;

const sm = SessionService.create();

export const LicenseSelection = () => {
    const [licenseTypes, setLicenseTypes] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
        listenToSession();
        return () => {
            if (initialSub) initialSub.unsubscribe();
            if (localDataSubscription) localDataSubscription.unsubscribe();
        };
    }, []);

    const prepareData = (values) => {
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === RGS_LICENSE_TYPES) {
                data = data.filter((license) => license.code !== RGS_ADM);
                setLicenseTypes(data);
            }
        }
    };

    const listenToSession = () => {
        const concerns = [RGS_LICENSE_TYPES];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const getData = () => {
        initialSub = licenseTypervice.get().subscribe(prepareData, (error) => {
            console.log('Dependencies Get Error: ', error);
        });
    };

    /** returns true if all data dependencies have been loaded from the api */
    const isReady = defined([licenseTypes]);

    const setLicenseType = (licenseType) => {
        sm.addItem(REGISTRATION_LICENSE_TYPE, licenseType);
    };

    if (loading || !isReady) {
        return <LoadingPage title='Loading License Types' />;
    }

    return (
        <div className='license-container pt-4'>
            <Container className='border border-info mt-3 pb-5'>
                <Row className='mb-2 mt-2 text-center'>
                    <Col>
                        <Logo src={BlackLogo} width={250} />
                        {/* <p className='mt-3'>System Description Here</p> */}
                    </Col>
                </Row>

                {/* Title */}
                <Row className='mb-4'>
                    <Col className='text-center'>
                        <h2 style={{ color: '#60caed' }}>
                            Welcome to Rental Good Standing, Select your License type
                        </h2>
                    </Col>
                </Row>

                {/* License Cards */}
                <Row>
                    {licenseTypes.map((license, index) => (
                        <Col key={index} md={4} className='mb-2'>
                            <Card
                                className='h-100 border border-info licenseCard'
                                onClick={() => setLicenseType(license)}>
                                <Card.Header className='text-center'>
                                    <i
                                        className='fas fa-certificate fa-4x'
                                        style={{ color: '#60caed' }}></i>{' '}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title className='mb-2 font-weight-bold'>
                                        {license.name}
                                    </Card.Title>
                                    <Card.Text style={{ color: '#60caed' }}>
                                        {license.description}
                                    </Card.Text>
                                    <ul>
                                        {license.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature.description}</li>
                                        ))}
                                    </ul>
                                </Card.Body>
                                <Card.Footer className='mb-2'>
                                    <p className='h4 text-center pb-2'>N${license.price}</p>
                                    <Button
                                        variant='primary'
                                        onClick={() => setLicenseType(license)}
                                        className='d-block mx-auto'>
                                        Select License
                                    </Button>
                                </Card.Footer>
                                <Link to={`/${COMPANY_REGISTRATION}`} className='stretched-link'>
                                    <span className='visually-hidden'></span>
                                </Link>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};
