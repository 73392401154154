import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    useGetFuelDeliveryNoteItemsQuery,
    useGetDeliveryNoteItemsQuery,
    useCreateDeliveryNoteItemMutation,
    useUpdateDeliveryNoteItemMutation,
    useDeleteDeliveryNoteItemMutation,
} from '../../../Redux/apiSlice';
import Spinner from '../../../components/shared/Spinner';
import DeliveryNoteItemModal from './DeliveryNoteItemModal';

import { toast } from 'react-toastify';
import DeletePopupModal from '../general/DeletePopupModal';
import util from '../../../util';

const DeliveryNoteItems = ({ deliveryNoteId }) => {
    const { _id: companyId } = useSelector((state) => state.company);

    const { data: items, isLoading: itemsLoading } = useGetFuelDeliveryNoteItemsQuery(companyId);
    const {
        data: deliveryNoteItems,
        isLoading: deliveryNoteItemsLoading,
        refetch: refetchDeliveryNoteItems,
    } = useGetDeliveryNoteItemsQuery(deliveryNoteId);

    const [newItem, setNewItem] = useState({
        delivery_note: deliveryNoteId,
        item: '',
        cost_price: 0,
        unit_price: 0,
        quantity: 0,
        terminal: '',
        meter_readings_before:0,
        meter_readings_after:0,
    });

    const [createDeliveryNoteItem, createDeliveryNoteItemResult] = useCreateDeliveryNoteItemMutation();
    const [updateDeliveryNoteItem, updateDeliveryNoteItemResult] = useUpdateDeliveryNoteItemMutation();
    const [deleteDeliveryNoteItem, deleteDeliveryNoteItemResult] = useDeleteDeliveryNoteItemMutation();

    const [deliveryNoteItemsArray, setDeliveryNoteItemsArray] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalDeliveryNoteItem, setModalDeliveryNoteItem] = useState({
        delivery_note: deliveryNoteId,
        item: '',
        cost_price: 0,
        unit_price: 0,
        quantity: 0,
        terminal: '',
        meter_readings_before:0,
        meter_readings_after:0,
    });

    // Delete Popup Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //handle show delete modal
    const handleShowDeleteModal = (item) => {
        setShowDeleteModal(true);
        setModalDeliveryNoteItem(item);
    };

    //handle close delete modal
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setModalDeliveryNoteItem({
            delivery_note: deliveryNoteId,
            item: '',
            cost_price: 0,
            unit_price: 0,
            quantity: 0,
            terminal: '',
            meter_readings_before:0,
            meter_readings_after:0,
        });
    };

    const handleNewItemChange = (e) => {
        const selectedItemId = e.target.value;
        const cost_price = items.find((item) => item.item === selectedItemId).cost_price;
        const unit_price = items.find((item) => item.item === selectedItemId).unit_price;
        const terminal = items.find((item) => item.item === selectedItemId).terminal;

        setNewItem((prevItem) => ({
            ...prevItem,
            item: selectedItemId,
            quantity: 1,
            cost_price,
            unit_price,
            terminal,
            meter_readings_before:0,
            meter_readings_after:0,
        }));
    };

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalDeliveryNoteItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    //handle update item
    const handleUpdateItem = () => {
        updateDeliveryNoteItem(modalDeliveryNoteItem);
        setShowModal(false);
        setModalDeliveryNoteItem({
            delivery_note: deliveryNoteId,
            item: '',
            cost_price: 0,
            unit_price: 0,
            quantity: 0,
            terminal: '',
            meter_readings_before:0,
            meter_readings_after:0,
        });
    };

    const handleDeleteItem = () => {
        deleteDeliveryNoteItem(modalDeliveryNoteItem._id);
        setShowDeleteModal(false);
        setModalDeliveryNoteItem({
            delivery_note: deliveryNoteId,
            item: '',
            cost_price: 0,
            quantity: 0,
            terminal: '',
            meter_readings_before:0,
            meter_readings_after:0,
        });
    };

    useEffect(() => {
        if (newItem.item) {
            console.log(newItem);
            console.log(deliveryNoteItems);
            const itemExists = deliveryNoteItems ? deliveryNoteItems.find((item) => item.item._id === newItem.item) : null;
            console.log(itemExists);
            if (itemExists) {
                const updatedItem = {
                    ...itemExists,
                    quantity: itemExists.quantity + 1,
                };
                updateDeliveryNoteItem(updatedItem);
            } else {
                createDeliveryNoteItem(newItem);
            }
            setNewItem({
                delivery_note: deliveryNoteId,
                item: '',
                cost_price: 0,
                unit_price: 0,
                quantity: 0,
                terminal: '',
                meter_readings_before:0,
                meter_readings_after:0,
            });
        }
    }, [newItem]);

    useEffect(() => {
        if (deliveryNoteItems) {
            setDeliveryNoteItemsArray(deliveryNoteItems);
        }
        if (createDeliveryNoteItemResult.data) {
            refetchDeliveryNoteItems();
        }
        if (createDeliveryNoteItemResult.isSuccess) {
            toast.success('Delivery Note Item Created Successfully');
        }
        if (deleteDeliveryNoteItemResult.data) {
            refetchDeliveryNoteItems();
        }
        if (deleteDeliveryNoteItemResult.isSuccess) {
            toast.success('Delivery Note Item Deleted Successfully');
        }
        if (updateDeliveryNoteItemResult.data) {
            refetchDeliveryNoteItems();
        }
        if (updateDeliveryNoteItemResult.isSuccess) {
            toast.success('Delivery Note Item Updated Successfully');
        }
    }, [
        deliveryNoteItems,
        deliveryNoteItemsLoading,
        createDeliveryNoteItemResult.isLoading,
        updateDeliveryNoteItemResult.isLoading,
        deleteDeliveryNoteItemResult.isLoading,
    ]);

    const getItemName = (itemId) => {
        const item = items.find((item) => item._id === itemId);
        return item ? item.name : '';
    };

    const openModal = (modalType, item) => {
        if (modalType === 'new') {
            setModalText('New Delivery Note Item');
            setModalDeliveryNoteItem({});
        } else if (modalType === 'edit' && item) {
            const getItemName = (itemId) => {
                const item = items.find((item) => item._id === itemId);
                return item ? item.name : '';
            };
            const modalText = `Edit ${getItemName(item.item)}`;
            setModalText(modalText);
            setModalDeliveryNoteItem(item);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalText('');
        setModalDeliveryNoteItem({
            delivery_note: deliveryNoteId,
            item: '',
            cost_price: 0,
            quantity: 0,
            terminal: '',
            meter_readings_before:0,
            meter_readings_after:0,
        });
    };

    if (
        itemsLoading ||
        deliveryNoteItemsLoading ||
        createDeliveryNoteItemResult.isLoading ||
        updateDeliveryNoteItemResult.isLoading ||
        deleteDeliveryNoteItemResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div>
            <Table hover>
                <thead>
                    <tr>
                        <th width='20%'>Item</th>
                        <th width='20%'>Meter Reading Before</th>
                        <th width='20%'>Volume</th>
                        <th width='20%'>Meter Reading Before</th>
                        <th width='20%'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {deliveryNoteItemsArray.map((item, index) => (
                        <tr key={index}>
                            <td>{item.item?.name}</td>
                            <td>{item.meter_readings_before}</td>
                            <td>{util.formatVolumeInLiters(item.quantity)}</td>
                            <td>{item.meter_readings_after}</td>
                            <td>
                                <Button
                                    variant='info'
                                    onClick={() => openModal('edit', item)}
                                    size='sm'
                                >
                                    Edit
                                </Button>{' '}
                                <Button
                                    variant='danger'
                                    onClick={() => handleShowDeleteModal(item)}
                                    size='sm'
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                        
                        
                    ))}
                    <tr>
                        <td>
                            <select name='item' value={newItem.item} onChange={handleNewItemChange}>
                                <option value='' disabled>
                                    Select Pump
                                </option>
                                {items &&
                                    items.map((item) => (
                                        <option key={item.item} value={item.item}>
                                            {`${item.terminal_no} (${item.item_name}) @ ${util.formatCurrency(item.unit_price)}`}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>

            <DeliveryNoteItemModal
                show={showModal}
                onHide={closeModal}
                modalText={modalText}
                handleChange={handleChange}
                handleUpdateDeliveryNoteItem={handleUpdateItem}
                deliveryNoteItem={modalDeliveryNoteItem}
            />

            <DeletePopupModal
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                onDelete={handleDeleteItem}
                recordName={"Delivery Note Item"}
            />
        </div>
    );
};

export default DeliveryNoteItems;
