/**
 * WorkshopProducts.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect } from 'react';
import Side_nav from '../../../shared_components/side_nav';
import Top_nav from '../../../shared_components/top_nav';
import Footer from '../../../shared_components/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import WorkshopProdutsAndServices from './WorkshopProdutsAndServices';
import Brands from './Brands';
import JobCardItemStatuses from './JobCardItemStatuses';
import JobCardItemTypes from './JobCardItemTypes/JobCardItemTypes';
import ItemCategories from '../material_management/item_categories/ItemCategories';
import Products from '../material_management/items/Products';

function WorkshopProducts() {
    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <div className='container'>
                        <div className='row'>
                            <Tabs>
                                <TabList>
                                    <Tab>Products & Services</Tab>
                                    <Tab>Product Categories</Tab>
                                    <Tab>Brands</Tab>
                                    <Tab>Job Card Item Statuses</Tab>
                                    <Tab>Job Card Item Types</Tab>
                                </TabList>
                                <TabPanel>
                                    <Products />
                                </TabPanel>
                                <TabPanel>
                                    <ItemCategories />
                                </TabPanel>
                                <TabPanel>
                                    <Brands />
                                </TabPanel>
                                <TabPanel>
                                    <JobCardItemStatuses />
                                </TabPanel>
                                <TabPanel>
                                    <JobCardItemTypes />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default WorkshopProducts;
