import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';

import {
    useGetCompanyItemsQuery,
    useCreateCompanyItemMutation,
    useUpdateCompanyItemMutation,
    useDeleteCompanyItemMutation,
} from '../../../Redux/apiSlice';
import WorkshopProductModal from './WorkshopProductModal';

function WorkshopProdutsAndServices() {
    const company = useSelector((state) => state.company);

    // get products
    const {
        data,
        error,
        isLoading,
        refetch: refechProducts,
    } = useGetCompanyItemsQuery(company._id);

    // create product
    const [createProduct, createProductResult] = useCreateCompanyItemMutation();

    // update product
    const [updateProduct, updateProductResult] = useUpdateCompanyItemMutation();

    // delete product
    const [deleteProduct, deleteProductResult] = useDeleteCompanyItemMutation();

    // product state
    const [product, setProduct] = React.useState({
        name: '',
        category: '',
        description: '',
        barcode: '',
        is_service: true,
        account_id: '',
        stock_type: '',
        cost_price: 0,
        unit_price: 0,
        quantity: 0,
        reorder_level: 0,
        recieving_quantity: 0,
        supplier: '',
        company: company._id,
    });

    // products array state
    const [products, setProductsArray] = React.useState([]);

    // modal text
    const [modalText, setModalText] = React.useState('New Product');

    // Product Modal state
    const [showProductModal, setShowProductModal] = React.useState(false);

    // handle show product modal
    const handleShowProductModal = () => {
        setShowProductModal(true);
        setModalText('New Product');
        // clear product state
        setProduct({
            name: '',
            category: '',
            description: '',
            barcode: '',
            is_service: true,
            account_id: '',
            stock_type: '',
            cost_price: 0,
            unit_price: 0,
            quantity: 0,
            reorder_level: 0,
            recieving_quantity: 0,
            supplier: '',
            company: company._id,
        });
    };

    // handle close product modal
    const handleCloseProductModal = () => {
        setShowProductModal(false);
    };

    // handle edit product
    const handleEditProduct = (product) => {
        setShowProductModal(true);
        setModalText('Edit Product');
        setProduct(product);
    };

    // search text
    const [searchText, setSearchText] = React.useState('');

    // handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    // handle is service change

    const handleIsServiceChange = (e) => {
        const { name, checked } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: checked,
        }));
    };

    // handle create product
    const handleCreateProduct = () => {
        //first remove all fields with empty string
        Object.keys(product).forEach((key) => product[key] === '' && delete product[key]);
        createProduct(product);
    };

    // handle update product
    const handleUpdateProduct = () => {
        Object.keys(product).forEach((key) => product[key] === '' && delete product[key]);
        updateProduct(product);
    };

    // handle delete product
    const handleDeleteProduct = () => {
        deleteProduct(product._id);
    };

    useEffect(() => {
        if (data) {
            setProductsArray(data);
        }

        if (createProductResult.data) {
            refechProducts();
            handleCloseProductModal();
        }

        if (updateProductResult.data) {
            refechProducts();
            handleCloseProductModal();
        }

        if (deleteProductResult.data) {
            refechProducts();
            handleCloseProductModal();
        }
    }, [
        data,
        createProductResult.isLoading,
        updateProductResult.isLoading,
        deleteProductResult.isLoading,
    ]);

    if (
        isLoading ||
        createProductResult.isLoading ||
        updateProductResult.isLoading ||
        deleteProductResult.isLoading
    ) {
        return <Spinner />;
    }
    return (
        <div className='card strpied-tabled-with-hover'>
            <CardHeader
                buttonText={'New Product'}
                handleSearch={handleSearch}
                handleShow={handleShowProductModal}
                headerTitile={'Products'}
                headerDescription={'Manage Products'}
                placeholder={'Search Products'}
                searchText={searchText}
            />

            <div className='card-body table-full-width table-responsive'>
                <table className='table table-hover table-striped'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products &&
                            products.map((product) => (
                                <tr key={product._id}>
                                    <td>{product.name}</td>
                                    <td>{product.category}</td>
                                    <td>{product.unit_price}</td>
                                    <td>{product.description}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditProduct(product)}
                                            text={'Edit'}
                                        />{' '}
                                        &nbsp;
                                        <OxButton
                                            action={() => handleDeleteProduct(product)}
                                            text={'Delete'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <WorkshopProductModal
                show={showProductModal}
                onHide={handleCloseProductModal}
                modalText={modalText}
                product={product}
                handleCreateProduct={handleCreateProduct}
                handleUpdateProduct={handleUpdateProduct}
                handleChange={handleChange}
                handleIsServiceChange={handleIsServiceChange}
            />
        </div>
    );
}

export default WorkshopProdutsAndServices;
