import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import InvoiceItems from './InvoiceItems';
import { useSelector } from 'react-redux';
import {
    useUpdateInvoiceMutation,
    useGetCompanyCustomersQuery,
    useCreateInvoiceManuallyMutation,
} from '../../../Redux/apiSlice';
import CustomerSearchDropDown from '../../../components/shared/CustomerSearchDropDown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

const InvoiceModal = ({ show, onHide, invoiceData }) => {
    //company
    const company = useSelector((state) => state.company);

    //check if invoice data has been passed
    if (!invoiceData._id) {
        invoiceData = {
            _id: '',
            customer: '',
            employee: '',
            invoice_number: '',
            invoice_date: '',
            due_date: '',
            sale_type: 'invoice',
            company: company._id,
        };
    }
    //get invoice items
    const [invoice, setInvoice] = useState(invoiceData);
    //create invoice
    const [createInvoice, createInvoiceResult] = useCreateInvoiceManuallyMutation();

    //update invoice
    const [updateInvoice, updateInvoiceResult] = useUpdateInvoiceMutation();

    //get customers
    const {
        data: customers,
        error: customersError,
        isLoading: customersLoading,
        refetch: refetchCustomers,
    } = useGetCompanyCustomersQuery(company._id);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInvoice({ ...invoice, [name]: value });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleCustomerSelection = (customer) => {
        console.log(customer);

        const id = customer.value;

        setInvoice({ 
            ...invoice, 
            customer: id,
        });
    };

    //handle create invoice
    const handleCreateInvoice = () => {
        //create invoice
        const newInvoice = {
            company: company._id,
            sale_type: 'invoice',
            customer: invoice.customer._id,
            invoice_date: invoice.invoice_date,
            due_date: invoice.due_date,
        };

        createInvoice(newInvoice);
    };

    //handle update invoice
    const handleUpdateInvoice = () => {
        //update invoice

        const updatedInvoice = {
            _id: invoice._id,
            company: company._id,
            sale_type: 'invoice',
            customer: invoice.customer,
            invoice_date: invoice.invoice_date,
            due_date: invoice.due_date,
        };

        updateInvoice(updatedInvoice);
    };

    useEffect(() => {
        if (createInvoiceResult.isSuccess) {
            toast.success('Invoice created successfully');
            setInvoice(createInvoiceResult.data);
        }

        if (updateInvoiceResult.isSuccess) {
            toast.success('Invoice updated successfully');
            setInvoice((prevInvoice) => ({
                ...prevInvoice,
                due_date: updateInvoiceResult.data.due_date,
                invoice_date: updateInvoiceResult.data.invoice_date,
            }));
        }
    }, [customersLoading, createInvoiceResult.isLoading, updateInvoiceResult.isLoading]);

    if (customersLoading || createInvoiceResult.isLoading || updateInvoiceResult.isLoading) {
        return <Spinner />
    }

    return (
        <Modal show={show} onHide={onHide} size='xl' fullscreen='md-down'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {invoice._id ? `EDIT INVOICE# ${invoice.invoice_number}` : `CREATE NEW INVOICE`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Invoice Number</Form.Label>
                                <Form.Control type='text' value={invoice.invoice_number} readOnly />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Customer</Form.Label> <br />
                                <CustomerSearchDropDown
                                    handleCustomerSelection={handleCustomerSelection}
                                />
                            </Form.Group>

                            {invoice.customer && (
                                <div>
                                    <p>
                                        <strong>
                                        <strong>
                                            {invoice.customer && invoice.customer?.business_name || invoice.customer.first_name}{' '}
                                            
                                        </strong>
                                        </strong>
                                    </p>
                                    <p className='mutted'>{invoice.customer.email}</p>
                                    <p>{invoice.customer.phone_number}</p>
                                </div>
                            )}
                        </div>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                            <Form.Group>
                                <Form.Label>Invoice Date</Form.Label>
                                <Form.Control
                                    type='date'
                                    name='invoice_date'
                                    value={formatDate(invoice.invoice_date)}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Due Date</Form.Label>
                                <Form.Control
                                    type='date'
                                    name='due_date'
                                    value={formatDate(invoice.due_date)}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    {/* Render other invoice fields here */}

                    {/* Embed the InvoiceItems component */}
                    {invoice._id.length > 0 && <InvoiceItems invoiceId={invoiceData._id} />}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                {invoiceData._id.length > 0 ? (
                    <Button variant='primary' onClick={handleUpdateInvoice}>
                        Update
                    </Button>
                ) : (
                    <Button variant='primary' onClick={handleCreateInvoice}>
                        Continue
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default InvoiceModal;
