/**
 * defaulters.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import { APIService, _GET, _POST } from '../api';

let instance;

export class UserService extends APIService {
    constructor() {
        const collection = 'users';
        super(collection, 'users');
    }

    /**
     * Checks if an username or email exists on the API
     * @param {string} value
     * @returns {Promise<object>}
     */

    async checkExists(value, type = 'username') {
        const url = `${this.url}?${type}=${value}`;
        try {
            const data = await _GET(url);
            return Promise.resolve(data);
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }
    async verifyOtp(email, otp) {
        const url = `/verify-otp`;

        try {
            const data = await _POST(url, { email, otp });
            console.log('Verified', data);
            return data;
        } catch (error) {
            console.warn(`${this._key} fetch one error`, error);
            return Promise.reject(error);
        }
    }

    static create() {
        if (!instance) {
            instance = new UserService();
        }
        return instance;
    }
}
