import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import OxButton from './OxButton';
import CustomerSearchDropDown from './CustomerSearchDropDown';
import SupplierSearchDropDown from './SupplierSearchDropDown';

const CardHeader = ({
    searchText,
    handleSearch,
    handleShow,
    headerTitle,
    headerDescription,
    placeholder,
    buttonText,
    handleDateFilterChange,
    statusFilters,
    statusFilterValue,
    handleStatusFilterChange,
    employeeFilters,
    employeeFilterLable,
    employeeFilterValue,
    handleEmployeeFilterChange,
    handleResetFilters,
    handleCustomerSelect,
    handleSupplierSelect,
}) => {
    const dateFilters = {
        options: [
            { value: 'today', label: 'Today' },
            { value: 'yesterday', label: 'Yesterday' },
            { value: 'this_month', label: 'This Month' },
            { value: 'this_year', label: 'This Year' },
            { value: 'last_month', label: 'Last Month' },
            { value: 'last_year', label: 'Last Year' },
            { value: 'all_time', label: 'All Time' },
            { value: 'custom', label: 'Custom' },
        ],
    };

    return (
        <Container className='card-header'>
            <Row>
                <Col>
                    <h4 className='card-title'>{headerTitle}</h4>
                    <p className='card-category'>{headerDescription}</p>
                </Col>
            </Row>

            <br />

            <Row>
                {handleSupplierSelect && (
                    <Col>
                        <Form.Group>
                            <Form.Label>Filter By Supplier</Form.Label> <br />
                           <SupplierSearchDropDown handleSupplierSelection={handleSupplierSelect} />
                        </Form.Group>
                    </Col>
                )}
                <Col>
                    {handleDateFilterChange && (
                        <>
                            <label className='form-control-label'>Filter By Date</label>
                            <Form.Control as='select' onChange={handleDateFilterChange}>
                                {dateFilters.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </>
                    )}
                </Col>
                <Col>
                    {statusFilters && (
                        <>
                            <label className='form-control-label'>Filter By Status</label>
                            <Form.Control
                                as='select'
                                onChange={handleStatusFilterChange}
                                value={statusFilterValue}>
                                <option value='All'>All</option>
                                {statusFilters.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </>
                    )}
                </Col>

                <Col>
                    {employeeFilters && (
                        <>
                            <label className='form-control-label'>{employeeFilterLable ? employeeFilterLable : "FILTER BY ASSIGNED TO"}</label>
                            <Form.Control
                                as='select'
                                onChange={handleEmployeeFilterChange}
                                value={employeeFilterValue}>
                                <option value='All'>All</option>
                                {employeeFilters.map((option) => (
                                    <option key={option._id} value={option._id}>
                                        {option.first_name} {option.last_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </>
                    )}
                </Col>
                {handleResetFilters && (
                    <Col>
                        <OxButton text='Reset Filters' action={handleResetFilters} />
                    </Col>
                )}
                {handleCustomerSelect && (
                    <Col>
                        <Form.Group>
                            <Form.Label>Filter By Customer</Form.Label> <br />
                            <CustomerSearchDropDown handleCustomerSelection={handleCustomerSelect} />
                        </Form.Group>
                    </Col>
                )}

                <Col>
                    <label className='form-control-label'>&nbsp;</label>
                    <br />
                    <OxButton text={buttonText} action={handleShow} />
                </Col>
            </Row>
            <hr />
        </Container>
    );
};

export default CardHeader;
