import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';
import { Dropdown } from 'react-bootstrap';

import {
    useGetTanksQuery,
    useCreateTankMutation,
    useUpdateTankMutation,
    useDeleteTankMutation,
} from '../../../Redux/apiSlice';
import TankModal from './TankModal';

function Tanks() {
    const company = useSelector((state) => state.company);

    // Get tanks
    const { data, error, isLoading, refetch: refetchTanks } = useGetTanksQuery(company._id);

    // Create tank
    const [createTank, createTankResult] = useCreateTankMutation();

    // Update tank
    const [updateTank, updateTankResult] = useUpdateTankMutation();

    // Delete tank
    const [deleteTank, deleteTankResult] = useDeleteTankMutation();

    // Tank state
    const [tank, setTank] = React.useState({
        name: '',
        tank_no: '',
        capacity: 0,
        volume: 0,
        item: '',
        company: company._id,
    });

    // Tanks array state
    const [tanks, setTanks] = React.useState([]);

    // Modal text
    const [modalText, setModalText] = React.useState('New Tank');

    // Tank Modal state
    const [showTankModal, setShowTankModal] = React.useState(false);

    // Handle show tank modal
    const handleShowTankModal = () => {
        setShowTankModal(true);
        setModalText('New Tank');
        setTank({
            name: '',
            tank_no: '',
            capacity: 0,
            volume: 0,
            item: '',
            company: company._id,
        });
    };

    // Handle close tank modal
    const handleCloseTankModal = () => {
        setShowTankModal(false);
    };

    // Handle edit tank
    const handleEditTank = (tank) => (e) => {
        e.preventDefault();
        setShowTankModal(true);
        setModalText('Edit Tank');

        setTank((tank) => ({
            ...tank,
            item: tank.item._id,
        }));
    };

    // Search text
    const [searchText, setSearchText] = React.useState('');

    // Handle search
    const handleSearch = (text) => {
        setSearchText(text);
    };

    // Handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTank((prevTank) => ({
            ...prevTank,
            [name]: value,
        }));
    };

    // Handle create tank
    const handleCreateTank = () => {
        createTank(tank);
    };

    // Handle update tank
    const handleUpdateTank = () => {
        updateTank(tank);
    };

    // Handle delete tank
    const handleDeleteTank = (tank) => (e) => {
        e.preventDefault();

        deleteTank(tank._id);
    };

    useEffect(() => {
        if (company._id) {
            refetchTanks();
        }
        if (data) {
            setTanks(data);
            console.log(data);
        }
        if (createTankResult.data) {
            refetchTanks();
            handleCloseTankModal();
        }

        if (updateTankResult.data) {
            refetchTanks();
            handleCloseTankModal();
        }

        if (deleteTankResult.data) {
            refetchTanks();
        }
    }, [data, createTankResult.isLoading, updateTankResult.isLoading, deleteTankResult.isLoading]);

    if (
        isLoading ||
        createTankResult.isLoading ||
        updateTankResult.isLoading ||
        deleteTankResult.isLoading
    ) {
        return <Spinner />;
    }
    return (
        <div className='card'>
            <CardHeader
                buttonText={'New Tank'}
                handleSearch={handleSearch}
                handleShow={handleShowTankModal}
                headerTitle={'TANKS'}
                headerDescription={'Manage Tanks'}
                placeholder={'Search Tanks'}
                searchText={searchText}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th width='20%'>Name</th>
                            <th width='20%'>Tank No</th>
                            <th width='20%'>Capacity</th>
                            <th width='20%'>Volume</th>
                            <th width='20%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tanks &&
                            tanks.map((tank) => (
                                <tr key={tank._id}>
                                    <td>{tank.name}</td>
                                    <td>{tank.tank_no}</td>
                                    <td>{tank.capacity}</td>
                                    <td>{tank.volume}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleEditTank(tank)}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={handleDeleteTank(tank)}>
                                                    Delete
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => console.log(e)}>
                                                    Readings
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <TankModal
                show={showTankModal}
                onHide={handleCloseTankModal}
                modalText={modalText}
                tank={tank}
                handleCreateTank={handleCreateTank}
                handleUpdateTank={handleUpdateTank}
                handleChange={handleChange}
            />
        </div>
    );
}

export default Tanks;
