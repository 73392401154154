import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../../../components/shared/Spinner';
import { strings } from '../../../strings/strings';
import {
    useGetPurchaseOrdersQuery,
    useCreatePurchaseOrderMutation,
    useUpdatePurchaseOrderMutation,
    useDeletePurchaseOrderMutation,
    useReceivePurchaseOrderMutation,
    useCancelPurchaseOrderMutation,
} from '../../../Redux/apiSlice';
import CardHeader from '../../../components/shared/CardHeader';
import CustomPagination from '../../../components/shared/OxygenPagination';
import PurchaseOrderModal from './PurchaseOrderModal';
import ReceivePOModal from '../general/ReceivePOModal';
import CancelPOModal from '../general/CancelPOModal';

function PurchaseOrders() {
    const getStatusBadge = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-secondary';
            case 'pending':
                return 'bg-info';
            case 'pendings':
                return 'bg-warning';
            case 'partial':
                return 'bg-primary';
            case 'recieved':
                return 'bg-success';
            case 'rejected':
                return 'bg-warning';
            default:
                return 'bg-secondary';
        }
    };
    const { _id: companyId } = useSelector((state) => state.company);
    const { _id: employeeId } = useSelector((state) => state.employee);

    const {
        data: purchaseOrders,
        isLoading,
        refetch: refetchPurchaseOrders,
    } = useGetPurchaseOrdersQuery(companyId);

    const [createPurchaseOrder, createPurchaseOrderResult] = useCreatePurchaseOrderMutation();
    const [updatePurchaseOrder, updatePurchaseOrderResult] = useUpdatePurchaseOrderMutation();
    const [deletePurchaseOrder, deletePurchaseOrderResult] = useDeletePurchaseOrderMutation();
    const [receivePurchaseOrder, receivePurchaseOrderResult] = useReceivePurchaseOrderMutation();
    const [cancelPurchaseOrder, cancelPurchaseOrderResult] = useCancelPurchaseOrderMutation();

    const [showModal, setShowModal] = useState(false);

    //set recieve po modal

    const [showReceiveModal, setShowReceiveModal] = useState(false);

    //set cancel po modal

    const [showCancelModal, setShowCancelModal] = useState(false);

    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Perform any other logic or data fetching based on the new page
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        // Perform any other logic or data fetching based on the new page size
    };

    const pageSizes = [25, 50, 100, 500];

    //handle show receive modal

    const handleReceiveModalClose = () => {
        setSelectedPurchaseOrder({});
        setShowReceiveModal(false);
    };

    const handleReceiveModalShow = (po) => (e) => {
        e.preventDefault();

        setSelectedPurchaseOrder(po);

        setShowReceiveModal(true);
    };

    //handle show cancel modal

    const handleCancelModalClose = () => {
        setSelectedPurchaseOrder({});
        setShowCancelModal(false);
    };

    const handleCancelModalShow = (po) => (e) => {
        e.preventDefault();

        setSelectedPurchaseOrder(po);

        setShowCancelModal(true);
    };

    //handle receive po

    const handleReceivePurchaseOrder = () => {
        receivePurchaseOrder({
            purchase_order: selectedPurchaseOrder._id,
            employee: employeeId,
        });
    };

    //handle cancel po

    const handleCancelPurchaseOrder = () => {
        cancelPurchaseOrder({
            purchase_order: selectedPurchaseOrder._id,
        });
    };

    const handlePurchaseOrderModalClose = () => {
        setSelectedPurchaseOrder({});
        setShowModal(false);
    };

    const handlePurchaseOrderModalShow = () => {
        setSelectedPurchaseOrder({});
        setShowModal(true);
    };

    const handleEditPurchaseOrder = (purchaseOrder) => (e) => {
        e.preventDefault();
        setSelectedPurchaseOrder({
            ...purchaseOrder,
            supplier: purchaseOrder.supplier?._id,
            employee: purchaseOrder.employee?._id,
        });
        setShowModal(true);
    };

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSupplierSelection = (supplier) => {
        setSelectedSupplier(supplier);
    };

    const handleFilterBySupplier = () => {
        if (selectedSupplier) {
            setFilteredPurchaseOrders(
                purchaseOrders.filter((po) => po.supplier?._id === selectedSupplier.value)
            );
        } else {
            setFilteredPurchaseOrders(purchaseOrders);
        }
    };

    const handleFilterBySearchQuery = () => {
        if (searchQuery) {
            const filteredOrders = purchaseOrders.filter(
                (po) =>
                    po.order_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    po.supplier?.business_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    po.created_by?.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    po.created_at.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredPurchaseOrders(filteredOrders);
        } else {
            setFilteredPurchaseOrders(purchaseOrders);
        }
    };

    const handleFilter = () => {};

    const handlePurchaseOrderModalHide = () => {
        refetchPurchaseOrders();
        setShowModal(false);
    };

    const handleCreateOrUpdatePurchaseOrder = (data) => {
        if (data._id) {
            // Update purchase order
            // TODO: Implement update logic
            console.log('Update purchase order:', data);
        } else {
            // Create purchase order
            createPurchaseOrder(data);
        }
    };

    const handleDeletePurchaseOrder = (purchaseOrder) => (e) => {
        e.preventDefault();
        deletePurchaseOrder(purchaseOrder._id);
        console.log('Delete purchase order:', purchaseOrder);
    };

    useEffect(() => {
        if (createPurchaseOrderResult.data) {
            toast.success('Purchase order created successfully');
            refetchPurchaseOrders();
            handlePurchaseOrderModalHide();
        }

        if (createPurchaseOrderResult.error) {
            toast.error('Something went wrong');
        }

        if (receivePurchaseOrderResult.data) {
            toast.success('Purchase order received successfully');
            refetchPurchaseOrders();
            handleReceiveModalClose();
        }

        if (receivePurchaseOrderResult.error) {
            toast.error('Something went wrong');
        }

        if (cancelPurchaseOrderResult.data) {
            toast.success('Purchase order cancelled successfully');
            refetchPurchaseOrders();
            handleCancelModalClose();
        }

        if (cancelPurchaseOrderResult.error) {
            toast.error('Something went wrong');
        }

        if (deletePurchaseOrderResult.data) {
            toast.success('Purchase order deleted successfully');
            refetchPurchaseOrders();
        }

        if (deletePurchaseOrderResult.error) {
            toast.error('Something went wrong');
        }

        if (purchaseOrders) {
            setFilteredPurchaseOrders(purchaseOrders);
        }

    }, [
        createPurchaseOrderResult.isLoading,
        receivePurchaseOrderResult.isLoading,
        cancelPurchaseOrderResult.isLoading,
        deletePurchaseOrderResult.isLoading,
    ]);

    if (
        isLoading ||
        createPurchaseOrderResult.isLoading ||
        receivePurchaseOrderResult.isLoading ||
        cancelPurchaseOrderResult.isLoading ||
        deletePurchaseOrderResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText='New Purchase Order'
                handleShow={handlePurchaseOrderModalShow}
                headerTitle='PURCHASE ORDERS'
                headerDescription='Manage Purchase Orders'
                searchText={searchQuery}
                handleSearch={handleSearchQueryChange}
                handleSupplierSelection={handleSupplierSelection}
            />

            <div className='card-body'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Order No.</th>
                            <th>Supplier</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseOrders &&
                            purchaseOrders.map((purchaseOrder) => (
                                <tr key={purchaseOrder._id}>
                                    <td>
                                        <span
                                            className={`badge ${getStatusBadge(
                                                purchaseOrder.status
                                            )} text-white`}>
                                            {purchaseOrder.status}
                                        </span>
                                    </td>
                                    <td>{purchaseOrder.order_no}</td>
                                    <td>{purchaseOrder.supplier?.business_name}</td>
                                    <td>{purchaseOrder.employee?.first_name}</td>
                                    <td>
                                        <Moment format='YYYY-MM-DD'>
                                            {purchaseOrder.created_at}
                                        </Moment>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {/* {purchaseOrder.status === 'pending' && ( */}
                                                    <Dropdown.Item
                                                        onClick={handleEditPurchaseOrder(
                                                            purchaseOrder
                                                        )}>
                                                        View
                                                    </Dropdown.Item>
                                                {/* // )} */}
                                                {purchaseOrder.status === 'pending' && (
                                                    <Dropdown.Item
                                                        onClick={handleReceiveModalShow(
                                                            purchaseOrder
                                                        )}>
                                                        Recieve PO
                                                    </Dropdown.Item>
                                                )}
                                                {purchaseOrder.status === 'pending' && (
                                                    <Dropdown.Item
                                                        onClick={handleCancelModalShow(
                                                            purchaseOrder
                                                        )}>
                                                        Cancel PO
                                                    </Dropdown.Item>
                                                )}
                                                <Dropdown.Item onClick={handleDeletePurchaseOrder(purchaseOrder)} >Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>

            {showModal && (
                <PurchaseOrderModal
                    show={showModal}
                    onHide={handlePurchaseOrderModalHide}
                    purchaseOrderData={selectedPurchaseOrder}
                />
            )}

            {showReceiveModal && (
                <ReceivePOModal
                    show={showReceiveModal}
                    onHide={handleReceiveModalClose}
                    onReceive={handleReceivePurchaseOrder}
                    recordName={'Purchase Order'}
                />
            )}

            {showCancelModal && (
                <CancelPOModal
                    show={showCancelModal}
                    onHide={handleCancelModalClose}
                    onCancel={handleCancelPurchaseOrder}
                    recordName={'Purchase Order'}
                />
            )}
        </div>
    );
}

export default PurchaseOrders;
