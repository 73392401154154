import React, { useEffect } from 'react';
import Side_nav from '../shared_components/side_nav';
import Top_nav from '../shared_components/top_nav';
import Footer from '../shared_components/footer';
//import { companyActions } from '../Redux/companySlice';
//import { use}
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OxButton from '../components/shared/OxButton';

import { companyAction } from '../Redux/companySlice';
import { Modal, Button, Form, Row, Col, Image, InputGroup } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { toast } from 'react-toastify';

import {
    useUpdateCompanyMutation,
    useGetPropertyImagesQuery,
    useGetCurrenciesQuery,
    useGetBusinessAccountsByAccountTypeQuery,
    useGetAccountTypesQuery,
    useGetExpenseAccountsQuery,
    useGetAssetsAccountsQuery,
} from '../Redux/apiSlice';
import Spinner from '../components/shared/Spinner';
import { strings } from '../strings/strings';
import TaxRates from './industries/general/TaxRates';
import { ENV } from '../config';
const { oxygen_end_point } = ENV;

const baseUrl = `${oxygen_end_point}/stream/`;

function Company() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const companyState = useSelector((state) => state.company);

    //asset account type

    const [assetAccountType, setAssetAccountType] = React.useState('');

    //liability account type

    const [liabilityAccountType, setLiabilityAccountType] = React.useState('');

    //income account type

    const [incomeAccountType, setIncomeAccountType] = React.useState('');

    //expense account type

    const [expenseAccountType, setExpenseAccountType] = React.useState('');

    const [updateCompany, updateCompanyResult] = useUpdateCompanyMutation();

    const {
        data: propertyImages,
        error,
        isLoading,
        refetch: refetchPropertyImages,
    } = useGetPropertyImagesQuery(companyState._id);

    const {
        data: currencies,
        error: currenciesError,
        isLoading: currenciesLoading,
        refetch: refetchCurrencies,
    } = useGetCurrenciesQuery();

    const {
        data: accountTypes,
        error: accountTypesError,
        isLoading: accountTypesLoading,
        refetch: refetchAccountTypes,
    } = useGetAccountTypesQuery(companyState._id);

    //load asset accounts

    const {
        data: assetAccounts,
        error: assetAccountsError,
        isLoading: assetAccountsLoading,
        refetch: refetchAssetAccounts,
    } = useGetBusinessAccountsByAccountTypeQuery(assetAccountType, companyState._id);

    //load liability accounts

    const {
        data: liabilityAccounts,
        error: liabilityAccountsError,
        isLoading: liabilityAccountsLoading,
        refetch: refetchLiabilityAccounts,
    } = useGetBusinessAccountsByAccountTypeQuery(liabilityAccountType, companyState._id);

    //load income accounts

    const {
        data: incomeAccounts,
        error: incomeAccountsError,
        isLoading: incomeAccountsLoading,
        refetch: refetchIncomeAccounts,
    } = useGetBusinessAccountsByAccountTypeQuery(incomeAccountType, companyState._id);

    //load expense accounts

    const {
        data: expenseAccounts,
        error: expenseAccountsError,
        isLoading: expenseAccountsLoading,
        refetch: refetchExpenseAccounts,
    } = useGetExpenseAccountsQuery(companyState._id);

    //full image url

    const [imageUrl, setImageUrl] = React.useState('');

    //company state variables

    const [company, setCompany] = React.useState(companyState);

    const handleBusinessDetailsUpdate = (e) => {
        e.preventDefault();

        updateCompany(company);
    };

    const handleCompanyLogoUpload = (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('_id', companyState._id);

        formData.append('file', file);

        formData.append('size', `${file.size}`);

        fetch(`${oxygen_end_point}/upload/${companyState._id}`, {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //handle change

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setCompany({
            ...company,
            [name]: value,
        });
    };

    useEffect(() => {
        if (updateCompanyResult.isSuccess) {
            //alert success
            dispatch(companyAction.setCompany(updateCompanyResult.data));
            toast.success('Company details updated successfully');
        }

        if (updateCompanyResult.isError) {
            //alert error
            toast.error('Error updating company details');
        }

        if (propertyImages) {
            setImageUrl(baseUrl + propertyImages[propertyImages.length - 1].filename);
        }

        if (accountTypes) {
            console.log(accountTypes);
            //set asset account type
            setAssetAccountType(
                accountTypes.find((accountType) => accountType.name === 'Asset')._id
            );

            //set liability account type
            setLiabilityAccountType(
                accountTypes.find((accountType) => accountType.name === 'Liability')._id
            );

            //set income account type
            setIncomeAccountType(
                accountTypes.find((accountType) => accountType.name === 'Revenue')._id
            );

            //set expense account type
            setExpenseAccountType(
                accountTypes.find((accountType) => accountType.name === 'Expense')._id
            );

            //set asset account type
            refetchAssetAccounts();

            //set liability account type
            refetchLiabilityAccounts();

            //set income account type
            refetchIncomeAccounts();

            //set expense account type
            refetchExpenseAccounts();
        }
    }, [updateCompanyResult.isLoading, propertyImages, currenciesLoading, accountTypesLoading]);

    if (updateCompanyResult.isLoading) {
        return <Spinner />;
    }

    return (
        <div className='wrapper'>
            <Side_nav />
            <div className='main-panel'>
                {/* Navbar */}
                <Top_nav />
                {/* End Navbar */}
                <div className='content'>
                    <Tabs>
                        <TabList>
                            <Tab>Company Details</Tab>
                            <Tab>General</Tab>
                            <Tab>Sales & Invoicing</Tab>
                            <Tab>Tax</Tab>
                            <Tab>Bulk Sms</Tab>
                            <Tab>Developer</Tab>
                            <Tab></Tab>
                        </TabList>
                        <TabPanel>
                            <div className='col-md-6' style={{ margin: 'auto' }}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h4>{companyState.name}</h4>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <Form>
                                                    {imageUrl != '' && (
                                                        <Image
                                                            src={imageUrl}
                                                            rounded
                                                            style={{ width: 200, height: 200 }}
                                                        />
                                                    )}
                                                    <Form.Group controlId='formFile'>
                                                        <Form.Label>
                                                            Upload Comapny Logo:
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <Form.Control
                                                                type='file'
                                                                name='sampleFile'
                                                                onChange={handleCompanyLogoUpload}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Form>

                                                <form>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='business_name'
                                                            className='col-4 col-form-label'>
                                                            Business Name*
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                type='text'
                                                                id='business_name'
                                                                name='name'
                                                                placeholder='Business Name'
                                                                value={company.name}
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='reg_no'
                                                            className='col-4 col-form-label'>
                                                            Registration No.{' '}
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='reg_no'
                                                                name='registration_number'
                                                                placeholder='Business Registration Number'
                                                                value={company.registration_number}
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='vat'
                                                            className='col-4 col-form-label'>
                                                            VAT Registrartion No.
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='vat'
                                                                name='vat_number'
                                                                placeholder='VAT Number'
                                                                value={company.vat_number}
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='phone'
                                                            className='col-4 col-form-label'>
                                                            Phone Number
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='phone'
                                                                name='phone_number'
                                                                placeholder='Phone Number'
                                                                value={company.phone_number}
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='email'
                                                            className='col-4 col-form-label'>
                                                            Email
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='email'
                                                                name='email'
                                                                placeholder='Email'
                                                                value={company.email}
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                required='required'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='website'
                                                            className='col-4 col-form-label'>
                                                            Website
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='website'
                                                                name='website'
                                                                value={company.website}
                                                                onChange={handleChange}
                                                                placeholder='website'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='address'
                                                            className='col-4 col-form-label'>
                                                            Residential Address
                                                        </label>
                                                        <div className='col-8'>
                                                            <textarea
                                                                id='address'
                                                                name='address'
                                                                value={company.address}
                                                                onChange={handleChange}
                                                                cols={40}
                                                                rows={4}
                                                                className='form-control'
                                                                defaultValue={''}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='postal_address'
                                                            className='col-4 col-form-label'>
                                                            Postal Address
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='postal_address'
                                                                name='postal_address'
                                                                value={company.postal_address}
                                                                onChange={handleChange}
                                                                placeholder='Postal Address'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <div className='offset-4 col-8'>
                                                            <OxButton
                                                                action={handleBusinessDetailsUpdate}
                                                                text='Update Business Details'
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='col-md-6' style={{ margin: 'auto' }}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h4>General Settings</h4>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <form>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='select'
                                                            className='col-4 col-form-label'>
                                                            Fiscal Year Start
                                                        </label>
                                                        <div className='col-8'>
                                                            <select
                                                                id='select'
                                                                name='fisical_year_start'
                                                                value={company.fisical_year_start}
                                                                onChange={handleChange}
                                                                className='form-control'>
                                                                <option value='01 January'>
                                                                    01 January
                                                                </option>
                                                                <option value='01 February'>
                                                                    01 February
                                                                </option>
                                                                <option value='01 March'>
                                                                    01 March
                                                                </option>
                                                                <option value='01 April'>
                                                                    01 April
                                                                </option>
                                                                <option value='01 May'>
                                                                    01 May
                                                                </option>
                                                                <option value='01 June'>
                                                                    01 June
                                                                </option>
                                                                <option value='01 July'>
                                                                    01 July
                                                                </option>
                                                                <option value='01 August'>
                                                                    01 August
                                                                </option>
                                                                <option value='01 September'>
                                                                    01 September
                                                                </option>
                                                                <option value='01 October'>
                                                                    01 October
                                                                </option>
                                                                <option value='01 November'>
                                                                    01 November
                                                                </option>
                                                                <option value='01 December'>
                                                                    01 December
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='currency'
                                                            className='col-4 col-form-label'>
                                                            Currency
                                                        </label>
                                                        <div className='col-8'>
                                                            <select
                                                                id='currency'
                                                                name='currency'
                                                                value={company.currency}
                                                                onChange={handleChange}
                                                                className='form-control'>
                                                                <option value='' disabled>
                                                                    Select Currency
                                                                </option>
                                                                {currencies &&
                                                                    currencies.map(
                                                                        (currency, index) => (
                                                                            <option
                                                                                key={index}
                                                                                value={
                                                                                    currency._id
                                                                                }>
                                                                                {`${currency.code}-${currency.name} (${currency.symbol})`}
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='employee_number_prefix'
                                                            className='col-4 col-form-label'>
                                                            Employee No. Prifix
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='employee_number_prefix'
                                                                name='employee_number_prefix'
                                                                value={
                                                                    company.employee_number_prefix
                                                                }
                                                                onChange={handleChange}
                                                                placeholder='Employee Number Prefix'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='employee_number_counter'
                                                            className='col-4 col-form-label'>
                                                            Employee No. Counter
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='employee_number_counter'
                                                                name='employee_number_counter'
                                                                placeholder='Employee Number Counter'
                                                                value={
                                                                    company.employee_number_counter
                                                                }
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                required='required'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='supplier_number_prefix'
                                                            className='col-4 col-form-label'>
                                                            Supplier No. Prifix
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='supplier_number_prefix'
                                                                name='supplier_number_prefix'
                                                                value={
                                                                    company.supplier_number_prefix
                                                                }
                                                                onChange={handleChange}
                                                                placeholder='Supplier Number Prefix'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='supplier_number_counter'
                                                            className='col-4 col-form-label'>
                                                            Supplier No. Counter
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='supplier_number_counter'
                                                                name='supplier_number_counter'
                                                                placeholder='Supplier Number Counter'
                                                                value={
                                                                    company.supplier_number_counter
                                                                }
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                required='required'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='job_card_number_prefix'
                                                            className='col-4 col-form-label'>
                                                            Job Card No. Prifix
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='job_card_number_prefix'
                                                                name='job_card_number_prefix'
                                                                value={
                                                                    company.job_card_number_prefix
                                                                }
                                                                onChange={handleChange}
                                                                placeholder='Job Card Number Prefix'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='job_card_number_counter'
                                                            className='col-4 col-form-label'>
                                                            Job Card No. Counter
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='job_card_number_counter'
                                                                name='job_card_number_counter'
                                                                placeholder='Job Card Number Counter'
                                                                value={
                                                                    company.job_card_number_counter
                                                                }
                                                                onChange={handleChange}
                                                                className='form-control here'
                                                                required='required'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <div className='offset-4 col-8'>
                                                            <OxButton
                                                                action={handleBusinessDetailsUpdate}
                                                                text='Update'
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='col-md-6' style={{ margin: 'auto' }}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h4>Sales & Distribution</h4>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <form>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='invoice_number_prefix'
                                                            className='col-4 col-form-label'>
                                                            Invoice No. Prefix
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='invoice_number_prefix'
                                                                name='invoice_number_prefix'
                                                                value={
                                                                    company.invoice_number_prefix
                                                                }
                                                                onChange={handleChange}
                                                                placeholder='Invoice Number Prefix'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='invoice_number_counter'
                                                            className='col-4 col-form-label'>
                                                            Last Used Invoice No.
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='invoice_number_counter'
                                                                name='invoice_number_counter'
                                                                value={
                                                                    company.invoice_number_counter
                                                                }
                                                                onChange={handleChange}
                                                                placeholder='Invoice Number Counter'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='default_invoice_comment'
                                                            className='col-4 col-form-label'>
                                                            Invoice Comment/Notes
                                                        </label>
                                                        <div className='col-8'>
                                                            <textarea
                                                                rows='7'
                                                                id='default_invoice_comment'
                                                                name='default_invoice_comment'
                                                                value={
                                                                    company.default_invoice_comment
                                                                }
                                                                onChange={handleChange}
                                                                cols={40}
                                                                className='form-control'
                                                                defaultValue={''}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/** Quotation Number and Prefix */}

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='quote_number_prefix'
                                                            className='col-4 col-form-label'>
                                                            Quote No. Prefix
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='quote_number_prefix'
                                                                name='quote_number_prefix'
                                                                value={company.quote_number_prefix}
                                                                onChange={handleChange}
                                                                placeholder='Quotation Number Prefix'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='quote_number_counter'
                                                            className='col-4 col-form-label'>
                                                            Last used Quote No.
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='quote_number_counter'
                                                                name='quote_number_counter'
                                                                value={company.quote_number_counter}
                                                                onChange={handleChange}
                                                                placeholder='Quotation Number Counter'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <h5>Bank Details</h5>
                                                    <hr />
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bank_name'
                                                            className='col-4 col-form-label'>
                                                            Bank
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='bank_name'
                                                                name='bank_name'
                                                                value={company.bank_name}
                                                                onChange={handleChange}
                                                                placeholder='Bank Name'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bank_account_holder'
                                                            className='col-4 col-form-label'>
                                                            Account Name
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='bank_account_holder'
                                                                name='bank_account_holder'
                                                                value={company.bank_account_holder}
                                                                onChange={handleChange}
                                                                placeholder='Account Name'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bank_account_number'
                                                            className='col-4 col-form-label'>
                                                            Account #
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='bank_account_number'
                                                                name='bank_account_number'
                                                                value={company.bank_account_number}
                                                                onChange={handleChange}
                                                                placeholder='Account Number'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bank_account_type'
                                                            className='col-4 col-form-label'>
                                                            Account Type
                                                        </label>
                                                        <div className='col-8'>
                                                            <select
                                                                id='bank_account_type'
                                                                name='bank_account_type'
                                                                value={company.bank_account_type}
                                                                onChange={handleChange}
                                                                className='form-control'>
                                                                <option value='cheque'>
                                                                    Cheque
                                                                </option>
                                                                <option value='savings'>
                                                                    Saving
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bank_branch'
                                                            className='col-4 col-form-label'>
                                                            Bank Branch
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='bank_branch'
                                                                name='bank_branch'
                                                                value={company.bank_branch}
                                                                onChange={handleChange}
                                                                placeholder='Bank Branch'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='branch_code'
                                                            className='col-4 col-form-label'>
                                                            Branch Code
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='branch_code'
                                                                name='branch_code'
                                                                value={company.branch_code}
                                                                onChange={handleChange}
                                                                placeholder='Branch Code'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <div className='offset-4 col-8'>
                                                            <OxButton
                                                                action={handleBusinessDetailsUpdate}
                                                                text='Update'
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <TaxRates />
                        </TabPanel>

                        <TabPanel>
                            <div className='col-md-6' style={{ margin: 'auto' }}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h4>Bulk SMS</h4>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <form>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bulk_sms_username'
                                                            className='col-4 col-form-label'>
                                                            Username
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='bulk_sms_username'
                                                                name='bulk_sms_username'
                                                                value={company.bulk_sms_username}
                                                                onChange={handleChange}
                                                                placeholder='Username'
                                                                className='form-control here'
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='bulk_sms_password'
                                                            className='col-4 col-form-label'>
                                                            Password
                                                        </label>
                                                        <div className='col-8'>
                                                            <input
                                                                id='bulk_sms_password'
                                                                name='bulk_sms_password'
                                                                value={company.bulk_sms_password}
                                                                onChange={handleChange}
                                                                placeholder='Password'
                                                                className='form-control here'
                                                                type='password'
                                                            />
                                                        </div>
                                                    </div>

                                                    {/** Quotation Number and Prefix */}

                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='job_card_done_sms_template'
                                                            className='col-4 col-form-label'>
                                                            Job Card Done SMS Template
                                                        </label>
                                                        <div className='col-8'>
                                                            <textarea
                                                                rows='7'
                                                                id='job_card_done_sms_template'
                                                                name='job_card_done_sms_template'
                                                                value={
                                                                    company.job_card_done_sms_template
                                                                }
                                                                onChange={handleChange}
                                                                cols={40}
                                                                className='form-control'
                                                                defaultValue={''}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label
                                                            htmlFor='new_invoice_sms_template'
                                                            className='col-4 col-form-label'>
                                                            Invice Issued SMS Template
                                                        </label>
                                                        <div className='col-8'>
                                                            <textarea
                                                                rows='7'
                                                                id='new_invoice_sms_template'
                                                                name='new_invoice_sms_template'
                                                                value={
                                                                    company.new_invoice_sms_template
                                                                }
                                                                onChange={handleChange}
                                                                cols={40}
                                                                className='form-control'
                                                                defaultValue={''}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='form-group row'>
                                                        <div className='offset-4 col-8'>
                                                            <OxButton
                                                                action={handleBusinessDetailsUpdate}
                                                                text='Update'
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>Company ID: {company._id}</TabPanel>
                    </Tabs>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Company;
