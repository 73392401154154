const formatDate = (date, includeEndTime = false) => {
    const year = date.getFullYear();
    let month = `${date.getMonth() + 1}`,
        day = `${date.getDate()}`,
        hours = '00',
        minutes = '00';

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    if (includeEndTime) {
        hours = '23';
        minutes = '59';
    }

    return `${[year, month, day].join('-')}T${hours}:${minutes}`;
};

export default {
    formatDate: (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    },
    formatCurrency: (number) => {
        // Convert number to a string and remove any commas
        const numString = String(number).replace(/,/g, '');

        // Check if the number is valid
        if (isNaN(numString)) {
            return 'Invalid number';
        }

        // Parse the number as a float with 2 decimal places
        const parsedNumber = parseFloat(numString).toFixed(2);

        // Split the number into integer and decimal parts
        const [integerPart, decimalPart] = parsedNumber.split('.');

        // Format the integer part with commas
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Create the currency string with the formatted parts
        const currencyString = `N$${formattedIntegerPart}.${decimalPart}`;

        return currencyString;
    },

    formatVolumeInLiters:(volume_ml) => {
        if (!volume_ml) return "0 liters";
        
       return volume_ml.toFixed(2) + " Liters";
        
    },
    //export a function that return a 6 digits employee number given a prefix and counter
    generateEntityNumber: (prefix, counter) => {
        return prefix + counter.toString().padStart(6, '0');
    },

    numberWithCommas: (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    buildQueryParams: (
        filterOption,
        assignedTo = null,
        status = null,
        queryType = 'string',
        customStartDate = null,
        customEndDate = null
    ) => {
        const currentDate = new Date();
        let startDate;
        let endDate;
        let dateQuery = '';

        switch (filterOption) {
            case 'today':
                startDate = new Date();
                endDate = new Date();
                break;
            case 'yesterday':
                startDate = new Date();
                startDate.setDate(startDate.getDate() - 1);
                endDate = new Date();
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'this_month':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                break;
            case 'this_year':
                startDate = new Date(currentDate.getFullYear(), 0, 1);
                break;
            case 'last_month':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                break;
            case 'last_year':
                startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
                endDate = new Date(currentDate.getFullYear(), 0, 1);
                break;
            case 'all_time':
                break;
            case 'custom':
                if (customStartDate && customEndDate) {
                    startDate = new Date(customStartDate);
                    endDate = new Date(customEndDate);
                }
                break;
            default:
        }

        if (startDate) {
            dateQuery += `&&created_at__gte=${formatDate(startDate)}`;
        }
        if (endDate) {
            dateQuery += `&&created_at__lte=${formatDate(endDate, true)}`;
        }

        const assignedToQuery =
            assignedTo && assignedTo !== 'All' ? `&&assigned_to=${assignedTo}` : '';
        const statusQuery = status && status !== 'All' ? `&&status=${status}` : '';

        // Combine the queries
        const combinedQuery = `${dateQuery}${assignedToQuery}${statusQuery}`;

        if (queryType === 'object') {
            const queryObject = {};

            queryObject['created_at'] = {};

            if (startDate) {
                queryObject['created_at']['$gte'] = formatDate(startDate);
            }
            if (endDate) {
                queryObject['created_at']['$lte'] = formatDate(endDate, true);
            }
            if (assignedToQuery) {
                queryObject['assigned_to'] = assignedTo;
            }
            if (statusQuery) {
                queryObject['invoice_status'] = status;
            }

            return queryObject;
        }

        console.log(combinedQuery);

        // Return the combined query
        return combinedQuery;
    },
};
