/**
 * EmailConfirmation.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

// EmailConfirmation.jsx
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { OxNotify } from '@shared';
import { UserService } from '@services';

const userService = UserService.create();

const validationSchema = yup.object({
    otp: yup.string().required('OTP is required').length(4, 'OTP should be 4 digits long'),
});

function EmailConfirmation({ onOTPVerified, email }) {
    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            const { otp } = values;
            // Here, verify the OTP with the backend.
            // If it's correct, call onOTPVerified, otherwise display an error.
            try {
                // Assuming you have an API endpoint to verify the OTP.
                const response = await userService.verifyOtp(email, values.otp);
                const { data, message } = response;
                console.log('response', response);
                OxNotify({
                    title: 'Success!',
                    content: message,
                    type: 'success',
                });

                // if (data.verified) {
                onOTPVerified();
                // } else {
                //     setServerError('Invalid OTP. Please try again.');
                // }
            } catch (error) {
                console.log('Error: ', error);
                OxNotify({
                    title: 'Error!',
                    content: error.message,
                    type: 'error',
                });
            }
        },
    });

    return (
        <Container>
            <Row
                className='justify-content-center align-items-center'
                style={{ marginTop: '70px' }}>
                <Col md={8}>
                    <h4>Enter the OTP sent to your email</h4>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className='mb-3'>
                            <Form.Label>OTP</Form.Label>
                            <Form.Control
                                type='text'
                                id='otp'
                                maxLength={6}
                                {...formik.getFieldProps('otp')}
                            />
                            {formik.touched.otp && formik.errors.otp ? (
                                <div className='text-danger'>{formik.errors.otp}</div>
                            ) : null}
                        </Form.Group>

                        <Button disabled={!formik.isValid} type='submit'>
                            Verify
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default EmailConfirmation;
