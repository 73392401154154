/**
 * CreateContract.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Button, Container, Row, Col } from 'react-bootstrap';
import { filter } from 'rxjs/operators';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { SessionService, ContractsService, PropertiesService } from '@services';
import { OxNotify } from '@shared';
import { LOCAL_STORAGE_ENUMS } from '@enums';

const { PROPERTIES, TENANTS } = LOCAL_STORAGE_ENUMS;

// service instances
const contractsService = ContractsService.create();
const propertiesService = PropertiesService.create();
const session = SessionService.session;

//subscriptions
let localDataSubscription = session;
let initialSub = session;

const displayError = (touched, errors, fieldName) => {
    if (touched[fieldName] && errors[fieldName]) {
        return <div className='text-danger'>{errors[fieldName]}</div>;
    }
    return null;
};

export const CreateContract = ({ customer, handleCloseModal }) => {
    const userState = useSelector((state) => state.user);
    const customer_id = customer?._id;

    const [properties, setProperties] = useState(undefined);
    const [tenants, setTenants] = useState(undefined);

    useEffect(() => {
        listenToSession();
        getMyProperties(customer.company);
        return function cleanup() {
            // eslint-disable-next-line no-unused-expressions
            localDataSubscription && localDataSubscription.unsubscribe();
            // eslint-disable-next-line no-unused-expressions
            initialSub && initialSub.unsubscribe();
        };
    }, []);

    const initialValues = {
        tenant: customer?._id,
        property: '',
        placement_date: new Date(),
        placement_start_date: '',
        placement_end_date: '',
        agent: userState.user._id,
        company: customer?.company,
        agreed_rent: '',
        payment_day: '',
        rental_deposit: '',
        created_by: userState.user._id,
    };

    const validationSchema = Yup.object({
        tenant: Yup.string().required('Tenant is required'),
        property: Yup.string().required('Property is required'),
        placement_date: Yup.date(),
        placement_start_date: Yup.date().required('Placement Start Date is required'),
        placement_end_date: Yup.date().required('Placement End Date is required'),
        agent: Yup.string().required('Agent is required'),
        company: Yup.string().required('Company is required'),
        agreed_rent: Yup.number().required('Agreed Rent is required'),
        payment_day: Yup.number().required('Payment Day is required').min(1).max(31),
        rental_deposit: Yup.number().required('Rental Deposit is required'),
        created_by: Yup.string().required('Created By is required'),
    });

    const prepareData = (values) => {
        if (values.data) {
            // eslint-disable-next-line prefer-const
            let { key, data } = values;
            if (key === PROPERTIES) {
                setProperties(data);
            }
            if (key === TENANTS) {
                setTenants(data);
            }
        }
    };

    const listenToSession = () => {
        const concerns = [PROPERTIES, TENANTS];
        localDataSubscription = session
            .pipe(filter((data) => concerns.includes(data.key)))
            .subscribe(prepareData, (error) => {
                console.error('Listen Error: ', error);
            });
    };

    const getMyProperties = () => {
        initialSub = propertiesService
            .fetchAllCompany(customer?.company)
            .subscribe(prepareData, (error) => {
                console.log('Dependencies Get Error: ', error);
            });
    };

    const onSubmit = (values) => {
        contractsService.create(values).subscribe(
            (response) => {
                console.log('response: ', response);
                OxNotify({
                    title: 'Success',
                    content: 'Contract Created Successfully',
                    type: 'success',
                    duration: 10,
                });
                handleCloseModal();
            },
            (error) => {
                console.log('Dependencies Get Error: ', error);
                OxNotify({
                    title: 'Error Creating Contract',
                    content: error.message,
                    type: 'error',
                    duration: 10,
                });
            }
        );
    };

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                        <Row>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Tenant</BootstrapForm.Label>
                                    <Field
                                        as='select'
                                        name='tenant'
                                        className='form-control'
                                        onChange={(event) => {
                                            console.log('event: ', event);
                                            setFieldValue('tenant', event.target.value);
                                        }}
                                        disabled={!!customer_id}>
                                        {customer_id ? (
                                            <option value={customer_id}>
                                                {customer.first_name} {customer.last_name}
                                            </option>
                                        ) : (
                                            <>
                                                <option value=''>Select a tenant</option>
                                                {tenants.map((tenant) => (
                                                    <option key={tenant._id} value={tenant._id}>
                                                        {tenant.first_name} {tenant.last_name}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </Field>
                                    {displayError(touched, errors, 'tenant')}
                                </BootstrapForm.Group>
                            </Col>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Property</BootstrapForm.Label>
                                    <Field as='select' name='property' className='form-control'>
                                        <option value=''>Select a property</option>
                                        {properties &&
                                            properties.map((property) => (
                                                <option key={property._id} value={property._id}>
                                                    {property.name}
                                                </option>
                                            ))}
                                    </Field>
                                    {displayError(touched, errors, 'property')}
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Placement End Date</BootstrapForm.Label>
                                    <Field
                                        name='placement_end_date'
                                        type='date'
                                        className='form-control'
                                    />
                                    {displayError(touched, errors, 'placement_end_date')}
                                </BootstrapForm.Group>
                            </Col>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Placement Start Date</BootstrapForm.Label>
                                    <Field
                                        name='placement_start_date'
                                        type='date'
                                        className='form-control'
                                    />
                                    {displayError(touched, errors, 'placement_start_date')}
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Company</BootstrapForm.Label>
                                    <Field name='company' disabled className='form-control' />
                                    {displayError(touched, errors, 'company')}
                                </BootstrapForm.Group>
                            </Col>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Agreed Rent</BootstrapForm.Label>
                                    <Field
                                        name='agreed_rent'
                                        type='number'
                                        className='form-control'
                                    />
                                    {displayError(touched, errors, 'agreed_rent')}
                                </BootstrapForm.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Payment Day</BootstrapForm.Label>
                                    <Field
                                        name='payment_day'
                                        type='number'
                                        className='form-control'
                                        min={1}
                                        max={31}
                                    />
                                    {displayError(touched, errors, 'payment_day')}
                                </BootstrapForm.Group>
                            </Col>
                            <Col>
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Rental Deposit</BootstrapForm.Label>
                                    <Field
                                        name='rental_deposit'
                                        type='number'
                                        className='form-control'
                                    />
                                    {displayError(touched, errors, 'rental_deposit')}
                                </BootstrapForm.Group>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col>
                                <Button variant='primary' type='submit'>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};
