import React, { useEffect } from 'react';
import PaymentMethodModal from './PaymentMethodModal';
import {
    useGetPaymentMethodsQuery,
    useCreatePaymentMethodMutation,
    useUpdatePaymentMethodMutation,
    useDeletePaymentMethodMutation,
} from '../../../Redux/apiSlice';
import { useSelector } from 'react-redux';
import CardHeader from '../../../components/shared/CardHeader';
import OxButton from '../../../components/shared/OxButton';
import Spinner from '../../../components/shared/Spinner';
import { toast } from 'react-toastify';

function PaymentMethods() {
    const company = useSelector((state) => state.company);

    const {
        data,
        error,
        isLoading,
        refetch: refetchPaymentMethods,
    } = useGetPaymentMethodsQuery(company._id);

    const [createPaymentMethod, createPaymentMethodResult] = useCreatePaymentMethodMutation();
    const [updatePaymentMethod, updatePaymentMethodResult] = useUpdatePaymentMethodMutation();
    const [deletePaymentMethod, deletePaymentMethodResult] = useDeletePaymentMethodMutation();

    const [paymentMethod, setPaymentMethod] = React.useState({
        name: '',
        description: '',
        account: '',
        company: company._id,
    });

    const [paymentMethods, setPaymentMethodsArray] = React.useState([]);
    const [modalText, setModalText] = React.useState('New Payment Method');
    const [showPaymentMethodModal, setShowPaymentMethodModal] = React.useState(false);

    const handleShowPaymentMethodModal = () => {
        setShowPaymentMethodModal(true);
        setModalText('New Payment Method');
        setPaymentMethod({
            name: '',
            description: '',
            account: '',
            company: company._id,
        });
    };

    const handleClosePaymentMethodModal = () => {
        setShowPaymentMethodModal(false);
    };

    const handleEditPaymentMethod = (paymentMethod) => {
        setShowPaymentMethodModal(true);
        setModalText('Edit Payment Method');
        setPaymentMethod(paymentMethod);
    };

    const handleSearch = (text) => {
        // Handle search functionality
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPaymentMethod((prevPaymentMethod) => ({
            ...prevPaymentMethod,
            [name]: value,
        }));
    };

    const handleCreatePaymentMethod = () => {
        //remove empty fields
        Object.keys(paymentMethod).forEach(
            (key) => paymentMethod[key] === '' && delete paymentMethod[key]
        );
        createPaymentMethod(paymentMethod);
    };

    const handleUpdatePaymentMethod = () => {
        updatePaymentMethod(paymentMethod);
    };

    const handleDeletePaymentMethod = () => {
        deletePaymentMethod(paymentMethod._id);
    };

    useEffect(() => {
        if (data) {
            setPaymentMethodsArray(data);
        }

        if (createPaymentMethodResult.isSuccess) {
            toast.success('Payment method created successfully');
            refetchPaymentMethods();
            handleClosePaymentMethodModal();
        }

        if (updatePaymentMethodResult.isError) {
            toast.error('Error updating payment method');
        }

        if (updatePaymentMethodResult.isSuccess) {
            toast.success('Payment method updated successfully');
            refetchPaymentMethods();
            handleClosePaymentMethodModal();
        }

        if (deletePaymentMethodResult.isSuccess) {
            toast.success('Payment method deleted successfully');
            refetchPaymentMethods();
            handleClosePaymentMethodModal();
        }
    }, [
        data,
        createPaymentMethodResult.isLoading,
        updatePaymentMethodResult.isLoading,
        deletePaymentMethodResult.isLoading,
    ]);

    if (
        isLoading ||
        createPaymentMethodResult.isLoading ||
        updatePaymentMethodResult.isLoading ||
        deletePaymentMethodResult.isLoading
    ) {
        return <Spinner />;
    }

    return (
        <div className='card'>
            <CardHeader
                buttonText={'New Payment Method'}
                handleSearch={handleSearch}
                handleShow={handleShowPaymentMethodModal}
                headerTitle={'Payment Methods'}
                headerDescription={'Manage Payment Methods'}
                placeholder={'Search'}
                searchText={''}
            />

            <div className='card-body'>
                <table className='table' style={{ width: '100%' }}>
                    <thead style={{ width: '100%' }}>
                        <tr>
                            <th width='40%'>Name</th>
                            <th width='40%'>Description</th>
                            <th width='20%'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentMethods &&
                            paymentMethods.map((paymentMethod) => (
                                <tr key={paymentMethod._id}>
                                    <td>{paymentMethod.name}</td>
                                    <td>{paymentMethod.description}</td>
                                    <td>
                                        <OxButton
                                            action={() => handleEditPaymentMethod(paymentMethod)}
                                            text={'Edit'}
                                        />{' '}
                                        &nbsp;
                                        <OxButton
                                            action={() => handleDeletePaymentMethod(paymentMethod)}
                                            text={'Delete'}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <PaymentMethodModal
                show={showPaymentMethodModal}
                onHide={handleClosePaymentMethodModal}
                modalText={modalText}
                paymentMethod={paymentMethod}
                handleChange={handleChange}
                handleCreateMethod={handleCreatePaymentMethod}
                handleUpdateMethod={handleUpdatePaymentMethod}
            />
        </div>
    );
}

export default PaymentMethods;
